import styled from "styled-components";
import { NavLink } from 'react-router-dom';

export const TableScrollableDiv = styled.div`
   overflow-y: auto; // Enable vertical scroll
  width: 97.2%; // Set the desired width
  margin-left: auto; // Center the div
  margin-right: auto; // Center the div
  max-height: calc(85vh - 52px - 50px); // Adjust the height as needed
  border: 1px solid #e1e1e1;

  // Custom scrollbar styles
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 10px;
    &:hover {
      background: #425a77;
    }
  }
`;



export const StyledTable = styled.table`
  table-layout: fixed;
  width: 97.2%; // Set the desired width
  margin-left: auto; // Center the table
  margin-right: auto; // Center the table
  border-collapse: collapse;
`;

export const StyledTableItem = styled.table`
  table-layout: fixed;
  width: 100%; // Set the desired width
  margin-left: auto; // Center the table
  margin-right: auto; // Center the table
  border-collapse: collapse;
`;
export const TableTitleText = styled.div`
   user-select: none;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: #ffffff;
  text-align: left; // Ensures text is centered within the element itself
  // No need for width and height since it's a text element and will naturally take the size of its content
&:hover {

cursor: pointer;

}
`;

export const TableItemText = styled.div`

  white-space: nowrap; /* Keep the text on a single line */
  overflow: hidden; /* Hide the overflow */
  text-overflow: ellipsis; /* Use ellipsis for overflow */
  //max-width: 150px;
  //min-width: 150px; /* Set a max-width for the text, adjust as needed */
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: left; // Ensures text is centered within the element itself
  // No need for width and height since it's a text element and will naturally take the size of its content
`;

export const StyledThead = styled.thead`
  background-color: #425a77;
  color: white;
`;

export const StyledTh = styled.th`
  padding: 12px 15px;
  text-align: left; // Keep it left-aligned or center if preferred
`;

export const StyledTd = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
`;

// Add specific styles for the 'Azione' column
export const StyledActionTh = styled(StyledTh)`
  width: 10%; // Adjust the width as necessary for the header
`;

export const StyledActionTd = styled(StyledTd)`
  width: 10%; // Adjust the width as necessary for the column
`;

/////////////////////////////////
// Add specific styles for the 'Azione' column
export const StyledCatTh = styled(StyledTh)`
  width: 10%; // Adjust the width as necessary for the header
`;

export const StyledCatTd = styled(StyledTd)`
  width: 10%; // Adjust the width as necessary for the column
`;

// Add specific styles for the 'Azione' column
export const StyledClassificazioneTh = styled(StyledTh)`
  width: 40%; // Adjust the width as necessary for the header
`;

export const StyledClassificazioneTd = styled(StyledTd)`
  width: 40%; // Adjust the width as necessary for the column
`;

// Add specific styles for the 'Azione' column
export const StyledDescrizioneAssetTh = styled(StyledTh)`
  width: 25%; // Adjust the width as necessary for the header
`;

export const StyledDescrizioneAssetTd = styled(StyledTd)`
  width: 25%; // Adjust the width as necessary for the column
`;

// Add specific styles for the 'Azione' column
export const StyledInventarioTh = styled(StyledTh)`
  width: 15%; // Adjust the width as necessary for the header
`;

export const StyledInventarioTd = styled(StyledTd)`
  width: 15%; // Adjust the width as necessary for the column
`;


// Add specific styles for the 'Azione' column
export const StyledStatoTh = styled(StyledTh)`
  width: 10%; // Adjust the width as necessary for the header
`;

export const StyledStatoTd = styled(StyledTd)`
  width: 10%; // Adjust the width as necessary for the column
`;


// Add specific styles for the 'Azione' column
export const StyledIdentificativoTh = styled(StyledTh)`
  width: 20%; // Adjust the width as necessary for the header
`;

export const StyledIdentificativoTd = styled(StyledTd)`
  width: 20%; // Adjust the width as necessary for the column
`;
export const StyledIdentificativo40Th = styled(StyledTh)`
  width: 40%; // Adjust the width as necessary for the header
`;

export const StyledIdentificativo40Td = styled(StyledTd)`
  width: 40%; // Adjust the width as necessary for the column
`;
// Add specific styles for the 'Azione' column
export const StyledIdentificativo30Th = styled(StyledTh)`
  width: 30%; // Adjust the width as necessary for the header
`;

export const StyledIdentificativo30Td = styled(StyledTd)`
  width: 30%; // Adjust the width as necessary for the column
`;

// Add specific styles for the 'Azione' column
export const StyledIdentificativo20Th = styled(StyledTh)`
  width: 20%; // Adjust the width as necessary for the header
`;

export const StyledIdentificativo20Td = styled(StyledTd)`
  width: 20%; // Adjust the width as necessary for the column
`;







export const StyledTbody = styled.tbody`

`;



export const TableContainer = styled.div`
  width: 100%;
  max-height: 85vh; // Limit the height of the table container
  overflow: hidden; // Hide any overflow
  //border-bottom: 1px solid #dddddd;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between; // This will align one child to the left and one to the right
  align-items: center;
  width: 97.2%; // Set the desired width
  margin-left: auto; // Center the div
  margin-right: auto; // Center the div
  height: 52px;
  gap: 20px;
  padding: 0 0px; // Add padding if necessary
  box-sizing: border-box; // Include padding in the element's total width and height

`;

export const Search = styled.input`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 0px 30px;
  margin-right: 0px;
  width: 100%;
  height: 30px;
  // Additional styles for the input
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro); // Example font size
  line-height: 1.5; // Example line height for better readability
  color: var(--color-lightslategray); // Text color

  // Remove default input styling
  outline: none;
  box-shadow: none;

  // Styling for placeholder text
  &::placeholder {
    color: #d1d1d1;
    
  }

  // Focus styles
  &:focus {
    border-color: var(--color-primary); // Change border color when focused
    // You can add other styles for the focus state
  }
`;

export const SearchIcon = styled.img`
  position: absolute;
  left: 10px; // Position from the right to place it inside the input
  top: 50%;
  transform: translateY(-50%);
  width: 16px; // Set a fixed size for the icon
  height: 16px;
  pointer-events: none; // Make sure clicks "go through" the icon
`;

export const SearchInputContainer = styled.div`

position: relative; // Position relative to allow absolute positioning of children
  width: 50%; // The width of the container
  margin-right: 0px;

`;

export const CheckBoxScrollableDiv = styled.div`

position: absolute;
overflow-x: hidden;
overflow-y: scroll; // Enable vertical scroll
  height: 98%; // Set the height of the table body here
  width: 100%;
  //border: 1px solid #e1e1e1;

  // Custom scrollbar styles
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c6c6c6;
    border-radius: 10px;
    &:hover {
      background: #425a77;
    }
  }
`;
