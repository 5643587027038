import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import {
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  addL1SchedaGeneraleNomeCompleto,
  addL1SchedaGeneraleNomeAbbreviato,
  addL1SchedaGeneraleIndirizzo,
  addL1SchedaGeneraleTelefono,
  addL1SchedaGeneraleFax,
  addL1SchedaGeneralePEC,
} from "../L1-Add-Recoil";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_L1_1 } from "../../tabs-description";

const MainComponent = () => {
  ////////////RECOIL

  const [nomeCompleto, setNomeCompleto] = useRecoilState(
    addL1SchedaGeneraleNomeCompleto
  );
  const [nomeAbbreviato, setNomeAbbreviato] = useRecoilState(
    addL1SchedaGeneraleNomeAbbreviato
  );
  const [Indirizzo, setIndirizzo] = useRecoilState(
    addL1SchedaGeneraleIndirizzo
  );
  const [Telefono, setTelefono] = useRecoilState(addL1SchedaGeneraleTelefono);
  const [Fax, setFax] = useRecoilState(addL1SchedaGeneraleFax);
  const [PEC, setPEC] = useRecoilState(addL1SchedaGeneralePEC);

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Nome completo
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={nomeCompleto}
              onChange={(e) => setNomeCompleto(e.target.value)}
            />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              Nome abbreviato
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={nomeAbbreviato}
              onChange={(e) => setNomeAbbreviato(e.target.value)}
            />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Indirizzo sede</InputDescriptionText>

            <InputTextbox
              placeholder=""
              value={Indirizzo}
              onChange={(e) => setIndirizzo(e.target.value)}
            />

            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Telefono</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={Telefono}
              onChange={(e) => setTelefono(e.target.value)}
            />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Fax</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={Fax}
              onChange={(e) => setFax(e.target.value)}
            />
            <IconContainer />
          </InputContainer>
          <InputContainer>
            <InputDescriptionText>Email</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={PEC}
              onChange={(e) => setPEC(e.target.value)}
            />
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
        {TAB_DESCRIPTION_L1_1 ? TAB_DESCRIPTION_L1_1 : TAB_DESCRIPTION_GLOBAL}
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
