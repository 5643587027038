import { atom, selector } from 'recoil';
import dayjs from 'dayjs';

// SCHEDA GENERALE
export const addL3RoleID = atom({
    key: 'addL3RoleID', 
    default: '',
  });
  

export const addL3Role = atom({
    key: 'addL3Role', 
    default: '',
  });
  
  export const addL3searchTerm = atom({
    key: 'addL3searchTerm', 
    default: '',
  });
  
  
  export const addL3birthDate = atom<dayjs.Dayjs | null>({
    key: 'addL3birthDate', 
    //default: dayjs(),
    default: null,
  });
  
  
  export const addL3RoleListDataID = atom({
    key: 'addL3RoleListDataID', 
    default: '',
  });
  
  
  export const addL3selectedAggrId = atom({
    key: 'addL3selectedAggrId', 
    default: '',
  });
  
  
  export const addL3selectedModelId = atom({
    key: 'addL3selectedModelId', 
    default: '',
  });

  export const addL3selectedModelRadioId = atom({
    key: 'addL3selectedModelRadioId', 
    default: '',
  });
  
  
  
  export const addL3selectedModelName = atom({
    key: 'addL3selectedModelName', 
    default: '',
  });
  
 

// DOCUMENTAZIONE

export const addL3DocumentsPath = atom({
    key: 'addL3DocumentsPath', 
    default: '',
  });
  
 
  export const addL3DocumentsAttached = atom<File[]>({
    key: 'addL3DocumentsAttached',
    default: [],
  });
  
  
  export const addL3AttachedFilesInfo = atom<Array<{ name: string, size: number, type: string } | null>>({
    key: 'addL3AttachedFilesInfo',
    default: [],
  });

  export interface addL3ComplianceData {
    id: string;
    selected: boolean;
    process_unit_association: {
      business_process: {
        id: string;
        name: string;
        kind: string;
        description: string;
        objective: string;
      };
    };
  }
  
  export interface BusinessProcess {
    id: string;
    name: string;
    description: string;
    kind: string;
    objective: string;
  }
  
  export interface addL3Model {
    id: string;
    organizational_unit: {
      id: string;
      prefLabel: string;
    };
    company_role: addL3Role[];
  }
  
  export interface addL3Role {
    id: string;
    role_description: string;
    // Add any other fields that might be relevant based on your application's needs
  }
  
  export interface roleDataInterface {
    id: string;
    role_description: string;
    responsibility?: string;
  }
  
  export interface RolesAccordionProps {
    role: addL3Role[];
    model: addL3Model;
    token: string | null | undefined;
  }
  
  export interface ModelSelectorProps {
    models: addL3Model[];
    selectedModelId: string;
    onModelChange: (newModelId: string) => void;
  }
 
  
  export const addL3RoleState = atom<addL3Role[]>({
    key: 'addL3RoleState',
    default: [],
  });
  
  export const addL3ComlpianceDataState = atom<addL3ComplianceData[]>({
    key: 'addL3ComlpianceDataState',
    default: [],
  });
  
  export const addL3RoleListData = atom<roleDataInterface[]>({
    key: 'addL3RoleListData',
    default: [],
  });

  export const addL3selectedCheckboxesProcessiState = atom<string[]>({
    key: 'addL3selectedCheckboxesProcessiState',
    default: [],
  });

  export interface Role {
    id: string;
    description: string;
    has_organization_model: string;
    longDescription: string;
    isResponsible: boolean;
  }
  
  export interface GroupedModel {
    id: string;
    name: string;

    roles: Role[];
    responsibleRole: Role;
  }

   
  export const addL3ModelDataState = atom<GroupedModel[]>({
    key: 'addL3ModelDataState',
    default: [],
  });

  export const addL3RoleDataState = atom<Role[]>({
    key: 'addL3RoleDataState',
    default: [],
  });

  export const addL3searchTermModelliRuoli = atom({
    key: 'addL3searchTermModelliRuoli',
    default: '',
  });

  export const addL3electedModelCheckboxesState = atom<{ [modelId: string]: string[] }>({
    key: 'addL3electedModelCheckboxesState',
    default: {},
  });

