import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import {
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { SelectedL4ID } from "../../../../../recoil";
import { InputContainer50 } from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import {
  LuoghiAttachedFilesInfo,
  LuoghiDocumentsAttached,
  LuoghiDocumentsPath,
  LuoghiEmail,
  LuoghiFax,
  LuoghiID,
  LuoghiIdentificativo,
  LuoghiIndirizzo,
  LuoghiName,
  LuoghiNomeAbbreviato,
  LuoghiTelefono,
  LuoghiComplianceDate,
} from "../Luoghi-Add-recoil";
import { DatePicker } from "@mui/x-date-pickers";
import { TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_LUOGHI_1 } from "../../tabs-description";

const useToken = () => {
  const { keycloak, initialized } = useKeycloak();
  return initialized && keycloak.authenticated ? keycloak.token : null;
};

type PersonItem = {
  id: string;

  surname: string;
  name: string;
  has_personal_card: string;
  member_of_formal_organization: string;
  reports_to: string;
  has_smart_working_site: string;
  documents_folder_link: string;
  based_at: string;

  national_identification_number: string;
  date_of_birth: string;
  has_place_of_birth: string;
  has_primary_residence: string;
  has_postal_address: string;
  personal_email: string;
  work_email: string;
  work_mobile_phone: string;
  personal_mobile_phone: string;
  has_identity_document: string;
  has_citizenship: string;
};

type PersonApiContentItem = {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  surname: string;
  name: string;
  has_personal_card: string;
  member_of_formal_organization: string;
  reports_to: string;
  has_smart_working_site: string;
  documents_folder_link: string;
  based_at: string;
  personal_card: {
    id: string;
    creationTs: string;
    updateTs: string;
    createdBy: string;
    updatedBy: string;
    national_identification_number: string;
    date_of_birth: string;
    has_place_of_birth: string;
    has_primary_residence: string;
    has_postal_address: string;
    personal_email: string;
    work_email: string;
    work_mobile_phone: string;
    personal_mobile_phone: string;
    has_identity_document: string;
    has_citizenship: string;
  };
};

const MainComponent = () => {
  const token = useToken();

  const [data, setData] = useState<PersonItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  ////////////RECOIL

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(
    LuoghiAttachedFilesInfo
  );
  const [documentsAttached, setDocumentsAttached] = useRecoilState(
    LuoghiDocumentsAttached
  );
  const [docPath, setDocPath] = useRecoilState(LuoghiDocumentsPath);
  const [email, setEmail] = useRecoilState(LuoghiEmail);
  const [fax, setFax] = useRecoilState(LuoghiFax);
  const [ID, setID] = useRecoilState(LuoghiID);
  const [identificativo, setIdentificativo] =
    useRecoilState(LuoghiIdentificativo);
  const [indirizzo, setIndirizzo] = useRecoilState(LuoghiIndirizzo);
  const [name, setName] = useRecoilState(LuoghiName);
  const [nomeAbbreviato, setNomeAbbreviato] =
    useRecoilState(LuoghiNomeAbbreviato);
  const [telefono, setTelefono] = useRecoilState(LuoghiTelefono);
  const [complianceDate, setComplianceDate] =
    useRecoilState(LuoghiComplianceDate);

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Denominazione
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              Identificativo
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>

            <InputTextbox
              placeholder=""
              value={identificativo}
              onChange={(e) => setIdentificativo(e.target.value)}
            />
            <IconContainer />
          </InputContainer>
          <InputContainer>
            <InputDescriptionText>Nome abbreviato</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={nomeAbbreviato}
              onChange={(e) => setNomeAbbreviato(e.target.value)}
            />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Indirizzo</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={indirizzo}
              onChange={(e) => setIndirizzo(e.target.value)}
            />
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Email
              {/* <Tooltip title="Campo obbligatorio">
                <InfoIcon style={{ fontSize: "14px", color: "#04446c", cursor: "pointer", marginLeft:"2px" }} />
              </Tooltip> */}
            </InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              Telefono
              {/* <Tooltip title="Campo obbligatorio">
                <InfoIcon style={{ fontSize: "14px", color: "#04446c", cursor: "pointer", marginLeft:"2px" }} />
              </Tooltip> */}
            </InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
            />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Fax</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={fax}
              onChange={(e) => setFax(e.target.value)}
            />
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
        {TAB_DESCRIPTION_LUOGHI_1 ? TAB_DESCRIPTION_LUOGHI_1 : TAB_DESCRIPTION_GLOBAL}
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
