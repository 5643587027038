import React, { useState, useEffect, useMemo, useRef, useContext, useCallback } from 'react';
import styled from "styled-components";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione"; // Correct path
import { debounce } from "lodash";
import { FixedSizeList as List } from "react-window";
import { ListChildComponentProps } from "react-window";

import { Box, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress, Checkbox } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextboxButton,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupTitleContainer,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { SelectedL4ID, SelectedL1ID, setErrorRec, isLoadingRec, isMessageBoxVisibleRec, setMessageRec } from "../../../../../recoil";

import CancelIcon from "@mui/icons-material/Clear";
import { ArrowButtonText, ButtonBaseText, ButtonGrey, ButtonText } from "../../../styled-components";
import {
  SearchIcon,
  SearchInputContainer,
  SearchInputContainerSolo,
  SearchPopup,
  StyledActionTh,
  StyledTable,
  StyledTableItem,
  StyledTbody,
  StyledTd,
  StyledTdCheckbox,
  StyledTdUnitaOperativa,
  StyledTh,
  StyledThead,
  StyledUnitaOperativaTh,
  TableContainer,
  TableContainerPopup,
  TableItemText,
  TableScrollableDiv,
  TableScrollableDivPopup,
  TableTitleText,
  TopContainer,
} from "../cybersecurity-board-organizzazione-unitaoperative-styled-components";
import {
  AssetDetails,
  DataItem,
  DataItem2,
  DataTableProps,
  DataTableProps2,
  SelectedPlaceUnitAssociationInfrastruttura,
  SortConfig2Rec,
  SortConfigRec,
  SortableKeys,
  SortableKeys2,
  dataTableState,
  dataTableState2,
  editL4InfrDataItem,
  editL4InfrDataItemState,
  editL4SelectedPlaceUnitAssociationInfrastrutturaState,
  editL4TestoAssets,
  editL4TestoLuoghi,
  editL4_3_selectedID,
  editL4isVisible,
  editL4isVisible2,
  editL4originalAssetAssociationsState,
  editL4originalPlaceUnitAssociationsState,
  editL4selectedCheckboxesAsset,
  editL4selectedCheckboxesPlaces,
  sortConfig2State,
  sortConfigState,
} from "../L4-Edit-recoil";
import { URL_AssetsByPersonSelection, URL_PlacesByPersonSelection } from "../../../../../API";

const useToken = () => {
  const { keycloak, initialized } = useKeycloak();
  return initialized && keycloak.authenticated ? keycloak.token : null;
};

///////////////// LAST POPUP

const useTogglePopup = (token: string | null | undefined) => {
  const [isVisible, setIsVisible] = useRecoilState(editL4isVisible);
  //useFetchData(token);
  // Toggler function
  const toggle = () => {
    setIsVisible((prevState) => !prevState);
  };

  return toggle;
};

const useTogglePopup2 = (token: string | null | undefined) => {
  const [isVisible2, setIsVisible2] = useRecoilState(editL4isVisible2);
  useFetchAssetAssociations(token);
  // Toggler function
  const toggle = () => {
    setIsVisible2((prevState) => !prevState);
  };

  return toggle;
};

interface LastPopupProps {
  isVisible: boolean;
}

interface LastPopupProps2 {
  isVisible: boolean;
}

const LastPopupBlack = styled.div<LastPopupProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  //border: 5px solid #ffffff;
  z-index: 100;
`;

const LastPopupBlack2 = styled.div<LastPopupProps2>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup2 = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  //border: 5px solid #ffffff;
  z-index: 100;
`;

///////////////////////////////////////// LAST POP UP END

type StyledTrProps = {
  selected: boolean;
};

type ApiContentItem = {
  id: string;
  name: string;
  hasUnit: string;
  CategoryPath: string;
  unit: {
    prefLabel: string;
  };
};


interface ApiContentItem2 {
  id: string;
  name: string;
  CategoryPath: string;
}

//////////////////////// DATA TABLE FOR POPUP

const StyledTr = styled.tr<StyledTrProps>`
  cursor: pointer;
  background-color: ${(props) => {
    //console.log(`Is row selected: ${props.selected}`); // This should log true or false based on selection
    return props.selected ? "#f3f7fb" : "#ffffff";
  }};

  &:hover {
    background-color: #e9eff7; // Make sure this doesn't override your selection color
  }
`;

// Define a function to update the 'testoLuoghi' state
const updateTestoLuoghi = (currentNames: string, name: string) => {
  let newNamesArray = currentNames.split(", ").filter((n) => n !== "Apri per modificare");

  const nameIndex = newNamesArray.indexOf(name);
  if (nameIndex > -1) {
    newNamesArray.splice(nameIndex, 1); // Remove the name if it exists
  } else {
    newNamesArray.push(name); // Add the name if it doesn't
  }

  return newNamesArray.length > 0 ? newNamesArray.join(", ") : "Apri per modificare";
};

// Define a function to update the 'selectedCheckboxesPlaces' state
const updateSelectedCheckboxesPlaces = (currentIds: string[], id: string) => {
  const newIds = new Set(currentIds);
  if (newIds.has(id)) {
    newIds.delete(id);
  } else {
    newIds.add(id);
  }
  return Array.from(newIds);
};

// Assume updateTestoLuoghi and updateSelectedCheckboxesPlaces are defined elsewhere
export const useHandleCheckboxPlacesChange = () => {

  const [selectedCheckboxesPlaces, setSelectedCheckboxesPlaces] = useRecoilState(editL4selectedCheckboxesPlaces);
 

  const handleCheckboxChange = useCallback((id: string) => {
    setSelectedCheckboxesPlaces((prev) => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      } else {
        return [...prev, id];
      }
    });
  }, [setSelectedCheckboxesPlaces, selectedCheckboxesPlaces]);

  return handleCheckboxChange;
};



// SAVE SELECTED CHECKBOXES PLACES

const useSavePlaceSelection = () => {
  const [selectedPlaceIds, ] = useRecoilState(editL4selectedCheckboxesPlaces);
  const [L4ID, ] = useRecoilState(SelectedL4ID);
  const [allPlaces, ] = useRecoilState(editL4originalPlaceUnitAssociationsState); 
  const token = useToken(); 
  const saveSelection = useCallback(async () => {
   
    const payload = allPlaces.map((assoc) => {
     
      const isSelected = selectedPlaceIds.includes(assoc.content.id);

      return {
        ...assoc,
        selected: isSelected,
      };
    });

    console.log('Sending updated payload:', JSON.stringify(payload));
    
    // Send the PATCH request
    try {
      const response = await fetch(`${URL_PlacesByPersonSelection}${L4ID}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload), 
      });

      if (!response.ok) {
        throw new Error(`Failed to update place selections: ${response.statusText}`);
      }

      console.log('Successfully updated place selections for person:', L4ID);
    } catch (error) {
      console.error('Error updating place selections:', error);
    }
  }, [token, selectedPlaceIds, L4ID, allPlaces]);

  return saveSelection;
};


interface SiteDetails {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  site_name: string;
  site_short_name: string;
  has_site_address: string;
  site_email: string;
  site_telephone: string;
  has_site_responsible: string;
  fax: string;
  has_compliance_responsible: string;
  compliance_responsible_assignment_start: string;
  has_documents_folder_link: string;
  compliance_responsible?: null; 
  site_responsible?: null; 
}

interface PlaceDetails {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  name: string;
  has_site: string;
  plan_ref: string;
  site: SiteDetails;
  has_compliance_responsible: string;
  compliance_responsible_assignment_start: string;
  has_documents_folder_link: string;
}

interface PlaceUnitAssociation {
  content: {
    id: string;
    creationTs: string;
    updateTs: string;
    createdBy: string;
    updatedBy: string;
    has_place: string;
    has_unit: string;
    has_person: string;
    place: PlaceDetails;
  };
  selected: boolean;
}


// PLACE UNIT ASSOCIATION

const useFetchPlaceUnitAssociations = (token: string | null | undefined) => {
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [selectedUnitAssociations, setSelectedUnitAssociations] = useRecoilState(editL4SelectedPlaceUnitAssociationInfrastrutturaState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [dataPlaces, setDataPlaces] = useRecoilState(editL4SelectedPlaceUnitAssociationInfrastrutturaState);
  const [selectedCheckboxesPlaces, setSelectedCheckboxesPlaces] = useRecoilState(editL4selectedCheckboxesPlaces);

  const [originalPlaceUnitAssociations, setOriginalPlaceUnitAssociations] = useRecoilState(editL4originalPlaceUnitAssociationsState);
  
  useEffect(() => {
    const fetchData = async () => {
    try {
      const response = await fetch(`${URL_PlacesByPersonSelection}${L4ID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data from API");
      }

      const data: PlaceUnitAssociation[] = await response.json();
      setOriginalPlaceUnitAssociations(data);

      const initiallySelectedIds = data
      .filter((assoc) => assoc.selected)    
      .map((assoc) => assoc.content.id); 

      setSelectedCheckboxesPlaces(initiallySelectedIds);
      console.log('initiallySelectedIds: ', initiallySelectedIds);
    
      const selectedAssociations = data
        //.filter((item) => !item.selected) // Filter for selected items
        .map((assoc) => ({
          id: assoc.content.id, // Use the id from content
          placeId: assoc.content.has_place, // Use the has_place id from content
          siteName: assoc.content.place.site.site_name, // Use the has_unit name from content, you might need to resolve the name from an id.
          planRef: assoc.content.place.plan_ref,
          placeName: assoc.content.place.name,
          selected: assoc.selected,
        }));

      // Set the selected associations
      setSelectedUnitAssociations(selectedAssociations);
      setDataPlaces(selectedAssociations);
    } catch (error) {
      setError(error instanceof Error ? error.message : "An unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  if (token && L4ID) fetchData();
}, [token, L4ID, setIsLoading, setError, setSelectedUnitAssociations]);

// Return anything that might be useful outside this hook
};

// PLACE UNIT ASSOCIATION END

// Click handler for headers
const useSort = () => {
  const [sortConfig, setSortConfig] = useRecoilState<SortConfigRec | null>(sortConfigState);

  const requestSort = (key: SortableKeys) => {
    // Explicitly declare `direction` as a type of `"ascending" | "descending"`
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { sortConfig, requestSort };
};

const useHandleRowSelect = () => {
  const [, setSelectedId] = useRecoilState(editL4_3_selectedID);

  // This function updates the selected ID in Recoil state
  const handleRowSelect = (id: string) => {
    setSelectedId(id); // Directly setting the new ID, simplifying the previous implementation
  };

  return handleRowSelect;
};

//////////////////////GLOBAL SEARCH

const useDebouncedGlobalFilter = () => {
  const [globalFilterText, setGlobalFilterText] = useState("");

  // Memorize the debounced function to prevent unnecessary re-creations
  const debouncedSetGlobalFilterText = useCallback(
    debounce((value) => setGlobalFilterText(value), 300),
    []
  );

  // Handler function for input changes
  const handleGlobalFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      debouncedSetGlobalFilterText(event.target.value);
    },
    [debouncedSetGlobalFilterText]
  );

  return { globalFilterText, handleGlobalFilterChange };
};
////////////////////////////////////// FILTER

const useDebouncedFilter = () => {
  const [filterText, setFilterText] = useState("");

  // Ensure the debounce function is memorized to prevent unnecessary re-creations
  const debouncedSetFilterText = useCallback(
    debounce((value) => setFilterText(value), 300),
    []
  );

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetFilterText(event.target.value);
  };

  return { filterText, handleFilterChange };
};

function useFilteredAndSortedData(data: SelectedPlaceUnitAssociationInfrastruttura[], sortConfig: SortConfigRec | null, filterText: string, globalFilterText: string): SelectedPlaceUnitAssociationInfrastruttura[] {
  return useMemo(() => {
    let filteredData = data
      .filter((item) => item.placeName.toLowerCase().includes(filterText.toLowerCase()))
      .filter((item) => {
        if (globalFilterText) {
          const allText = Object.values(item).join(" ").toLowerCase();
          return allText.includes(globalFilterText.toLowerCase());
        }
        return true;
      });

    if (sortConfig) {
      // Check if sortConfig is not null
      filteredData.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        return 0;
      });
    }

    return filteredData;
  }, [data, sortConfig, filterText, globalFilterText]);
}

interface ItemData {
  items: Array<{
    id: string;
    name: string;
    site_name: string;
  }>;
  handleCheckboxChange: ReturnType<typeof useHandleCheckboxPlacesChange>;
  handleRowSelect: ReturnType<typeof useHandleRowSelect>;
}

const Row = ({ index, style, data }: ListChildComponentProps<ItemData>) => {
  const [selectedId] = useRecoilState(editL4_3_selectedID);
  const [selectedCheckboxesPlaces] = useRecoilState(editL4selectedCheckboxesPlaces);
  const item = data.items[index]; // Access the item data using index

  return (
    <StyledTr style={{ ...style, display: "flex" }} key={item.id} onClick={() => data.handleRowSelect(item.id)} selected={selectedId === item.id}>
      <StyledTdCheckbox>
        <Checkbox
          checked={selectedCheckboxesPlaces.includes(item.id)}
          onChange={() => data.handleCheckboxChange(item.id)}
          sx={{
            color: "#425a77",
            "& .MuiSvgIcon-root": { fontSize: 16 },
            "&.Mui-checked": { color: "#425a77" },
            padding: "0px",
          }}
        />
      </StyledTdCheckbox>
      <StyledTd>{item.name}</StyledTd>
      <StyledTd>{item.site_name}</StyledTd>
    </StyledTr>
  );
};

const DataTable: React.FC<DataTableProps> = ({ data }) => {
  const [selectedCheckboxesPlaces, setSelectedCheckboxesPlaces] = useRecoilState(editL4selectedCheckboxesPlaces);
  const [selectedId, setSelectedId] = useRecoilState(editL4_3_selectedID);
  const handleRowSelect = useHandleRowSelect();
  const handleCheckboxPlacesChange = useHandleCheckboxPlacesChange();
  const { filterText, handleFilterChange } = useDebouncedFilter();
  const { globalFilterText, handleGlobalFilterChange } = useDebouncedGlobalFilter();
  const { sortConfig, requestSort } = useSort();
  const filteredAndSortedData = useFilteredAndSortedData(data, sortConfig, filterText, globalFilterText);


  const itemData = {
    items: filteredAndSortedData,
    handleCheckboxPlacesChange,
    handleRowSelect,
    selectedCheckboxesPlaces,
    selectedId,
  };

  return (
    <TableContainerPopup>
      <TopContainer>
        <SearchInputContainer>
          <SearchPopup placeholder="Filtra per luogo" onChange={handleFilterChange} />
          <SearchIcon src="/funnel.svg" />
        </SearchInputContainer>
        <SearchInputContainer>
          <SearchPopup placeholder="Cerca in tutta la tabella" onChange={handleGlobalFilterChange} />
          <SearchIcon src="/search.svg" />
        </SearchInputContainer>
      </TopContainer>

      <StyledTable>
        <StyledThead>
          {/* <StyledUnitaOperativaTh onClick={() => requestSort('id')}><TableTitleText>▼ Selezionato</TableTitleText></StyledUnitaOperativaTh> */}

          <StyledActionTh onClick={() => requestSort("placeName")}>
            <TableTitleText>▼ </TableTitleText>
          </StyledActionTh>
          <StyledTh onClick={() => requestSort("placeName")}>
            <TableTitleText>▼ Luogo</TableTitleText>
          </StyledTh>
          <StyledTh onClick={() => requestSort("siteName")}>
            <TableTitleText>▼ Componente organizzativa</TableTitleText>
          </StyledTh>
        </StyledThead>
      </StyledTable>
      <TableScrollableDivPopup>
        <StyledTableItem>
          <StyledTbody>
            {filteredAndSortedData.map((item) => (
              <StyledTr
                key={item.id} // Use the unique ID as key instead of index
                onClick={() => handleRowSelect(item.id)} // Set the selected item ID on click
                selected={selectedId === item.id} // Conditionally apply the background color
              >
                <StyledTdCheckbox>
                  <TableItemText>
                    {" "}
                    <Checkbox
                      checked={selectedCheckboxesPlaces.includes(item.id)}
                      onChange={() => handleCheckboxPlacesChange(item.id)} 
                      sx={{
                        color: "#425a77",
                        "& .MuiSvgIcon-root": { fontSize: 16 },
                        "&.Mui-checked": { color: "#425a77" },
                        padding: "0px",
                      }}
                    />
                  </TableItemText>
                </StyledTdCheckbox>
          
                <StyledTd title={item.placeName}>
                  <TableItemText>{item.placeName}</TableItemText>
                </StyledTd>
                <StyledTd title={item.siteName}>
                  <TableItemText>{item.siteName}</TableItemText>
                </StyledTd>
              </StyledTr>
            ))}

            {/* <List
              height={390} // Adjust based on your needs
              itemCount={filteredAndSortedData.length}
              itemSize={50} // Adjust based on your row height
              width="100%"
              itemData={itemData}
            >
              {Row}
            </List> */}
          </StyledTbody>
        </StyledTableItem>
      </TableScrollableDivPopup>
    </TableContainerPopup>
  );
};

///////////////////DATA TABLE END
//  FETCH ASSET DATA 

const useFetchAssetAssociations = (token: string | null | undefined) => {
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const [data, setData] = useRecoilState(editL4InfrDataItemState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [selectedCheckboxesAssets, setSelectedCheckboxesAssets] = useRecoilState(editL4selectedCheckboxesAsset);

  const [originalAssetAssociations, setOriginalAssetAssociations] = useRecoilState(editL4originalAssetAssociationsState);

  useEffect(() => {
    const fetchData = async () => {
    setIsLoading(true);
    try {
      // Check for token existence before making the API call
      if (!token) throw new Error("User is not authenticated");

      // Fetch data from the API
      const response = await fetch(`${URL_AssetsByPersonSelection}${L4ID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the API call was successful
      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }

      // Parse the JSON response
      const responseData: AssetDetails[] = await response.json();
      
      setOriginalAssetAssociations(responseData);
   
      const initiallySelectedIds = responseData
      .filter((assoc) => assoc.selected)    
      .map((assoc) => assoc.content.id); 

      setSelectedCheckboxesAssets(initiallySelectedIds);
      console.log("Response Asset Data:", responseData);

      // Assuming responseData is the array you want to map
      const transformedData: editL4InfrDataItem[] = responseData.map((item: AssetDetails) => {
        return {
          id: item.content.id,
          name: item.content.name,
          hasUnit: item.content.hasUnit,  
          CategoryPath: item.content.category.name,

          // Map other properties as needed
        };
      });

      console.log("Transformed Data:", transformedData);
      setData(transformedData);
    } catch (err) {
      // Error handling
      setError(err instanceof Error ? err.message : "An unknown error occurred");
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (token && L4ID) fetchData();
}, [token, L4ID]);

//Return anything that might be useful outside this hook
};


// FETCH ASSET DATA END

// Utility function to update name list
const updateNameList = (currentNames: string, name: string) => {
  let newNamesArray = currentNames.split(", ").filter((n) => n !== "Apri per modificare");
  const nameIndex = newNamesArray.indexOf(name);

  if (nameIndex > -1) {
    newNamesArray.splice(nameIndex, 1); // Remove the name if it exists
  } else {
    newNamesArray.push(name); // Add the name if it doesn't
  }

  return newNamesArray.length > 0 ? newNamesArray.join(", ") : "Apri per modificare";
};

// Utility function to update selected IDs
const updateSelectedIds = (currentIds: string[], id: string) => {
  const newIds = new Set(currentIds);

  if (newIds.has(id)) {
    newIds.delete(id);
  } else {
    newIds.add(id);
  }

  return Array.from(newIds);
};

const useSaveAssetsSelection = () => {
  const [selectedAssetsIds, ] = useRecoilState(editL4selectedCheckboxesAsset);
  const [L4ID, ] = useRecoilState(SelectedL4ID);
  const [allAssets, ] = useRecoilState(editL4originalAssetAssociationsState); 

  const token = useToken(); 

  const saveSelection = useCallback(async () => {
   
    const payload = allAssets.map((assoc) => {
     
      const isSelected = selectedAssetsIds.includes(assoc.content.id);

      return {
        ...assoc,
        selected: isSelected,
      };
    });

    console.log('Sending updated payload:', JSON.stringify(payload));
    
    // Send the PATCH request
    try {
      const response = await fetch(`${URL_AssetsByPersonSelection}${L4ID}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload), 
      });

      if (!response.ok) {
        throw new Error(`Failed to update asset selections: ${response.statusText}`);
      }

      console.log('Successfully updated asset selections for person:', L4ID);
    } catch (error) {
      console.error('Error updating place selections:', error);
    }
  }, [token, selectedAssetsIds, L4ID, allAssets]);

  return saveSelection;
};


// Event handler that uses the utility functions
export const useHandleCheckboxAssetsChange = () => {
  const [selectedCheckboxesAssets, setSelectedCheckboxesAssets] = useRecoilState(editL4selectedCheckboxesAsset);
 
  const handleCheckboxChange = useCallback((id: string, name: string) => {
    // Update the list of selected checkboxes
    setSelectedCheckboxesAssets((prev) => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      } else {
        return [...prev, id];
      }
    });
 
  }, [setSelectedCheckboxesAssets, selectedCheckboxesAssets]);

  return handleCheckboxChange;
};

// Click handler for headers
const useSort2 = () => {
  const [sortConfig2, setSortConfig2] = useRecoilState<SortConfig2Rec | null>(sortConfig2State);

  const requestSort2 = (key: SortableKeys2) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig2 && sortConfig2.key === key && sortConfig2.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig2({ key, direction });
  };

  return { sortConfig2, requestSort2 };
};
function useFilteredAndSortedData2(data: DataItem2[], sortConfig: SortConfig2Rec | null, filterText: string, globalFilterText: string): DataItem2[] {
  const [sortConfig2, setSortConfig2] = useRecoilState(sortConfig2State);
  return useMemo(() => {
    let filteredData = data
      .filter((item) => item.name.toLowerCase().includes(filterText.toLowerCase()))
      .filter((item) => {
        if (globalFilterText) {
          const allText = Object.values(item).join(" ").toLowerCase();
          return allText.includes(globalFilterText.toLowerCase());
        }
        return true;
      });

    if (sortConfig2) {
      // Check if sortConfig is not null
      filteredData.sort((a, b) => {
        const aValue = a[sortConfig2.key];
        const bValue = b[sortConfig2.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig2.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        return 0;
      });
    }

    return filteredData;
  }, [data, sortConfig, filterText, globalFilterText]);
}

const DataTable2: React.FC<DataTableProps2> = ({ data }) => {
  const [selectedCheckboxesAssets, setSelectedCheckboxesAssets] = useRecoilState(editL4selectedCheckboxesAsset);
  const [selectedId, setSelectedId] = useRecoilState(editL4_3_selectedID);
  const handleRowSelect = useHandleRowSelect();
  const handleCheckboxChange2 = useHandleCheckboxAssetsChange();
  const { filterText, handleFilterChange } = useDebouncedFilter();
  const { globalFilterText, handleGlobalFilterChange } = useDebouncedGlobalFilter();
  const { sortConfig2, requestSort2 } = useSort2();
  const filteredAndSortedData = useFilteredAndSortedData2(data, sortConfig2, filterText, globalFilterText);

  return (
    <TableContainerPopup>
      <TopContainer>
        <SearchInputContainer>
          <SearchPopup placeholder="Filtra per asset" onChange={handleFilterChange} />
          <SearchIcon src="/funnel.svg" />
        </SearchInputContainer>
        <SearchInputContainer>
          <SearchPopup placeholder="Cerca in tutta la tabella" onChange={handleGlobalFilterChange} />
          <SearchIcon src="/search.svg" />
        </SearchInputContainer>
      </TopContainer>

      <StyledTable>
        <StyledThead>
          {/* <StyledUnitaOperativaTh onClick={() => requestSort('id')}><TableTitleText>▼ Selezionato</TableTitleText></StyledUnitaOperativaTh> */}

          <StyledActionTh onClick={() => requestSort2("name")}>
            <TableTitleText>▼ </TableTitleText>
          </StyledActionTh>
          <StyledTh onClick={() => requestSort2("name")}>
            <TableTitleText>▼ Asset</TableTitleText>
          </StyledTh>
          <StyledTh onClick={() => requestSort2("CategoryPath")}>
            <TableTitleText>▼ Classificazione</TableTitleText>
          </StyledTh>
        </StyledThead>
      </StyledTable>
      <TableScrollableDivPopup>
        <StyledTableItem>
          <StyledTbody>
            {filteredAndSortedData.map((item) => (
              <StyledTr
                key={item.id} // Use the unique ID as key instead of index
                onClick={() => handleRowSelect(item.id)} // Set the selected item ID on click
                selected={selectedId === item.id} // Conditionally apply the background color
              >
                <StyledTdCheckbox>
                  <TableItemText>
                    {" "}
                    <Checkbox
                      checked={selectedCheckboxesAssets.includes(item.id)}
                      onChange={() => handleCheckboxChange2(item.id, item.name)}
                      sx={{
                        color: "#425a77",
                        "& .MuiSvgIcon-root": { fontSize: 16 },
                        "&.Mui-checked": { color: "#425a77" },
                        padding: "0px",
                      }}
                    />
                  </TableItemText>
                </StyledTdCheckbox>
                {/* <StyledTdUnitaOperativa title={item.id}><TableItemText>{item.id}</TableItemText></StyledTdUnitaOperativa> */}
                <StyledTd title={item.name}>
                  <TableItemText>{item.name}</TableItemText>
                </StyledTd>
                <StyledTd title={item.CategoryPath}>
                  <TableItemText>{item.CategoryPath}</TableItemText>
                </StyledTd>
              </StyledTr>
            ))}
          </StyledTbody>
        </StyledTableItem>
      </TableScrollableDivPopup>
    </TableContainerPopup>
  );
};

const handleCloseAndNavigate = () => {
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  setIsMessageBoxVisible(false); // Close the message box

  const { closeOverlay } = useContext(OverlayContext);
  closeOverlay();
};

const MainComponent = () => {
  const [isVisible, setIsVisible] = useRecoilState(editL4isVisible);
  const [isVisible2, setIsVisible2] = useRecoilState(editL4isVisible2);
  const [testoLuoghi, setTestoLuoghi] = useRecoilState(editL4TestoLuoghi);
  const [testoAsset, setTestoAsset] = useRecoilState(editL4TestoAssets);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [filterText, setFilterText] = useState("");
  const debouncedSetFilterText = useCallback(debounce(setFilterText, 300), []);
  const debouncedSetGlobalFilterText = useCallback(debounce(setGlobalFilterText, 300), []);

  const [sortConfig, setSortConfig] = useRecoilState(sortConfigState);
  const [sortConfig2, setSortConfig2] = useRecoilState(sortConfig2State);
  const [dataTable, setDataTable] = useRecoilState(dataTableState);
  const [dataTable2, setDataTable2] = useRecoilState(dataTableState2);

  const [dataAssets, setDataAssets] = useRecoilState(editL4InfrDataItemState);

  const { closeOverlay } = useContext(OverlayContext);

  const token = useToken(); // Call useToken here at the top level

  const togglePopup = useTogglePopup(token);
  const togglePopup2 = useTogglePopup2(token);

  const saveSelectionPlaces = useSavePlaceSelection();
  const saveSelectionAssets = useSaveAssetsSelection();
  const [selectedCheckboxesPlaces, setSelectedCheckboxesPlaces] = useRecoilState(editL4selectedCheckboxesPlaces);
  useFetchPlaceUnitAssociations(token);
  const [dataPlaces, setDataPlaces] = useRecoilState(editL4SelectedPlaceUnitAssociationInfrastrutturaState);

  useEffect(() => {
    
    console.log('Currently selected checkbox IDs:', selectedCheckboxesPlaces);
  }, [selectedCheckboxesPlaces]); 


  //useFetchData(token);

  //useFetchData2(token);

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Luoghi accessibili</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup}>
              {/* <ButtonBaseText>{testoLuoghi}</ButtonBaseText> */}
              <ButtonBaseText>Apri per modificare</ButtonBaseText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Asset responsabile</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup2}>
              {/* <ButtonBaseText>{testoAsset}</ButtonBaseText> */}
              <ButtonBaseText>Apri per modificare</ButtonBaseText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>
        </CreaContainerColumn>

        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>SELEZIONA LUOGHI</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
            {/* <DataTable data={dataTable} sortConfig={sortConfig} filterText={filterText} globalFilterText={globalFilterText} /> */}

              <DataTable data={dataPlaces} sortConfig={sortConfig} filterText={filterText} globalFilterText={globalFilterText} />
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <div style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Annulla</ButtonText>
                </ButtonGrey>

                <ButtonGrey onClick={saveSelectionPlaces}>
                  <ButtonText>Salva</ButtonText>
                </ButtonGrey>
              </div>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>

        <LastPopupBlack2 isVisible={isVisible2}>
          <LastPopup2>
            <LastPopupTitleContainer>SELEZIONA ASSET</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <DataTable2 data={dataAssets} sortConfig={sortConfig2} filterText={filterText} globalFilterText={globalFilterText} />
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <div style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                <ButtonGrey onClick={togglePopup2}>
                  <ButtonText>Annulla</ButtonText>
                </ButtonGrey>

                <ButtonGrey onClick={saveSelectionAssets}>
                  <ButtonText>Salva</ButtonText>
                </ButtonGrey>
              </div>
            </LastPopupButtonContainer>
          </LastPopup2>
        </LastPopupBlack2>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_infrastructure.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
          La scheda rivela gli elementi dell’infrastruttura dell’Organizzazione così come risulta dall’inventario dettagliato degli asset e dei luoghi. Questi elementi sono organizzati in linea con il
          sistema di classificazione e catalogazione adottato dall'ente e sono associati all’entità selezionata.
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
