import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione"; // Correct path
import InfoIcon from "@mui/icons-material/InfoOutlined";

import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  CustomDropdownItem,
  CustomDropdownList,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  DropDownMenuScrollbar,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputDescriptionTextDouble,
  InputDescriptionTextTriple,
  InputTextbox,
  InputTextboxButton,
  InputTextboxDouble,
  InputTextboxTriple,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  PartiOrganizzazioneSelectedL1,
  PartiOrganizzazioneSelectedL1ID,
  PartiOrganizzazioneDirettore,
  PartiOrganizzazioneDirettoreID,
  PartiOrganizzazioneModello,
  PartiOrganizzazionePersonale,
  PartiOrganizzazioneDH,
  PartiOrganizzazioneOrdinaria,
  PartiLegalForm,
  PartiselectedLegalFormID,
  PartiselectedCategoryID,
  PartiselectedCategory,
} from "../Parti-Add-recoil";

import CancelIcon from "@mui/icons-material/Clear";
import {
  ArrowButtonText,
  ButtonGrey,
  ButtonText,
} from "../../../styled-components";
import {
  URL_DirectorList,
  URL_LV1List,
  URL_LegalForm,
  URL_OrgModel,
} from "../../../../../API";
import { TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_PARTI_2 } from "../../tabs-description";

const MainComponent = () => {
  const useToken = () => {
    const { keycloak, initialized } = useKeycloak();
    return initialized && keycloak.authenticated ? keycloak.token : null;
  };

  const token = useToken(); // Call useToken here at the top level

  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [dataLegalForm, setDataLegalForm] = useState<LegalFormDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  const [LV1List, setLV1List] = useRecoilState(PartiOrganizzazioneSelectedL1);
  const [LV1ListId, setLV1ListId] = useRecoilState(
    PartiOrganizzazioneSelectedL1ID
  );
  const [director, setDirector] = useRecoilState(PartiOrganizzazioneDirettore);
  const [directorId, setDirectorId] = useRecoilState(
    PartiOrganizzazioneDirettoreID
  );

  const [legalForm, setLegalForm] = useRecoilState(PartiLegalForm);
  const [legalFormId, setLegalFormId] = useRecoilState(
    PartiselectedLegalFormID
  );
  const [Modello, setModello] = useRecoilState(PartiOrganizzazioneModello);
  const [Personale, setPersonale] = useRecoilState(
    PartiOrganizzazionePersonale
  );
  const [category, setCategory] = useRecoilState(PartiselectedCategory);
  const [categoryID, setCategoryID] = useRecoilState(PartiselectedCategoryID);
  const [DH, setDH] = useRecoilState(PartiOrganizzazioneDH);
  const [Ordinaria, setOrdinaria] = useRecoilState(
    PartiOrganizzazioneOrdinaria
  );

  const { closeOverlay } = useContext(OverlayContext);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); // Close the message box
    closeOverlay();
  };

  const categoryOptions = [
    { name: "Cliente", id: "thirdparty.client" },
    { name: "Fornitore", id: "thirdparty.supplier" },
    { name: "Altro ente", id: "thirdparty.other" },
  ];

  /////////GET DIRECTORS////////////////

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch data from the API
        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response Data:", responseData);

        // Assuming responseData is the array you want to map
        const transformedData: DirectorDataItem[] = responseData.map(
          (item: DirectorApiContentItem) => {
            return {
              director_id: item.id,
              director_name: item.alias, // Changed from 'site_name' to 'name'

              // Map other properties as needed
            };
          }
        );

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data on component mount or when token changes
    fetchData();
  }, [token]); // Depend on the token

  ////////////////////

  /////FILTER INPUT DIRECTORS

  // Create a map from director names to IDs
  const directorNameToId = new Map(
    data.map((item) => [item.director_name, item.director_id])
  );

  const handleDirectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDirector(value);

    // If the value is a director name, also set the director ID
    if (directorNameToId.has(value)) {
      setDirectorId(directorNameToId.get(value) || "");
      console.log(directorNameToId.get(value) || "");
    }
  };

  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  ////////////////////TOGGLE DROPDOWN///////////

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Event listener to close dropdown if clicked outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Add a type guard for event.target
      if (
        dropdownRef.current &&
        event.target instanceof Node &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    // Clear the director name in the input box
    setDirector("");

    // Reset the director ID
    setDirectorId("");

    // Open the dropdown if it is not already open

    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (selectedDirectorId: string) => {
    // Find the director name corresponding to the selected ID
    const selectedDirectorName = data.find(
      (item) => item.director_id === selectedDirectorId
    )?.director_name;

    if (selectedDirectorName) {
      // Update the director name in the input box
      setDirector(selectedDirectorName);

      // Update the director ID
      setDirectorId(selectedDirectorId);
    }

    // Close the dropdown
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    console.log("Selected Director ID:", directorId);
  }, [directorId]); // This effect will run whenever directorId changes

  /// CATEGORY DROPDOWN
  const [isDropdownOpenCategory, setIsDropdownOpenCategory] = useState(false);
  const dropdownRefCategory = useRef<HTMLDivElement>(null);
  // Function to toggle the dropdown menu
  const handleDropdownToggleCategory = () => {
    setIsDropdownOpenCategory(!isDropdownOpenCategory);
  };

  // Function to clear the selected category
  const clearCategorySelection = () => {
    setCategoryID("");
    setIsDropdownOpenCategory(false);
  };

  // Function to handle category selection
  const handleOptionSelectCategory = (id: string) => {
    const selectedCategory = categoryOptions.find((option) => option.id === id);
    if (selectedCategory) {
      setCategory(selectedCategory.name);
      setCategoryID(selectedCategory.id);
    }
    setIsDropdownOpenCategory(false);
  };

  // Event listener to close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRefCategory.current &&
        event.target instanceof Node &&
        !dropdownRefCategory.current.contains(event.target)
      ) {
        setIsDropdownOpenCategory(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /////////GET LEGAL FORM////////////////

  type LegalFormApiContentItem = {
    id: string;
    category: string;
  };

  type LegalFormDataItem = {
    legalform_id: string;
    legalform_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch data from the API
        const response = await fetch(URL_LegalForm, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response Data:", responseData);

        // Assuming responseData is the array you want to map
        const transformedData: LegalFormDataItem[] = responseData
          .map((item: LegalFormApiContentItem) => ({
            legalform_id: item.id,
            legalform_name: item.category,
          }))
          .sort((a: LegalFormDataItem, b: LegalFormDataItem) =>
            a.legalform_name.localeCompare(b.legalform_name)
          ); // Sorting alphabetically

        console.log("Transformed Data:", transformedData);
        setDataLegalForm(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data on component mount or when token changes
    fetchData();
  }, [token]); // Depend on the token

  ////////////////////

  /////FILTER INPUT LEEGAL FORM

  // Create a map from director names to IDs
  const legalFormNameToId = new Map(
    dataLegalForm.map((item) => [item.legalform_id, item.legalform_name])
  );

  const handleLegalFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setLegalForm(value);

    // If the value is a director name, also set the director ID
    if (legalFormNameToId.has(value)) {
      setLegalFormId(legalFormNameToId.get(value) || "");
      console.log(legalFormNameToId.get(value) || "");
    }
  };

  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  ////////////////////TOGGLE DROPDOWN///////////

  const [isDropdownOpenLegalForm, setIsDropdownOpenLegalForm] = useState(false);
  const dropdownRefLegalForm = useRef<HTMLDivElement>(null);

  // Event listener to close dropdown if clicked outside
  useEffect(() => {
    function handleClickOutsideLegalForm(event: MouseEvent) {
      // Add a type guard for event.target
      if (
        dropdownRefLegalForm.current &&
        event.target instanceof Node &&
        !dropdownRefLegalForm.current.contains(event.target)
      ) {
        setIsDropdownOpenLegalForm(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutsideLegalForm);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutsideLegalForm);
    };
  }, []);

  const handleDropdownToggleLegalForm = () => {
    // Clear the director name in the input box
    setLegalForm("");

    // Reset the director ID
    setLegalForm("");

    // Open the dropdown if it is not already open

    setIsDropdownOpenLegalForm(!isDropdownOpenLegalForm);
  };

  const handleOptionSelectLegalForm = (selectedLegalFormId: string) => {
    // Find the director name corresponding to the selected ID
    const selectedLegalFormName = dataLegalForm.find(
      (item) => item.legalform_id === selectedLegalFormId
    )?.legalform_name;

    if (selectedLegalFormName) {
      // Update the director name in the input box
      setLegalForm(selectedLegalFormName);

      // Update the director ID
      setLegalFormId(selectedLegalFormId);
    }

    // Close the dropdown
    setIsDropdownOpenLegalForm(false);
  };

  useEffect(() => {
    console.log("Selected Legal Form ID:", legalFormId);
  }, [legalFormId]); // This effect will run whenever directorId changes

  ///////////////////////////////////LV1 ITEMLIST

  type LV1ApiContentItem = {
    id: string;
    alias: string;
  };

  type LV1DataItem = {
    LV1_id: string;
    LV1_name: string;
  };

  const [dataLV1, setDataLV1] = useState<LV1DataItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch dataLV1 from the API
        const response = await fetch(URL_LV1List, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response dataLV1:", responseData);

        // Access the content array from the responseData
        const contentArray = responseData.content; // This line is added to access the content field

        // Check if contentArray is indeed an array
        if (!Array.isArray(contentArray)) {
          throw new Error("Expected content to be an array");
        }

        // Map the content array to transform the dataLV1
        const transformedData: LV1DataItem[] = contentArray.map(
          (item: LV1ApiContentItem) => {
            return {
              LV1_id: item.id,
              LV1_name: item.alias, // Changed from 'site_name' to 'name'
              // Map other properties as needed
            };
          }
        );

        console.log("Transformed dataLV1:", transformedData);
        setDataLV1(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch dataLV1 on component mount or when token changes
    fetchData();
  }, [token]); // Depend on the token

  ////////////////////

  /////FILTER INPUT LV1

  // Create a map from director names to IDs
  const LV1NameToId = new Map(
    dataLV1.map((item) => [item.LV1_name, item.LV1_id])
  );

  const handleLV1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLV1List(value);

    // If the value is a director name, also set the director ID
    if (LV1NameToId.has(value)) {
      setLV1ListId(LV1NameToId.get(value) || "");
      console.log(LV1NameToId.get(value) || "");
    }
  };

  ////////////////////TOGGLE DROPDOWN///////////

  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useState(false);
  const dropdownRefLV1 = useRef<HTMLDivElement>(null);

  // Event listener to close dropdown if clicked outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Add a type guard for event.target
      if (
        dropdownRefLV1.current &&
        event.target instanceof Node &&
        !dropdownRefLV1.current.contains(event.target)
      ) {
        setIsDropdownOpenLV1(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggleLV1 = () => {
    setLV1List("");
    setLV1ListId("");
    setIsDropdownOpenLV1(!isDropdownOpenLV1);
  };

  const handleOptionSelectLV1 = (selectedDirectorId: string) => {
    // Find the director name corresponding to the selected ID
    const selectedLV1Name = dataLV1.find(
      (item) => item.LV1_id === selectedDirectorId
    )?.LV1_name;

    if (selectedLV1Name) {
      // Update the director name in the input box
      setLV1List(selectedLV1Name);

      // Update the director ID
      setLV1ListId(selectedDirectorId);
    }

    // Close the dropdown
    setIsDropdownOpenLV1(false);
  };

  useEffect(() => {
    console.log("Selected LV1ListId ID:", LV1ListId);
  }, [LV1ListId]); // This effect will run whenever directorId changes

  const ClearLV1 = () => {
    setLV1List("");
    setLV1ListId("");
  };

  const ClearLV2 = () => {
    setDirector("");
    setDirectorId("");
  };

  const ClearCategory = () => {
    setCategory("");
    setCategoryID("");
  };

  const ClearLegalForm = () => {
    setLegalForm("");
    setLegalFormId("");
  };
  /////////////////////// END LIST LV1 ////////

  ///////////////// LAST POPUP

  const [isVisible, setIsVisible] = useState(false);
  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  interface LastPopupProps {
    isVisible: boolean;
  }

  const LastPopupBlack = styled.div<LastPopupProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  `;

  const LastPopup = styled.div`
    font-family: var(--font-titillium-web);
    font-size: var(--font-size-micro);
    color: var(--color-steelblue-700);
    position: fixed;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-aliceblue);

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    //border: 5px solid #ffffff;
    z-index: 100;
  `;

  ///////////////////MODEL SELECTOR

  const [Modeldata, setModelData] = useState<Model[]>([]);
  const [selectedModelId, setSelectedModelId] = useState("");
  const [selectedModelName, setSelectedModelName] = useState("");
  const selectedModel = Modeldata.find((model) => model.id === selectedModelId);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const infoResponse = await fetch(`${URL_OrgModel}/info`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!infoResponse.ok) {
          throw new Error(`API request failed: ${infoResponse.statusText}`);
        }

        const { total } = await infoResponse.json();

        const dataResponse = await fetch(`${URL_OrgModel}?size=${total}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!dataResponse.ok) {
          throw new Error(`API request failed: ${dataResponse.statusText}`);
        }

        const responseData: { content: Model[] } = await dataResponse.json();

        if (responseData && Array.isArray(responseData.content)) {
          setModelData(responseData.content);
          // Set the selectedModelId to the id of the first model
          setSelectedModelId(responseData.content[0].id);
        } else {
          console.error(
            "responseData.content is not an array or does not exist:",
            responseData
          );
        }
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]); // Adjust this line if token is not a fixed value or comes from props/state

  const handleModelChange = (newModelId: string) => {
    setSelectedModelId(newModelId);
    setModello(newModelId);
    console.log(newModelId);
  };

  interface Model {
    id: string;
    // ... other properties of the model
    roles: Role[]; // Ensure this property exists in your Model interface

    model_name: string; // Added model_name
    responsible_role: {
      // Adjusted to include responsible role details
      id: string;
      role_description: string;
      responsibility: string;
    };
  }

  interface ModelSelectorProps {
    models: Model[];
    onModelChange: (newModelId: string) => void; // Now expects a string
  }

  // Subcomponent for selecting models
  const ModelSelector: React.FC<ModelSelectorProps> = ({
    models,
    onModelChange,
  }) => {
    return (
      <FormControl component="fieldset">
        <FormLabel
          component="legend"
          sx={{ color: "#04446c", padding: "8px" }}
        ></FormLabel>

        <RadioGroup
          aria-label="model"
          name="model"
          value={selectedModelId}
          onChange={(event) => onModelChange(event.target.value)}
          sx={{ padding: "5px 10px 10px 20px" }}
        >
          {models.map((model) => (
            <FormControlLabel
              key={model.id}
              value={model.id}
              control={
                <Radio sx={{ "& .MuiSvgIcon-root": { fontSize: "1rem" } }} />
              } // Adjust the size as needed
              label={model.model_name}
              sx={{
                color: "primary.main",
                "& .MuiTypography-root": { fontSize: "0.875rem" },
              }} // Adjust the color and size of the label
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };

  // Define a Role interface based on the actual structure of your role objects
  interface Role {
    id: string;
    role_description: string;
    responsibility: string;
    // ... other role properties
  }

  // Define the props for the RolesAccordion component
  interface RolesAccordionProps {
    roles: Role[];
  }

  const RolesAccordion: React.FC<
    RolesAccordionProps & { responsibleRole: Model["responsible_role"] }
  > = ({ roles, responsibleRole }) => {
    return (
      <Box sx={{ mt: 2 }}>
        {/* Accordion for Responsible Role */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{responsibleRole.role_description}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{responsibleRole.responsibility}</Typography>
          </AccordionDetails>
        </Accordion>

        {/* Existing Role Accordions */}
        {roles.map((role: Role) => (
          <Accordion key={role.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{role.role_description}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{role.responsibility}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  /////////////////////////////////////////

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          {/* <InputContainer>
            <InputDescriptionText>Forma giuridica</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup}>
            <ArrowButtonText>Apri per selezionare</ArrowButtonText> </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer> */}

          <InputContainer>
            <InputDescriptionText>
              Forma giuridica
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>

            <InputTextbox
              list="legalform-list"
              value={legalForm}
              onChange={handleLegalFormChange}
              onClick={handleDropdownToggleLegalForm}
              placeholder="Seleziona"
            />
            {legalForm && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6", // Change the color if needed
                }}
                onClick={ClearLegalForm} // Clears the input field
              />
            )}
            <TriangleContainer>
              {isDropdownOpenLegalForm ? "▲" : "▼"}{" "}
              {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpenLegalForm && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefLegalForm}>
                  {dataLegalForm
                    .filter((item) =>
                      item.legalform_name
                        .toLowerCase()
                        .includes(legalForm.toLowerCase())
                    )
                    .map((filteredItem, index) => (
                      <CustomDropdownItem
                        key={index}
                        onClick={() =>
                          handleOptionSelectLegalForm(filteredItem.legalform_id)
                        }
                      >
                        {filteredItem.legalform_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              Componente organizzativa
            </InputDescriptionText>

            <InputTextbox
              list="l1-list"
              value={LV1List}
              onChange={handleLV1Change}
              onClick={handleDropdownToggleLV1}
              placeholder="Seleziona"
            />
            {LV1List && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6", // Change the color if needed
                }}
                onClick={ClearLV1} // Clears the input field
              />
            )}
            <TriangleContainer>
              {isDropdownOpenLV1 ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpenLV1 && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefLV1}>
                  {dataLV1
                    .filter((item) =>
                      item.LV1_name.toLowerCase().includes(
                        LV1List.toLowerCase()
                      )
                    )
                    .map((filteredItem, index) => (
                      <CustomDropdownItem
                        key={index}
                        onClick={() =>
                          handleOptionSelectLV1(filteredItem.LV1_id)
                        }
                      >
                        {filteredItem.LV1_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionTextTriple>
              Ambito d'interesse {DH.length}/255
            </InputDescriptionTextTriple>
            <InputTextboxTriple
              placeholder=""
              value={DH}
              onChange={(e) => setDH(e.target.value)}
              maxLength={255}
            />
            {/* <IconContainer /> */}
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Categoria
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox
              placeholder="Seleziona"
              value={category}
              readOnly
              onClick={handleDropdownToggleCategory}
            />
            {categoryID && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6",
                }}
                onClick={clearCategorySelection} // Adjust the function to clear the selection
              />
            )}
            <TriangleContainer>
              {isDropdownOpenCategory ? "▲" : "▼"}{" "}
              {/* Change icons as needed */}
            </TriangleContainer>
            {isDropdownOpenCategory && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefCategory}>
                  {categoryOptions.map((option, index) => (
                    <CustomDropdownItem
                      key={index}
                      onClick={() => handleOptionSelectCategory(option.id)}
                    >
                      {option.name}
                    </CustomDropdownItem>
                  ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Legale rappresentante</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={Ordinaria}
              onChange={(e) => setOrdinaria(e.target.value)}
            />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Responsabile</InputDescriptionText>

            <InputTextbox
              list="directors-list"
              value={director}
              onChange={handleDirectorChange}
              onClick={handleDropdownToggle}
              placeholder="Seleziona"
            />
            {director && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6", // Change the color if needed
                }}
                onClick={ClearLV2} // Clears the input field
              />
            )}
            <TriangleContainer>
              {isDropdownOpen ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpen && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRef}>
                  {data
                    .filter((item) =>
                      item.director_name
                        .toLowerCase()
                        .includes(director.toLowerCase())
                    )
                    .map((filteredItem, index) => (
                      <CustomDropdownItem
                        key={index}
                        onClick={() =>
                          handleOptionSelect(filteredItem.director_id)
                        }
                      >
                        {filteredItem.director_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          {/* <InputContainer>
            <InputDescriptionText>Personale</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={Personale}
              onChange={(e) => setPersonale(e.target.value)}
            />
            <IconContainer />
          </InputContainer> */}
        </CreaContainerColumn>

        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>SELEZIONA MODELLO</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <LastPopupSearch placeholder="Cerca" />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer>

              <LastPopupScrollbar>
                {/* MUI TreeView to visualize the data */}
                <Grid container spacing={2} sx={{ width: "100%" }}>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        backgroundColor: "#fff", // Set the background color to white
                        borderRadius: "4px", // Set the border-radius to 4px
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Apply a shadow
                        padding: "0px 10px 10px 5px", // Optional: Add padding inside the box if needed
                        marginTop: "16px",
                        marginLeft: "20px",
                      }}
                    >
                      <ModelSelector
                        models={Modeldata}
                        onModelChange={handleModelChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    {selectedModelId &&
                      selectedModel &&
                      selectedModel.roles && (
                        <RolesAccordion
                          roles={selectedModel.roles}
                          responsibleRole={selectedModel.responsible_role}
                        />
                      )}
                  </Grid>
                </Grid>
              </LastPopupScrollbar>
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <ButtonGrey onClick={togglePopup}>
                <ButtonText>Chiudi</ButtonText>
              </ButtonGrey>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L2-organizzazione.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
        {TAB_DESCRIPTION_PARTI_2 ? TAB_DESCRIPTION_PARTI_2 : TAB_DESCRIPTION_GLOBAL}
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
