import React, {
  useState,
  useEffect,
  useRef,
  RefObject,
  useContext,
  useMemo,
  useCallback,
} from "react";
import styled from "styled-components";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione"; // Correct path
import { Checkbox, Divider } from "@mui/material";
import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/Circle";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { debounce } from "lodash";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  CustomDropdownItem,
  CustomDropdownList,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  DropDownMenuScrollbar,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  InputTextboxButton,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  SelectedL4ID,
  SelectedL1ID,
  selectedCheckboxesCompliance,
  isLoadingRec,
  setErrorRec,
  isMessageBoxVisibleRec,
  setMessageRec,
} from "../../../../../recoil";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  ArrowButtonText,
  ButtonBaseText,
  ButtonGrey,
  ButtonText,
  NavButtonWhiteText,
  NavMessageText,
} from "../../../styled-components";
import {
  IconContainerList,
  ListContainer,
  ListScrollbar,
  ListTextbox,
} from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import {
  SearchIcon,
  SearchInputContainer,
  SearchInputContainerSolo,
  SearchPopup,
  StyledActionTh,
  StyledTable,
  StyledTableItem,
  StyledTbody,
  StyledTd,
  StyledTdCheckbox,
  StyledTdUnitaOperativa,
  StyledTh,
  StyledThead,
  StyledUnitaOperativaTh,
  TableContainerPopup,
  TableItemText,
  TableScrollableDivPopup,
  TableTitleText,
  TopContainer,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-unitaoperative-styled-components";

import {
  ProcessiAttachedFilesInfo,
  ProcessiComplianceDate,
  ProcessiComplianceResponsible,
  ProcessiDescription,
  ProcessiID,
  ProcessiDocumentsAttached,
  ProcessiDocumentsPath,
  ProcessiKind,
  ProcessiName,
  ProcessiObjective,
  ProcessiComplianceResponsibleID,
  ProcessiIsDropdownOpenDirectors,
  LV1DataItemState,
  LV1DataItem,
  directorDataState,
  DirectorDataItem,
  ProcessiOrganizzazioneSelectedL1,
  Processi_2_isDropdownOpenLV1,
  ProcessiConfirmedLV1List,
  ProcessiOrganizzazioneSelectedL1ID,
  ProcessiIsDropdownOpenDirectorsCompliance,
  ProcessiIsVisible,
  ProcessiSearchTerm,
  addProcessComplianceDataState,
  addProcessSelectedCheckboxesProcessiState,
  ProcessiSearchTermProcessi,
  addProcessComplianceData,
} from "../Processi-Add-recoil";

import {
  URL_ComplianceProcessList,
  URL_DirectorList,
  URL_L2ByL1,
  URL_LV1List,
  URL_OrgCard,
  URL_PersonRoles,
  URL_ProcessPost,
} from "../../../../../API";
import { addAssetLV2ListID } from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/ElencoAsset-Add-recoil";
import { TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_PROCESSI_BUSINESS_6 } from "../../tabs-description";

const useToken = () => {
  const { keycloak, initialized } = useKeycloak();
  return initialized && keycloak.authenticated ? keycloak.token : null;
};

//////////// POPUP

interface LastPopupProps {
  isVisible: boolean;
}

const LastPopupBlack = styled.div<LastPopupProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  //border: 5px solid #ffffff;
  z-index: 100;
`;

///////////// POPUP END

// Search input component
interface SearchInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchInput: React.FC<SearchInputProps> = React.memo(
  ({ value, onChange }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    // useEffect(() => {
    //   // Automatically focus the input when the component mounts
    //   if (inputRef.current) {
    //     inputRef.current.focus();
    //   }
    // }, []);
    return (
      <LastPopupSearch
        ref={inputRef}
        type="text"
        value={value}
        onChange={onChange}
        placeholder="Cerca"
      />
    );
  }
);

type DirectorApiContentItem = {
  id: string;
  alias: string;
};

interface SearchInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type LV1ApiContentItem = {
  id: string;
  alias: string;
};

const useHandleCloseAndNavigate = () => {
  const [, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const { closeOverlay } = useContext(OverlayContext);
  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); // Close the message box
    //closeOverlay(); // Ensure closeOverlay is defined or imported
  };

  return handleCloseAndNavigate;
};

//LV1 LIST FETCH

const useFetchDataLv1 = (token: string | undefined | null) => {
  const [dataLV1, setDataLV1] = useRecoilState(LV1DataItemState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(
    isMessageBoxVisibleRec
  );
  const [message, setMessage] = useRecoilState(setMessageRec);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch dataLV1 from the API
        const response = await fetch(URL_LV1List, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response dataLV1:", responseData);

        // Access the content array from the responseData
        const contentArray = responseData.content; // This line is added to access the content field

        // Check if contentArray is indeed an array
        if (!Array.isArray(contentArray)) {
          throw new Error("Expected content to be an array");
        }

        // Map the content array to transform the dataLV1
        const transformedData: LV1DataItem[] = contentArray.map(
          (item: LV1ApiContentItem) => {
            return {
              LV1_id: item.id,
              LV1_name: item.alias, // Changed from 'site_name' to 'name'
              // Map other properties as needed
            };
          }
        );

        console.log("Transformed dataLV1:", transformedData);
        setDataLV1(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  return dataLV1;
};
/////// LV1 LIST END
// GROUP COMPLIANCE BY PROCESS

const groupComplianceByBusinessProcess = (
  complianceData: addProcessComplianceData[]
) => {
  return complianceData.reduce((acc, item) => {
    const bpId = item.id;
    if (!acc[bpId]) {
      acc[bpId] = [];
    }
    acc[bpId].push(item);
    return acc;
  }, {} as { [key: string]: addProcessComplianceData[] });
};

// GROUP COMPLIANCE BY PROCESS END
// ACCORDION
interface RolesAccordionProps {
  token: string | null | undefined;
}

const RolesAccordion: React.FC<RolesAccordionProps> = ({ token }) => {
  const { complianceData } = useFetchComplianceData(token);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [searchTerm, setSearchTerm] = useRecoilState(
    ProcessiSearchTermProcessi
  );
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(
    addProcessSelectedCheckboxesProcessiState
  );

  const handleCheckboxChange = (itemId: string, checked: boolean) => {
    setSelectedCheckboxes((prevSelected) => {
      const updatedSelected = checked
        ? [...prevSelected, itemId]
        : prevSelected.filter((id) => id !== itemId);
      return updatedSelected;
    });
  };

  useEffect(() => {
    console.log("Selected checkboxes:", selectedCheckboxes);
  }, [selectedCheckboxes]);

  // useEffect(() => {
  //   // Initialize the selectedCheckboxes state with IDs of initially selected items
  //   const initiallySelected = complianceData.filter((item) => item.selected).map((item) => item.id);
  //   setSelectedCheckboxes(initiallySelected);
  // }, [complianceData, setSelectedCheckboxes]);

  const filteredComplianceData = useMemo(
    () =>
      searchTerm
        ? complianceData.filter(
            (dataItem) =>
              dataItem.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              dataItem.description
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              dataItem.kind.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : complianceData,
    [complianceData, searchTerm]
  );

  return (
    <Box sx={{ mt: 2, ml: 4, width: "100%" }}>
      <Accordion expanded={true}>
        <AccordionSummary
          sx={{
            height: "10px", // Set the height to 10px
            minHeight: "10px !important", // Important to override minimum height styles from MUI
            padding: "0px", // Remove padding to keep the element compact
            alignItems: "center", // Ensures content is vertically centered (may not be visible due to small height)
          }}
        >
          <Typography color="#04446c"></Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ flexDirection: "column" }}>
          {isLoading && <Typography>Loading...</Typography>}
          {error && <Typography color="error">{error}</Typography>}
          {Object.entries(
            groupComplianceByBusinessProcess(filteredComplianceData)
          ).length > 0 ? (
            Object.entries(
              groupComplianceByBusinessProcess(filteredComplianceData)
            ).map(([businessProcessId, complianceItems]) => (
              <Accordion
                expanded={true}
                key={businessProcessId}
                sx={{ mt: 1, boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)" }}
              >
                <AccordionSummary
                  sx={{
                    paddingTop: "0px",
                    fontWeight: 500,
                    fontStyle: "italic",
                    marginBottom: "-20px",
                  }}
                >
                  <Typography>{complianceItems[0]?.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {complianceItems.map((item, index) => (
                    <Box key={index} sx={{ mt: 1 }}>
                      <Divider sx={{ my: 2 }} />
                      <Typography>
                        <br />
                      </Typography>
                      <Typography>Categoria: {item.kind}</Typography>
                      <Typography>
                        <br />
                      </Typography>
                      <Typography>Descrizione: {item.description}</Typography>
                      <Typography>
                        <br />
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCheckboxes.includes(item.id)}
                            onChange={(e) =>
                              handleCheckboxChange(item.id, e.target.checked)
                            }
                            inputProps={{ "aria-label": "Is Responsible" }}
                            disabled={false}
                            sx={{
                              color: "#04446c",
                              "& .MuiSvgIcon-root": { fontSize: 16 },
                              "&.Mui-checked": { color: "#04446c" },
                              padding: "0px",
                              marginRight: "10px",
                            }}
                          />
                        }
                        label="Questo processo Compliance è associato al processo generale selezionato"
                        sx={{
                          marginTop: "10px",
                          marginLeft: "0px",
                          color: "#04446c",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                        labelPlacement="end"
                      />
                      <Typography>
                        <br />
                      </Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Typography sx={{ textAlign: "center", width: "100%", my: 2 }}>
              Nessun dato esistente.
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

// ACCORDION END

// FETCH COMPLIANCE DATA

const useFetchComplianceData = (token: string | null | undefined) => {
  const [complianceData, setComplianceData] = useRecoilState(
    addProcessComplianceDataState
  );
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(
    addProcessSelectedCheckboxesProcessiState
  );
  const [LV2ListId, setLV2ListId] = useRecoilState(addAssetLV2ListID);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${URL_ComplianceProcessList}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }
        const json = await response.json();

        const mappedData = json.map((item: any) => ({
          id: item.id,
          name: item.name,
          kind: item.kind,

          description: item.description,
        }));

        setComplianceData(mappedData);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchData();
    }
  }, [token, setIsLoading, setError, setComplianceData]);

  return { complianceData };
};

// FETCH COMPLIANCE DATA END
/// FETCH DATA

const useFetchDirectorData = (token: string | null | undefined) => {
  const [dataDirector, setDataDirector] = useRecoilState(directorDataState);

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(
    isMessageBoxVisibleRec
  );
  const [message, setMessage] = useRecoilState(setMessageRec);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch data from the API
        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response Data:", responseData);

        // Assuming responseData is the array you want to map
        const transformedData: DirectorDataItem[] = responseData.map(
          (item: DirectorApiContentItem) => {
            return {
              director_id: item.id,
              director_name: item.alias, // Changed from 'site_name' to 'name'

              // Map other properties as needed
            };
          }
        );

        console.log("Transformed Data:", transformedData);
        setDataDirector(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [token]);
};

//////// DATA FETCH END

// HANDLE LV1 CHANGE

const useHandleLV1Change = () => {
  const [LV1List, setLV1List] = useRecoilState(
    ProcessiOrganizzazioneSelectedL1
  );

  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLV1List(event.target.value);
    },
    [setLV1List]
  );
};

/////

// HANDLE DROP DOWN TOGGLE

export const useDropdownToggleLV1 = () => {
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(
    Processi_2_isDropdownOpenLV1
  );
  const [, setConfirmedLV1List] = useRecoilState(ProcessiConfirmedLV1List);
  const [LV1List] = useRecoilState(ProcessiOrganizzazioneSelectedL1);

  const handleDropdownToggleLV1 = () => {
    if (!isDropdownOpenLV1) {
      setConfirmedLV1List(LV1List);
    }
    setIsDropdownOpenLV1(!isDropdownOpenLV1);
  };

  return handleDropdownToggleLV1;
};

////// DROP DOWN TOGGLE END

////// SELECT LV1

const useHandleOptionSelectLV1 = (data: LV1DataItem[]) => {
  const [dataLV1, setDataLV1] = useRecoilState(LV1DataItemState);
  const [LV1List, setLV1List] = useRecoilState(
    ProcessiOrganizzazioneSelectedL1
  );
  const handleDropdownToggleLV1 = useDropdownToggleLV1();
  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(
    ProcessiConfirmedLV1List
  );
  const [LV1ListId, setLV1ListId] = useRecoilState(
    ProcessiOrganizzazioneSelectedL1ID
  );
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(
    Processi_2_isDropdownOpenLV1
  );

  const handleOptionSelectLV1 = useCallback(
    (selectedLV1Id: string) => {
      console.log(`Handle LV1 ID: ${selectedLV1Id}`); // Log the selected LV1 ID for debugging
      console.log("Selected ID:", selectedLV1Id);
      console.log("DataLV1:", dataLV1);
      const selectedLV1Name = dataLV1.find(
        (item) => item.LV1_id === selectedLV1Id
      )?.LV1_name;

      if (selectedLV1Name) {
        setLV1List(selectedLV1Name); // Update input text
        setConfirmedLV1List(selectedLV1Name); // Confirm the selection
        setLV1ListId(selectedLV1Id);
        setL1ID(selectedLV1Id);
        setIsDropdownOpenLV1(false); // Close dropdown
      }
    },
    [dataLV1]
  );

  return handleOptionSelectLV1;
};

//SELECT LV1 END

// OUTSIDE CLICK LV1

const useOutsideClickLV1 = (ref: RefObject<HTMLDivElement>) => {
  const [LV1List, setLV1List] = useRecoilState(
    ProcessiOrganizzazioneSelectedL1
  );
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(
    Processi_2_isDropdownOpenLV1
  );
  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(
    ProcessiConfirmedLV1List
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (isDropdownOpenLV1) {
          setLV1List(confirmedLV1List); // Revert to confirmed value
          setIsDropdownOpenLV1(false); // Close dropdown
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    ref,
    confirmedLV1List,
    isDropdownOpenLV1,
    setLV1List,
    setIsDropdownOpenLV1,
  ]);
};

// OUTSIDE CLICK END

/// HANDEL OPTION SELECT DIRECTOR

const useHandleOptionSelectDirector = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useRecoilState(
    ProcessiIsDropdownOpenDirectorsCompliance
  );
  const [dataDirector] = useRecoilState(directorDataState);
  const [, setDirector] = useRecoilState(ProcessiComplianceResponsible);
  const [, setDirectorId] = useRecoilState(ProcessiComplianceResponsibleID);

  const handleSelectDirector = useCallback(
    (selectedDirectorId: string) => {
      const selectedDirector = dataDirector.find(
        (director) => director.director_id === selectedDirectorId
      );

      if (selectedDirector) {
        setDirector(selectedDirector.director_name);
        setDirectorId(selectedDirector.director_id);
        setIsDropdownOpen(false); // Close the dropdown after selection
      }
    },
    [dataDirector, setDirector, setDirectorId, setIsDropdownOpen]
  );

  return handleSelectDirector;
};

/// HANDLE DIRECTOR CHANGE

const useHandleDirectorChange = () => {
  const [dataDirector] = useRecoilState(directorDataState);
  const [, setDirector] = useRecoilState(ProcessiComplianceResponsible);
  const [, setDirectorId] = useRecoilState(ProcessiComplianceResponsibleID);

  // Create a memoized function to handle the input change
  const handleDirectorChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      // Map creation could potentially be moved outside of the callback if the dataDirector state doesn't change frequently
      const directorNameToId = new Map(
        dataDirector.map((item) => [item.director_name, item.director_id])
      );

      setDirector(value);

      // If the value matches a director name, also set the director ID
      if (directorNameToId.has(value)) {
        setDirectorId(directorNameToId.get(value) || "");
      }
    },
    [dataDirector, setDirector, setDirectorId]
  );

  return handleDirectorChange;
};

// DIRECTOR CHANGE END

// DROP DOWN TOGGLE DIRECTOR

const useDropdownToggleDirectors = () => {
  const [, setDirector] = useRecoilState(ProcessiComplianceResponsible);
  const [, setDirectorId] = useRecoilState(ProcessiComplianceResponsibleID);
  const [isDropdownOpen, setIsDropdownOpen] = useRecoilState(
    ProcessiIsDropdownOpenDirectorsCompliance
  );

  // Use useCallback to memoize the function, so it doesn't get recreated on every render
  const handleDropdownToggleDirector = useCallback(() => {
    setDirector("");
    setDirectorId("");
    setIsDropdownOpen(!isDropdownOpen);
  }, [isDropdownOpen, setDirector, setDirectorId, setIsDropdownOpen]);

  return handleDropdownToggleDirector;
};

// DROPDOWN TOGGLE END

// OUTSIDE CLICK director

const useOutsideClickDirectors = (ref: RefObject<HTMLDivElement>) => {
  // Use the state related to the directors' dropdown visibility
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(
    ProcessiIsDropdownOpenDirectorsCompliance
  );
  // Optionally, if you want to revert to a confirmed director when clicking outside, use the state that holds the confirmed director

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (isDropdownOpenDirectors) {
          setIsDropdownOpenDirectors(false); // Close the dropdown
          // Optionally, revert to the confirmed director
          // setDirector(confirmedDirector);
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    isDropdownOpenDirectors,
    setIsDropdownOpenDirectors /* confirmedDirector, setDirector */,
  ]);
};

// OUTSIDE CLICK END

// CLEAR LV2

const useClearLV2 = () => {
  const [, setL1ID] = useRecoilState(SelectedL1ID);
  const [, setDirector] = useRecoilState(ProcessiComplianceResponsible);
  const [, setDirectorId] = useRecoilState(ProcessiComplianceResponsibleID);

  // Define the clear function using useCallback to ensure it's only recreated if one of the dependencies changes
  const clearLV2 = useCallback(() => {
    setL1ID("");
    setDirector("");
    setDirectorId("");
  }, [setL1ID, setDirector, setDirectorId]);

  return clearLV2;
};

// CLEAR LV2 END

const MainComponent = () => {
  const token = useToken();

  const [dataDirector, setDataDirector] = useRecoilState(directorDataState);

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(
    isMessageBoxVisibleRec
  );
  const [message, setMessage] = useRecoilState(setMessageRec);

  const [LV1List, setLV1List] = useRecoilState(
    ProcessiOrganizzazioneSelectedL1
  );
  const [LV1ListId, setLV1ListId] = useRecoilState(
    ProcessiOrganizzazioneSelectedL1ID
  );
  const [director, setDirector] = useRecoilState(ProcessiComplianceResponsible);
  const [directorId, setDirectorId] = useRecoilState(
    ProcessiComplianceResponsibleID
  );
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);

  const [dataLV1, setDataLV1] = useState<LV1DataItem[]>([]);
  const finalDataLV1 = useFetchDataLv1(token);

  const [complianceDate, setComplianceDate] = useRecoilState(
    ProcessiComplianceDate
  );

  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(
    ProcessiConfirmedLV1List
  );

  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(
    Processi_2_isDropdownOpenLV1
  );
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(
    ProcessiIsDropdownOpenDirectorsCompliance
  );

  const [isVisible, setIsVisible] = useRecoilState(ProcessiIsVisible);

  const [searchTerm, setSearchTerm] = useRecoilState(ProcessiSearchTerm);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const dropdownRefDirector = useRef<HTMLDivElement>(null);
  const dropdownRefLV1 = useRef<HTMLDivElement>(null);

  const handleCloseAndNavigate = useHandleCloseAndNavigate();

  const handleDropdownToggleLV1 = useDropdownToggleLV1();
  const handleOptionSelectLV1 = useHandleOptionSelectLV1(dataLV1);
  const handleLV1Change = useHandleLV1Change();

  const handleDirectorChange = useHandleDirectorChange();
  const handleOptionSelectDirector = useHandleOptionSelectDirector();
  const handleDropdownToggleDirectors = useDropdownToggleDirectors();

  const ClearLV2 = useClearLV2();

  const { closeOverlay } = useContext(OverlayContext);

  useOutsideClickLV1(dropdownRefLV1);
  useOutsideClickDirectors(dropdownRefDirector);
  //useOutsideClickDirectors(dropdownRefDirector);

  useFetchDirectorData(token);
  const { complianceData } = useFetchComplianceData(token);

  useEffect(() => {
    // Find the item in finalDataLV1 that matches the LV1ListId
    const selectedLV1 = finalDataLV1.find((item) => item.LV1_id === LV1ListId);
    // If found, update the LV1List to the name (alias) of the found item
    if (selectedLV1) {
      setLV1List(selectedLV1.LV1_name);
    }
  }, [LV1ListId, finalDataLV1, setLV1List]); // Dependencies array includes LV1ListId, finalDataLV1, and setLV1List

  const togglePopup = () => {
    setIsVisible(!isVisible);
  };
  // useEffect(() => {
  //   console.log("Selected LV1ListId ID:", LV1ListId, "L1ID: ", L1ID);
  // }, [LV1ListId, L1ID]); // This effect will run whenever directorId changes

  // useEffect(() => {
  //   console.log("Selected Director ID:", directorId);
  // }, [directorId]); // This effect will run whenever directorId changes

  // When clicking outside, ensure to revert to confirmed value if dropdown was open

  // const ClearLV1 = () => {
  //   setLV1List("");
  //   setLV1ListId("");
  //   setL1ID("");

  //   setSelectedUnitCheckboxes([]);
  //   setSelectedRolesRadio({});
  //   setDataTable([]); //wassat
  //   setTestoRuoli("Apri per modificare");
  //   setTestoUnitaOperative("Apri per modificare");
  // };

  /////////////////////////////////////////

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Responsabile Compliance</InputDescriptionText>

            <InputTextbox
              list="directors-list"
              value={director}
              onChange={handleDirectorChange}
              onClick={handleDropdownToggleDirectors}
              placeholder="Seleziona"
            />
            {director && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6", // Change the color if needed
                }}
                onClick={ClearLV2} // Clears the input field
              />
            )}
            <TriangleContainer>
              {isDropdownOpenDirectors ? "▲" : "▼"}{" "}
              {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpenDirectors && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefDirector}>
                  {dataDirector
                    .filter((item) =>
                      item.director_name
                        .toLowerCase()
                        .includes(director.toLowerCase())
                    )
                    .map((filteredItem, index) => (
                      <CustomDropdownItem
                        key={index}
                        onClick={() =>
                          handleOptionSelectDirector(filteredItem.director_id)
                        }
                      >
                        {filteredItem.director_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Processi Compliance</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup}>
              <ArrowButtonText>Apri per selezionare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Data inizio incarico</InputDescriptionText>
            {/* <InputTextbox placeholder="" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} /> */}
            <DatePicker
              //label="Data inizio incarico"
              value={complianceDate}
              format="DD-MM-YYYY"
              onChange={(newDate: dayjs.Dayjs | null) =>
                setComplianceDate(newDate)
              }
              sx={{
                "& .MuiInputBase-input": {
                  height: "2px", // Example to change the height
                },
                ".MuiInputBase-root": {
                  // Background color for the input field
                  borderRadius: "0px",
                  backgroundColor: "#ffffff", // Example background color
                  marginBottom: "20px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#cccccc", // Your default border color
                  },
                },
                // Hover state
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#cccccc", // Your hover border color
                },
                // Focused state
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1a81c3", // Your focused border color
                  borderWidth: "1px",
                  boxShadow: "0px 4px 3px rgba(0, 0, 0, 0.1)",
                },
                ".MuiInputBase-input": {
                  // Font color, size, and style for the input text
                  color: "#0e163d", // Example font color
                  fontSize: "15px", // Example font size
                  fontFamily: "Titillium Web", // Example font family
                },

                ".MuiSvgIcon-root": {
                  // Style for the calendar icon
                  color: "#808080", // Example icon color
                  fontSize: "1.25rem", // Example icon size
                },

                // '&:hover .MuiOutlinedInput-notchedOutline': {
                //   borderColor: '#6d6d6d', // Change border color on hover
                // },
                width: "100%",
              }}
            />
          </InputContainer>
        </CreaContainerColumn>
        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>
              LISTA PROCESSI COMPLIANCE
            </LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <SearchInput
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer>

              <LastPopupScrollbar>
                <Grid container spacing={2} sx={{ width: "100%" }}>
                  {/* <Grid item xs={4}>
                    <Box
                      sx={{
                        backgroundColor: "#fff", // Set the background color to white
                        borderRadius: "4px", // Set the border-radius to 4px
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Apply a shadow
                        padding: "0px 10px 10px 5px", // Optional: Add padding inside the box if needed
                        marginTop: "16px",
                        marginLeft: "20px",
                      }}
                    >
                      <ModelSelector models={Modeldata} onModelChange={handleModelChange} selectedModelId={selectedModelId} />
                    </Box>
                  </Grid> */}

                  {complianceData && <RolesAccordion token={token} />}
                </Grid>
              </LastPopupScrollbar>
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                }}
              >
                <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Annulla</ButtonText>
                </ButtonGrey>
                {/* <ButtonGrey onClick={() => saveChanges()}>
                  <ButtonText>Salva</ButtonText>
                </ButtonGrey> */}
                <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Salva</ButtonText>
                </ButtonGrey>
              </div>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare
          src="/description_show_compliance.svg"
          alt="Description Icon"
        />
        <DescriptionContainerCreaText>
        {TAB_DESCRIPTION_PROCESSI_BUSINESS_6 ? TAB_DESCRIPTION_PROCESSI_BUSINESS_6 : TAB_DESCRIPTION_GLOBAL}
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
