import { atom, selector } from 'recoil';
import dayjs from 'dayjs';


export const editL4Title = atom({
  key: 'editL4Title', 
  default: '',
});

//Scheda generale
export const editL4PersonalCardId = atom({
  key: 'editL4PersonalCardId', 
  default: '',
});
export const editL4Surname = atom({
  key: 'editL4Surname', 
  default: '',
}); 

export const editL4Name = atom({
  key: 'editL4Name', 
  default: '',
});


export const editL4birthDate = atom<dayjs.Dayjs | null>({
  key: 'editL4birthDate', 
  default: dayjs(),
});

export const editL4birthPlace = atom({
  key: 'editL4birthPlace', 
  default: '',
});


export const editL4gender = atom({
  key: 'editL4gender', 
  default: '',
});


export const editL4fiscalCode = atom({
  key: 'editL4fiscalCode', 
  default: '',
});


export const editL4residenceAddress = atom({
  key: 'editL4residenceAddress', 
  default: '',
});

export const editL4domicileAddress = atom({
  key: 'editL4domicileAddress', 
  default: '',
});


export const editL4workEmail = atom({
  key: 'editL4workEmail', 
  default: '',
});


export const editL4personalEmail = atom({
  key: 'editL4personalEmail', 
  default: '',
});


export const editL4workPhone = atom({
  key: 'editL4workPhone', 
  default: '',
});


export const editL4privatePhone = atom({
  key: 'editL4privatePhone', 
  default: '',
});


export const editL4idDocumentNumber = atom({
  key: 'editL4idDocumentNumber', 
  default: '',
});

//Organizzazione 

export const editL4RoleDescription = atom({
  key: 'editL4RoleDescription', 
  default: '',
});

export const editL4Responsibility = atom({
  key: 'editL4Responsibility', 
  default: '',
});

export const editL4prefLabel = atom({
  key: 'editL4prefLabel', 
  default: '',
});


//Infrastruttura

export const editL4Luoghi = atom<[]>({
  key: 'editL4Luoghi', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

//Documentazione

export const editL4DocPath = atom({
  key: 'editL4DocPath', 
  default: '',
});

export const editL4selectedModelId = atom({
  key: 'editL4selectedModelId',
  default: '',
});

export const editL4selectedModelName = atom({
  key: 'editL4selectedModelName',
  default: '',
});

export const editL4selectedModelId2 = atom({
  key: 'editL4selectedModelId2',
  default: '',
});

export const editL4selectedModelName2 = atom({
  key: 'editL4selectedModelName2',
  default: '',
});

export const editL4selectedAggrId = atom({
  key: 'editL4selectedAggrId',
  default: '',
});

export const editL4selectedComplianceAggrId = atom({
  key: 'editL4selectedComplianceAggrId',
  default: '',
});

export const editL4searchTerm = atom({
  key: 'editL4searchTerm',
  default: '',
});

export const editL4searchTerm2 = atom({
  key: 'editL4searchTerm2',
  default: '',
});





export interface editL4ComplianceData {
  id: string;
  selected: boolean;
  compliance_process_unit_association: {

    compliance_process: {

      name: string;
      description: string;
    };
    process_unit_association: {
     
      business_process: {
        id: string;
        name: string;
      };
    };
  };
 
};

export const editL4ComlpianceDataState = atom<editL4ComplianceData[]>({
  key: 'editL4ComlpianceDataState', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export interface Role {
  id: string;
  role_description: string;
  responsibility: string;
};

export interface Model {
  id: string;
  organizational_unit: {
    id: string;
    prefLabel: string;  
  };
  company_role: Role[]; 
}

export const editL4ModelDataState = atom<Model[]>({
  key: 'editL4ModelDataState', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export const editL4RoleState = atom<Role[]>({
  key: 'editL4RoleState', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

type CheckboxProcessiIdType = string;

export const editL4selectedCheckboxesProcessiState = atom<CheckboxProcessiIdType[]>({
  key: 'editL4selectedCheckboxesProcessiState',
  default: [], // default to an empty array
});

type CheckboxComplianceIdType = string;

export const editL4selectedCheckboxesComplianceState = atom<CheckboxComplianceIdType[]>({
  key: 'editL4selectedCheckboxesComplianceState',
  default: [], // default to an empty array
});
// SCHEDA GENERALE

export const editL4PersonID = atom({
  key: 'editL4PersonID', 
  default: '',
});


// DOCUMENTAZIONE

export const editL4DocumentsPath = atom({
    key: 'editL4DocumentsPath', 
    default: '',
  });
  
 
  export const editL4DocumentsAttached = atom<File[]>({
    key: 'editL4DocumentsAttached',
    default: [],
  });
  
  
  export const editL4AttachedFilesInfo = atom<Array<{ name: string, size: number, type: string } | null>>({
    key: 'editL4AttachedFilesInfo',
    default: [],
  });

  
export const editL4ComponenteOrganizzativa = atom({
  key: 'editL4ComponenteOrganizzativa', 
  default: '',
});

export const editL4SuperioreDiretto = atom({
  key: 'editL4SuperioreDiretto', 
  default: '',
});

export const editL4UnitaOperative = atom<string[]>({
  key: 'editL4UnitaOperative', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export const editL4RuoliEnte = atom<string[]>({
  key: 'editL4RuoliEnte', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});


export const editL4isResponsible = atom({
  key: 'editL4isResponsible', 
  default: '',
});

//ORGANIZZAZIONE 

export type LV1DataItem = {
  LV1_id: string;
  LV1_name: string;
};

export const LV1DataItemState = atom<LV1DataItem[]>({
  key: 'LV1DataItemState', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});


export const editL4_2_isDropdownOpenLV1 = atom({
  key: 'editL4_2_isDropdownOpenL1', 
  default: false,
});

export const editL4_2_isDropdownOpenDirectors = atom<boolean>({
  key: 'editL4_2_isDropdownOpenDirectors', 
  default: false,
});

export const editL4_2_selectedID = atom<string | null>({
  key: 'editL4_2_selectedID', 
  default: null,
});

export const editL4isDropDownOpen = atom<boolean>({
  key: 'editL4isDropDownOpen', // Unique ID (with respect to other atoms/selectors)
  default: false, // Updated default value to be an empty object
});

export const editL4ConfirmedLV1List = atom<string>({
  key: 'editL4ConfirmedLV1List', // Unique ID (with respect to other atoms/selectors)
  default: '', // Updated default value to be an empty object
});

interface SelectedRolesByModel {
  [modelId: string]: string; // Maps a model ID to a selected role ID
}

export const editL4selectedRolesByModel = atom<SelectedRolesByModel>({
  key: 'editL4selectedRolesByModel', // Unique ID (with respect to other atoms/selectors)
  default: {}, // Updated default value to be an empty object
});


export interface editL4_2_Model {
  id: string;
  alias: string; // Added to match the JSON structure
  // Assuming other properties can be dynamic or optional, based on your JSON
}

export const editL4_2_ModelState = atom<editL4_2_Model[]>({
  key: 'editL4_2_ModelState', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});


export type DirectorDataItem = {
  director_id: string;
  director_name: string;
};

export const directorDataState = atom<DirectorDataItem[]>({
  key: 'directorDataState', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export const editL4OrgUnitsSearchTerm = atom({
  key: 'editL4OrgUnitsSeatchTerm', 
  default: '',
});


export const editL4TestoUnits = atom({
  key: 'editL4TestoUnits', 
  default: 'Apri per modificare',
});

export const editL4TestoRoles = atom({
  key: 'editL4TestoRoles', 
  default: 'Apri per modificare',
});

export const editL4navMessage1 = atom({
  key: 'editL4navMessage1', 
  default: 'Apri per modificare',
});

export const editL4navMessage2 = atom({
  key: 'editL4navMessage2', 
  default: 'Apri per modificare',
});

export const editL4_2_selectedModelId = atom({
  key: 'editL4_2_selectedModelId', 
  default: '',
});

export const editL4_2_selectedModelName = atom({
  key: 'editL4_2_selectedModelName', 
  default: '',
});

export const editL4isVisible = atom({
  key: 'editL4isVisible', 
  default: false,
});

export const editL4isVisible2 = atom({
  key: 'editL4isVisible2', 
  default: false,
});


export const editL4TestoLuoghi = atom({
  key: 'editL4TestoLuoghi', 
  default: 'Apri per modificare',
});

export const editL4TestoAssets = atom({
  key: 'editL4TestoAssets', 
  default: 'Apri per modificare',
});

export const editL4selectedCheckboxesUnit = atom<string[]>({
  key: 'editL4selectedCheckboxesUnit', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});


export interface DataItemTable_4_2 {
  id: string;
  alias: string;
}

export type SortableKeys_4_2 = keyof DataItemTable_4_2;

export type SortConfig_4_2 = {
  key: keyof DataItemTable_4_2; // ensure that key is a key of DataItem
  direction: "ascending" | "descending";
};

export const sortConfigStateDataItemTable_4_2 = atom<SortConfig_4_2 | null>({
  key: 'sortConfigStateDataItemTable_4_2',
  default: null,
});

/////    ADD3    ADD UNIT SELECTED CHECKBOXES

export const editL4_3_selectedID = atom<string | null>({
  key: 'editL4_3_selectedID', 
  default: null,
});



export interface SelectedRadioRoles {
  [modelId: string]: string; // Maps a model ID to a selected role ID
}

export const editL4selectedRadioRoles = atom<SelectedRadioRoles>({
  key: 'editL4selectedRadioRoles', // Unique ID (with respect to other atoms/selectors)
  default: {}, // Updated default value to be an empty object
});

export interface CombinedRole {
  role_description_id?: string; // Use for roles in organizationModel.roles
  role_description_name?: string; // Use for roles in organizationModel.roles
  responsible_role_description_id?: string; // Use for responsible_role
  responsible_role_description_name?: string; // Use for responsible_role
  responsibility?: string; // Common field, included if you need to display responsibilities
  responsible_responsiblity?: string;
}

export const CombinedRoleItemState = atom<CombinedRole[]>({
  key: 'CombinedRoleItemState', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export const ResponsibleRoleState  = atom<CombinedRole | null>({
  key: 'ResponsibleRoleState', // Unique ID (with respect to other atoms/selectors)
  default: null, // Default value // Default value (aka initial value)
});
//ADD PLACE SELECTED CHECKBOXES

export const editL4selectedCheckboxesPlaces = atom<string[]>({
  key: 'editL4selectedCheckboxesPlaces', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});


//ADD ASSET SELECTED CHECKBOXES

export const editL4selectedCheckboxesAsset = atom<string[]>({
  key: 'editL4selectedCheckboxesAsset', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

/// INFRASTRUTTURA


//ADDL4-3 DATAITEM

export interface DataItem {
  id: string;
  name: string;
  has_site: string;
  site_name: string;
  // Add any other properties that might exist in your data objects
}

export const dataTableState = atom<DataItem[]>({
  key: 'dataTableState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});


////////ADDL4-3 DATAITEM2
export interface DataItem2 {
  id: string;
  name: string;
  CategoryPath: string;
  
  // Add any other properties that might exist in your data objects
}

export const dataTableState2 = atom<DataItem2[]>({
  key: 'dataTableState2', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});


// export type DataTableProps = {
//   data: DataItem[];
//   sortConfig: SortConfigRec | null; // Optional
//   filterText?: string; // Optional
//   globalFilterText?: string; // Optional
// };

export type DataTableProps = {
  data: SelectedPlaceUnitAssociationInfrastruttura[];
  sortConfig: SortConfigRec | null; // Optional
  filterText?: string; // Optional
  globalFilterText?: string; // Optional
};

export type DataTableProps2 = {
  data: DataItem2[];
  sortConfig: SortConfig2Rec | null; // Optional
  filterText?: string; // Optional
  globalFilterText?: string; // Optional
};


export type SortableKeys = keyof SelectedPlaceUnitAssociationInfrastruttura;
export type SortableKeys2 = keyof DataItem2;

// Define the sort configuration type
export type SortConfigRec = {
  key: keyof SelectedPlaceUnitAssociationInfrastruttura; // ensure that key is a key of DataItem
  direction: "ascending" | "descending";
};

// Define the sort configuration type
export type SortConfig2Rec = {
  key: keyof DataItem2; // ensure that key is a key of DataItem
  direction: "ascending" | "descending";
};

export const sortConfigState = atom<SortConfigRec | null>({
  key: 'sortConfigState',
  default: null,
});

export const sortConfig2State = atom<SortConfig2Rec | null>({
  key: 'sortConfig2State',
  default: null,
});

// PROCESSI


export const editL4OrganizzazioneSelectedL1 = atom({
  key: 'editL4OrganizzazioneSelectedL1', 
  default: '',
});

export const editL4OrganizzazioneSelectedL1ID = atom({
  key: 'editL4OrganizzazioneSelectedL1ID', 
  default: '',
});


export const editL4OrganizzazioneDirettore = atom({
  key: 'editL4OrganizzazioneDirettore', 
  default: '',
});

export const editL4OrganizzazioneDirettoreID = atom({
  key: 'editL4OrganizzazioneDirettoreID', 
  default: '',
});

export const editL4OrganizzazioneModello = atom({
  key: 'editL4OrganizzazioneModello', 
  default: '',
});

export const editL4OrganizzazionePersonale = atom({
  key: 'editL4OrganizzazionePersonale', 
  default: '',
});

export const editL4OrganizzazioneDH = atom({
  key: 'editL4OrganizzazioneDH', 
  default: '',
});

export const editL4OrganizzazioneOrdinaria = atom({
  key: 'editL4OrganizzazioneOrdinaria', 
  default: '',
});

//COMPLIANCE


export const editL4ComplianceDirettoreCompliance = atom({
  key: 'editL4ComplianceDirettoreCompliance', 
  default: '',
});

export const editL4ComplianceDirettoreComplianceID = atom({
  key: 'editL4ComplianceDirettoreComplianceID', 
  default: '',
});

export const editL4ComplianceDate = atom<dayjs.Dayjs | null>({
  key: 'editL4ComplianceDate', 
  default: dayjs(), // Initialize with the current date  
});

export interface SelectedPlaceUnitAssociation {
  id: string;
  role_description: string,
  responsibility: string,
 
}

export const SelectedPlaceUnitAssociationState = atom<SelectedPlaceUnitAssociation[]>({
  key: 'SelectedPlaceUnitAssociationState',
  default: [],
});

export interface RoleCompliance {
  id: string;
  role_description: string;
  responsibility: string;
};

export interface ModelCompliance {
  id: string;
  unit: {
    id: string;
    prefLabel: string;
    compliance_responsible_assignment_start: string;
  };
  compliance_role: RoleCompliance[]; 
}

export const ModelComplianceState = atom<ModelCompliance[]>({
  key: 'ModelComplianceState',
  default: [],
});

export const RoleComplianceState = atom<RoleCompliance[]>({
  key: 'RoleComplianceState',
  default: [],
});



export interface editL4Role {
  id: string;
  role_description: string;
  responsibility: string;
};


export interface editL4Model {
  id: string;
  organizational_unit: {
    id: string;
    prefLabel: string;  
  };
  company_role: editL4Role[]; 
}

export interface edit4ComplianceData {
  id: string;
  selected: boolean;
    process_unit_association: {
      business_process: {
        id: string;
        name: string;
        description: string;
        kind: string;
        objective: string;
      };
    };
};

export const edit4ComplianceDataState = atom<edit4ComplianceData[]>({
  key: 'edit4ComplianceDataState', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export interface editL4Role2 {
  id: string;
  role_description: string;
  responsibility: string;
}

export const editL4Role2State = atom<editL4Role2[]>({
  key: 'editL4Role2State',
  default: [],
});

export interface editL4Model2 {
  unit: {
    id: string;
    prefLabel: string;
    compliance_responsible_assignment_start: string;
  };
  compliance_role: editL4Role2[];
}

export const editL4Model2State = atom<editL4Model2[]>({
  key: 'editL4Model2State',
  default: [],
});

export interface editL4ComplianceData {
  id: string;
  selected: boolean;
    process_unit_association: {
      has_unit: string;
      business_process: {
        id: string;
        name: string;
        description: string;
        kind: string;
        objective: string;
      };
    };
};

export const editL4ComplianceDataState = atom<editL4ComplianceData[]>({
  key: 'editL4ComplianceDataState', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export interface editL4RoleCompliance {
  id: string;
  role_description: string;
  responsibility: string;
};

export interface editL4ModelCompliance {
  id: string;
  unit: {
    id: string;
    prefLabel: string;
    compliance_responsible_assignment_start: string;
  };
  compliance_role: editL4RoleCompliance[]; 
}

export const editL4ModelComplianceState = atom<editL4ModelCompliance[]>({
  key: 'editL4ModelComplianceState',
  default: [],
});

export const editL4RoleComplianceState = atom<editL4RoleCompliance[]>({
  key: 'editL4RoleComplianceState',
  default: [],
});



export interface editL4SelectedPlaceUnitAssociation {
  id: string;
  role_description: string,
  responsibility: string,
 
}

export const editL4SelectedPlaceUnitAssociationState = atom<editL4SelectedPlaceUnitAssociation[]>({
  key: 'editL4SelectedPlaceUnitAssociationState',
  default: [],
});


export type FileListItem = {
  id: string;
  path: string;
  name: string;
};

export const editL4FileListItemState = atom<FileListItem[]>({
  key: 'editL4FileListItemState',
  default: [],
});

export type editL4PersonItem = {
  id: string;
personal_card_id: string;
  surname: string;
  name: string;
  has_personal_card: string;
  member_of_formal_organization: string;
  reports_to: string;
  has_smart_working_site: string;
  documents_folder_link: string;
  based_at: string;
 

   
    national_identification_number: string;
    date_of_birth: string;
    has_place_of_birth: string;
    has_primary_residence: string;
    has_postal_address: string;
    personal_email: string;
    work_email: string;
    work_mobile_phone: string;
    personal_mobile_phone: string;
    has_identity_document: string;
    has_citizenship: string;

 
};

export const editL4PersonItemState = atom<editL4PersonItem[]>({
  key: 'editL4PersonItemState',
  default: [],
});

export interface SelectedPlaceUnitAssociationInfrastruttura {
  id: string;
  placeId: string;
  siteName: string;
  placeName: string;
  planRef: string;
  selected: boolean;
}

export const editL4SelectedPlaceUnitAssociationInfrastrutturaState = atom<SelectedPlaceUnitAssociationInfrastruttura[]>({
  key: 'editL4SelectedPlaceUnitAssociationInfrastrutturaState',
  default: [],
});

interface SiteDetails {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  site_name: string;
  site_short_name: string;
  has_site_address: string;
  site_email: string;
  site_telephone: string;
  has_site_responsible: string;
  fax: string;
  has_compliance_responsible: string;
  compliance_responsible_assignment_start: string;
  has_documents_folder_link: string;
  compliance_responsible?: null; 
  site_responsible?: null; 
}

interface PlaceDetails {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  name: string;
  has_site: string;
  plan_ref: string;
  site: SiteDetails;
  has_compliance_responsible: string;
  compliance_responsible_assignment_start: string;
  has_documents_folder_link: string;
}

interface PlaceUnitAssociation {
  content: {
    id: string;
    creationTs: string;
    updateTs: string;
    createdBy: string;
    updatedBy: string;
    has_place: string;
    has_unit: string;
    has_person: string;
    place: PlaceDetails;
  };
  selected: boolean;
}

export const editL4originalPlaceUnitAssociationsState = atom<PlaceUnitAssociation[]>({
  key: 'editL4originalPlaceUnitAssociations',
  default: [],
});

export interface CybersecurityResponsible {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  surname: string;
  name: string;
  has_personal_card: string;
  member_of_formal_organization: string;
  reports_to: string;
  has_smart_working_site: string;
  documents_folder_link: string;
  based_at: string;
}

export interface UnitDetails {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  prefLabel: string;
  subOrganizationOf: string;
  hasOrganizationalCard: string;
  isOrganizationalUnit: boolean;
  hasUnitResponsible: string;
  isFormalOrganization: boolean;
  hasDomain: string;
  hasEntityLegalForm: string;
  identifier: string;
  hasRegisteredSite: string;
  hasModel: string;
  hasPrimarySite: string;
  pec: string;
  hasLegalRepresentative: string;
  hasDocumentsFolderLink: string;
  ordinary_activity: string;
  dh_activity: string;
  has_compliance_responsible: string;
  compliance_responsible_assignment_start: string;
  compliance_responsible?: null;
  primary_site?: null;
  responsible?: null;
  organizationalCard?: null;
  organizationModel?: null;
  entityLegalForm?: null;
}

export interface CategoryDetails {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  name: string;
  sub_category_of: string;
}

export interface AssetContent {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  name: string;
  hasOrganization: string;
  hasCategory: string;
  hasContainer: string;
  inventoryN: string;
  hasResponsible: string;
  hasUnit: string;
  hasPlace: string;
  state: string;
  has_cybersecurity_responsible: string;
  cybersecurity_responsible_assignment_start: string;
  documents_folder_link: string;
  cybersecurity_responsible: CybersecurityResponsible;
  unit: UnitDetails;
  category: CategoryDetails;
}

export interface AssetDetails {
  content: AssetContent;
  selected: boolean;
}

export const editL4originalAssetAssociationsState = atom<AssetDetails[]>({
  key: 'editL4originalAssetAssociations',
  default: [],
});

export interface editL4InfrDataItem {
  id: string;
  name: string;
  hasUnit: string;
  CategoryPath: string;

}

export const editL4InfrDataItemState = atom<editL4InfrDataItem[]>({
  key: 'editL4InfrDataItemState',
  default: [],
});

export type SelectedCheckboxesByUnitType = {
  [unitId: string]: string[];
};


export const selectedCheckboxesByUnitState = atom<SelectedCheckboxesByUnitType>({
  key: 'selectedCheckboxesByUnitState',
  default: {}  // This will hold the structure: { [unitId: string]: string[] }
});

export const isRolesChanged = atom({
  key: 'isRolesChanged', 
  default: false,
});
