import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import {
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconContainerLong,
  IconContainerLongDouble,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputDescriptionTextDouble,
  InputTextbox,
  InputTextboxDouble,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { SelectedL4ID } from "../../../../../recoil";
import { InputContainer50 } from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import {
  ProcessiComplianceAttachedFilesInfo,
  ProcessiComplianceComplianceDate,
  ProcessiComplianceComplianceResponsible,
  ProcessiComplianceDescription,
  ProcessiComplianceID,
  ProcessiComplianceDocumentsAttached,
  ProcessiComplianceDocumentsPath,
  ProcessiComplianceKind,
  ProcessiComplianceName,
  ProcessiComplianceObjective,
  ProcessiComplianceRifNorm,
  ProcessiComplianceNota,
} from "../ProcessiCompliance-Add-recoil";
import { DatePicker } from "@mui/x-date-pickers";

const useToken = () => {
  const { keycloak, initialized } = useKeycloak();
  return initialized && keycloak.authenticated ? keycloak.token : null;
};

const MainComponent = () => {
  const token = useToken();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  ////////////RECOIL

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(
    ProcessiComplianceAttachedFilesInfo
  );
  const [documentsAttached, setDocumentsAttached] = useRecoilState(
    ProcessiComplianceDocumentsAttached
  );
  const [docPath, setDocPath] = useRecoilState(ProcessiComplianceDocumentsPath);
  const [ID, setID] = useRecoilState(ProcessiComplianceID);
  const [name, setName] = useRecoilState(ProcessiComplianceName);
  const [complianceResponsible, setComplianceResponsible] = useRecoilState(
    ProcessiComplianceComplianceResponsible
  );
  const [complianceDate, setComplianceDate] = useRecoilState(
    ProcessiComplianceComplianceDate
  );
  const [description, setDescription] = useRecoilState(
    ProcessiComplianceDescription
  );
  const [kind, setKind] = useRecoilState(ProcessiComplianceKind);
  const [objective, setObjective] = useRecoilState(ProcessiComplianceObjective);
  const [rifNorm, setrifNorm] = useRecoilState(ProcessiComplianceRifNorm);
  const [nota, setNota] = useRecoilState(ProcessiComplianceNota);

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Denominazione
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <IconContainerLong />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              Categoria
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={kind}
              onChange={(e) => setKind(e.target.value)}
            />
            <IconContainerLong />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              Riferimento normativo
              {/* <Tooltip title="Campo obbligatorio">
                <InfoIcon style={{ fontSize: "14px", color: "#04446c", cursor: "pointer", marginLeft:"2px" }} />
              </Tooltip> */}
            </InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={rifNorm}
              onChange={(e) => setrifNorm(e.target.value)}
            />
            <IconContainerLong />
          </InputContainer>

          <InputContainer>
            <InputDescriptionTextDouble>
              Descrizione {description.length}/255
            </InputDescriptionTextDouble>
            <InputTextboxDouble
              maxLength={255}
              placeholder=""
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <IconContainerLongDouble />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Nota {nota.length}/125 </InputDescriptionText>
            <InputTextbox
              maxLength={125}
              placeholder=""
              value={nota}
              onChange={(e) => setNota(e.target.value)}
            />
            <IconContainerLong />
          </InputContainer>
        </CreaContainerColumn>

        {/* <CreaContainerColumn>

      

        </CreaContainerColumn> */}
      </FlexContainerCrea>
      {/* <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
              <DescriptionContainerCreaText>
             
              Inserire i dettagli identificativi dell'unità operativa, comprensivi di un nome completo che rifletta le competenze e i servizi offerti, un nome abbreviato per una rapida identificazione, oltre ai principali canali di comunicazione per facilitare un contatto efficace e una pronta corrispondenza


              </DescriptionContainerCreaText>

        </DescriptionContainerCreaMultiple> */}
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
