import styled from "styled-components";
import { NavLink } from 'react-router-dom';

export const TableScrollableDiv = styled.div`
   overflow-y: auto; // Enable vertical scroll
  width: 98.2%; // Set the desired width
  margin-left: 10px; // Center the div
  margin-right: auto; // Center the div
  max-height: calc(85vh - 52px - 50px); // Adjust the height as needed
  border: 1px solid #e1e1e1;

  // Custom scrollbar styles
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 10px;
    &:hover {
      background: #425a77;
    }
  }
`;

export const TableScrollableDivPopup = styled.div`
   overflow-y: auto; // Enable vertical scroll
  width: 98.2%; // Set the desired width
  margin-left: 10px; // Center the div
  margin-right: auto; // Center the div
  max-height: calc(78vh - 52px - 50px); // Adjust the height as needed
  border: 1px solid #e1e1e1;

  // Custom scrollbar styles
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 10px;
    &:hover {
      background: #425a77;
    }
  }
`;


export const TableScrollableDivCampoApplicazione = styled.div`
   overflow-y: auto; // Enable vertical scroll
  width: 100%; // Set the desired width
  margin-left: 0px; // Center the div
  margin-right: auto; // Center the div
  max-height: calc(73vh - 52px - 50px); // Adjust the height as needed
  border: 1px solid #e1e1e1;

  // Custom scrollbar styles
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 10px;
    &:hover {
      background: #425a77;
    }
  }
`;

export const StyledTable = styled.table`
  table-layout: fixed;
  width: 98.2%; // Set the desired width
  margin-left: 10px; // Center the table
  margin-right: auto; // Center the table
  border-collapse: collapse;
`;

export const StyledTableCampoApplicazione = styled.table`
  table-layout: fixed;
  width: 100%; // Set the desired width
  margin-left: 0px; // Center the table
  margin-right: auto; // Center the table
  border-collapse: collapse;
`;

export const StyledTableItem = styled.table`
  flex: 1;
  table-layout: fixed;
  width: 100%; // Set the desired width
  margin-left: auto; // Center the table
  margin-right: auto; // Center the table
  border-collapse: collapse;

`;
export const TableTitleText = styled.div`
   user-select: none;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: #ffffff;
  text-align: left; // Ensures text is centered within the element itself
  // No need for width and height since it's a text element and will naturally take the size of its content
&:hover {

cursor: pointer;

}
`;

export const TableItemText = styled.div`

  white-space: nowrap; /* Keep the text on a single line */
  overflow: hidden; /* Hide the overflow */
  text-overflow: ellipsis; /* Use ellipsis for overflow */
  //max-width: 150px;
  //min-width: 150px; /* Set a max-width for the text, adjust as needed */
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: left; // Ensures text is centered within the element itself
  // No need for width and height since it's a text element and will naturally take the size of its content
`;

export const StyledThead = styled.thead`
  background-color: #425a77;
  color: white;
`;

export const StyledTh = styled.th`
  padding: 12px 15px;
  text-align: left; // Keep it left-aligned or center if preferred
`;

export const StyledTh10 = styled.th`
  padding: 12px 15px;
  text-align: left; // Keep it left-aligned or center if preferred
  width: 10%;
`;

export const StyledThComplianceRole = styled.th`
  padding: 12px 15px;
  text-align: left; // Keep it left-aligned or center if preferred
  width: 35%;
`;

export const StyledThRifNorm = styled.th`
  padding: 12px 15px;
  text-align: left; // Keep it left-aligned or center if preferred
  width: 15%;
`;

export const StyledGestioneTh = styled.th`
  padding: 12px 15px;
  text-align: left; // Keep it left-aligned or center if preferred
  width: 25%;
`;

export const StyledTd = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
`;

export const StyledTd10 = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 10%;
  
`;

export const StyledTdCentered = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 10%;
  text-align: center; 
`;

export const StyledTdComplianceRole = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
    width: 35%;
`;


export const StyledTdRifNorm = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
    width: 15%;
`;

export const StyledTdCategory = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 18%;
`;

export const StyledTdGestione = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 25%;
`;


export const StyledTdUnitaOperativa = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 30%;
`;

export const StyledPlaceTd = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 20%;
`;



export const StyledLuoghiTd = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 35%;
`;

export const StyledComponenteTd = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 50%;
`;

export const StyledTdCheckbox = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #dddddd;
  width: 5%;
`;

// Add specific styles for the 'Azione' column
export const StyledActionTh = styled(StyledTh)`
  width: 5%; // Adjust the width as necessary for the header
`;

// Add specific styles for the 'Azione' column
export const StyledRuoligeneraliTh = styled(StyledTh)`
  width: 20%; // Adjust the width as necessary for the header
`;


export const StyledCategoryTh = styled(StyledTh)`
  width: 18%; // Adjust the width as necessary for the header
`;
export const StyledLuoghiTh = styled(StyledTh)`
  width: 35%; // Adjust the width as necessary for the header
`;
export const StyledComponenteTh = styled(StyledTh)`
  width: 50%; // Adjust the width as necessary for the header
`;


export const StyledUnitaOperativaTh = styled(StyledTh)`
  width: 30%; // Adjust the width as necessary for the header
`;

export const StyledPlanRefTh = styled(StyledTh)`
  width: 15%; // Adjust the width as necessary for the header
`;

export const StyledPersone1Th = styled(StyledTh)`
  width: 20%; // Adjust the width as necessary for the header
`;

export const StyledPersone2Th = styled(StyledTh)`
  width: 35%; // Adjust the width as necessary for the header
`;


export const StyledActionTd = styled(StyledTd)`
  width: 10%; // Adjust the width as necessary for the column
`;

export const StyledRuoliGeneraliTd = styled(StyledTd)`
  width: 20%; // Adjust the width as necessary for the column
`;

export const StyledPlanRefTd = styled(StyledTd)`
  width: 15%; // Adjust the width as necessary for the column
`;


export const StyledPersone1Td = styled(StyledTd)`
  width: 20%; // Adjust the width as necessary for the column
`;
export const StyledPersone2Td = styled(StyledTd)`
  width: 35%; // Adjust the width as necessary for the column
`;



export const StyledTbody = styled.tbody`
`;



export const TableContainer = styled.div`
  width: 100%;
  max-height: 85vh; // Limit the height of the table container
  overflow: hidden; // Hide any overflow
  //border-bottom: 1px solid #dddddd;
`;

export const TableContainerPopup = styled.div`
  width: 100%;
  padding-right: 0.1%;
  max-height: 78vh; // Limit the height of the table container
  overflow: hidden; // Hide any overflow
  //border-bottom: 1px solid #dddddd;
`;

export const TableContainerCampoApplicazione = styled.div`
  width: 100%;
  max-height: 73vh; // Limit the height of the table container
  overflow: hidden; // Hide any overflow
  //border-bottom: 1px solid #dddddd;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between; // This will align one child to the left and one to the right
  align-items: center;
  width: 98.2%; // Set the desired width
  margin-left: 10px; // Center the div
  margin-right: auto; // Center the div
  height: 52px;
  gap: 20px;
  padding: 0 0px; // Add padding if necessary
  box-sizing: border-box; // Include padding in the element's total width and height

`;

export const TopContainerRoles = styled.div`
  display: flex;
  justify-content: space-between; // This will align one child to the left and one to the right
  align-items: center;
  margin-top: 0px;
  width: 100%; // Set the desired width
  margin-left: 0px; // Center the div
  margin-right: auto; // Center the div
  height: 30x;
  gap: 10px;
  padding: 0 0px; // Add padding if necessary
  box-sizing: border-box; // Include padding in the element's total width and height

`;


export const TopContainerCampoApplicazione = styled.div`
  display: flex;
  justify-content: space-between; // This will align one child to the left and one to the right
  align-items: center;
  width: 100%; // Set the desired width
  margin-left: 0px; // Center the div
  margin-right: auto; // Center the div
  height: 52px;
  gap: 20px;
  padding: 0 0px; // Add padding if necessary
  box-sizing: border-box; // Include padding in the element's total width and height

`;

export const Search = styled.input`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 0px 30px;
  margin-right: 0px;
  width: 100%;
  height: 30px;
  // Additional styles for the input
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro); // Example font size
  line-height: 1.5; // Example line height for better readability
  color: var(--color-lightslategray); // Text color

  // Remove default input styling
  outline: none;
  box-shadow: none;

  // Styling for placeholder text
  &::placeholder {
    color: #d1d1d1;
    
  }

  // Focus styles
  &:focus {
    border-color: var(--color-primary); // Change border color when focused
    // You can add other styles for the focus state
  }
`;

export const SearchPopup = styled.input`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 0px 30px;
  margin-right: 0px;
  width: 100%;
  height: 30px;
  // Additional styles for the input
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro); // Example font size
  line-height: 1.5; // Example line height for better readability
  color: var(--color-lightslategray); // Text color

  // Remove default input styling
  outline: none;
  box-shadow: none;

  // Styling for placeholder text
  &::placeholder {
    color: #d1d1d1;
    
  }

  // Focus styles
  &:focus {
    border-color: var(--color-primary); // Change border color when focused
    // You can add other styles for the focus state
  }
`;

export const SearchCampoApplicazione = styled.input`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 0px 30px;
  margin-right: 0px;
  width: 100%;
  height: 30px;
  // Additional styles for the input
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro); // Example font size
  line-height: 1.5; // Example line height for better readability
  color: var(--color-lightslategray); // Text color

  // Remove default input styling
  outline: none;
  box-shadow: none;

  // Styling for placeholder text
  &::placeholder {
    color: #d1d1d1;
    
  }

  // Focus styles
  &:focus {
    border-color: var(--color-primary); // Change border color when focused
    // You can add other styles for the focus state
  }
`;

export const SearchIcon = styled.img`
  position: absolute;
  left: 10px; // Position from the right to place it inside the input
  top: 50%;
  transform: translateY(-50%);
  width: 16px; // Set a fixed size for the icon
  height: 16px;
  pointer-events: none; // Make sure clicks "go through" the icon
`;

export const SearchInputContainer = styled.div`

position: relative; // Position relative to allow absolute positioning of children
  width: 50%; // The width of the container
  margin-right: 0px;

`;

export const SearchInputContainerSolo = styled.div`

position: relative; // Position relative to allow absolute positioning of children
  width: 100%; // The width of the container
  margin-right: 0px;

`;

export const SearchInputContainerSoloRoles = styled.div`

position: relative; // Position relative to allow absolute positioning of children
  width: 100%; // The width of the container
  margin-right: 0px;
  //margin-top: 0px;
`;

export const CheckBoxScrollableDiv = styled.div`

position: absolute;
overflow-x: hidden;
overflow-y: scroll; // Enable vertical scroll
  height: 98%; // Set the height of the table body here
  width: 100%;
  //border: 1px solid #e1e1e1;

  // Custom scrollbar styles
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c6c6c6;
    border-radius: 10px;
    &:hover {
      background: #425a77;
    }
  }
`;
