import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from "react";
import styled from "styled-components";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione"; // Correct path
import { Box, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Checkbox } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextboxButton,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { isLoadingRec, isMessageBoxVisibleRec, SelectedL4ID, setErrorRec, setMessageRec } from "../../../../../recoil";

import { ArrowButtonText, ButtonGrey, ButtonText } from "../../../styled-components";
import { URL_PersonRoles, URL_UnitProcessRolePerson, useToken } from "../../../../../API";

import {
  SelectedCheckboxesByUnitType,
  editL4ComlpianceDataState,
  editL4ComplianceData,
  editL4Model,
  editL4ModelDataState,
  editL4Role,
  editL4RoleState,
  editL4searchTerm,
  editL4selectedAggrId,
  editL4selectedCheckboxesProcessiState,
  editL4selectedModelId,
  editL4selectedModelName,
  selectedCheckboxesByUnitState,
} from "../L4-Edit-recoil";

// INTERFACES

interface RolesAccordionProps {
  role: editL4Role[];
  model: editL4Model;
  token: string | null | undefined;
}

interface ModelSelectorProps {
  models: editL4Model[];
  selectedModelId: string;
  onModelChange: (newModelId: string) => void; // Now expects a string
}

type GroupedModels = {
  [key: string]: {
    id: string;
    organizational_unit: {
      id: string;
      prefLabel: string;
    };
    company_role: editL4Role[];
  };
};

///////////// POPUP

interface LastPopupProps {
  isVisible: boolean;
}

const LastPopupBlack = styled.div<LastPopupProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  //border: 5px solid #ffffff;
  z-index: 100;
`;

///////////// POPUP END

export const useFetchModelData = (token: string | null | undefined, L4ID: string) => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [modelData, setModelData] = useRecoilState(editL4ModelDataState);
  const [selectedModelId, setSelectedModelId] = useRecoilState(editL4selectedModelId);
  const [selectedModelName, setSelectedModelName] = useRecoilState(editL4selectedModelName);
  const [selectedModelRoles, setSelectedModelRoles] = useRecoilState(editL4RoleState);
  const [selectedAggrId, setSelectedAggrId] = useRecoilState(editL4selectedAggrId);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      if (!token) throw new Error("User is not authenticated");

      const response = await fetch(`${URL_PersonRoles}${L4ID}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }

      const responseData: editL4Model[] = await response.json();
      console.log("API2:", responseData);

      const groupedModels = responseData.reduce((acc: GroupedModels, item) => {
        if (!acc[item.organizational_unit.id]) {
          acc[item.organizational_unit.id] = {
            ...item,
            company_role: Array.isArray(item.company_role) ? [...item.company_role] : [item.company_role],
          };
        } else {
          if (Array.isArray(item.company_role)) {
            acc[item.organizational_unit.id].company_role.push(...item.company_role);
          } else {
            acc[item.organizational_unit.id].company_role.push(item.company_role);
          }
        }
        return acc;
      }, {} as GroupedModels);

      const transformedData: editL4Model[] = Object.values(groupedModels);

      setModelData(transformedData);

      if (transformedData.length > 0) {
        setSelectedModelId(transformedData[0].organizational_unit.id);
        setSelectedModelName(transformedData[0].organizational_unit.prefLabel);
        setSelectedModelRoles(transformedData[0].company_role);
        setSelectedAggrId(transformedData[0].id);
      }

      console.log("General data: ", transformedData);
    } catch (err) {
      setError(err instanceof Error ? err.message : "An unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  }, [token, L4ID]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    modelData,
  };
};

// CHECKBOXES SELECTED BY UNIT ID
const useCheckboxSelections = () => {
  const [selectedCheckboxesByUnit, setSelectedCheckboxesByUnit] = useRecoilState(selectedCheckboxesByUnitState);
  const [selectedModelId] = useRecoilState(editL4selectedModelId); // No need to set if not used outside

  const handleCheckboxChange = useCallback(
    (checkboxId: string, isChecked: boolean) => {
      setSelectedCheckboxesByUnit((prevState) => {
        // Creating a deep clone for the arrays to ensure immutability
        const newSelections = { ...prevState };
        const currentSelections = newSelections[selectedModelId] ? [...newSelections[selectedModelId]] : [];

        if (isChecked) {
          // Ensure no duplicates if somehow triggered
          if (!currentSelections.includes(checkboxId)) {
            currentSelections.push(checkboxId);
          }
        } else {
          const index = currentSelections.indexOf(checkboxId);
          if (index > -1) {
            currentSelections.splice(index, 1); // Remove the item at the found index
          }
        }

        newSelections[selectedModelId] = currentSelections;
        console.log("Updated selections:", newSelections);
        return newSelections;
      });
    },
    [setSelectedCheckboxesByUnit, selectedModelId]
  );

  return {
    handleCheckboxChange,
    selectedCheckboxes: selectedCheckboxesByUnit[selectedModelId] || [],
  };
};

// MODEL SELECTOR

const ModelSelector: React.FC<ModelSelectorProps> = ({ models, onModelChange, selectedModelId }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" sx={{ color: "#6f8aab", padding: "8px" }}></FormLabel>
      <RadioGroup aria-label="model" name="model" value={selectedModelId} onChange={(event) => onModelChange(event.target.value)} sx={{ padding: "5px 10px 10px 20px" }}>
        {models.map((model) => (
          <FormControlLabel
            key={model.organizational_unit.id}
            value={model.organizational_unit.id}
            control={
              <Radio
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#6f8aab" },
                }}
              />
            }
            label={model.organizational_unit.prefLabel} // Use organizational_unit.prefLabel as label
            sx={{
              color: "#6f8aab",
              "& .MuiTypography-root": { fontSize: "0.875rem" },
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

// MODEL SELECTOR END

// ACCORDION

const RolesAccordion: React.FC<RolesAccordionProps> = ({ role, model, token }) => {
  const [complianceData, setComplianceData] = useRecoilState(editL4ComlpianceDataState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [selectedAggrId, setSelectedAggrId] = useRecoilState(editL4selectedAggrId);
  const [searchTerm, setSearchTerm] = useRecoilState(editL4searchTerm);
  const [, setSelectedCheckboxes] = useRecoilState(selectedCheckboxesByUnitState);

  const { handleCheckboxChange, selectedCheckboxes } = useCheckboxSelections();

  console.log("Aggregated ID: ", selectedAggrId);

  const filteredComplianceData = useMemo(
    () =>
      complianceData.filter((item) => {
        const process = item.process_unit_association.business_process;
        const searchLower = searchTerm.toLowerCase();
        return (
          process.name.toLowerCase().includes(searchLower) ||
          process.description.toLowerCase().includes(searchLower) ||
          process.kind.toLowerCase().includes(searchLower) ||
          process.objective.toLowerCase().includes(searchLower)
        );
      }),

    [complianceData, searchTerm]
  );

  if (!role) return null;

  return (
    <Box sx={{ mt: 2 }}>
      {role.map((role, roleIndex) => (
        <Accordion key={role.id} expanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color="#04446c">{role.role_description}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ flexDirection: "column" }}>
            {isLoading && <Typography>Loading...</Typography>}
            {error && <Typography color="error">{error}</Typography>}
            {Object.entries(filteredComplianceData).map(([businessProcessId, complianceItems]) => (
              <Accordion expanded={true} key={businessProcessId} sx={{ mt: 1, boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)" }}>
                <AccordionSummary
                  sx={{
                    paddingTop: "0px",
                    fontWeight: 500,
                    fontStyle: "italic",
                    marginBottom: "-15px",
                  }}
                >
                  {/* Assuming the first item's business process name is representative of all items in the group */}

                  <Typography>{complianceItems?.process_unit_association.business_process.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {filteredComplianceData.map((item, index) => (
                    <Box key={index} sx={{ mt: 1 }}>
                      {/* <Typography>{item.process_unit_association.business_process.name}</Typography> */}
                      <Divider sx={{ my: 2 }} />
                      <Typography>
                        <br />
                      </Typography>
                      <Typography>Categoria: {item.process_unit_association.business_process.kind}</Typography>
                      <Typography>
                        <br />
                      </Typography>
                      <Typography>Obiettivo: {item.process_unit_association.business_process.objective}</Typography>
                      <Typography>
                        <br />
                      </Typography>
                      <Typography>Descrizione: {item.process_unit_association.business_process.description}</Typography>

                      <Typography>
                        <br />
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCheckboxes.includes(item.process_unit_association.business_process.id)}
                            onChange={(e) => handleCheckboxChange(item.process_unit_association.business_process.id, e.target.checked)}
                            inputProps={{ "aria-label": "Is Responsible" }}
                            disabled={false}
                            sx={{
                              color: "#04446c",
                              "& .MuiSvgIcon-root": { fontSize: 16 },
                              "&.Mui-checked": { color: "#04446c" },
                              padding: "0px",
                              marginRight: "10px",
                            }}
                          />
                        }
                        label="Questo processo é associato alla persona selezionata"
                        sx={{
                          marginTop: "10px", // Adjust top margin as needed

                          marginLeft: "0px",
                          color: "#04446c", // Change label color using the theme's color palette
                          display: "flex", // Ensures the label and checkbox are in a flex container
                          flexDirection: "row", // Aligns the label and checkbox horizontally
                          justifyContent: "flex-start", // Aligns the checkbox and label to the start of the flex container
                          alignItems: "center", // Centers the label and checkbox vertically
                          // Custom label positioning can be adjusted here
                        }}
                        labelPlacement="end" // Adjust label placement relative to the checkbox ("end" positions the label after the checkbox)
                      />
                      <Typography>
                        <br />
                      </Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

// ACCORDION END

// FETCH COMPLIANCE DATA

const useFetchComplianceData = (token: string | null | undefined, selectedAggrId: string) => {
  const [complianceData, setComplianceData] = useRecoilState(editL4ComlpianceDataState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(selectedCheckboxesByUnitState);

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedAggrId || !token) {
        console.log("Missing token or selectedAggrId");
        return;
      }

      setIsLoading(true);
      setError(null);
      const url = `${URL_UnitProcessRolePerson}${selectedAggrId}`;

      try {
        const response = await fetch(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }
        const data = await response.json();

        if (Array.isArray(data)) {
          // Transform each item to include the selected property directly within the ComplianceData object
          const transformedData = data
            .map((item) => ({
              ...item.content, // Spread the content of each item
              selected: item.selected, // Add the selected property
            }))
            .sort((a, b) => b.selected - a.selected);
          setComplianceData(transformedData);

          const selectedIdsByUnit: SelectedCheckboxesByUnitType = {};
          transformedData.forEach((item) => {
            const unitId = item.process_unit_association.has_unit;
            const processId = item.process_unit_association.business_process.id;
            if (!selectedIdsByUnit[unitId]) {
              selectedIdsByUnit[unitId] = [];
            }
            if (item.selected) {
              selectedIdsByUnit[unitId].push(processId);
            }
          });

          setSelectedCheckboxes(selectedIdsByUnit);

          console.log("API accordion: ", transformedData);
        } else {
          setError("Data received is not in expected format");
          setComplianceData([]);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, selectedAggrId]);

  return { complianceData };
};

// FETCH COMPLIANCE DATA END

//HANDLE MODEL CHANGE

const useHandleModelChange = () => {
  const [Modeldata, setModelData] = useRecoilState(editL4ModelDataState);
  const [, setSelectedModelId] = useRecoilState(editL4selectedModelId);
  const [, setSelectedModelRoles] = useRecoilState(editL4RoleState);

  const handleModelChange = useCallback(
    (newModelId: string) => {
      setSelectedModelId(newModelId);

      const newModel = Modeldata.find((model) => model.organizational_unit.id === newModelId);
      if (newModel) {
        setSelectedModelRoles(newModel.company_role);
      } else {
        // Handle the case where the newModelId does not match any model
        setSelectedModelRoles([]);
      }
    },
    [Modeldata, setSelectedModelId, setSelectedModelRoles]
  );

  return handleModelChange;
};
// MODEL CHANGE END

// SEARCH

// Search input component
interface SearchInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchInput: React.FC<SearchInputProps> = React.memo(({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  // useEffect(() => {
  //   // Automatically focus the input when the component mounts
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, []);
  return <LastPopupSearch ref={inputRef} type="text" value={value} onChange={onChange} placeholder="Cerca" />;
});

// SEARCH END

const MainComponent = () => {
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const token = useToken(); // Call useToken here at the top level
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [searchTerm, setSearchTerm] = useRecoilState(editL4searchTerm);
  const handleModelChange = useHandleModelChange();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const { closeOverlay } = useContext(OverlayContext);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); // Close the message box
    closeOverlay();
  };

  ///////////////// LAST POPUP

  const [isVisible, setIsVisible] = useState(false);
  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  ///////////////////////////////////////// LAST POP UP END

  ///////////////////MODEL SELECTOR

  const [ComplianceData, setComplianceData] = useRecoilState(editL4ComlpianceDataState);
  const [Modeldata, setModelData] = useRecoilState(editL4ModelDataState);
  const [selectedModelId, setSelectedModelId] = useRecoilState(editL4selectedModelId);
  const [selectedModelName, setSelectedModelName] = useRecoilState(editL4selectedModelName);
  const [selectedAggrId, setSelectedAggrId] = useRecoilState(editL4selectedAggrId);
  const selectedModel = Modeldata.find((model) => model.organizational_unit.id === selectedModelId);
  const [selectedModelRoles, setSelectedModelRoles] = useRecoilState(editL4RoleState);

  const { modelData } = useFetchModelData(token, L4ID);
  const { complianceData } = useFetchComplianceData(token, selectedAggrId);

  useEffect(() => {
    if (modelData) {
      setModelData(modelData);
    }
  }, [modelData]);

  useEffect(() => {
    if (complianceData) {
      setComplianceData(complianceData);
    }
  }, [complianceData]);

  // Use `groupedByBusinessProcess` to render nested accordions in your component

  //const url = `http://localhost:8091/api/v1/unit-compliance-process-compliance-role-person/selection/bycomplianceroleperson/${selectedAggrId}`; staff_companyrole_units.ecubit030

  /////////////////////////////////////////

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          {/*       
        <InputContainer>
            <InputDescriptionText>Ruoli</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup2} >

            <ArrowButtonText>Clicca per visualizzare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer> */}

          <InputContainer>
            <InputDescriptionText>Processi generali</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup}>
              <ArrowButtonText>Apri per modificare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn></CreaContainerColumn>

        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>LISTA PROCESSI GENERALI</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <SearchInput value={searchTerm} onChange={handleSearchChange} />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer>

              <LastPopupScrollbar>
                <Grid container spacing={2} sx={{ width: "100%" }}>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        backgroundColor: "#fff", // Set the background color to white
                        borderRadius: "4px", // Set the border-radius to 4px
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Apply a shadow
                        padding: "0px 10px 10px 5px", // Optional: Add padding inside the box if needed
                        marginTop: "16px",
                        marginLeft: "20px",
                      }}
                    >
                      <ModelSelector models={Modeldata} onModelChange={handleModelChange} selectedModelId={selectedModelId} />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    {selectedModelRoles && selectedModel && <RolesAccordion role={selectedModelRoles} model={selectedModel} token={token} />}
                  </Grid>
                </Grid>
              </LastPopupScrollbar>
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                }}
              >
                <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Annulla</ButtonText>
                </ButtonGrey>

                <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Salva</ButtonText>
                </ButtonGrey>
              </div>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_process.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
          La scheda fornisce una panoramica dettagliata dei processi operativi standard praticati all'interno dell’Organizzazione, includendo sia i processi interdisciplinari sia quelli specifici.
          Questo elenco riflette il flusso di lavoro distintivo, delineando le operazioni e le interazioni tipiche che definiscono l'ambiente operativo e/o produttivo.
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
