import React, { useState, useEffect, useRef, useContext, Suspense, lazy } from "react";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useRecoilState, useRecoilValue } from "recoil";
import dayjs from "dayjs";

import {

setErrorRec,
isMessageBoxVisibleRec,
setMessageRec,
isLoadingRec,
reFetchIndicatorL4,

} from "../../../../recoil"; 

import SchedaGenerale from "./cybersecurity-board-compliance-ruoli-Add-sub/ruolicompliance-Add-1-SchedaGenerale";
//import Organizzazione from "./cybersecurity-board-compliance-ruoli-Add-sub/infrastruttura-luoghi-Add-2-Organizzazione";
// const Infrastruttura = lazy(() => import ("./cybersecurity-board-compliance-ruoli-Add-sub/organigramma-L4-Add-3-Infrastruttura").then(module => ({ default: module.default })));
// import Processi from "./cybersecurity-board-compliance-ruoli-Add-sub/organigramma-L4-Add-4-Processi";
// import Parti from "./cybersecurity-board-compliance-ruoli-Add-sub/organigramma-L4-Add-3-Infrastruttura";
import Compliance from "./cybersecurity-board-compliance-ruoli-Add-sub/ruolicompliance-Add-6-GestioneCompliance";
import Documentazione, { useInitiateUpload } from "./cybersecurity-board-compliance-ruoli-Add-sub/ruolicompliance-Add-7-Documentazione";

import {
  ButtonGrey,
  ButtonText,
} from "../../styled-components";

import {
  FrameTopGradient100,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteTitleAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  Backdrop,
  MessageContainer,
  CloseMessageButton,
  MessageboxText,
  BackdropTransparent,
  CreaSubMenuButtonMultiple,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "../../cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";

import {
  RuoliComplianceAttachedFilesInfo,
  RuoliComplianceComplianceDate,
  RuoliComplianceComplianceResponsible,
  RuoliComplianceDescription,
  RuoliComplianceID,
  RuoliComplianceDocumentsAttached,
  RuoliComplianceDocumentsPath,
  RuoliComplianceKind,
  RuoliComplianceName,
  RuoliComplianceObjective,
  RuoliComplianceComplianceResponsibleID,
  RuoliComplianceIsDropdownOpenDirectors,
  RuoliComplianceConfirmedLV1List,
  RuoliComplianceDeleteConfirmationDialog,
  RuoliComplianceIsDropdownOpenDirectorsCompliance,
  RuoliComplianceOrganizzazioneSelectedL1,
  RuoliComplianceOrganizzazioneSelectedL1ID,
  RuoliCompliance_2_isDropdownOpenLV1,
  RuoliComplianceRifNorm,
  RuoliComplianceNota,

  
} from "./RuoliCompliance-Add-recoil";

import { URL_ComplianceProcess, URL_ComplianceRolesShort, URL_LV4List, URL_LV4List_short, URL_PlacesFullList, URL_Process, useApi, useToken } from "../../../../API";



function generateUUID(): string {
  return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function useGenerateUUID(): string {

  const [ProcessoID, setProcessoID] = useRecoilState<string>(RuoliComplianceID);

  useEffect(() => {
    // Generate UUID and set it
    const newUUID = generateUUID();
    setProcessoID(newUUID);
    console.log("New place ID: ", newUUID);
  }, [setProcessoID]); // This effect should run only once when the component mounts

  return ProcessoID;
}


export const useHandleSave = () => {

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const { closeOverlay } = useContext(OverlayContext);
  const { post } = useApi();


  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(RuoliComplianceAttachedFilesInfo);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(RuoliComplianceDocumentsAttached);
  const [docPath, setDocPath] = useRecoilState(RuoliComplianceDocumentsPath);
  const [ID, setID] = useRecoilState(RuoliComplianceID);
  const [name, setName] = useRecoilState(RuoliComplianceName);
  const [complianceResponsible, setComplianceResponsible] = useRecoilState(RuoliComplianceComplianceResponsible);
  const [complianceDate, setComplianceDate] = useRecoilState(RuoliComplianceComplianceDate);
  const [description, setDescription] = useRecoilState(RuoliComplianceDescription);
  const [kind, setKind] = useRecoilState(RuoliComplianceKind);
  const [objective, setObjective] = useRecoilState(RuoliComplianceObjective);
  const [rifNorm, setrifNorm] = useRecoilState(RuoliComplianceRifNorm);
  const [nota, setNota] = useRecoilState(RuoliComplianceNota);

  const [organizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(RuoliComplianceComplianceResponsible);
  const [organizzazioneDirettoreID, setOrganizzazioneDirettoreID] = useRecoilState(RuoliComplianceComplianceResponsibleID);
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(RuoliComplianceIsDropdownOpenDirectorsCompliance);

  const handleSave = async () => {

    if (!kind || !name ) {
      setMessage("Si prega di compilare tutti i campi obbligatori: denominazione e categoria");
      setIsMessageBoxVisible(true);
      return; // Exit the function early
    }

    const newData = {

      id: ID,
      name: name,
      kind: kind,
      norm_ref: rifNorm,
      note: nota,
      description: description,
      // has_compliance_responsible: organizzazioneDirettoreID,
      // compliance_responsible_assignment_start: complianceDate,
      //documents_folder_link: 'RuoliCompliance',
    };
    
    try {

      setIsLoading(true);
      console.log(JSON.stringify(newData));
      const responseData = await post(
        URL_ComplianceRolesShort,
        newData
      );
      
      console.log("Compliance role created successfully:", responseData);
      setMessage("Compliance role created successfully!");
      setIsMessageBoxVisible(true);
     
    } catch (error) {
      console.error("Error creating Compliance role:", error);
      setMessage(`Error creating Compliance role: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSave};
};

const MainComponent = () => {

  const token = useToken(); 

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(RuoliComplianceAttachedFilesInfo);
  const [complianceDate, setComplianceDate] = useRecoilState(RuoliComplianceComplianceDate);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(RuoliComplianceDocumentsAttached);
  const [documentsPath, setDocumentsPath] = useRecoilState(RuoliComplianceDocumentsPath);
  const [ID, setID] = useRecoilState(RuoliComplianceID);
  const [name, setName] = useRecoilState(RuoliComplianceName);
 const [organizzazioneSelectedL1, setOrganizzazioneSelectedL1] = useRecoilState(RuoliComplianceOrganizzazioneSelectedL1);
  const [organizzazioneSelectedL1ID, setOrganizzazioneSelectedL1ID] = useRecoilState(RuoliComplianceOrganizzazioneSelectedL1ID);
  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(RuoliComplianceConfirmedLV1List);
  const [organizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(RuoliComplianceComplianceResponsible);
  const [organizzazioneDirettoreID, setOrganizzazioneDirettoreID] = useRecoilState(RuoliComplianceComplianceResponsibleID);
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(RuoliComplianceIsDropdownOpenDirectorsCompliance);
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(RuoliCompliance_2_isDropdownOpenLV1);


  const { handleSave} = useHandleSave();
  const initiateUpload = useInitiateUpload();

  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorL4);

  // CUSTOM HOOKS

  useGenerateUUID();

  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  const handleCloseAndNavigate = () => {
    
    setRefetchIndicator(prev => prev + 1);
    setIsMessageBoxVisible(false); // Close the message box
    closeOverlay();
    
  };


  ///////////////// SAVE AND UPLOAD /////////

  const handleSaveAndUpload = async () => {
    await handleSave();
    initiateUpload(); // Now you can call this function directly
  };

  ///////////////// MESSAGE ANNULLA BUTTON /////////

  const handleClose = () => {

    closeOverlay();

    setAttachedFilesInfo([]); // Assuming this resets to an empty array
    setDocumentsAttached([]); // Assuming this is a boolean that should be reset to false
    setDocumentsPath(''); // Assuming this resets to an empty string
    
    // setSurname('');
    // setName('');
    // setBirthDate(dayjs());
    // setBirthPlace('');
    // setGender('');
    // setFiscalCode('');
    // setResidenceAddress('');
    // setDomicileAddress('');
    // setWorkEmail('');
    // setPersonalEmail('');
    // setWorkPhone('');
    // setPrivatePhone('');
    
  };

  ////////////////////CHANGE ACTIVE COMPONENT///////////

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      // case "Organizzazione":
      //   return <Organizzazione />;
      // case "Infrastruttura":
      //   return <Infrastruttura />;
      // case "ProcessiGenerali":
      //   return <Processi />;
      // case "PartiInteressate":
      //   return <Parti />;
      // case "SistemaCompliance":
      //   return <Compliance />;
      // case "Documentazione":
      //   return <Documentazione />;
      // default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}



      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>
        NUOVO RUOLO COMPLIANCE
      </OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SchedaGenerale")}
            to="/Cybersecurity/GestioneCompliance/RuoliCompliance/CreaRuoliCompliance/1"
          >
            Scheda generale
          </CreaSubMenuButtonMultiple>

          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Organizzazione")}
            to="/Cybersecurity/GestioneCompliance/RuoliCompliance/CreaRuoliCompliance/2"
          >
            Organizzazione
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/GestioneCompliance/RuoliCompliance/CreaRuoliCompliance/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/GestioneCompliance/RuoliCompliance/CreaRuoliCompliance/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Infrastruttura/UnitaOperative/CreaL2/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SistemaCompliance")}
            to="/Cybersecurity/GestioneCompliance/RuoliCompliance/CreaRuoliCompliance/6"
          >
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Documentazione")}
            to="/Cybersecurity/GestioneCompliance/RuoliCompliance/CreaRuoliCompliance/7"
          >
            Documentazione
          </CreaSubMenuButtonMultiple> */}
        </SubMenuButtonContainerOrganigrammaMultipleShort>
        <Suspense fallback={<div>Loading...</div>}>
        {renderActiveComponent()}
        </Suspense>
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={handleClose}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSaveAndUpload}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default MainComponent;
