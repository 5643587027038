import React, { useState, useEffect, useRef, useContext, } from "react";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate, } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { CreaContainerColumn, CreaContainerColumnMultiple, CreaContainerColumnVertical, CreaContainerColumnVerticalMultiple, CustomDropdownItem, CustomDropdownList, DescriptionContainerCrea, DescriptionContainerCreaMultiple, DescriptionContainerCreaText, DropDownMenuScrollbar, FlexContainerCrea, FlexContainerCreaMultiple, IconContainer, IconSquare, InputContainer, InputDescriptionText, InputDescriptionTextDatePicker, InputTextbox, OverlayComponentWhiteAdd1, OverlayComponentWhiteAdd1Multiple, TriangleContainer } from '../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { 
  TemplaterDateEnd,
  TemplaterDateStart,
  TemplaterDocN,
  TemplaterName,
  TemplaterSurname,
  TemplaterDirectorId,
  TemplaterDate,
  TemplaterDirector,
  TemplaterComplianceRole,
  TemplaterComplianceRoleId,
  TemplaterDirectory,
TemplaterComplianceRoleResponsibility,


  

} from '../../../../../recoil';
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CancelIcon from "@mui/icons-material/Clear";

const MainComponent = () => {

  const useToken = () => {
    const { keycloak, initialized } = useKeycloak();
    return initialized && keycloak.authenticated ? keycloak.token : null;
  };

  const token = useToken(); // Call useToken here at the top level


////////////RECOIL

const [date, setDate] = useRecoilState(TemplaterDate);
const [dateStart, setDateStart] = useRecoilState(TemplaterDateStart);
const [dateEnd, setDateEnd] = useRecoilState(TemplaterDateEnd);
const [DocN, setDocN] = useRecoilState(TemplaterDocN);
const [Name, setName] = useRecoilState(TemplaterName);
const [Surname, setSurname] = useRecoilState(TemplaterSurname);
const [ComplianceRole, setComplianceRole] = useRecoilState(TemplaterComplianceRole);
const [ComplianceRoleId, setComplianceRoleId] = useRecoilState(TemplaterComplianceRoleId);
const [ComplianceRoleResponsibility, setComplianceRoleResponsibility] = useRecoilState(TemplaterComplianceRoleResponsibility);
const [Director, setDirector] = useRecoilState(TemplaterDirector);
const [DirectorId, setDirectorId] = useRecoilState(TemplaterDirectorId);
const [Directory, setDirectory] = useRecoilState(TemplaterDirectory);

useEffect(() => {
  // Set the dateStart state to today's date in "DD-MM-YYYY" format when the component mounts
  setDate(dayjs().format("DD-MM-YYYY"));
}, []); // Empty dependency array means this effect runs once on mount

////////GET DIRECTORS////////////////
const [dataCompliance, setDataCompliance] = useState<ComplianceRoleDataItem[]>([]);
const [data, setData] = useState<DirectorDataItem[]>([]);
const [isLoading, setIsLoading] = useState(false);
const [error, setError] = useState<string | null>(null);
const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
const [message, setMessage] = useState("");

type DirectorApiContentItem = {
  id: string;
  alias: string;
};

type DirectorDataItem = {
  director_id: string;
  director_name: string;
};

type ComplianceRoleApiContentItem = {
  compliance_role: {
    id: string;
    role_description: string;
    responsibility: string;
  };
  person: {
    name: string;
    surname: string;
    documents_folder_link: string;
  };

  unit: {
    compliance_responsible_assignment_start: string;
  };
};

type ComplianceRoleDataItem = {
  compliance_id: string;
  compliance_name: string;
  compliance_responsibility: string;
  compliance_date_start: string;
  person_name: string;
  person_surname: string;
  documents_folder: string;
};


useEffect(() => {
  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Check for token existence before making the API call
      if (!token) throw new Error("User is not authenticated");

      // Fetch data from the API
      const response = await fetch(
        `http://localhost:8091/api/v1/personroles-compliance/${DirectorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check if the API call was successful
      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }

      // Parse the JSON response
      const responseData = await response.json();
      console.log("Response Data:", responseData);

      // Assuming responseData is the array you want to map
      const transformedData: ComplianceRoleDataItem[] = responseData.map(
        (item: ComplianceRoleApiContentItem) => {
          return {
            compliance_id: item.compliance_role.id,
            compliance_name: item.compliance_role.role_description,
            compliance_responsibility: item.compliance_role.responsibility,
            documents_folder: item.person.documents_folder_link,
            person_name: item.person.name,
            person_surname: item.person.surname,
            compliance_date_start: item.person.surname,


            // Map other properties as needed
          };
        }
      );

      console.log("Transformed Data:", transformedData);
      setDataCompliance(transformedData);
    } catch (err) {
      // Error handling
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch data on component mount or when token changes
  fetchData();
}, [DirectorId, token]); // Depend on the token




useEffect(() => {
  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Check for token existence before making the API call
      if (!token) throw new Error("User is not authenticated");

      // Fetch data from the API
      const response = await fetch(
        "http://localhost:8091/api/v1/persons/minimal/organizations.ecubit01",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check if the API call was successful
      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }

      // Parse the JSON response
      const responseData = await response.json();
      console.log("Response Data:", responseData);

      // Assuming responseData is the array you want to map
      const transformedData: DirectorDataItem[] = responseData.map(
        (item: DirectorApiContentItem) => {
          return {
            director_id: item.id,
            director_name: item.alias, 

            // Map other properties as needed
          };
        }
      );

      console.log("Transformed Data:", transformedData);
      setData(transformedData);
    } catch (err) {
      // Error handling
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch data on component mount or when token changes
  fetchData();
}, [token]); // Depend on the token

////////////////////

/////FILTER INPUT DIRECTORS

// Create a map from director names to IDs
const directorNameToId = new Map(
  data.map((item) => [item.director_name, item.director_id])
);

const handleDirectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const value = event.target.value;
  setDirector(value);

  // If the value is a director name, also set the director ID
  if (directorNameToId.has(value)) {
    setDirectorId(directorNameToId.get(value) || "");
    console.log(directorNameToId.get(value) || "");
  }
};



////////////////////TOGGLE DROPDOWN///////////

const [isDropdownOpen, setIsDropdownOpen] = useState(false);
const dropdownRef = useRef<HTMLDivElement>(null);

// Event listener to close dropdown if clicked outside
useEffect(() => {
  function handleClickOutside(event: MouseEvent) {
    // Add a type guard for event.target
    if (
      dropdownRef.current &&
      event.target instanceof Node &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  }

  // Bind the event listener
  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    // Unbind the event listener on clean up
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);

const handleDropdownToggle = () => {
  // Clear the director name in the input box
  setDirector("");

  // Reset the director ID
  setDirectorId("");


  // Open the dropdown if it is not already open

  setIsDropdownOpen(!isDropdownOpen);
};

const handleOptionSelect = (selectedDirectorId: string) => {
  // Find the director name corresponding to the selected ID
  const selectedDirectorName = data.find(
    (item) => item.director_id === selectedDirectorId
  )?.director_name;

  if (selectedDirectorName) {
    // Update the director name in the input box
    setDirector(selectedDirectorName);

    // Update the director ID
    setDirectorId(selectedDirectorId);
  }

  // Close the dropdown
  setIsDropdownOpen(false);
  setComplianceRole(""); // Clear the director name
  setComplianceRoleId(""); // Clear the director ID
};


useEffect(() => {
  console.log("Selected Director ID:", DirectorId);
}, [DirectorId]); // This effect will run whenever directorId changes

const ClearLV1 = () => {
  setDirector(""); // Clear the director name
  setDirectorId(""); // Clear the director ID
  setComplianceRole(""); // Clear the director name
  setComplianceRoleId(""); // Clear the director ID
  setIsDropdownOpen(false); // Optionally close the dropdown if open
};



///////////////////////////////FILTER INPUT COMPLIANCE ROLE

// Create a map from director names to IDs
const complianceNameToId = new Map(
  data.map((item) => [item.director_name, item.director_id])
);

const handleComplianceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const value = event.target.value;
  setComplianceRole(value);

  // If the value is a director name, also set the director ID
  if (complianceNameToId.has(value)) {
    setComplianceRoleId(complianceNameToId.get(value) || "");
    console.log(complianceNameToId.get(value) || "");
  }
};



////////////////////TOGGLE DROPDOWN///////////

const [isDropdownComplianceOpen, setIsDropdownComplianceOpen] = useState(false);
const dropdownComplianceRef = useRef<HTMLDivElement>(null);

// Event listener to close dropdown if clicked outside
useEffect(() => {
  function handleClickOutside(event: MouseEvent) {
    // Add a type guard for event.target
    if (
      dropdownComplianceRef.current &&
      event.target instanceof Node &&
      !dropdownComplianceRef.current.contains(event.target)
    ) {
      setIsDropdownComplianceOpen(false);
    }
  }

  // Bind the event listener
  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    // Unbind the event listener on clean up
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);

const handleDropdownComplianceToggle = () => {
  // Clear the director name in the input box
  setComplianceRole("");

  // Reset the director ID
  setComplianceRoleId("");

  // Open the dropdown if it is not already open

  setIsDropdownComplianceOpen(!isDropdownOpen);
};


const handleOptionComplianceSelect = (selectedComplianceRoleId: string) => {
  // Find the compliance role by ID
  const selectedComplianceRole = dataCompliance.find(
    (item) => item.compliance_id === selectedComplianceRoleId
  );

  if (selectedComplianceRole) {

    const todayDateFormatted = dayjs().format("DD-MM-YYYY");

    setComplianceRole(selectedComplianceRole.compliance_name);
    setComplianceRoleId(selectedComplianceRoleId); 
    setComplianceRoleResponsibility(selectedComplianceRole.compliance_responsibility)
    setDirectory(selectedComplianceRole.documents_folder); 
    setName(selectedComplianceRole.person_name);
    setSurname(selectedComplianceRole.person_surname);
    setDateStart(todayDateFormatted);
   // setDateStart(selectedComplianceRole.compliance_date_start);

  }

  // Close the dropdown
  setIsDropdownComplianceOpen(false);
};



useEffect(() => {
  console.log("Selected Compliance ID:", ComplianceRoleId, "Directory: ", Directory, "Responsibility: ", ComplianceRoleResponsibility);
}, [ComplianceRoleId]); // This effect will run whenever directorId changes

const ClearLV2 = () => {
  setComplianceRole(""); // Clear the director name
  setComplianceRoleId(""); // Clear the director ID
  setIsDropdownComplianceOpen(false); // Optionally close the dropdown if open
};



//////////////RANDOM NUMBER 

function generateRandom8DigitNumber() {
  return Math.floor(Math.random() * 1000000);
}

useEffect(() => {
  // Get the current year from dayjs
  const currentYear = dayjs().format('YYYY');

  // Generate the random number and prepend the year and a slash
  const newDocN = `${currentYear}${generateRandom8DigitNumber().toString()}`;

  // Set the new value in state
  setDocN(newDocN);
}, []); // Empty dependency array ensures this runs only once when the component mounts


  return (
   <OverlayComponentWhiteAdd1Multiple>
    <FlexContainerCrea>
          <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Nome e cognome</InputDescriptionText>

            <InputTextbox
              list="directors-list"
              value={Director}
              onChange={handleDirectorChange}
              onClick={handleDropdownToggle}
              placeholder="Seleziona"
            />
            {Director && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6", // Change the color if needed
                }}
                onClick={ClearLV1} // Clears the input field
              />
            )}
            <TriangleContainer>
              {isDropdownOpen ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpen && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRef}>
                  {data
                    .filter((item) =>
                      item.director_name
                        .toLowerCase()
                        .includes(Director.toLowerCase())
                    )
                    .map((filteredItem, index) => (
                      <CustomDropdownItem
                        key={index}
                        onClick={() =>
                          handleOptionSelect(filteredItem.director_id)
                        }
                      >
                        {filteredItem.director_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Ruolo compliance</InputDescriptionText>

            <InputTextbox
              list="compliance-list"
              value={ComplianceRole}
              onChange={handleComplianceChange}
              onClick={handleDropdownComplianceToggle}
              placeholder="Seleziona"
            />
            {ComplianceRole && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6", // Change the color if needed
                }}
                onClick={ClearLV2} // Clears the input field
              />
            )}
            <TriangleContainer>
              {isDropdownComplianceOpen ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownComplianceOpen && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownComplianceRef}>
                  {dataCompliance
                    .filter((item) =>
                      item.compliance_name
                        .toLowerCase()
                        .includes(ComplianceRole.toLowerCase())
                    )
                    .map((filteredItem, index) => (
                      <CustomDropdownItem
                        key={index}
                        onClick={() =>
                          handleOptionComplianceSelect(filteredItem.compliance_id)
                        }
                      >
                        {filteredItem.compliance_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>
            <InputContainer>
              <InputDescriptionText>Documento numero:</InputDescriptionText>
              <InputTextbox   disabled={true} placeholder="" value={DocN} onChange={(e) => setDocN(e.target.value)} />
              <IconContainer />
            </InputContainer>

          </CreaContainerColumn>

          <CreaContainerColumn>
          <InputContainer>
              <InputDescriptionText>Data inizio incarico:</InputDescriptionText>
              <InputTextbox   disabled={true} placeholder="" value={dateStart} onChange={(e) => setDateStart(e.target.value)} />
              <IconContainer />
            </InputContainer>

            <InputContainer>
              <InputDescriptionText>Data fine incarico:</InputDescriptionText>
              <InputTextbox   disabled={true} placeholder="" value={dateEnd} onChange={(e) => setDateEnd(e.target.value)} />
              <IconContainer />
            </InputContainer>

            <InputContainer>
              <InputDescriptionText>Data di oggi:</InputDescriptionText>
              <InputTextbox   disabled={true} placeholder="" value={date} onChange={(e) => setDate(e.target.value)} />
              <IconContainer />
            </InputContainer>
        
        

           
          </CreaContainerColumn>

        
        </FlexContainerCrea>
        <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
              <DescriptionContainerCreaText>
             
              Inserire i dettagli identificativi dell'unità operativa, comprensivi di un nome completo che rifletta le competenze e i servizi offerti, un nome abbreviato per una rapida identificazione, oltre ai principali canali di comunicazione per facilitare un contatto efficace e una pronta corrispondenza


              </DescriptionContainerCreaText>

        </DescriptionContainerCreaMultiple>

   </ OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;