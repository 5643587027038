import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import {
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { SelectedL4ID } from "../../../../../recoil";
import { InputContainer50 } from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import {
  addL4DocumentsPath,
  addL4Name,
  addL4Surname,
  addL4birthDate,
  addL4birthPlace,
  addL4domicileAddress,
  addL4fiscalCode,
  addL4gender,
  addL4personalEmail,
  addL4privatePhone,
  addL4residenceAddress,
  addL4workEmail,
  addL4workPhone,
} from "../L4-Add-recoil";
import { DatePicker } from "@mui/x-date-pickers";

const useToken = () => {
  const { keycloak, initialized } = useKeycloak();
  return initialized && keycloak.authenticated ? keycloak.token : null;
};

type PersonItem = {
  id: string;

  surname: string;
  name: string;
  has_personal_card: string;
  member_of_formal_organization: string;
  reports_to: string;
  has_smart_working_site: string;
  documents_folder_link: string;
  based_at: string;

  national_identification_number: string;
  date_of_birth: string;
  has_place_of_birth: string;
  has_primary_residence: string;
  has_postal_address: string;
  personal_email: string;
  work_email: string;
  work_mobile_phone: string;
  personal_mobile_phone: string;
  has_identity_document: string;
  has_citizenship: string;
};

type PersonApiContentItem = {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  surname: string;
  name: string;
  has_personal_card: string;
  member_of_formal_organization: string;
  reports_to: string;
  has_smart_working_site: string;
  documents_folder_link: string;
  based_at: string;
  personal_card: {
    id: string;
    creationTs: string;
    updateTs: string;
    createdBy: string;
    updatedBy: string;
    national_identification_number: string;
    date_of_birth: string;
    has_place_of_birth: string;
    has_primary_residence: string;
    has_postal_address: string;
    personal_email: string;
    work_email: string;
    work_mobile_phone: string;
    personal_mobile_phone: string;
    has_identity_document: string;
    has_citizenship: string;
  };
};

const MainComponent = () => {
  const token = useToken();

  const [data, setData] = useState<PersonItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  ////////////RECOIL

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [surname, setSurname] = useRecoilState(addL4Surname);
  const [name, setName] = useRecoilState(addL4Name);
  const [birthDate, setBirthDate] = useRecoilState(addL4birthDate);
  const [birthPlace, setBirthPlace] = useRecoilState(addL4birthPlace);
  const [gender, setGender] = useRecoilState(addL4gender);
  const [fiscalCode, setFiscalCode] = useRecoilState(addL4fiscalCode);
  const [residenceAddress, setResidenceAddress] = useRecoilState(addL4residenceAddress);
  const [domicileAddress, setDomicileAddress] = useRecoilState(addL4domicileAddress);
  const [workEmail, setWorkEmail] = useRecoilState(addL4workEmail);
  const [personalEmail, setPersonalEmail] = useRecoilState(addL4personalEmail);
  const [workPhone, setWorkPhone] = useRecoilState(addL4workPhone);
  const [privatePhone, setPrivatePhone] = useRecoilState(addL4privatePhone);
  const [docPath, setDocPath] = useRecoilState(addL4DocumentsPath);

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Cognome
              <Tooltip title="Campo obbligatorio">
                <InfoIcon style={{ fontSize: "14px", color: "#04446c", cursor: "pointer", marginLeft:"2px" }} />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox placeholder="" value={surname} onChange={(e) => setSurname(e.target.value)} />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Nome 
            <Tooltip title="Campo obbligatorio">
                <InfoIcon style={{ fontSize: "14px", color: "#04446c", cursor: "pointer", marginLeft:"2px" }} />
              </Tooltip></InputDescriptionText>
            <InputTextbox placeholder="" value={name} onChange={(e) => setName(e.target.value)} />
          </InputContainer>

          <InputContainer50>
            <InputContainer>
              <InputDescriptionText>Genere</InputDescriptionText>
              <InputTextbox placeholder="" value={gender} onChange={(e) => setGender(e.target.value)} />
            </InputContainer>
            <InputContainer>
              <InputDescriptionText>Data di nascita</InputDescriptionText>
              {/* <InputTextbox placeholder="" value={birthDate} onChange={(e) => setBirthDate(e.target.value)} /> */}
              <DatePicker
              //label="Data inizio incarico"
              value={birthDate}
              
              format="DD-MM-YYYY"
              onChange={(newDate: dayjs.Dayjs | null) => setBirthDate(newDate)}
              sx={{
                '& .MuiInputBase-input': {
                  height: '2px', // Example to change the height
                  
                },
                '.MuiInputBase-root': {
                  // Background color for the input field
                  borderRadius: '0px',
                  backgroundColor: '#ffffff', // Example background color
                  marginBottom: "20px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#cccccc', // Your default border color
                   
                  },
                },
                 // Hover state
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#cccccc', // Your hover border color
        },
        // Focused state
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#1a81c3', // Your focused border color
          borderWidth: '1px',
          boxShadow: "0px 4px 3px rgba(0, 0, 0, 0.1)",
        },
                '.MuiInputBase-input': {
                  // Font color, size, and style for the input text
                  color: '#0e163d', // Example font color
                  fontSize: '15px', // Example font size
                  fontFamily: 'Titillium Web', // Example font family
                },

                '.MuiSvgIcon-root': {
                  // Style for the calendar icon
                  color: '#808080', // Example icon color
                  fontSize: '1.25rem', // Example icon size
                },
                
          
                  // '&:hover .MuiOutlinedInput-notchedOutline': {
                  //   borderColor: '#6d6d6d', // Change border color on hover
                  // },
                width: '100%',
                
              }}
             
            />
          </InputContainer>
         
          </InputContainer50>

          <InputContainer>
            <InputDescriptionText>Luogo di Nascita</InputDescriptionText>
            <InputTextbox placeholder="" value={birthPlace} onChange={(e) => setBirthPlace(e.target.value)} />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Codice Fiscale
            <Tooltip title="Campo obbligatorio">
                <InfoIcon style={{ fontSize: "14px", color: "#04446c", cursor: "pointer", marginLeft:"2px" }} />
              </Tooltip></InputDescriptionText>
            <InputTextbox placeholder="" value={fiscalCode} onChange={(e) => setFiscalCode(e.target.value)} />
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Indirizzo Residenza</InputDescriptionText>
            <InputTextbox placeholder="" value={residenceAddress} onChange={(e) => setResidenceAddress(e.target.value)} />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Indirizzo Domiciliazione</InputDescriptionText>
            <InputTextbox placeholder="" value={domicileAddress} onChange={(e) => setDomicileAddress(e.target.value)} />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Email Lavorativa</InputDescriptionText>
            <InputTextbox placeholder="" value={workEmail} onChange={(e) => setWorkEmail(e.target.value)} />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Email Personale</InputDescriptionText>
            <InputTextbox placeholder="" value={personalEmail} onChange={(e) => setPersonalEmail(e.target.value)} />
          </InputContainer>

          <InputContainer50>
            <InputContainer>
              <InputDescriptionText>Cellulare lavoro</InputDescriptionText>
              <InputTextbox placeholder="" value={workPhone} onChange={(e) => setWorkPhone(e.target.value)} />
            </InputContainer>
            <InputContainer>
              <InputDescriptionText>Cellulare privato</InputDescriptionText>
              <InputTextbox placeholder="" value={privatePhone} onChange={(e) => setPrivatePhone(e.target.value)} />
            </InputContainer>
          </InputContainer50>
        </CreaContainerColumn>
      </FlexContainerCrea>
      {/* <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
              <DescriptionContainerCreaText>
             
              Inserire i dettagli identificativi dell'unità operativa, comprensivi di un nome completo che rifletta le competenze e i servizi offerti, un nome abbreviato per una rapida identificazione, oltre ai principali canali di comunicazione per facilitare un contatto efficace e una pronta corrispondenza


              </DescriptionContainerCreaText>

        </DescriptionContainerCreaMultiple> */}
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
