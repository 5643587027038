import { useEffect, useState } from "react";
import React from 'react';
import { Navigate } from "react-router-dom";
import { FunctionComponent, ComponentType, FC } from "react";
import { Routes, Route, RouteProps, useNavigationType, useLocation } from "react-router-dom";
import Keycloak from "keycloak-js";
import keycloakConfig from "./keycloakConfig";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import GlobalStyles from './global';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import PageLogin from "./pages/page-login";

////////////////////////////////////DASHBOARD

import Board27001Dashboard from "./pages/components/cybersecurity/cybersecurity-board-dashboard";
import Board27001ProcessoCompliance from "./pages/components/cybersecurity/cybersecurity-board-processicompliance";

import Board27001GapAnalysis from "./pages/components/cybersecurity/cybersecurity-board-gap-analysis";
import Board27001Controlli from "./pages/components/cybersecurity/cybersecurity-board-gap-analysis-controlli";

import Board27001ControlliCrea1 from "./pages/components/cybersecurity/cybersecurity-board-gap-analysis-controlli-Add";
import Board27001ControlliModifica1 from "./pages/components/cybersecurity/cybersecurity-board-gap-analysis-controlli-Edit";



import Board27001RisorseMateriali from "./pages/components/cybersecurity/cybersecurity-board-risorsemateriali";
import Board27001RisorseUmane from "./pages/components/cybersecurity/cybersecurity-board-risorseumane";
import Board27001AltreRisorse from "./pages/components/cybersecurity/cybersecurity-board-altrerisorse";
import Board27001PianificazioneOperativa from "./pages/components/cybersecurity/cybersecurity-board-pianificazioneoperativa";
import Board27001Monitoraggio from "./pages/components/cybersecurity/cybersecurity-board-monitoraggio";
import Board27001AnalisiRevisione from "./pages/components/cybersecurity/cybersecurity-board-analisirevisione";
import Board27001RiskManagement from "./pages/components/cybersecurity/cybersecurity-board-riskmanagement";
import Board27001RiskManagementMetodo from "./pages/components/cybersecurity/cybersecurity-board-riskmanagement-metodo";
import Board27001RiskManagementEsempio from "./pages/components/cybersecurity/cybersecurity-board-riskmanagement-esempio";

import Board27001RiskStrumentiAggiuntivi from "./pages/components/cybersecurity/cybersecurity-board-strumentiaggiuntivi";

////////////////////////////////////CONTESTO E LEADERSHIP

//////////////ORGANIZZAZIONE

import Board27001ContestoLeadershipOrganizzazione from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-organizzazione";

// ORGANIGRAMMA
import Board27001ContestoLeadershipOrganizzazioneOrganigrammaL1L2L3L4Component from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-L1-L2-L3-L4";

import Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL1Component1 from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-L1-Add";
import Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1 from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-L1-Edit";
import Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1 from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-L1-Show";

// UNITA OPERATIVA
import Board27001ContestoLeadershipOrganizzazioneUnitaOperativa from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-unitaoperative";

import Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL2Component1 from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-L2-Add";
import Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL2Component1 from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-L2-Show";

//RUOLI GENERALI
import Board27001ContestoLeadershipOrganizzazioneRuoliGenerali from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-ruoligenerali";

import Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL3Component1 from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-L3-Add";


//PERSONE
import Board27001ContestoLeadershipOrganizzazionePersone from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-persone";

import Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL4Component1 from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-L4-Add";
import Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL4Component1 from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-L4-Show";
import Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL4Component1 from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-L4-Edit";
/////////////INFRASTRUTTURA



import Board27001ContestoLeadershipInfrastruttura from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-infrastruttura";


//STRUTTURA ASSET
import Board27001ContestoLeadershipInfrastrutturaStrutturaAsset from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-strutturaasset";

import Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddCategoria from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-strutturaasset-Categoria-Add";
import Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddAsset from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-strutturaasset-Asset-Add";


//ELENCO ASSET
import Board27001ContestoLeadershipInfrastrutturaElencoAsset from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-elencoasset";

import Board27001ContestoLeadershipInfrastrutturaStatoAssetAdd from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-elencoasset-Add";
import Board27001ContestoLeadershipInfrastrutturaStatoAssetEdit from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-elencoasset-Edit";


//LUOGHI
import Board27001ContestoLeadershipInfrastrutturaLuoghi from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-luoghi";

import Board27001ContestoLeadershipInfrastrutturaLuoghiAdd from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-luoghi-Add";

/////////////PROCESSI GENERALI
import Board27001ContestoLeadershipProcessiGenerali from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-processigenerali"


//BUSINESS PROCESSI
import Board27001ContestoLeadershipBusinessProcessi from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-processigenerali-sub/cybersecurity-board-processigenerali-businessprocessi"

import Board27001ContestoLeadershipBusinessProcessiAdd from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-processigenerali-sub/cybersecurity-board-processigenerali-businessprocessi-Add"


import Board27001ContestoLeadershipBusinessProcedure from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-processigenerali-sub/cybersecurity-board-processigenerali-businessprocedure"


/////////////PARTI INTERESSATE

import Board27001ContestoLeadershipPartiInteressate from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-partiinteressate"

//PARTI INTERESSATE
import Board27001ContestoLeadershipPartiInteressatePartiInteressate from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-partiinteressate-sub/cybersecurity-board-partiinteressate-partiinteressate"

import Board27001ContestoLeadershipPartiInteressatePartiInteressateAdd from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-partiinteressate-sub/cybersecurity-board-partiinteressate-partiinteressate-Add"


//CLIENTI
import Board27001ContestoLeadershipPartiInteressateClienti from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-partiinteressate-sub/cybersecurity-board-partiinteressate-clienti"

import Board27001ContestoLeadershipPartiInteressateClientiAdd from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-partiinteressate-sub/cybersecurity-board-partiinteressate-clienti-Add"

//FORNITORI
import Board27001ContestoLeadershipPartiInteressateFornitori from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-partiinteressate-sub/cybersecurity-board-partiinteressate-fornitori"

import Board27001ContestoLeadershipPartiInteressateFornitoriAdd from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-partiinteressate-sub/cybersecurity-board-partiinteressate-fornitori-Add"


//ALTRI ENTI
import Board27001ContestoLeadershipPartiInteressateAltriEnti from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-partiinteressate-sub/cybersecurity-board-partiinteressate-altrienti"


import Board27001ContestoLeadershipPartiInteressateAltriEntiAdd from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-partiinteressate-sub/cybersecurity-board-partiinteressate-altrienti-Add"



///////////// GESTIONE COMPLIANCE

import Board27001ContestoLeadershipGestioneCompliance from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-gestionecompliance"

//RUOLI COMPLIANCE
import Board27001ContestoLeadershipGestioneComplianceRuoliCompliance from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-compliance-sub/cybersecurity-board-gestionecompliance-ruolicompliance"

import Board27001ContestoLeadershipGestioneComplianceRuoliComplianceAdd from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-compliance-sub/cybersecurity-board-gestionecompliance-ruolicompliance-Add"


//CAMPO APPLICAZIONE
import Board27001ContestoLeadershipGestioneComplianceCampoApplicazione from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-compliance-sub/cybersecurity-board-gestionecompliance-campoapplicazione"

import Board27001ContestoLeadershipGestioneComplianceCampoApplicazioneAdd from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-compliance-sub/cybersecurity-board-gestionecompliance-campoapplicazione-Add"


// ADEMPIMENTI
import Board27001ContestoLeadershipGestioneComplianceAdempimenti from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-compliance-sub/cybersecurity-board-gestionecompliance-adempimenti"

import Board27001ContestoLeadershipGestioneComplianceAdempimentiAdd from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-compliance-sub/cybersecurity-board-gestionecompliance-adempimenti-Add"


//PROCESSI COMPLIANCE
import Board27001ContestoLeadershipGestioneComplianceProcessiCompliance from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-compliance-sub/cybersecurity-board-gestionecompliance-processicompliance"

import Board27001ContestoLeadershipGestioneComplianceProcessiComplianceAdd from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-compliance-sub/cybersecurity-board-gestionecompliance-processicompliance-Add"


//TOP MANAGEMENT
import Board27001ContestoLeadershipGestioneComplianceTopManagement from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-compliance-sub/cybersecurity-board-gestionecompliance-topmanagement"

import Board27001ContestoLeadershipGestioneComplianceTopManagementAdd from "./pages/components/cybersecurity/cybersecurity-board-contestoleadership-compliance-sub/cybersecurity-board-gestionecompliance-topmanagement-Add"


////////////////////////////////////PIANIFICAZIONE STRATEGICA
 


import Board27001Pianostrategico from "./pages/components/cybersecurity/cybersecurity-board-pianostrategico";


////////////////////////////////////DOCUMENTAZIONE


import Board27001Documentazione from "./pages/components/cybersecurity/cybersecurity-board-documentazione";

import Board27001DocumentazioneLetteraIncaricoAdd from "./pages/components/cybersecurity/cybersecurity-board-documentazione-sub/cybersecurity-board-documentazione-letteraincarico-Add";


/////////////GLOBAL
import MenuNav from "./pages/components/menu-nav";
import MenuIso from "./pages/components/menu-iso";

interface PrivateRouteProps {
  component: FC<any>; // Use your specific component prop types instead of any if possible
}
// Initialize Keycloak outside of the component
const keycloakInstance = new Keycloak(keycloakConfig);

// Create a PrivateRoute wrapper
interface PrivateRouteProps {
  component: FC<any>; // Specify the correct type for your components here
}

// Use the existing keycloakInstance for the PrivateRoute
const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component }) => {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return <div>Loading...</div>; // Or some loading component
  }

  if (keycloak.authenticated) {
    return <Component />;
  } else {
    // Use the existing keycloakInstance to login
    keycloak.login();
    return <div>Redirecting to Keycloak login...</div>; // Or some loading screen
  }
};


function App() {

  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/pagelogin":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector('head > meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <>
      <ReactKeycloakProvider authClient={keycloakInstance}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <GlobalStyles /> 
      <ThemeProvider theme={theme}>
        <MenuIso />
        <MenuNav />

        <Routes>
          {/* <Route path="/" element={<PrivateRoute component={Board27001Dashboard} />} /> */}
          <Route path="/" element={<Navigate to="/Cybersecurity/GapAnalysis" replace />} />
   
          {/* <Route path="/Cybersecurity/Dashboard" element={<PrivateRoute component={Board27001Dashboard}/>} /> */}
          <Route path="/Cybersecurity/Dashboard" element={<PrivateRoute component={Board27001GapAnalysis}/>} />
          <Route path="/Cybersecurity/PianoStrategico" element={<PrivateRoute component={Board27001Pianostrategico}/>} />
          <Route path="/Cybersecurity/ProcessiCompliance" element={<PrivateRoute component={Board27001ProcessoCompliance}/>} />

          {/* GAP ANALYSIS TOOL */}
          <Route path="/Cybersecurity/GapAnalysis" element={<PrivateRoute component={Board27001GapAnalysis}/>} />
          <Route path="/Cybersecurity/Controlli" element={<PrivateRoute component={Board27001Controlli}/>} />

          <Route path="/Cybersecurity/Controlli/CreaControllo/1" element={<PrivateRoute component={Board27001ControlliCrea1}/>} />
          <Route path="/Cybersecurity/Controlli/CreaControllo/2" element={<PrivateRoute component={Board27001ControlliCrea1}/>} />
          <Route path="/Cybersecurity/Controlli/CreaControllo/3" element={<PrivateRoute component={Board27001ControlliCrea1}/>} />
          <Route path="/Cybersecurity/Controlli/CreaControllo/7" element={<PrivateRoute component={Board27001ControlliCrea1}/>} />

          <Route path="/Cybersecurity/Controlli/ModificaControllo/1" element={<PrivateRoute component={Board27001ControlliModifica1}/>} />
          <Route path="/Cybersecurity/Controlli/ModificaControllo/2" element={<PrivateRoute component={Board27001ControlliModifica1}/>} />
          <Route path="/Cybersecurity/Controlli/ModificaControllo/3" element={<PrivateRoute component={Board27001ControlliModifica1}/>} />
          <Route path="/Cybersecurity/Controlli/ModificaControllo/7" element={<PrivateRoute component={Board27001ControlliModifica1}/>} />

          {/* GAP ANALYSIS TOOL END */}
          <Route path="/Cybersecurity/RisorseMateriali" element={<PrivateRoute component={Board27001RisorseMateriali}/>} />
          <Route path="/Cybersecurity/RisorseUmane" element={<PrivateRoute component={Board27001RisorseUmane}/>} />
          <Route path="/Cybersecurity/AltreRisorse" element={<PrivateRoute component={Board27001AltreRisorse}/>} />
          <Route path="/Cybersecurity/PianificazioneOperativa" element={<PrivateRoute component={Board27001PianificazioneOperativa}/>} />
          <Route path="/Cybersecurity/Monitoraggio" element={<PrivateRoute component={Board27001Monitoraggio}/>} />
          <Route path="/Cybersecurity/AnalisiRevisione" element={<PrivateRoute component={Board27001AnalisiRevisione}/>} />
          <Route path="/Cybersecurity/RiskManagement" element={<PrivateRoute component={Board27001RiskManagement}/>} />
          <Route path="/Cybersecurity/Metodo" element={<PrivateRoute component={Board27001RiskManagementMetodo}/>} />
          <Route path="/Cybersecurity/Esempio" element={<PrivateRoute component={Board27001RiskManagementEsempio}/>}  />
          <Route path="/Cybersecurity/StrumentiAggiuntivi" element={<PrivateRoute component={Board27001RiskStrumentiAggiuntivi}/>} />

            {/* ORGANIZZAZIONE */}


          <Route path="/Cybersecurity/Organizzazione/*" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazione} />}>
            <Route index element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaL1L2L3L4Component} />} />
            <Route path="Organigramma" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaL1L2L3L4Component} />}>
              
              <Route path="CreaL1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL1Component1} />} />

              <Route path="CreaL1/1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL1Component1} />} />
              <Route path="CreaL1/2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL1Component1} />} />
              <Route path="CreaL1/3" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL1Component1} />} />
              <Route path="CreaL1/4" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL1Component1} />} />
              <Route path="CreaL1/5" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL1Component1} />} />
              <Route path="CreaL1/6" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL1Component1} />} />
              <Route path="CreaL1/7" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL1Component1} />} />




              <Route path="ModificaL1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />

              <Route  path="VisualizzaL1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />

              <Route  path="VisualizzaL1/1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />
              <Route  path="VisualizzaL1/2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />
              <Route  path="VisualizzaL1/3" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />
              <Route  path="VisualizzaL1/4" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />
              <Route  path="VisualizzaL1/5" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />
              <Route  path="VisualizzaL1/6" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />
              <Route  path="VisualizzaL1/7" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />



            </Route>

          

            <Route path="UnitaOperative" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneUnitaOperativa} />} >

           
              <Route path="CreaL2/1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL2Component1} />} />
              <Route path="CreaL2/2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL2Component1} />} />
              <Route path="CreaL2/3" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL2Component1} />} />
              <Route path="CreaL2/4" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL2Component1} />} />
              <Route path="CreaL2/5" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL2Component1} />} />
              <Route path="CreaL2/6" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL2Component1} />} />
              <Route path="CreaL2/7" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL2Component1} />} />

              <Route path="ModificaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />

              <Route  path="VisualizzaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />

              <Route  path="VisualizzaL2/1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL2Component1} />} />
              <Route  path="VisualizzaL2/2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL2Component1} />} />
              <Route  path="VisualizzaL2/3" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL2Component1} />} />
              <Route  path="VisualizzaL2/4" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL2Component1} />} />
              <Route  path="VisualizzaL2/5" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL2Component1} />} />
              <Route  path="VisualizzaL2/6" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL2Component1} />} />
              <Route  path="VisualizzaL2/7" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL2Component1} />} />


      

            </Route>


            <Route path="Ruoli" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneRuoliGenerali} />}> 

            <Route path="CreaL3/1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL3Component1} />} />
              <Route path="CreaL3/2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL3Component1} />} />
              <Route path="CreaL3/3" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL3Component1} />} />
              <Route path="CreaL3/4" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL3Component1} />} />
              <Route path="CreaL3/5" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL3Component1} />} />
              <Route path="CreaL3/6" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL3Component1} />} />
              <Route path="CreaL3/7" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL3Component1} />} />
              

           
            <Route path="ModificaL3" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />

            <Route  path="VisualizzaL3" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />


            </Route>

            <Route path="Persone" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazionePersone} />} >
          
          
              <Route path="CreaL4/1" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL4Component1} />} />
              <Route path="CreaL4/2" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL4Component1} />} />
              <Route path="CreaL4/3" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL4Component1} />} />
              <Route path="CreaL4/4" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL4Component1} />} />
              <Route path="CreaL4/5" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL4Component1} />} />
              <Route path="CreaL4/6" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL4Component1} />} />
              <Route path="CreaL4/7" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaAddL4Component1} />} />
              


          
              <Route path="VisualizzaL4/1" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL4Component1} />} />
              <Route path="VisualizzaL4/2" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL4Component1} />} />
              <Route path="VisualizzaL4/3" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL4Component1} />} />
              <Route path="VisualizzaL4/4" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL4Component1} />} />
              <Route path="VisualizzaL4/5" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL4Component1} />} />
              <Route path="VisualizzaL4/6" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL4Component1} />} />
              <Route path="VisualizzaL4/7" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL4Component1} />} />



              <Route path="ModificaL4/1" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL4Component1} />} />
              <Route path="ModificaL4/2" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL4Component1} />} />
              <Route path="ModificaL4/3" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL4Component1} />} />
              <Route path="ModificaL4/4" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL4Component1} />} />
              <Route path="ModificaL4/5" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL4Component1} />} />
              <Route path="ModificaL4/6" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL4Component1} />} />
              <Route path="ModificaL4/7" element={<PrivateRoute component={ Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL4Component1} />} />


          </Route>
          </Route>

          {/* INFRASTRUTTURA */}

          <Route path="/Cybersecurity/Infrastruttura/*" element={<PrivateRoute component={Board27001ContestoLeadershipInfrastruttura} />} > 
          <Route index element={<PrivateRoute component={Board27001ContestoLeadershipInfrastrutturaStrutturaAsset} />} />

          <Route path="StrutturaAsset" element={<PrivateRoute component={Board27001ContestoLeadershipInfrastrutturaStrutturaAsset} />}>
              
          <Route path="CreaCategoria/1" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddCategoria} />} />
              <Route path="CreaCategoria/2" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddCategoria} />} />
              <Route path="CreaCategoria/3" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddCategoria} />} />
              <Route path="CreaCategoria/4" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddCategoria} />} />
              <Route path="CreaCategoria/5" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddCategoria} />} />
              <Route path="CreaCategoria/6" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddCategoria} />} />
              <Route path="CreaCategoria/7" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddCategoria} />} />
              
              
              
              <Route path="ModificaL1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />
              <Route  path="VisualizzaL1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />


              <Route path="CreaAsset/1" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddAsset} />} />
              <Route path="CreaAsset/2" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddAsset} />} />
              <Route path="CreaAsset/3" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddAsset} />} />
              <Route path="CreaAsset/4" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddAsset} />} />
              <Route path="CreaAsset/5" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddAsset} />} />
              <Route path="CreaAsset/6" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddAsset} />} />
              <Route path="CreaAsset/7" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStrutturaAssetAddAsset} />} />
              

              <Route path="ModificaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />
              <Route  path="VisualizzaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />

            </Route>

            <Route path="StatoAsset" element={<PrivateRoute component={Board27001ContestoLeadershipInfrastrutturaElencoAsset} />} >

              <Route path="CreaStatoAsset/1" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStatoAssetAdd} />} />
              <Route path="CreaStatoAsset/2" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStatoAssetAdd} />} />
              <Route path="CreaStatoAsset/3" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStatoAssetAdd} />} />
              <Route path="CreaStatoAsset/4" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStatoAssetAdd} />} />
              <Route path="CreaStatoAsset/5" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStatoAssetAdd} />} />
              <Route path="CreaStatoAsset/6" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStatoAssetAdd} />} />
              <Route path="CreaStatoAsset/7" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStatoAssetAdd} />} />
              
              <Route path="ModificaStatoAsset/1" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStatoAssetEdit} />} />
              <Route path="ModificaStatoAsset/2" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStatoAssetEdit} />} />
              <Route path="ModificaStatoAsset/3" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStatoAssetEdit} />} />
              <Route path="ModificaStatoAsset/4" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStatoAssetEdit} />} />
              <Route path="ModificaStatoAsset/5" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStatoAssetEdit} />} />
              <Route path="ModificaStatoAsset/6" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStatoAssetEdit} />} />
              <Route path="ModificaStatoAsset/7" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaStatoAssetEdit} />} />


            <Route path="ModificaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />
            <Route  path="VisualizzaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />


            </Route>

            <Route path="Luoghi" element={<PrivateRoute component={Board27001ContestoLeadershipInfrastrutturaLuoghi} />} >



            <Route path="CreaLuoghi/1" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaLuoghiAdd} />} />
              <Route path="CreaLuoghi/2" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaLuoghiAdd} />} />
              <Route path="CreaLuoghi/3" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaLuoghiAdd} />} />
              <Route path="CreaLuoghi/4" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaLuoghiAdd} />} />
              <Route path="CreaLuoghi/5" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaLuoghiAdd} />} />
              <Route path="CreaLuoghi/6" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaLuoghiAdd} />} />
              <Route path="CreaLuoghi/7" element={<PrivateRoute component={ Board27001ContestoLeadershipInfrastrutturaLuoghiAdd} />} />
              

              <Route path="ModificaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />
              <Route  path="VisualizzaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />

            </Route>

          </Route>



        {/* PROCESSI GENERALI */}


          <Route path="/Cybersecurity/ProcessiGenerali/*" element={<PrivateRoute component={Board27001ContestoLeadershipProcessiGenerali} />} > 
          <Route index element={<PrivateRoute component={Board27001ContestoLeadershipBusinessProcessi} />} />

          <Route path="BusinessProcessi" element={<PrivateRoute component={Board27001ContestoLeadershipBusinessProcessi} />}>
              
          <Route path="CreaBusinessProcessi/1" element={<PrivateRoute component={ Board27001ContestoLeadershipBusinessProcessiAdd} />} />
              <Route path="CreaBusinessProcessi/2" element={<PrivateRoute component={ Board27001ContestoLeadershipBusinessProcessiAdd} />} />
              <Route path="CreaBusinessProcessi/3" element={<PrivateRoute component={ Board27001ContestoLeadershipBusinessProcessiAdd} />} />
              <Route path="CreaBusinessProcessi/4" element={<PrivateRoute component={ Board27001ContestoLeadershipBusinessProcessiAdd} />} />
              <Route path="CreaBusinessProcessi/5" element={<PrivateRoute component={ Board27001ContestoLeadershipBusinessProcessiAdd} />} />
              <Route path="CreaBusinessProcessi/6" element={<PrivateRoute component={ Board27001ContestoLeadershipBusinessProcessiAdd} />} />
              <Route path="CreaBusinessProcessi/7" element={<PrivateRoute component={ Board27001ContestoLeadershipBusinessProcessiAdd} />} />
              

              <Route path="ModificaL1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />
              <Route  path="VisualizzaL1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />

             
            </Route>


          </Route>

          {/* PARTI INTERESSATE */}

          <Route path="/Cybersecurity/PartiInteressate/*" element={<PrivateRoute component={Board27001ContestoLeadershipPartiInteressate} />} > 
          <Route index element={<PrivateRoute component={Board27001ContestoLeadershipPartiInteressatePartiInteressate} />} />

          <Route path="PartiInteressate" element={<PrivateRoute component={Board27001ContestoLeadershipPartiInteressatePartiInteressate} />}>
              
              
              <Route path="CreaPartiInteressate/1" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressatePartiInteressateAdd} />} />
              <Route path="CreaPartiInteressate/2" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressatePartiInteressateAdd} />} />
              <Route path="CreaPartiInteressate/3" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressatePartiInteressateAdd} />} />
              <Route path="CreaPartiInteressate/4" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressatePartiInteressateAdd} />} />
              <Route path="CreaPartiInteressate/5" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressatePartiInteressateAdd} />} />
              <Route path="CreaPartiInteressate/6" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressatePartiInteressateAdd} />} />
              <Route path="CreaPartiInteressate/7" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressatePartiInteressateAdd} />} />
              
              
              <Route path="ModificaL1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />
              <Route  path="VisualizzaL1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />


            </Route>

            <Route path="Clienti" element={<PrivateRoute component={Board27001ContestoLeadershipPartiInteressateClienti} />} >

            <Route path="CreaClienti/1" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateClientiAdd} />} />
              <Route path="CreaClienti/2" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateClientiAdd} />} />
              <Route path="CreaClienti/3" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateClientiAdd} />} />
              <Route path="CreaClienti/4" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateClientiAdd} />} />
              <Route path="CreaClienti/5" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateClientiAdd} />} />
              <Route path="CreaClienti/6" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateClientiAdd} />} />
              <Route path="CreaClienti/7" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateClientiAdd} />} />
              


<Route path="ModificaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />
<Route  path="VisualizzaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />



</Route>

            <Route path="Fornitori" element={<PrivateRoute component={Board27001ContestoLeadershipPartiInteressateFornitori} />} >

           
            <Route path="CreaFornitori/1" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateFornitoriAdd} />} />
              <Route path="CreaFornitori/2" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateFornitoriAdd} />} />
              <Route path="CreaFornitori/3" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateFornitoriAdd} />} />
              <Route path="CreaFornitori/4" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateFornitoriAdd} />} />
              <Route path="CreaFornitori/5" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateFornitoriAdd} />} />
              <Route path="CreaFornitori/6" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateFornitoriAdd} />} />
              <Route path="CreaFornitori/7" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateFornitoriAdd} />} />
              
            <Route path="ModificaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />
            <Route  path="VisualizzaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />

        

            </Route>


            <Route path="AltriEnti" element={<PrivateRoute component={Board27001ContestoLeadershipPartiInteressateAltriEnti} />} >


<Route path="CreaAltriEnti/1" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateAltriEntiAdd} />} />
              <Route path="CreaAltriEnti/2" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateAltriEntiAdd} />} />
              <Route path="CreaAltriEnti/3" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateAltriEntiAdd} />} />
              <Route path="CreaAltriEnti/4" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateAltriEntiAdd} />} />
              <Route path="CreaAltriEnti/5" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateAltriEntiAdd} />} />
              <Route path="CreaAltriEnti/6" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateAltriEntiAdd} />} />
              <Route path="CreaAltriEnti/7" element={<PrivateRoute component={ Board27001ContestoLeadershipPartiInteressateAltriEntiAdd} />} />
             


              <Route path="ModificaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />
              <Route  path="VisualizzaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />

            </Route>

          </Route>


        {/* GESTIONE COMPLIANCE */}


          <Route path="/Cybersecurity/GestioneCompliance/*" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneCompliance} />}>
            <Route index element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceCampoApplicazione} />} />
            <Route path="RuoliCompliance" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceRuoliCompliance} />}>
              
              <Route path="CreaRuoliCompliance/1" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceRuoliComplianceAdd} />} />
              <Route path="CreaRuoliCompliance/2" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceRuoliComplianceAdd} />} />
              <Route path="CreaRuoliCompliance/3" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceRuoliComplianceAdd} />} />
              <Route path="CreaRuoliCompliance/4" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceRuoliComplianceAdd} />} />
              <Route path="CreaRuoliCompliance/5" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceRuoliComplianceAdd} />} />
              <Route path="CreaRuoliCompliance/6" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceRuoliComplianceAdd} />} />
              <Route path="CreaRuoliCompliance/7" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceRuoliComplianceAdd} />} />



              <Route path="ModificaL1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />
              <Route  path="VisualizzaL1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />


            </Route>

          

            <Route path="CampoApplicazione" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceCampoApplicazione} />} >
           
            <Route path="CreaCampoApplicazione/1" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceCampoApplicazioneAdd} />} />
              <Route path="CreaCampoApplicazione/2" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceCampoApplicazioneAdd} />} />
              <Route path="CreaCampoApplicazione/3" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceCampoApplicazioneAdd} />} />
              <Route path="CreaCampoApplicazione/4" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceCampoApplicazioneAdd} />} />
              <Route path="CreaCampoApplicazione/5" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceCampoApplicazioneAdd} />} />
              <Route path="CreaCampoApplicazione/6" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceCampoApplicazioneAdd} />} />
              <Route path="CreaCampoApplicazione/7" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceCampoApplicazioneAdd} />} />

           
         
              <Route path="ModificaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />
              <Route  path="VisualizzaL2" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />

      

            </Route>


            <Route path="Adempimenti" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceAdempimenti} />}> 

            <Route path="CreaAdempimenti/1" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceAdempimentiAdd} />} />
              <Route path="CreaAdempimenti/2" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceAdempimentiAdd} />} />
              <Route path="CreaAdempimenti/3" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceAdempimentiAdd} />} />
              <Route path="CreaAdempimenti/4" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceAdempimentiAdd} />} />
              <Route path="CreaAdempimenti/5" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceAdempimentiAdd} />} />
              <Route path="CreaAdempimenti/6" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceAdempimentiAdd} />} />
              <Route path="CreaAdempimenti/7" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceAdempimentiAdd} />} />



            <Route path="ModificaL3" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />
            <Route  path="VisualizzaL3" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />


            </Route>
            <Route path="ProcessiCompliance" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceProcessiCompliance} />}>
          
            <Route path="CreaProcessiCompliance/1" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceProcessiComplianceAdd} />} />
              <Route path="CreaProcessiCompliance/2" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceProcessiComplianceAdd} />} />
              <Route path="CreaProcessiCompliance/3" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceProcessiComplianceAdd} />} />
              <Route path="CreaProcessiCompliance/4" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceProcessiComplianceAdd} />} />
              <Route path="CreaProcessiCompliance/5" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceProcessiComplianceAdd} />} />
              <Route path="CreaProcessiCompliance/6" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceProcessiComplianceAdd} />} />
              <Route path="CreaProcessiCompliance/7" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceProcessiComplianceAdd} />} />



              </Route>
            <Route path="TopManagement" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceTopManagement} />} >


 <Route path="CreaTopManagement/1" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceTopManagementAdd} />} />
              <Route path="CreaTopManagement/2" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceTopManagementAdd} />} />
              <Route path="CreaTopManagement/3" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceTopManagementAdd} />} />
              <Route path="CreaTopManagement/4" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceTopManagementAdd} />} />
              <Route path="CreaTopManagement/5" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceTopManagementAdd} />} />
              <Route path="CreaTopManagement/6" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceTopManagementAdd} />} />
              <Route path="CreaTopManagement/7" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceTopManagementAdd} />} />



            </Route>

          </Route>

          <Route path="/Cybersecurity/Documentazione/*" element={<PrivateRoute component={Board27001Documentazione} />}>
            <Route index element={<PrivateRoute component={Board27001Documentazione} />} />
            <Route path="LetteraIncarico" element={<PrivateRoute component={Board27001DocumentazioneLetteraIncaricoAdd} />}>
              
              <Route path="CreaLetteraIncarico/1" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceRuoliComplianceAdd} />} />
              <Route path="CreaRuoliCompliance/2" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceRuoliComplianceAdd} />} />
              <Route path="CreaRuoliCompliance/3" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceRuoliComplianceAdd} />} />
              <Route path="CreaRuoliCompliance/4" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceRuoliComplianceAdd} />} />
              <Route path="CreaRuoliCompliance/5" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceRuoliComplianceAdd} />} />
              <Route path="CreaRuoliCompliance/6" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceRuoliComplianceAdd} />} />
              <Route path="CreaRuoliCompliance/7" element={<PrivateRoute component={Board27001ContestoLeadershipGestioneComplianceRuoliComplianceAdd} />} />



              <Route path="ModificaL1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaEditL1Component1} />} />
              <Route  path="VisualizzaL1" element={<PrivateRoute component={Board27001ContestoLeadershipOrganizzazioneOrganigrammaShowL1Component1} />} />


            </Route>


          </Route>

      

        </Routes>
        </ThemeProvider>
        </LocalizationProvider>
      </ReactKeycloakProvider>
    </>
  );
}
export default App;
