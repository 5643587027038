//ORGANIZZAZIONE
export const PATH_L1 = 'componenti_organizzative/componente_';
export const PATH_L2 = 'unita_operative/unita_';
export const PATH_L4 = 'persone/persona_';
//PARTI INTERESSATE
export const PATH_PartiInteressate = 'parti_interessate/parte_';
//ASSET
export const PATH_Assets = 'assets/asset_';
//INFRASTRUTTURA
export const PATH_Luoghi = 'luoghi';
//PROCESSI
export const PATH_Processi = 'processi_business';
//GESTIONE COMPLIANCE
export const PATH_ProcessiCompliance = 'processi_compliance';
export const PATH_Adempimenti = 'adempimenti';
//GAP ANALYSIS CONTROLLI
export const PATH_Controlli = 'controls';
