import React, { useState, useEffect, useRef, useContext, } from "react";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate, } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { CreaContainerColumn, CreaContainerColumnMultiple, CreaContainerColumnVertical, CreaContainerColumnVerticalMultiple, DescriptionContainerCrea, DescriptionContainerCreaMultiple, DescriptionContainerCreaText, FlexContainerCrea, FlexContainerCreaMultiple, IconContainer, IconSquare, InputContainer, InputDescriptionText, InputTextbox, OverlayComponentWhiteAdd1, OverlayComponentWhiteAdd1Multiple } from '../cybersecurity-board-organizzazione-organigramma-styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { 
SelectedL4ID, isLoadingRec, isMessageBoxVisibleRec, setErrorRec, setMessageRec,

} from '../../../../../recoil';
import { InputContainer50 } from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import { showL4PersonItem, showL4ComponenteOrganizzativa, showL4DocPath, showL4Name, showL4PersonItemState, showL4RuoliEnte, showL4SuperioreDiretto, showL4Surname, showL4Title, showL4UnitaOperative, showL4birthDate, showL4birthPlace, showL4domicileAddress, showL4fiscalCode, showL4gender, showL4idDocumentNumber, showL4personalEmail, showL4privatePhone, showL4residenceAddress, showL4workEmail, showL4workPhone } from "../L4-Show-recoil";
import { URL_LV4List, useToken } from "../../../../../API";



const useFetchPersonData = (token: string | null | undefined) => {

  const [data, setData] = useRecoilState(showL4PersonItemState);
 const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [surname, setSurname] = useRecoilState(showL4Surname);
  const [name, setName] = useRecoilState(showL4Name);
  const [birthDate, setBirthDate] = useRecoilState(showL4birthDate);
  const [birthPlace, setBirthPlace] = useRecoilState(showL4birthPlace);
  const [gender, setGender] = useRecoilState(showL4gender);
  const [fiscalCode, setFiscalCode] = useRecoilState(showL4fiscalCode);
  const [residenceAddress, setResidenceAddress] = useRecoilState(showL4residenceAddress);
  const [domicileAddress, setDomicileAddress] = useRecoilState(showL4domicileAddress);
  const [workEmail, setWorkEmail] = useRecoilState(showL4workEmail);
  const [personalEmail, setPersonalEmail] = useRecoilState(showL4personalEmail);
  const [workPhone, setWorkPhone] = useRecoilState(showL4workPhone);
  const [privatePhone, setPrivatePhone] = useRecoilState(showL4privatePhone);
  const [docPath, setDocPath] = useRecoilState(showL4DocPath);
  const [Title, setTitle] = useRecoilState(showL4Title);
  const [superioreDiretto, setSuperioreDiretto] = useRecoilState(showL4SuperioreDiretto);
  const [componenteOrganizzativa, setComponenteOrganizzativa] = useRecoilState(showL4ComponenteOrganizzativa);

  useEffect(() => {
    const fetchData = async () => {
    setIsLoading(true);
    try {
      if (!token) throw new Error("User is not authenticated");

      const response = await fetch(`${URL_LV4List}${L4ID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }

      const responseData = await response.json();
      console.log("Response Data:", responseData);


       // Directly set the Recoil states using the fetched data
       setSurname(responseData.surname);
       setName(responseData.name);
       setBirthDate(responseData.personal_card.date_of_birth);
       setBirthPlace(responseData.personal_card.has_place_of_birth);
       setGender (responseData.personal_card.has_citizenship)
       setFiscalCode(responseData.personal_card.national_identification_number);
       setResidenceAddress(responseData.personal_card.has_primary_residence);
       setDomicileAddress(responseData.personal_card.has_postal_address);
       setWorkEmail(responseData.personal_card.work_email);
       setPersonalEmail(responseData.personal_card.personal_email);
       setWorkPhone(responseData.personal_card.work_mobile_phone);
       setPrivatePhone(responseData.personal_card.personal_mobile_phone);
       setSuperioreDiretto(responseData.reports_to);
       setComponenteOrganizzativa(responseData.based_at);
       setDocPath(responseData.documents_folder_link);
       setTitle(`${responseData.surname} ${responseData.name}`)

      // Since responseData is an object, directly use its properties to create a PersonItem
      const transformedData: showL4PersonItem = {
        id: responseData.id,
        surname: responseData.surname,
        name: responseData.name,
        has_personal_card: responseData.has_personal_card,
        member_of_formal_organization: responseData.member_of_formal_organization,
        reports_to: responseData.reports_to,
        has_smart_working_site: responseData.has_smart_working_site,
        documents_folder_link: responseData.documents_folder_link,
        based_at: responseData.based_at,
        national_identification_number: responseData.personal_card.national_identification_number,
        date_of_birth: responseData.personal_card.date_of_birth,
        has_place_of_birth: responseData.personal_card.has_place_of_birth,
        has_primary_residence: responseData.personal_card.has_primary_residence,
        has_postal_address: responseData.personal_card.has_postal_address,
        personal_email: responseData.personal_card.personal_email,
        work_email: responseData.personal_card.work_email,
        work_mobile_phone: responseData.personal_card.work_mobile_phone,
        personal_mobile_phone: responseData.personal_card.personal_mobile_phone,
        has_identity_document: responseData.personal_card.has_identity_document,
        has_citizenship: responseData.personal_card.has_citizenship,
      };

      console.log("Transformed Data:", transformedData);
      setData([transformedData]); // Set data as an array with the single transformed item

    } catch (err) {
      setError(err instanceof Error ? err.message : "An unknown error occurred");
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (token && L4ID) fetchData();

}, [token, L4ID, setData]); 

// Return anything that might be needed outside of the hook
};



const MainComponent = () => {


  const [data, setData] = useRecoilState(showL4PersonItemState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);


////////////RECOIL


const [surname, setSurname] = useRecoilState(showL4Surname);
const [name, setName] = useRecoilState(showL4Name);
const [birthDate, setBirthDate] = useRecoilState(showL4birthDate);
const [birthPlace, setBirthPlace] = useRecoilState(showL4birthPlace);
const [gender, setGender] = useRecoilState(showL4gender);
const [fiscalCode, setFiscalCode] = useRecoilState(showL4fiscalCode);
const [residenceAddress, setResidenceAddress] = useRecoilState(showL4residenceAddress);
const [domicileAddress, setDomicileAddress] = useRecoilState(showL4domicileAddress);
const [workEmail, setWorkEmail] = useRecoilState(showL4workEmail);
const [personalEmail, setPersonalEmail] = useRecoilState(showL4personalEmail);
const [workPhone, setWorkPhone] = useRecoilState(showL4workPhone);
const [privatePhone, setPrivatePhone] = useRecoilState(showL4privatePhone);
const [docPath, setDocPath] = useRecoilState(showL4DocPath);
const [Title, setTitle] = useRecoilState(showL4Title);

const [idDocumentNumber, setIdDocumentNumber] = useRecoilState(showL4idDocumentNumber);
const [ruoliEnte, setRuoliEnte] = useRecoilState(showL4RuoliEnte);
const [unitaOperative, setUnitaOperative] = useRecoilState(showL4UnitaOperative);
const [superioreDiretto, setSuperioreDiretto] = useRecoilState(showL4SuperioreDiretto);
const [componenteOrganizzativa, setComponenteOrganizzativa] = useRecoilState(showL4ComponenteOrganizzativa);

const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

const token = useToken();

useFetchPersonData(token);


  return (
   <OverlayComponentWhiteAdd1Multiple>
    <FlexContainerCrea>
      
          <CreaContainerColumn>
           <InputContainer>
  <InputDescriptionText>Cognome</InputDescriptionText>
  <InputTextbox  placeholder="" value={surname} onChange={(e) => setSurname(e.target.value)} />
</InputContainer>

<InputContainer>
  <InputDescriptionText>Nome</InputDescriptionText>
  <InputTextbox  placeholder="" value={name} onChange={(e) => setName(e.target.value)} />
</InputContainer>


<InputContainer50>
<InputContainer>
  <InputDescriptionText>Genere</InputDescriptionText>
  <InputTextbox  placeholder="" value={gender} onChange={(e) => setGender(e.target.value)} />
</InputContainer>
<InputContainer>
  <InputDescriptionText>Data di nascita</InputDescriptionText>
  <InputTextbox  placeholder="" value={birthDate?.toString()} onChange={(e) => setBirthPlace(e.target.value)} />
</InputContainer>
</InputContainer50>



<InputContainer>
  <InputDescriptionText>Luogo di nascita</InputDescriptionText>
  <InputTextbox  placeholder="" value={birthPlace} onChange={(e) => setBirthPlace(e.target.value)} />
</InputContainer>



<InputContainer>
  <InputDescriptionText>Codice fiscale</InputDescriptionText>
  <InputTextbox  placeholder="" value={fiscalCode} onChange={(e) => setFiscalCode(e.target.value)} />
</InputContainer>


       
          </CreaContainerColumn>

          <CreaContainerColumn>
          <InputContainer>
  <InputDescriptionText>Indirizzo residenza</InputDescriptionText>
  <InputTextbox  placeholder="" value={residenceAddress} onChange={(e) => setResidenceAddress(e.target.value)} />
</InputContainer>

<InputContainer>
  <InputDescriptionText>Indirizzo domiciliazione</InputDescriptionText>
  <InputTextbox  placeholder="" value={domicileAddress} onChange={(e) => setDomicileAddress(e.target.value)} />
</InputContainer>

<InputContainer>
  <InputDescriptionText>Email lavorativa</InputDescriptionText>
  <InputTextbox  placeholder="" value={workEmail} onChange={(e) => setWorkEmail(e.target.value)} />
</InputContainer>

<InputContainer>
  <InputDescriptionText>Email personale</InputDescriptionText>
  <InputTextbox  placeholder="" value={personalEmail} onChange={(e) => setPersonalEmail(e.target.value)} />
</InputContainer>

<InputContainer50>
<InputContainer>
  <InputDescriptionText>Cellulare lavoro</InputDescriptionText>
  <InputTextbox  placeholder="" value={workPhone} onChange={(e) => setWorkPhone(e.target.value)} />
</InputContainer>
<InputContainer>
  <InputDescriptionText>Cellulare privato</InputDescriptionText>
  <InputTextbox  placeholder="" value={privatePhone} onChange={(e) => setPrivatePhone(e.target.value)} />
</InputContainer>
</InputContainer50>


          </CreaContainerColumn>

        
        </FlexContainerCrea>
        {/* <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
              <DescriptionContainerCreaText>
             
              Inserire i dettagli identificativi dell'unità operativa, comprensivi di un nome completo che rifletta le competenze e i servizi offerti, un nome abbreviato per una rapida identificazione, oltre ai principali canali di comunicazione per facilitare un contatto efficace e una pronta corrispondenza


              </DescriptionContainerCreaText>

        </DescriptionContainerCreaMultiple> */}

   </ OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;