import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import styled from "styled-components";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione"; // Correct path

import { Box, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import {
  AttachedFilesList,
  Backdrop,
  BackdropTransparent,
  BackgroundIcon,
  CloseMessageButton,
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  CustomDropdownItem,
  CustomDropdownList,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  DropDownMenuScrollbar,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconButton,
  IconContainer,
  IconSquare,
  IconsGroup,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  InputTextboxButton,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
  Search,
  TriangleContainer,
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  addL2OrganizzazioneSelectedL1,
  addL2OrganizzazioneSelectedL1ID,
  addL2OrganizzazioneDirettore,
  addL2OrganizzazioneDirettoreID,
  addL2OrganizzazioneModello,
  addL2OrganizzazionePersonale,
  addL2OrganizzazioneDH,
  addL2OrganizzazioneOrdinaria,
  SelectedL4ID,
  isLoadingRec,
  setErrorRec,
  isMessageBoxVisibleRec,
  setMessageRec,
} from "../../../../../recoil";

import CancelIcon from "@mui/icons-material/Clear";
import { ArrowButtonText, ButtonGrey, ButtonText } from "../../../styled-components";
import {
  IconContainerList,
  ListContainer,
  ListDescriptionText,
  ListScrollbar,
  ListTextbox,
} from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import {
  SearchIcon,
  SearchInputContainer,
  SearchPopup,
  StyledComponenteTd,
  StyledComponenteTh,
  StyledLuoghiTd,
  StyledLuoghiTh,
  StyledPlanRefTd,
  StyledPlanRefTh,
  StyledTable,
  StyledTableItem,
  StyledTbody,
  StyledTd,
  StyledTdUnitaOperativa,
  StyledTh,
  StyledThead,
  StyledUnitaOperativaTh,
  TableContainer,
  TableContainerPopup,
  TableItemText,
  TableScrollableDiv,
  TableScrollableDivPopup,
  TableTitleText,
  TopContainer,
} from "../cybersecurity-board-organizzazione-unitaoperative-styled-components";
import { URL_AssetsByResponsible, URL_PlacesByPersonSelection, useToken } from "../../../../../API";
import { SelectedPlaceUnitAssociationInfrastruttura, showL4InfrDataItem, showL4InfrDataItemState, showL4SelectedPlaceUnitAssociationInfrastrutturaState, showL4isVisible, showL4isVisible2, showL4isVisiblePopUp2 } from "../L4-Show-recoil";



type ApiContentItem = {
  id: string;
  name: string;
  hasUnit: string;
  CategoryPath: string;
  unit: {
    prefLabel: string;
  };
};


type SortableKeys = keyof showL4InfrDataItem;
type SortableKeysPlaces = keyof SelectedPlaceUnitAssociationInfrastruttura;


interface PlaceUnitAssociation {
  content: {
    id: string;
    has_place: string;
    has_unit: string;
    place: {
      name: string;
      plan_ref: string;
      site: {
        site_name: string;
      };
    };
  };
  selected: boolean;
}

//POPUPS


interface LastPopupProps {
  isVisible: boolean;
}

const LastPopupBlack = styled.div<LastPopupProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  //border: 5px solid #ffffff;
  z-index: 100;
`;

////////////////// LAST POP UP END

///////////////// LAST POPUP PLACES

interface LastPopupProps2 {
  isVisiblePlaces: boolean;
}

const LastPopupBlack2 = styled.div<LastPopupProps2>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisiblePlaces }) => (isVisiblePlaces ? 1 : 0)};
  visibility: ${({ isVisiblePlaces }) => (isVisiblePlaces ? "visible" : "hidden")};
`;

const LastPopup2 = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  //border: 5px solid #ffffff;
  z-index: 100;
`;

// LAST POP UP PLACES END


// PLACE UNIT ASSOCIATION

const useFetchPlaceUnitAssociations = (token: string | null | undefined) => {
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [selectedUnitAssociations, setSelectedUnitAssociations] = useRecoilState(showL4SelectedPlaceUnitAssociationInfrastrutturaState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [dataPlaces, setDataPlaces] = useRecoilState(showL4SelectedPlaceUnitAssociationInfrastrutturaState);
  
  useEffect(() => {
    const fetchData = async () => {
    try {
      const response = await fetch(`${URL_PlacesByPersonSelection}${L4ID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data from API");
      }

      const data: PlaceUnitAssociation[] = await response.json();

      // Filter out non-selected associations and map to the required structure
      const selectedAssociations = data
        .filter((item) => !item.selected) // Filter for selected items
        .map((assoc) => ({
          id: assoc.content.id, // Use the id from content
          placeId: assoc.content.has_place, // Use the has_place id from content
          siteName: assoc.content.place.site.site_name, // Use the has_unit name from content, you might need to resolve the name from an id.
          planRef: assoc.content.place.plan_ref,
          placeName: assoc.content.place.name,
        }));

      // Set the selected associations
      setSelectedUnitAssociations(selectedAssociations);
      setDataPlaces(selectedAssociations);
    } catch (error) {
      setError(error instanceof Error ? error.message : "An unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  if (token && L4ID) fetchData();
}, [token, L4ID, setIsLoading, setError, setSelectedUnitAssociations]);

// Return anything that might be useful outside this hook
};

// PLACE UNIT ASSOCIATION END

//  FETCH ASSET DATA 

const useFetchAssetAssociations = (token: string | null | undefined) => {
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const [data, setData] = useRecoilState(showL4InfrDataItemState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  useEffect(() => {
    const fetchData = async () => {
    setIsLoading(true);
    try {
      // Check for token existence before making the API call
      if (!token) throw new Error("User is not authenticated");

      // Fetch data from the API
      const response = await fetch(`${URL_AssetsByResponsible}${L4ID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Check if the API call was successful
      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }

      // Parse the JSON response
      const responseData = await response.json();

      // Directly access the content field
      const apiContentItems: ApiContentItem[] = responseData.content;

      console.log("Response Data:", responseData);

      // Assuming responseData is the array you want to map
      const transformedData: showL4InfrDataItem[] = apiContentItems.map((item: ApiContentItem) => {
        return {
          id: item.id,
          name: item.name,
          hasUnit: item.unit.prefLabel,
          CategoryPath: item.CategoryPath,

          // Map other properties as needed
        };
      });

      console.log("Transformed Data:", transformedData);
      setData(transformedData);
    } catch (err) {
      // Error handling
      setError(err instanceof Error ? err.message : "An unknown error occurred");
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (token && L4ID) fetchData();
}, [token, L4ID]);

//Return anything that might be useful outside this hook
};


// FETCH ASSET DATA END

//////////////////////// DATA TABLE ASSET FOR POPUP

const DataTable: React.FC<{ data: showL4InfrDataItem[] }> = ({ data }) => {
  type StyledTrProps = {
    selected: boolean;
  };

  const StyledTr = styled.tr<StyledTrProps>`
    cursor: pointer;
    background-color: ${(props) => {
      //console.log(`Is row selected: ${props.selected}`); // This should log true or false based on selection
      return props.selected ? "#f3f7fb" : "#ffffff";
    }};

    &:hover {
      background-color: #e9eff7; // Make sure this doesn't override your selection color
    }
  `;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const [sortConfig, setSortConfig] = useState<{ key: SortableKeys; direction: "ascending" | "descending" } | null>(null);

  // Function to handle sorting
  const sortedData = useMemo(() => {
    let sortableItems = [...data]; // Create a new array to avoid mutating the original data
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        // Handle different types if necessary, e.g., string vs number
        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        // Add more conditions for other types if necessary

        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  // Click handler for headers
  const requestSort = (key: SortableKeys) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleRowSelect = (id: string) => {
    setSelectedId((prevId) => {
      //console.log(`Previous ID: ${prevId}, New ID: ${id}`);
      return id;
    });

    //setL2ID(id);
  };

  //////////////////////GLOBAL SEARCH

  const [globalFilterText, setGlobalFilterText] = useState("");

  const handleGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalFilterText(event.target.value);
  };

  ////////////////////////////////////// FILTER

  const [filterText, setFilterText] = useState("");
  // Event handler for the search input
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  // Filter and then sort the data
  const filteredAndSortedData = useMemo(() => {
    const filteredData = data
      .filter((item) => {
        return item.name.toLowerCase().includes(filterText.toLowerCase());
      })
      .filter((item) => {
        // If there's global filter text, check all the relevant properties
        if (globalFilterText) {
          const allText = Object.values(item).join(" ").toLowerCase();
          return allText.includes(globalFilterText.toLowerCase());
        }
        return true; // If there's no global filter text, don't filter further
      });

    // Then sort the filtered data
    if (sortConfig !== null) {
      filteredData.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        return 0;
      });
    }

    return filteredData;
  }, [data, sortConfig, filterText, globalFilterText]);

  return (
    <TableContainerPopup>
      <TopContainer>
        <SearchInputContainer>
          <SearchPopup placeholder="Filtra per asset" onChange={handleFilterChange} />
          <SearchIcon src="/funnel.svg" />
        </SearchInputContainer>
        <SearchInputContainer>
          <SearchPopup placeholder="Cerca in tutta la tabella" onChange={handleGlobalFilterChange} />
          <SearchIcon src="/search.svg" />
        </SearchInputContainer>

        {/* <IconsGroup>
    <IconButton>
        <BackgroundIcon loading="eager" alt="" src="/doc.svg" />
      </IconButton>
      <IconButton>
        <BackgroundIcon loading="eager" alt="" src="/edit.svg" />
      </IconButton>
      <IconButton>
        <BackgroundIcon loading="eager" alt="" src="/delete.svg" />
      </IconButton>
      <IconButton>
      <BackgroundIcon
        loading="eager"
        alt=""
        src="/add.svg"
       
      />
      </IconButton>
    </IconsGroup> */}
      </TopContainer>

      <StyledTable>
        <StyledThead>
          <StyledUnitaOperativaTh onClick={() => requestSort("name")}>
            <TableTitleText>▼ Asset</TableTitleText>
          </StyledUnitaOperativaTh>
          <StyledUnitaOperativaTh onClick={() => requestSort("hasUnit")}>
            <TableTitleText>▼ Unità operativa</TableTitleText>
          </StyledUnitaOperativaTh>
          <StyledTh onClick={() => requestSort("CategoryPath")}>
            <TableTitleText>▼ Classificazione asset</TableTitleText>
          </StyledTh>
        </StyledThead>
      </StyledTable>
      <TableScrollableDivPopup>
        <StyledTableItem>
          <StyledTbody>
            {filteredAndSortedData.map((item) => (
              <StyledTr
                key={item.id} // Use the unique ID as key instead of index
                onClick={() => handleRowSelect(item.id)} // Set the selected item ID on click
                selected={selectedId === item.id} // Conditionally apply the background color
              >
                <StyledTdUnitaOperativa title={item.name}>
                  <TableItemText>{item.name}</TableItemText>
                </StyledTdUnitaOperativa>
                <StyledTdUnitaOperativa title={item.hasUnit}>
                  <TableItemText>{item.hasUnit}</TableItemText>
                </StyledTdUnitaOperativa>
                <StyledTd title={item.CategoryPath}>
                  <TableItemText>{item.CategoryPath}</TableItemText>
                </StyledTd>
              </StyledTr>
            ))}
          </StyledTbody>
        </StyledTableItem>
      </TableScrollableDivPopup>
    </TableContainerPopup>
  );
};

///////////////////DATA TABLE ASSET END

const MainComponent = () => {

  const token = useToken(); // Call useToken here at the top level
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const [selectedUnitAssociations, setSelectedUnitAssociations] = useRecoilState(showL4SelectedPlaceUnitAssociationInfrastrutturaState);

  const [isVisiblePlaces, setIsVisiblePlaces] = useRecoilState(showL4isVisible2);
  const [isVisible, setIsVisible] = useRecoilState(showL4isVisiblePopUp2);
  
  const [data, setData] = useRecoilState(showL4InfrDataItemState);
  const [dataPlaces, setDataPlaces] = useRecoilState(showL4SelectedPlaceUnitAssociationInfrastrutturaState);

  const { closeOverlay } = useContext(OverlayContext);

  useFetchPlaceUnitAssociations(token);
  useFetchAssetAssociations(token);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); 
  };

  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  const togglePopupPlaces = () => {
    setIsVisiblePlaces(!isVisiblePlaces);
  };

  

  //////////////////// DATA TABLE LUOGHI

  const DataTablePlaces: React.FC<{ data: SelectedPlaceUnitAssociationInfrastruttura[] }> = ({ data }) => {
    type StyledTrProps = {
      selected: boolean;
    };

    const StyledTr = styled.tr<StyledTrProps>`
      cursor: pointer;
      background-color: ${(props) => {
        //console.log(`Is row selected: ${props.selected}`); // This should log true or false based on selection
        return props.selected ? "#f3f7fb" : "#ffffff";
      }};

      &:hover {
        background-color: #e9eff7; // Make sure this doesn't override your selection color
      }
    `;

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

    const [sortConfig, setSortConfig] = useState<{ key: SortableKeysPlaces; direction: "ascending" | "descending" } | null>(null);

    // Function to handle sorting
    const sortedData = useMemo(() => {
      let sortableItems = [...data]; // Create a new array to avoid mutating the original data
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          const aValue = a[sortConfig.key];
          const bValue = b[sortConfig.key];

          // Handle different types if necessary, e.g., string vs number
          if (typeof aValue === "string" && typeof bValue === "string") {
            return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
          // Add more conditions for other types if necessary

          return 0;
        });
      }
      return sortableItems;
    }, [data, sortConfig]);

    // Click handler for headers
    const requestSort = (key: SortableKeysPlaces) => {
      let direction: "ascending" | "descending" = "ascending";
      if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    const [selectedIdPlaces, setSelectedIdPlaces] = useState<string | null>(null);

    const handleRowSelect = (id: string) => {
      setSelectedIdPlaces((prevId) => {
        //console.log(`Previous ID: ${prevId}, New ID: ${id}`);
        return id;
      });

      //setL2ID(id);
    };

    //////////////////////GLOBAL SEARCH

    const [globalFilterText, setGlobalFilterText] = useState("");

    const handleGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setGlobalFilterText(event.target.value);
    };

    ////////////////////////////////////// FILTER

    const [filterText, setFilterText] = useState("");
    // Event handler for the search input
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterText(event.target.value);
    };

    // Filter and then sort the data
    const filteredAndSortedData = useMemo(() => {
      const filteredData = data
        .filter((item) => {
          return item.placeName.toLowerCase().includes(filterText.toLowerCase());
        })
        .filter((item) => {
          // If there's global filter text, check all the relevant properties
          if (globalFilterText) {
            const allText = Object.values(item).join(" ").toLowerCase();
            return allText.includes(globalFilterText.toLowerCase());
          }
          return true; // If there's no global filter text, don't filter further
        });

      // Then sort the filtered data
      if (sortConfig !== null) {
        filteredData.sort((a, b) => {
          const aValue = a[sortConfig.key];
          const bValue = b[sortConfig.key];

          if (typeof aValue === "string" && typeof bValue === "string") {
            return sortConfig.direction === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
          return 0;
        });
      }

      return filteredData;
    }, [data, sortConfig, filterText, globalFilterText]);

    return (
      <TableContainerPopup>
        <TopContainer>
          <SearchInputContainer>
            <SearchPopup placeholder="Filtra per luogo" onChange={handleFilterChange} />
            <SearchIcon src="/funnel.svg" />
          </SearchInputContainer>
          <SearchInputContainer>
            <SearchPopup placeholder="Cerca in tutta la tabella" onChange={handleGlobalFilterChange} />
            <SearchIcon src="/search.svg" />
          </SearchInputContainer>

          {/* <IconsGroup>
      <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/doc.svg" />
        </IconButton>
        <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/edit.svg" />
        </IconButton>
        <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/delete.svg" />
        </IconButton>
        <IconButton>
        <BackgroundIcon
          loading="eager"
          alt=""
          src="/add.svg"
         
        />
        </IconButton>
      </IconsGroup> */}
        </TopContainer>

        <StyledTable>
          <StyledThead>
            <StyledPlanRefTh onClick={() => requestSort("planRef")}>
              <TableTitleText>▼ Identificativo</TableTitleText>
            </StyledPlanRefTh>

            <StyledLuoghiTh onClick={() => requestSort("placeName")}>
              <TableTitleText>▼ Luogo</TableTitleText>
            </StyledLuoghiTh>

            <StyledComponenteTh onClick={() => requestSort("siteName")}>
              <TableTitleText>▼ Componente organizzativa</TableTitleText>
            </StyledComponenteTh>
            {/* <StyledTh onClick={() => requestSort('CategoryPath')}><TableTitleText>▼ Classificazione asset</TableTitleText></StyledTh> */}
          </StyledThead>
        </StyledTable>
        <TableScrollableDivPopup>
          <StyledTableItem>
            <StyledTbody>
              {filteredAndSortedData.map((item) => (
                <StyledTr
                  key={item.id} // Use the unique ID as key instead of index
                  onClick={() => handleRowSelect(item.id)} // Set the selected item ID on click
                  selected={selectedIdPlaces === item.id} // Conditionally apply the background color
                >
                  <StyledPlanRefTd title={item.planRef}>
                    <TableItemText>{item.planRef}</TableItemText>
                  </StyledPlanRefTd>

                  <StyledLuoghiTd title={item.placeName}>
                    <TableItemText>{item.placeName}</TableItemText>
                  </StyledLuoghiTd>

                  <StyledComponenteTd title={item.siteName}>
                    <TableItemText>{item.siteName}</TableItemText>
                  </StyledComponenteTd>
                  {/* <StyledTd title={item.CategoryPath}><TableItemText>{item.CategoryPath}</TableItemText></StyledTd> */}
                </StyledTr>
              ))}
            </StyledTbody>
          </StyledTableItem>
        </TableScrollableDivPopup>
      </TableContainerPopup>
    );
  };

  ///////////////////DATA TABLE PLACES END

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Luoghi accessibili</InputDescriptionText>
            <InputTextboxButton onClick={togglePopupPlaces}>
              <ArrowButtonText>Apri per visualizzare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>
          {/* <ListContainer>
           
           <ListDescriptionText>Luoghi accessibili</ListDescriptionText>
           <ListTextbox>
           <ListScrollbar>

          
    
      {selectedUnitAssociations.map(association => (
        <li key={association.id} style={{ textIndent: '-19px', marginLeft: '20px', lineHeight: '1.5' }}>
       
          {association.usiteName}
        </li>
      ))}
    
  
           </ListScrollbar>
           </ListTextbox>
            
        
         </ListContainer> */}
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Asset responsabile</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup}>
              <ArrowButtonText>Apri per visualizzare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>
        </CreaContainerColumn>

        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>LISTA ASSET</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              {/* <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <LastPopupSearch placeholder="Cerca" />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer> */}

              {/* <LastPopupScrollbar> */}
              <DataTable data={data} />
              {/* </LastPopupScrollbar> */}
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <ButtonGrey onClick={togglePopup}>
                <ButtonText>Chiudi</ButtonText>
              </ButtonGrey>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>

        <LastPopupBlack2 isVisiblePlaces={isVisiblePlaces}>
          <LastPopup2>
            <LastPopupTitleContainer>LISTA LUOGHI</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              {/* <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <LastPopupSearch placeholder="Cerca" />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer> */}

              {/* <LastPopupScrollbar> */}
              <DataTablePlaces data={dataPlaces} />
              {/* </LastPopupScrollbar> */}
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <ButtonGrey onClick={togglePopupPlaces}>
                <ButtonText>Chiudi</ButtonText>
              </ButtonGrey>
            </LastPopupButtonContainer>
          </LastPopup2>
        </LastPopupBlack2>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_infrastructure.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
          La scheda rivela gli elementi dell’infrastruttura dell’Organizzazione così come risulta dall’inventario dettagliato degli asset e dei luoghi. Questi elementi sono organizzati in linea con il
          sistema di classificazione e catalogazione adottato dall'ente e sono associati all’entità selezionata.
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
