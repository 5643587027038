import React, { useState, useEffect, useRef, useContext } from "react";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import { useRecoilState, useRecoilValue } from "recoil";

import {
  addL2SchedaGeneraleNomeCompleto,
  addL2SchedaGeneraleNomeAbbreviato,
  addL2SchedaGeneraleEmail,
  addL2SchedaGeneraleTelefono,
  addL2SchedaGeneraleFax,
  addL2SchedaGeneralePEC,
  addL2SchedaGeneraleReferente,
  addL1SchedaGeneraleIndirizzo,
  addL2OrganizzazioneModello,
  addL2AttachedFilesInfo,
  addL2ComplianceDate,
  addL2ComplianceDirettoreCompliance,
  addL2ComplianceDirettoreComplianceID,
  addL2DocumentsAttached,
  addL2DocumentsPath,
  addL2OrganizzazioneDH,
  addL2OrganizzazioneDirettore,
  addL2OrganizzazioneDirettoreID,
  addL2OrganizzazioneOrdinaria,
  addL2OrganizzazionePersonale,
  addL2OrganizzazioneSelectedL1,
  addL2OrganizzazioneSelectedL1ID,
  addL2SchedaGeneraleIndirizzo,


} from "../../../../recoil";

import SchedaGenerale from "./cybersecurity-board-org-org-L3-Add-sub/organigramma-L3-Add-1-SchedaGenerale";
import Organizzazione from "./cybersecurity-board-org-org-L3-Add-sub/organigramma-L3-Add-2-Organizzazione";
// import Infrastruttura from "./cybersecurity-board-org-org-L3-Add-sub/organigramma-L3-Add-3-Infrastruttura";
// import Processi from "./cybersecurity-board-org-org-L3-Add-sub/organigramma-L3-Add-3-Infrastruttura";
// import Parti from "./cybersecurity-board-org-org-L3-Add-sub/organigramma-L3-Add-3-Infrastruttura";
// import Compliance from "./cybersecurity-board-org-org-L3-Add-sub/organigramma-L3-Add-6-GestioneCompliance";
// import Documentazione from "./cybersecurity-board-org-org-L3-Add-sub/organigramma-L3-Add-7-Documentazione";

import {
  FrameNavLogo,
  PicLogoIcon,
  FrameTopGradient,
  ButtonIsoActiveText,
  ButtonIsoActive,
  ButtonIsoInactiveText,
  ButtonIsoInactive,
  FrameButtonIso,
  FrameUserSearch,
  FrameUserSearchAuto,
  ButtonUser,
  ButtonNotification,
  IcoUserNotification,
  FrameLeftNav,
  FrameNavButtons,
  NavButtonWhite,
  NavButtonWhiteFocused,
  NavButtonWhiteText,
  NavButtonWhiteChild,
  NavButtonWhiteChildText,
  NavButtonWhiteChildFocused,
  NavButtonBlue,
  NavButtonBlueFocused,
  NavButtonBlueText,
  NavButtonBlueChild,
  NavButtonBlueChildFocused,
  ButtonGreen,
  ButtonGrey,
  ButtonText,
  ArrowGrey,
  ArrowGreyRight,
  ArrowWhite,
  ArrowWhiteRight,
  LineMagenta40,
  LineMagenta50,
  FrameButtonIcon,
  IcoButtonIcon,
  FrameNavWorkspace,
  WorkspaceLine1,
  WorkspaceLine2,
  FrameBoardMain,
  PageRoot,
  ScrollableDiv,
  MainBoardSubMenuButton,
  MainBoardSubMenuButtonContainer,
  MainBoardContentContainer,
  MainBoardSubMenuButtonContainerOrganigramma,
} from "../../styled-components";

import {
  FrameTopGradient100,
  SearchBarWithIconsRoot,
  Search,
  LineDivider,
  IconButton,
  BackgroundIcon,
  MainBoardContentContainerColumn,
  MainBoardContentContainerFile,
  FileButtonArrowGrey,
  FileButtonWhiteText,
  SearchGroup,
  IconsGroup,
  Title,
  TitleText,
  BoardScrollableDiv,
  ButtonClose,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteTitleAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  InputTextbox,
  CreaContainerColumn,
  FlexContainerCrea,
  InputContainer,
  IconContainer,
  InputDescriptionText,
  SubMenuButtonContainerOrganigrammaNoScroll,
  CreaSubMenuButton,
  SubMenuButtonContainerOrganigramma,
  Backdrop,
  MessageContainer,
  CloseMessageButton,
  MessageboxText,
  BackdropTransparent,
  DescriptionContainerCrea,
  DescriptionContainerCreaText,
  IconSquare,
  CreaSubMenuButtonMultiple,
  SubMenuButtonContainerOrganigrammaMultiple,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";
import { URL_DirectorList, URL_LV1 } from "../../../../API";
import { useOverlayControlL3Show } from "./cybersecurity-board-organizzazione-ruoligenerali";
import { addL3electedModelCheckboxesState } from "./L3-Add-recoil";



const useToken = () => {
  const { keycloak, initialized } = useKeycloak();
  return initialized && keycloak.authenticated ? keycloak.token : null;
};

const useApi = () => {
  const token = useToken();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  //////////post
  const post = async (url: string, data: any) => {
    if (!token) {
      throw new Error("User is not authenticated");
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      // Attempt to parse the response as JSON, but handle empty or non-JSON responses gracefully
      let responseData;
      try {
        responseData = await response.json(); // May throw if the response is not JSON or is empty
      } catch (parseError) {
        if (response.ok) {
          // If the response was OK but parsing failed, it could be an empty response
          return null; // Or some default value as appropriate for your application
        } else {
          // If the response was not OK and parsing failed, throw with the status text
          throw new Error(`API request failed: ${response.statusText}`);
        }
      }

      // Check the OK status after attempting to parse the response
      if (!response.ok) {
        console.error("Error response data:", responseData);
        // Include the parsed responseData in the thrown Error, if available
        throw new Error(
          `API request failed: ${response.statusText}`,
          responseData
        );
      }

      return responseData; // Return the parsed response data
    } catch (error) {
      throw error; // Rethrow the error for the caller to handle
    }
  };

  /////////get

  const get = async (url: string) => {
    if (!token) throw new Error("User is not authenticated");

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`API request failed: ${response.statusText}`);
    }

    return await response.json();
  };

  return { post, get };
};

const YourAddL1Component = () => {



  // GLOBAL SHOW L3 PROPAGATION

  const { toggleOverlayL3, isVisibleL3, isAnimatingL3, closeOverlayL3 } = useOverlayControlL3Show("/Cybersecurity/Organizzazione/Ruoli/AggiungiL3/1");

  const [nomeCompleto, setNomeCompleto] = useRecoilState(addL2SchedaGeneraleNomeCompleto);
  const [nomeAbbreviato, setNomeAbbreviato] = useRecoilState(addL2SchedaGeneraleNomeAbbreviato);
  const [Email, setEmail] = useRecoilState(addL2SchedaGeneraleEmail);
  const [Telefono, setTelefono] = useRecoilState(addL2SchedaGeneraleTelefono);
  const [Fax, setFax] = useRecoilState(addL2SchedaGeneraleFax);
  const [PEC, setPEC] = useRecoilState(addL2SchedaGeneralePEC);
  const [Referente, setReferente] = useRecoilState(addL2SchedaGeneraleReferente);
  const [Indirizzo, setIndirizzo] = useRecoilState(addL1SchedaGeneraleIndirizzo);
  const [Modello, setModello] = useRecoilState(addL2OrganizzazioneModello);
  const [AttachedFilesInfo, setAttachedFilesInfo] = useRecoilState(addL2AttachedFilesInfo);
  const [ComplianceDate, setComplianceDate] = useRecoilState(addL2ComplianceDate);
  const [ComplianceDirettoreCompliance, setComplianceDirettoreCompliance] = useRecoilState(addL2ComplianceDirettoreCompliance);
  const [ComplianceDirettoreComplianceID, setComplianceDirettoreComplianceID] = useRecoilState(addL2ComplianceDirettoreComplianceID);
  const [DocumentsAttached, setDocumentsAttached] = useRecoilState(addL2DocumentsAttached);
  const [DocumentsPath, setDocumentsPath] = useRecoilState(addL2DocumentsPath);
  const [OrganizzazioneDH, setOrganizzazioneDH] = useRecoilState(addL2OrganizzazioneDH);
  const [OrganizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(addL2OrganizzazioneDirettore);
  const [OrganizzazioneDirettoreID, setOrganizzazioneDirettoreID] = useRecoilState(addL2OrganizzazioneDirettoreID);
  const [OrganizzazioneOrdinaria, setOrganizzazioneOrdinaria] = useRecoilState(addL2OrganizzazioneOrdinaria);
  const [OrganizzazionePersonale, setOrganizzazionePersonale] = useRecoilState(addL2OrganizzazionePersonale);
  const [OrganizzazioneSelectedL1, setOrganizzazioneSelectedL1] = useRecoilState(addL2OrganizzazioneSelectedL1);
  const [OrganizzazioneSelectedL1ID, setOrganizzazioneSelectedL1ID] = useRecoilState(addL2OrganizzazioneSelectedL1ID);
  

  const [selectedModelCheckboxes, setSelectedModelCheckboxes] = useRecoilState(
    addL3electedModelCheckboxesState
  );




  //const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const token = useToken(); // Call useToken here at the top level
  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  ////////////POST/////////////////
  const { post } = useApi();



  const handleSave = async () => {
    const newSiteData = {
      pref_label: nomeCompleto,
      has_responsible: OrganizzazioneDirettoreID,
      has_primary_site: OrganizzazioneSelectedL1ID,
      pec: PEC,
      dh_activity: OrganizzazioneDH,
      ordinary_activity: OrganizzazioneOrdinaria,
      has_model: Modello, 
      compliance_responsible_assignment_start: ComplianceDate,     
      organizational_card: {
        altLabel: nomeAbbreviato,
        email: Email,
        main_web_site: Indirizzo,
        telephone: Telefono,
        has_contact_person: Referente,
        has_compliance_responsible: ComplianceDirettoreComplianceID,
        documents_folder_link: DocumentsPath,
        fax: Fax,
      },
    };


    
    console.log(JSON.stringify(newSiteData));

    try {
      setIsLoading(true);
      // Call post directly with the object
      const responseData = await post(
        URL_LV1,
        newSiteData
      );

      console.log("Unit created successfully:", responseData);
      setMessage("Unit created successfully!");
      setIsLoading(false);
      setIsMessageBoxVisible(true);
      //navigate(-2); navigate two pages before
      // Clear the form fields
      setNomeCompleto("");
      setNomeAbbreviato("");
      setEmail("");
      setTelefono("");
      setFax("");
      setPEC("");
      setReferente("");
      setIndirizzo("");
      setModello("");
      setAttachedFilesInfo([]);
   setOrganizzazioneSelectedL1('');
   setOrganizzazioneSelectedL1ID('');

      setComplianceDirettoreCompliance("");
      setComplianceDirettoreComplianceID("");
      setDocumentsAttached([]);
      setDocumentsPath("");
      setOrganizzazioneDH("");
      setOrganizzazioneDirettore("");
      setOrganizzazioneDirettoreID("");
      setOrganizzazioneOrdinaria("");
      setOrganizzazionePersonale("");
      
      // If you're updating a list in the UI, you might want to add the new site to that list
    } catch (error) {
      console.error("Error creating site:", error);
      setMessage(
        `Error creating site: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
      setIsLoading(false);
      setIsMessageBoxVisible(true);
      // Handle errors, such as by displaying an error message to the user
    }
  };

  //////////////////////////

  /////////GET DIRECTORS////////////////

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch data from the API
        const response = await fetch(
          URL_DirectorList,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response Data:", responseData);

        // Assuming responseData is the array you want to map
        const transformedData: DirectorDataItem[] = responseData.map(
          (item: DirectorApiContentItem) => {
            return {
              director_id: item.id,
              director_name: item.alias, // Changed from 'site_name' to 'name'

              // Map other properties as needed
            };
          }
        );

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data on component mount or when token changes
    fetchData();
  }, [token]); // Depend on the token

  ////////////////////

  /////FILTER INPUT DIRECTORS

  // Create a map from director names to IDs
  const directorNameToId = new Map(
    data.map((item) => [item.director_name, item.director_id])
  );

 
  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); // Close the message box
    closeOverlayL3();
    setSelectedModelCheckboxes({});
  };

  ////////////////////CHANGE ACTIVE COMPONENT///////////
  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      case "Organizzazione":
        return <Organizzazione />;
      // case "Infrastruttura":
      //   return <Infrastruttura />;
      // case "ProcessiGenerali":
      //   return <Processi />;
      // case "PartiInteressate":
      //   return <Parti />;
      // case "SistemaCompliance":
      //   return <Compliance />;
      // case "Documentazione":
      //   return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlayL3();
                setSelectedModelCheckboxes({});
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>
        RUOLI E MODELLI
      </OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SchedaGenerale")}
            to="/Cybersecurity/Organizzazione/Ruoli/CreaL3/1"
          >
            Ruoli
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Organizzazione")}
            to="/Cybersecurity/Organizzazione/Ruoli/CreaL3/2"
          >
            Modelli
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/Organizzazione/UnitaOperative/CreaL2/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/Organizzazione/UnitaOperative/CreaL2/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Organizzazione/UnitaOperative/CreaL2/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SistemaCompliance")}
            to="/Cybersecurity/Organizzazione/Ruoli/CreaL3/6"
          >
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Documentazione")}
            to="/Cybersecurity/Organizzazione/Ruoli/CreaL3/7"
          >
            Documentazione
          </CreaSubMenuButtonMultiple> */}
        </SubMenuButtonContainerOrganigrammaMultipleShort>

        {renderActiveComponent()}
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={handleCloseAndNavigate}>
          <ButtonText>Chiudi</ButtonText>
        </ButtonGrey>
        {/* <ButtonGrey onClick={handleSave}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey> */}
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default YourAddL1Component;
