import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import {
  AttachedFilesList,
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconSquare,
  InputButtonText,
  InputContainer,

  InputDescriptionText,
  InputTextbox,
  InputTextboxButton,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";

import { NavButtonWhiteText } from "../../../styled-components";
import DownloadIcon from '@mui/icons-material/Download';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { IconContainerList, ListContainer, ListDescriptionText, ListScrollbar, ListTextbox } from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import { URL_FileManager, URL_FileManagerList, useToken } from "../../../../../API";
import { showL4AttachedFilesInfo, showL4DocPath, showL4DocumentsAttached } from "../L4-Show-recoil";


const useApi = () => {
  const token = useToken();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  //////////post
  const post = async (url: string, data: any, isFile: boolean = false) => {
    if (!token) {
      throw new Error("User is not authenticated");
    }
    try {
      // Log to check if the data is FormData or a JSON string
      if (isFile) {
        console.log("Sending as FormData (file):", data);
      } else {
        console.log("Sending as JSON string:", JSON.stringify(data));
      }

      const body = isFile ? data : JSON.stringify(data);

      // Log the body for debugging purposes
      console.log('Request body:', body);

      const response = await fetch(url, {
        method: "POST",
        //mode: 'no-cors',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: isFile ? data : JSON.stringify(data),
      });

       // If the server returned any X-App-Error header, log it
    const appError = response.headers.get('X-App-Error');
    if (appError) {
      console.error('X-App-Error:', appError);
    }

      // Attempt to parse the response as JSON, but handle empty or non-JSON responses gracefully
      let responseData;
      try {
        responseData = await response.json(); // May throw if the response is not JSON or is empty
      } catch (parseError) {
        if (response.ok) {
          // If the response was OK but parsing failed, it could be an empty response
          return null; // Or some default value as appropriate for your application
        } else {
          // If the response was not OK and parsing failed, throw with the status text
          throw new Error(`API request failed: ${response.statusText}`);
        }
      }

      // Check the OK status after attempting to parse the response
      if (!response.ok) {
        console.error("Error response data:", responseData);
        // Include the parsed responseData in the thrown Error, if available
        throw new Error(
          `API request failed: ${response.statusText}`,
          responseData
        );
      }

      return responseData; // Return the parsed response data
    } catch (error) {
      throw error; // Rethrow the error for the caller to handle
    }
  };

  /////////get

  const get = async (url: string) => {
    if (!token) throw new Error("User is not authenticated");
  
    try {
      const response = await fetch(url, {
        method: "GET",
        //mode: "no-cors",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Log headers
      console.log("Headers:", Array.from(response.headers.entries()));
  
      if (!response.ok) {
        // Log error from response body if it's not ok
        const errorBody = await response.text(); // or response.json() if it's a JSON response
        console.error("Error body:", errorBody);
        throw new Error(`API request failed: ${response.statusText}`);
      }
  
      const responseBody = await response.json();
      console.log("Response body:", responseBody);
      return responseBody;
    } catch (error) {
      console.error('Error during fetch:', error);
      // If you want to log the request itself, you'd typically need to log the data you know about
      // since the request has already been sent and you can't inspect it retrospectively.
      console.log(`Request was made to URL: ${url} with token: ${token}`);
      throw error; // Rethrow the error after logging
    }
  };
  


  /////////////UPLOAD

  const uploadFile = async (dir: string, file: File, fileName: string) => {
    const formData = new FormData();
    formData.append("file", file, fileName);

    // Assuming '/api/v1/storage/' is the correct endpoint for file uploads
    // Adjust the endpoint as needed based on your API


    // SHOULD BE LOCALHOST:8095
    const url = `${URL_FileManager}${encodeURIComponent(dir)}&file=${encodeURIComponent(fileName)}`;
    return await post(url, formData, true);
  };

  return { post, get, uploadFile };
};



const MainComponent = () => {

  
  const token = useToken();
  const { uploadFile } = useApi();

  //////////////DOWNLOAD

 

  const handleDownload = async (filePath: string) => {



  const pathSegments = filePath.split('/');

  const file = pathSegments.pop() || 'defaultFileName';

  const dir = pathSegments.join('/');
   
    const url = `${URL_FileManager}${encodeURIComponent(dir)}&file=${encodeURIComponent(file)}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          // Assuming you have the token available here, otherwise you'll need to retrieve it
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        // Log the error header if available
        const errorHeader = response.headers.get('X-App-Error');
        console.error('Error header:', errorHeader);
        throw new Error(`API request failed: ${response.statusText}`);
      }
  
        // Convert headers to an array and log them for debugging
        response.headers.forEach((value, key) => {
          console.log(`Response header: ${key} = ${value}`);
        });
  
      // Handle the file download
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    
    // Here, we ensure that download always receives a string. If pop() is undefined, we provide an empty string as fallback
    link.download = filePath.split('/').pop() || '';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  
  ////////////RECOIL

  const [docPath, setDocPath] = useRecoilState(showL4DocPath);
  const [docAttached, setDocAttached] = useRecoilState(showL4DocumentsAttached);
  const [filesInfo, setFilesInfo] = useRecoilState(showL4AttachedFilesInfo);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const removeFile = (index: number) => {
    // Remove file from docAttached and filesInfo based on index
    setDocAttached((currentFiles) => currentFiles.filter((_, i) => i !== index));
    setFilesInfo((currentFilesInfo) => currentFilesInfo.filter((_, i) => i !== index));
  };

  const handleButtonClick = () => {
    // Check if the current ref is not null before calling click
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFileList = Array.from(files); // Convert FileList to Array
  
      // Append new files to the existing files
      setDocAttached((currentFiles) => [...currentFiles, ...newFileList]);
  
      const newFileInfoArray = newFileList.map(file => ({
        name: file.name,
        size: file.size,
        type: file.type,
      }));
      // Append new file info for display
      setFilesInfo((currentFilesInfo) => [...currentFilesInfo, ...newFileInfoArray]);
    }
  };
  


  const handleFileUpload = async (selectedFiles: FileList | null) => {
    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const dir = "directory"; // Set the directory where you want to upload the file
        const fileName = file.name; // Or set a new file name if needed
        try {
          await uploadFile(dir, file, fileName);
          console.log("File uploaded successfully:", fileName);
        } catch (error) {
          console.error("Error uploading file:", fileName, error);
        }
      }
    }
  };
  
// Temporary function to initiate file upload for testing
const initiateUpload = () => {
  if (docAttached && docAttached.length > 0) {
    // Create a new DataTransfer instance
    const dataTransfer = new DataTransfer();
    // Add each file to the DataTransfer object
    docAttached.forEach(file => dataTransfer.items.add(file));
    // Now dataTransfer.files is a FileList object
    handleFileUpload(dataTransfer.files);
  } else {
    console.log('No files selected for upload.');
  }
};

type FileListItem = {
  id: string;
  path: string;
  name: string;
};

const [isLoading, setIsLoading] = useState(true);
const [error, setError] = useState<string | null>(null);
const [directoryContents, setDirectoryContents] = useState<FileListItem[]>([]);

useEffect(() => {
  const fetchDirectoryContents = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${URL_FileManagerList}${docPath}`, {
        method: 'GET', // Assuming it's a GET request
        headers: {
          // Assuming you have the token available here, otherwise you'll need to retrieve it
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch directory contents');
      }

      const filePaths: string[] = await response.json();
      const transformedData: FileListItem[] = filePaths.map((filePath, index) => ({
        id: `file-${index}`, // Generate a unique ID for each file
        path: filePath, // Store the full file path
        name: filePath.split('/').pop() || '', // Extract the file name from the path
      }));
      
      setDirectoryContents(transformedData);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  fetchDirectoryContents();
}, [docPath, token]); // Adding docPath and token to the dependency array to refetch if they change

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>

        <ListContainer>
           
           <ListDescriptionText>Documentazione</ListDescriptionText>
           <ListTextbox>
           <ListScrollbar>

           {directoryContents.map((file) => (
            <li key={file.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{file.name}</span>
              <IconButton
                onClick={() => handleDownload(file.path)}
                size="small"
                style={{ marginLeft: 'auto' }} // This will push the button to the right
              >
                <DownloadIcon style={{ fontSize: '14px', color: '#cccccc' }} />
              </IconButton>
            </li>
          ))}
          
           </ListScrollbar>
           </ListTextbox>
            
           {/* <IconContainerList /> */}
         </ListContainer>

 

{/* <div>

      <button onClick={handleDownload}>Download File</button>

    </div> */}
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Nome cartella documentazione
            </InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={docPath}
              onChange={(e) => setDocPath(e.target.value)}
            />
            <IconContainer />
          </InputContainer>
          {/* <button onClick={initiateUpload}>Upload Files</button> */}
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_docs.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
        Nella scheda è descritto il percorso della cartella principale dove archiviare i documenti dell’entità selezionata e utilizzare la funzione di allegato per inserire direttamente la documentazione rilevante per la compliance in Cybersecurity in riferimento all’entità, garantendo così un accesso immediato e organizzato ai file essenziali.

        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
