import { atom, selector } from 'recoil';
import dayjs from 'dayjs';

// SCHEDA GENERALE

export const AdempimentiID = atom({
    key: 'AdempimentiID', 
    default: '',
  });
  
export const AdempimentiName = atom({
    key: 'AdempimentiName', 
    default: '',
  });
  
  export const AdempimentiKind = atom({
    key: 'AdempimentiKind', 
    default: '',
  });
  
  export const AdempimentiNota = atom({
    key: 'AdempimentiNota', 
    default: '',
  });

  export const AdempimentiRifNorm = atom({
    key: 'AdempimentiRifNorm', 
    default: '',
  });
  
  export const AdempimentiObjective = atom({
    key: 'AdempimentiObjective', 
    default: '',
  });
  
  
  export const AdempimentiDescription = atom({
    key: 'AdempimentiDescription', 
    default: '',
  });

  // COMPLIANCE
  
 
  export const AdempimentiComplianceDate = atom<dayjs.Dayjs | null>({
    key: 'AdempimentiComplianceDate', 
    //default: dayjs(),
    default: null,
  });

  export const AdempimentiIsDropdownOpenDirectors = atom<boolean>({
    key: 'AdempimentiIsDropdownOpenDirectors', 
    default: false,
  });

  export const AdempimentiComplianceResponsible = atom({
    key: 'AdempimentiComplianceResponsible', 
    default: '',
  });
  
  export const AdempimentiComplianceResponsibleID = atom({
    key: 'AdempimentiComplianceResponsibleID', 
    default: '',
  });
  

// DOCUMENTAZIONE

export const AdempimentiDocumentsPath = atom({
    key: 'AdempimentiDocumentsPath', 
    default: '',
  });
  
 
  export const AdempimentiDocumentsAttached = atom<File[]>({
    key: 'AdempimentiDocumentsAttached',
    default: [],
  });
  
  
  export const AdempimentiAttachedFilesInfo = atom<Array<{ name: string, size: number, type: string } | null>>({
    key: 'AdempimentiAttachedFilesInfo',
    default: [],
  });

  // EXTRA
    
  export type LV1DataItem = {
    LV1_id: string;
    LV1_name: string;
  };
  
  export const LV1DataItemState = atom<LV1DataItem[]>({
    key: 'LV1DataItemState', // Unique ID (with respect to other atoms/selectors)
    default: [], // Default value (aka initial value)
  });

  export type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };
  export const directorDataState = atom<DirectorDataItem[]>({
    key: 'directorDataState', // Unique ID (with respect to other atoms/selectors)
    default: [], // Default value (aka initial value)
  });

  export const AdempimentiOrganizzazioneSelectedL1 = atom({
    key: 'AdempimentiOrganizzazioneSelectedL1', 
    default: '',
  });

  export const AdempimentiOrganizzazioneSelectedL1ID = atom({
    key: 'AdempimentiOrganizzazioneSelectedL1ID', 
    default: '',
  });

  export const Adempimenti_2_isDropdownOpenLV1 = atom({
    key: 'Adempimenti_2_isDropdownOpenLV1', 
    default: false,
  });

  export const AdempimentiConfirmedLV1List = atom<string>({
    key: 'AdempimentiConfirmedLV1List', // Unique ID (with respect to other atoms/selectors)
    default: '', // Updated default value to be an empty object
  });
  
  export const AdempimentiIsDropdownOpenDirectorsCompliance = atom<boolean>({
    key: 'AdempimentiIsDropdownOpenDirectorsCompliance', 
    default: false,
  });
  
  export const AdempimentiDeleteConfirmationDialog = atom<boolean>({
    key: 'AdempimentiDeleteConfirmationDialog', 
    default: false,
  });