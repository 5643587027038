import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Titillium Web, Arial',
    h1: {
      fontSize: '2rem',
    },
    body1: {
      fontSize: '13px',
    },
  },
  palette: {
    primary: {
      main: '#04446c',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
        },
      },
    },
    // Adding styles for MuiList
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent', // Example, using CSS variable
          padding: '8px',
        },
      },
    },
    // Adding styles for MuiListItem
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'var(--color-lightsteelblue-100, #cbd4ec)', // Example, using CSS variable
          },
          '&.Mui-selected': {
            backgroundColor: 'var(--color-lightsteelblue-200, #b8c7f1)',
            color: 'var(--color-darkslategray, #26394d)',
            '&:hover': {
              backgroundColor: 'var(--color-lightsteelblue-300, #a4b8d9)',
            },
          },
        },
      },
    },
    // Add overrides for other MUI components as needed
  },

  
});

export default theme;
