import React, { useState, useEffect, useRef, useContext } from "react";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import dayjs from 'dayjs';

import { noWait, useRecoilState, useRecoilValue } from "recoil";

import {
  SelectedL1ID,
  SelectedL2ID,
  SelectedL3ID,
  SelectedL4ID,
  addL1ComplianceDate,
  addL1ComplianceDirettoreCompliance,
  addL1ComplianceDirettoreComplianceID,
  addL1OrganizzazioneDirettore,
  addL1OrganizzazioneDirettoreID,
  addL1SchedaGeneraleFax,
  addL1SchedaGeneraleIndirizzo,
  addL1SchedaGeneraleNomeAbbreviato,
  addL1SchedaGeneraleNomeCompleto,
  addL1SchedaGeneralePEC,
  addL1SchedaGeneraleTelefono,
  addL1AttachedFilesInfo,
  addL1DocumentsAttached,
  addL1DocumentsPath,
  TemplaterDateEnd,
  TemplaterDateStart,
  TemplaterDocN,
  TemplaterName,
  TemplaterSurname,
  TemplaterDirectorId,
  TemplaterDate,
  TemplaterDirector,
  TemplaterComplianceRole,
  TemplaterComplianceRoleId,
  TemplaterDirectory,
  TemplaterComplianceRoleResponsibility,
  
} from "../../../../recoil";

import SchedaGenerale from "../cybersecurity-board-documentazione-sub/cybersecurity-board-documentazione-letteraincarico-Add-sub/cybersecurity-board-documentazione-letteraincarico-Add-1";
// import Organizzazione from "./cybersecurity-board-organizzazione-organigramma-L1-Add-sub/organigramma-L1-Add-2-Organizzazione";
// import Infrastruttura from "./cybersecurity-board-organizzazione-organigramma-L1-Add-sub/organigramma-L1-Add-3-Infrastruttura";
// import Processi from "./cybersecurity-board-organizzazione-organigramma-L1-Add-sub/organigramma-L1-Add-3-Infrastruttura";
// import Parti from "./cybersecurity-board-organizzazione-organigramma-L1-Add-sub/organigramma-L1-Add-3-Infrastruttura";
// import Compliance from "./cybersecurity-board-organizzazione-organigramma-L1-Add-sub/organigramma-L1-Add-6-GestioneCompliance";
// import Documentazione from "./cybersecurity-board-organizzazione-organigramma-L1-Add-sub/organigramma-L1-Add-7-Documentazione";

import {
  FrameNavLogo,
  PicLogoIcon,
  FrameTopGradient,
  ButtonIsoActiveText,
  ButtonIsoActive,
  ButtonIsoInactiveText,
  ButtonIsoInactive,
  FrameButtonIso,
  FrameUserSearch,
  FrameUserSearchAuto,
  ButtonUser,
  ButtonNotification,
  IcoUserNotification,
  FrameLeftNav,
  FrameNavButtons,
  NavButtonWhite,
  NavButtonWhiteFocused,
  NavButtonWhiteText,
  NavButtonWhiteChild,
  NavButtonWhiteChildText,
  NavButtonWhiteChildFocused,
  NavButtonBlue,
  NavButtonBlueFocused,
  NavButtonBlueText,
  NavButtonBlueChild,
  NavButtonBlueChildFocused,
  ButtonGreen,
  ButtonGrey,
  ButtonText,
  ArrowGrey,
  ArrowGreyRight,
  ArrowWhite,
  ArrowWhiteRight,
  LineMagenta40,
  LineMagenta50,
  FrameButtonIcon,
  IcoButtonIcon,
  FrameNavWorkspace,
  WorkspaceLine1,
  WorkspaceLine2,
  FrameBoardMain,
  PageRoot,
  ScrollableDiv,
  MainBoardSubMenuButton,
  MainBoardSubMenuButtonContainer,
  MainBoardContentContainer,
  MainBoardSubMenuButtonContainerOrganigramma,
} from "../../styled-components";

import {
  FrameTopGradient100,
  SearchBarWithIconsRoot,
  Search,
  LineDivider,
  IconButton,
  BackgroundIcon,
  MainBoardContentContainerColumn,
  MainBoardContentContainerFile,
  FileButtonArrowGrey,
  FileButtonWhiteText,
  SearchGroup,
  IconsGroup,
  Title,
  TitleText,
  BoardScrollableDiv,
  ButtonClose,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteTitleAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  InputTextbox,
  CreaContainerColumn,
  FlexContainerCrea,
  InputContainer,
  IconContainer,
  InputDescriptionText,
  SubMenuButtonContainerOrganigrammaNoScroll,
  CreaSubMenuButton,
  SubMenuButtonContainerOrganigramma,
  Backdrop,
  MessageContainer,
  CloseMessageButton,
  MessageboxText,
  BackdropTransparent,
  DescriptionContainerCrea,
  DescriptionContainerCreaText,
  IconSquare,
  CreaSubMenuButtonMultiple,
  SubMenuButtonContainerOrganigrammaMultiple,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { URL_DirectorList, URL_DocGen_Lettera, URL_DocNumber } from "../../../../API";

const useToken = () => {
  const { keycloak, initialized } = useKeycloak();
  return initialized && keycloak.authenticated ? keycloak.token : null;
};

const useApi = () => {
  const token = useToken();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  //////////post
  const post = async (url: string, data: any) => {
    if (!token) {
      throw new Error("User is not authenticated");
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      // Attempt to parse the response as JSON, but handle empty or non-JSON responses gracefully
      let responseData;
      try {
        responseData = await response.json(); // May throw if the response is not JSON or is empty
      } catch (parseError) {
        if (response.ok) {
          // If the response was OK but parsing failed, it could be an empty response
          return null; // Or some default value as appropriate for your application
        } else {
          // If the response was not OK and parsing failed, throw with the status text
          throw new Error(`API request failed: ${response.statusText}`);
        }
      }

      // Check the OK status after attempting to parse the response
      if (!response.ok) {
        console.error("Error response data:", responseData);
        // Include the parsed responseData in the thrown Error, if available
        throw new Error(
          `API request failed: ${response.statusText}`,
          responseData
        );
      }

      return responseData; // Return the parsed response data
    } catch (error) {
      throw error; // Rethrow the error for the caller to handle
    }
  };

  /////////get

  const get = async (url: string) => {
    if (!token) throw new Error("User is not authenticated");

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`API request failed: ${response.statusText}`);
    }

    return await response.json();
  };

  return { post, get };
};


const YourAddL1Component = () => {
  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const token = useToken(); // Call useToken here at the top level
  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  ////////////POST/////////////////
  const { post } = useApi();

  const [directorLV1, setDirectorLV1] = useRecoilState(addL1OrganizzazioneDirettore);
  const [directorIdLV1, setDirectorIdLV1] = useRecoilState(addL1OrganizzazioneDirettoreID);
  const [directorComplianceLV1, setDirectorComplianceLV1] = useRecoilState(addL1ComplianceDirettoreCompliance);
  const [directorComplianceIdLV1, setDirectorComplianceIdLV1] = useRecoilState(addL1ComplianceDirettoreComplianceID);
  const [complianceDateLV1, setComplianceDateLV1] = useRecoilState(addL1ComplianceDate);
  const [FaxLV1, setFaxLV1] = useRecoilState(addL1SchedaGeneraleFax);
  const [IndirizzoLV1, setIndirizzoLV1] = useRecoilState(addL1SchedaGeneraleIndirizzo);
  const [NomeAbbreviatoLV1, setNomeAbbreviatoLV1] = useRecoilState(addL1SchedaGeneraleNomeAbbreviato);
  const [NomeCompletoLV1, setNomeCompletoLV1] = useRecoilState(addL1SchedaGeneraleNomeCompleto);
  const [PECLV1, setPECLV1] = useRecoilState(addL1SchedaGeneralePEC);
  const [TelefonoLV1, setTelefonoLV1] = useRecoilState(addL1SchedaGeneraleTelefono);

  const [AttachedFileInfoLV1, setAttachedFileInfoLV1] = useRecoilState(addL1AttachedFilesInfo);
  const [DocumentsAttachedLV1, setDocumentsAttachedLV1] = useRecoilState(addL1DocumentsAttached);
  const [DocumentsPathLV1, setDocumentsPathLV1] = useRecoilState(addL1DocumentsPath);
 

  const [date, setDate] = useRecoilState(TemplaterDate);
  const [dateStart, setDateStart] = useRecoilState(TemplaterDateStart);
  const [dateEnd, setDateEnd] = useRecoilState(TemplaterDateEnd);
  const [DocN, setDocN] = useRecoilState(TemplaterDocN);
  const [Name, setName] = useRecoilState(TemplaterName);
  const [Surname, setSurname] = useRecoilState(TemplaterSurname);
  const [ComplianceRole, setComplianceRole] = useRecoilState(TemplaterComplianceRole);
  const [ComplianceRoleId, setComplianceRoleId] = useRecoilState(TemplaterComplianceRoleId);
  const [ComplianceRoleResponsibility, setComplianceRoleResponsibility] = useRecoilState(TemplaterComplianceRoleResponsibility);
  const [Director, setDirector] = useRecoilState(TemplaterDirector);
  const [DirectorId, setDirectorId] = useRecoilState(TemplaterDirectorId);
  const [Directory, setDirectory] = useRecoilState(TemplaterDirectory);

///////////////////////////// FILE GENERATOR

async function fetchAndIncrementDocNumber() { 
  const response = await fetch(URL_DocNumber, {

  headers: {
      'Authorization': `Bearer ${token}`, // Include the token in the request headers
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch the current document number');
  }
  const currentNumberJson = await response.json();
  const currentDocumentNumber = currentNumberJson.document_number;

  
  const incrementResponse = await fetch(`${URL_DocNumber}/increment`, {
    method: 'PATCH',

    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  
  });

  if (!incrementResponse.ok) {
    throw new Error('Failed to increment the document number');
  }

  
  return currentDocumentNumber;
}






  function downloadFile(blob: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  
  async function generateAndDownloadLetter(templateFields: TemplateFields,) {
    const apiUrl = URL_DocGen_Lettera;
    const documentFolderLink = Directory; 
    const fileName = `lettera_di_incarico_${templateFields.person_surname}${templateFields.person_name}${templateFields.compliance_role_name}_${templateFields.document_n}.docx`;
  
    
   
    console.log("Template Fields: ", templateFields);
    console.log("File Name: ", fileName);
    console.log("Document Folder Link: ", documentFolderLink);
  
    try {
      const requestBody = JSON.stringify({
        ...templateFields 
      });
 
      console.log("Request Body: ", requestBody);
  
      const response = await fetch(`${apiUrl}?dir=${encodeURIComponent(documentFolderLink)}&file=${fileName}`, {
        method: 'POST',
    
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, 
        },
        body: requestBody,
      });
  
   
      console.log("Response Status: ", response.status);
      console.log("Response Status Text: ", response.statusText);
      console.log(`Link: ${apiUrl}?dir=${encodeURIComponent(documentFolderLink)}&file=${fileName}`)
  
      if (!response.ok) {
      
        const errorDetail = response.headers.get('X-Error-Detail');
        const errorMsg = errorDetail ? `${response.statusText}: ${errorDetail}` : response.statusText;
        throw new Error(`Error: ${errorMsg}`);
      }

      const blob = await response.blob();
      downloadFile(blob, fileName);
    } catch (error) {
      console.error('Failed to generate or download the document:', error);
    }
  }
  
  
  interface TemplateFields {
    person_name: string;
    person_surname: string;
    compliance_role_name: string;
    compliance_role_responsibility: string;
    compliance_role_assignment_start: string;
    compliance_role_assignment_end: string;
    date: string;
    document_n: string;
   
  }
  
  async function prepareAndGenerateDocument() {
    try {
      const documentNumber = await fetchAndIncrementDocNumber();
      const templateFields: TemplateFields = {
        person_name: Name, // Example, replace with actual
        person_surname: Surname, // Example, replace with actual
        compliance_role_name: ComplianceRole, // Example, replace with actual
        compliance_role_responsibility: ComplianceRoleResponsibility, // Example, replace with actual
        compliance_role_assignment_start: dateStart, // Example, replace with actual
        compliance_role_assignment_end: 'da definire', // Example, replace with actual
        date: date,
        document_n: documentNumber.toString(),
        //document_n: '1',
      };
  
      // Now, call generateAndDownloadLetter with the prepared fields
      await generateAndDownloadLetter(templateFields);
    } catch (error) {
      console.error('Error preparing or generating document:', error);
    }
  }
  
  
 
  const handleSave = async () => {

  
    prepareAndGenerateDocument()
  };


  //////////////////////////

  /////////GET DIRECTORS////////////////

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch data from the API
        const response = await fetch(
          URL_DirectorList,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response Data:", responseData);

        // Assuming responseData is the array you want to map
        const transformedData: DirectorDataItem[] = responseData.map(
          (item: DirectorApiContentItem) => {
            return {
              director_id: item.id,
              director_name: item.alias, // Changed from 'site_name' to 'name'

              // Map other properties as needed
            };
          }
        );

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data on component mount or when token changes
    fetchData();
  }, [token]); // Depend on the token

  ////////////////////

 
  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); // Close the message box
    closeOverlay();
  };

  ////////////////////CHANGE ACTIVE COMPONENT///////////
  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      // case "Organizzazione":
      //   return <Organizzazione />;
      // case "Infrastruttura":
      //   return <Infrastruttura />;
      // case "ProcessiGenerali":
      //   return <Processi />;
      // case "PartiInteressate":
      //   return <Parti />;
      // case "SistemaCompliance":
      //   return <Compliance />;
      // case "Documentazione":
      //   return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>
        NUOVA LETTERA D'INCARICO
      </OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SchedaGenerale")}
            to="/Cybersecurity/Documentazione/LetteraIncarico/CreaLetteraIncarico/1"
          >
            Scheda generale
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Organizzazione")}
            to="/Cybersecurity/Documentazione/LetteraIncarico/CreaLetteraIncarico/2"
          >
            Organizzazione
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SistemaCompliance")}
            to="/Cybersecurity/Documentazione/LetteraIncarico/CreaLetteraIncarico/6"
          >
            Sistema Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Documentazione")}
            to="/Cybersecurity/Documentazione/LetteraIncarico/CreaLetteraIncarico/7"
          >
            Documentazione
          </CreaSubMenuButtonMultiple> */}
        </SubMenuButtonContainerOrganigrammaMultipleShort>

        {renderActiveComponent()}
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={closeOverlay}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSave}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default YourAddL1Component;
