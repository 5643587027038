import React, { useState, useEffect, useRef, useContext } from "react";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import { useRecoilState, useRecoilValue } from "recoil";


import SchedaGenerale from "./cybersecurity-board-infrastruttura-elencoasset-Add/infrastruttura-elencoasset-Add-1-SchedaGenerale";
import Organizzazione from "./cybersecurity-board-infrastruttura-elencoasset-Add/infrastruttura-elencoasset-Add-2-Organizzazione";
//import Infrastruttura from "./cybersecurity-board-infrastruttura-elencoasset-Add/infrastruttura-elencoasset-Add-3-Infrastruttura";
//import Processi from "./cybersecurity-board-infrastruttura-elencoasset-Add/infrastruttura-elencoasset-Add-4-Processi";
//import Parti from "./cybersecurity-board-infrastruttura-elencoasset-Add/infrastruttura-elencoasset-Add-3-Infrastruttura";
import Compliance from "./cybersecurity-board-infrastruttura-elencoasset-Add/infrastruttura-elencoasset-Add-6-GestioneCompliance";
import Documentazione, { useInitiateUploadAsset } from "./cybersecurity-board-infrastruttura-elencoasset-Add/infrastruttura-elencoasset-Add-7-Documentazione";

import {
  FrameNavLogo,
  PicLogoIcon,
  FrameTopGradient,
  ButtonIsoActiveText,
  ButtonIsoActive,
  ButtonIsoInactiveText,
  ButtonIsoInactive,
  FrameButtonIso,
  FrameUserSearch,
  FrameUserSearchAuto,
  ButtonUser,
  ButtonNotification,
  IcoUserNotification,
  FrameLeftNav,
  FrameNavButtons,
  NavButtonWhite,
  NavButtonWhiteFocused,
  NavButtonWhiteText,
  NavButtonWhiteChild,
  NavButtonWhiteChildText,
  NavButtonWhiteChildFocused,
  NavButtonBlue,
  NavButtonBlueFocused,
  NavButtonBlueText,
  NavButtonBlueChild,
  NavButtonBlueChildFocused,
  ButtonGreen,
  ButtonGrey,
  ButtonText,
  ArrowGrey,
  ArrowGreyRight,
  ArrowWhite,
  ArrowWhiteRight,
  LineMagenta40,
  LineMagenta50,
  FrameButtonIcon,
  IcoButtonIcon,
  FrameNavWorkspace,
  WorkspaceLine1,
  WorkspaceLine2,
  FrameBoardMain,
  PageRoot,
  ScrollableDiv,
  MainBoardSubMenuButton,
  MainBoardSubMenuButtonContainer,
  MainBoardContentContainer,
  MainBoardSubMenuButtonContainerOrganigramma,
} from "../../styled-components";

import {
  FrameTopGradient100,
  SearchBarWithIconsRoot,
  Search,
  LineDivider,
  IconButton,
  BackgroundIcon,
  MainBoardContentContainerColumn,
  MainBoardContentContainerFile,
  FileButtonArrowGrey,
  FileButtonWhiteText,
  SearchGroup,
  IconsGroup,
  Title,
  TitleText,
  BoardScrollableDiv,
  ButtonClose,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteTitleAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  InputTextbox,
  CreaContainerColumn,
  FlexContainerCrea,
  InputContainer,
  IconContainer,
  InputDescriptionText,
  SubMenuButtonContainerOrganigrammaNoScroll,
  CreaSubMenuButton,
  SubMenuButtonContainerOrganigramma,
  Backdrop,
  MessageContainer,
  CloseMessageButton,
  MessageboxText,
  BackdropTransparent,
  DescriptionContainerCrea,
  DescriptionContainerCreaText,
  IconSquare,
  CreaSubMenuButtonMultiple,
  SubMenuButtonContainerOrganigrammaMultiple,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "../../cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { URL_AssetDetails, URL_DirectorList, URL_LV1, useApi, useToken } from "../../../../API";
import { AssetAttachedFilesInfo, AssetComplianceDate, AssetDocumentsAttached, AssetDocumentsPath, AssetID, AssetIdentificativo, AssetName, AssetOrganizzazioneSelectedL1ID, AssetSelectedCategoryNode, addAssetAssetStatusID, addAssetDirector, addAssetDirectorCompliance, addAssetDirectorComplianceID, addAssetDirectorID, addAssetLV1List, addAssetLV1ListID, addAssetLV2List, addAssetLV2ListID, addAssetLV3List, addAssetLV3ListID, addAssetSelectedCategoryID, addAssetSpecificheTecniche } from "./ElencoAsset-Add-recoil";
import { PATH_Assets } from "../../../../docPaths";
import { reFetchIndicatorAssetsList } from "../../../../recoil";
import dayjs from 'dayjs';

function generateUUID(): string {
  return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function useGenerateUUID(): string {

  const [assID, setAssID] = useRecoilState<string>(AssetID);

  useEffect(() => {
    // Generate UUID and set it
    const newUUID = generateUUID();
    setAssID(newUUID);
    console.log("New asset ID: ", newUUID);
  }, [setAssID]); // This effect should run only once when the component mounts

  return assID;
}

const YourAddL1Component = () => {

  const [assID, setAssID] = useRecoilState(AssetID);
  const [LV1List, setLV1List] = useRecoilState(addAssetLV1List);
  const [LV2List, setLV2List] = useRecoilState(addAssetLV2List);
  const [LV3List, setLV3List] = useRecoilState(addAssetLV3List);
  const [selectedL1, setSelectedL1] = useRecoilState(addAssetLV1ListID);
  const [selectedL2, setSelectedL2] = useRecoilState(addAssetLV2ListID);
  const [selectedL3, setSelectedL3] = useRecoilState(addAssetLV3ListID);  
  const [nomeCompleto, setNomeCompleto] = useRecoilState(AssetName);
  const [identificativo, setIdentificativo] = useRecoilState(AssetIdentificativo);
  const [specifiche, setSpecifiche] = useRecoilState(addAssetSpecificheTecniche);
  const [status, setStatus] = useRecoilState(addAssetAssetStatusID);
  const [category, setCategory] = useRecoilState(addAssetSelectedCategoryID);
  const [director, setDirector] = useRecoilState(addAssetDirectorID);
  const [ComplianceDate, setComplianceDate] = useRecoilState(AssetComplianceDate);
  const [ComplianceDirettoreCompliance, setComplianceDirettoreCompliance] = useRecoilState(addAssetDirectorCompliance);
  const [ComplianceDirettoreComplianceID, setComplianceDirettoreComplianceID] = useRecoilState(addAssetDirectorComplianceID);
  const [AttachedFilesInfo, setAttachedFilesInfo] = useRecoilState(AssetAttachedFilesInfo);
  const [DocumentsAttached, setDocumentsAttached] = useRecoilState(AssetDocumentsAttached);
  const [DocumentsPath, setDocumentsPath] = useRecoilState(AssetIdentificativo);
  const [selectedNodeId, setSelectedNodeId] =  useRecoilState(AssetSelectedCategoryNode);


  useGenerateUUID();
  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const token = useToken(); // Call useToken here at the top level
  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useState(false);
  const [message, setMessage] = useState("");
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorAssetsList);

  ////////////POST/////////////////
  const { post } = useApi();

const initiateUpload = useInitiateUploadAsset();

  const handleSave = async () => {

    if (!AssetName || !identificativo || !status || !category || !selectedL1 || !selectedL2 || !selectedL3) {

      setIsMessageBoxVisibleClosing(true);
      setMessage('Si prega di compilare tutti i campi obbligatori: Nome, Identificativo, Stato, Categoria, Componente organizzativa, Unità operativa e Luogo')
      return
    }

    const newSiteData = {
      id: assID,
      name: nomeCompleto,
      hasOrganization: `organizations.ecubit02`,
      hasCategory: category,
      inventoryN: identificativo,
      hasResponsible: director,
      hasUnit: selectedL2,
      hasPlace: selectedL3,
      state: status,
      has_description: specifiche,
      has_cybersecurity_responsible: ComplianceDirettoreComplianceID,
      cybersecurity_responsible_assignment_start: ComplianceDate ? dayjs(ComplianceDate).format('YYYY-MM-DD HH:mm:ss') : null, // Format date
      has_documents_folder_link: `${PATH_Assets}${identificativo}`
    };


    
    console.log(JSON.stringify(newSiteData));

    try {
      setIsLoading(true);
      // Call post directly with the object
      const responseData = await post(
        URL_AssetDetails,
        newSiteData
      );
      initiateUpload();
      setRefetchIndicator(prev => prev + 1);
      console.log("Asset created successfully:", responseData);
      setMessage("Asset created successfully!");
      setIsLoading(false);
      setIsMessageBoxVisible(true);
      //navigate(-2); navigate two pages before
      // Clear the form fields
      setNomeCompleto("");
      setCategory('');
      setIdentificativo('');
      setDirector('');
      setSelectedL1('');
      setSelectedL2('');
      setSelectedL3('');
      setLV1List('');
      setLV2List('');
      setLV3List('');
      setStatus('');
      setSpecifiche('');
      setAttachedFilesInfo([]);
      setComplianceDirettoreCompliance("");
      setComplianceDirettoreComplianceID("");
      setDocumentsAttached([]);
      setDocumentsPath("");
      setSelectedNodeId('');
    
      
      // If you're updating a list in the UI, you might want to add the new site to that list
    } catch (error) {
      console.error("Error creating asset:", error);
      setMessage(
        `Error creating asset: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
      setIsLoading(false);
      setIsMessageBoxVisibleClosing(true);
      // Handle errors, such as by displaying an error message to the user
    }
  };

  //////////////////////////

  /////////GET DIRECTORS////////////////

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch data from the API
        const response = await fetch(
          URL_DirectorList,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response Data:", responseData);

        // Assuming responseData is the array you want to map
        const transformedData: DirectorDataItem[] = responseData.map(
          (item: DirectorApiContentItem) => {
            return {
              director_id: item.id,
              director_name: item.alias, // Changed from 'site_name' to 'name'

              // Map other properties as needed
            };
          }
        );

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data on component mount or when token changes
    fetchData();
  }, [token]); // Depend on the token

  ////////////////////

  /////FILTER INPUT DIRECTORS

  // Create a map from director names to IDs
  const directorNameToId = new Map(
    data.map((item) => [item.director_name, item.director_id])
  );

 
  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); // Close the message box
    closeOverlay();
  };

  const handleClose = () => {
    setIsMessageBoxVisibleClosing(false); // Close the message box
 
  };

  ////////////////////CHANGE ACTIVE COMPONENT///////////
  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      case "Organizzazione":
        return <Organizzazione />;
      // case "Infrastruttura":
      //   return <Infrastruttura />;
      // case "ProcessiGenerali":
      //   return <Processi />;
      // case "PartiInteressate":
      //   return <Parti />;
      case "SistemaCompliance":
        return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Chiudi</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      
{isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleClose}>
              <MessageboxText>Chiudi</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>
        NUOVO ASSET
      </OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SchedaGenerale")}
            to="/Cybersecurity/Infrastruttura/StatoAsset/CreaStatoAsset/1"
          >
            Scheda generale
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Organizzazione")}
            to="/Cybersecurity/Infrastruttura/StatoAsset/CreaStatoAsset/2"
          >
            Organizzazione
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/Organizzazione/StatoAsset/CreaStatoAsset/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/Infrastruttura/StatoAsset/CreaStatoAsset/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/Organizzazione/StatoAsset/CreaStatoAsset/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SistemaCompliance")}
            to="/Cybersecurity/Infrastruttura/StatoAsset/CreaStatoAsset/6"
          >
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Documentazione")}
            to="/Cybersecurity/Infrastruttura/StatoAsset/CreaStatoAsset/7"
          >
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>

        {renderActiveComponent()}
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={closeOverlay}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSave}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default YourAddL1Component;
