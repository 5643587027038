import { atom, selector } from 'recoil';
import dayjs from 'dayjs';


//ADD L2 SCHEDA GENERALE

export const PartiOrganizationID = atom({
  key: 'PartiOrganizationID', 
  default: '',
});

export const PartiSchedaGeneraleNomeCompleto = atom({
  key: 'PartiSchedaGeneraleNomeCompleto', 
  default: '',
});

export const PartiSchedaGeneraleNomeAbbreviato = atom({
  key: 'PartiSchedaGeneraleNomeAbbreviato', 
  default: '',
});

export const PartiSchedaGeneraleReferente = atom({
  key: 'PartiSchedaGeneraleReferente', 
  default: '',
});

export const PartiSchedaGeneraleIndirizzo= atom({
  key: 'PartiSchedaGeneraleIndirizzo', 
  default: '',
});

export const PartiSchedaGeneraleEmail = atom({
  key: 'PartiSchedaGeneraleEmail', 
  default: '',
});

export const PartiSchedaGeneraleTelefono = atom({
  key: 'PartiSchedaGeneraleTelefono', 
  default: '',
});

export const PartiSchedaGeneraleFax = atom({
  key: 'PartiSchedaGeneraleFax', 
  default: '',
});

export const PartiSchedaGeneraleIVA = atom({
  key: 'PartiSchedaGeneraleIVA', 
  default: '',
});

//ADD L2 ORGANIZZAZIONE

export const PartiOrganizzazioneSelectedL1 = atom({
  key: 'PartiOrganizzazioneSelectedL1', 
  default: '',
});

export const PartiOrganizzazioneSelectedL1ID = atom({
  key: 'PartiOrganizzazioneSelectedL1ID', 
  default: '',
});


export const PartiOrganizzazioneDirettore = atom({
  key: 'PartiOrganizzazioneDirettore', 
  default: '',
});

export const PartiOrganizzazioneDirettoreID = atom({
  key: 'PartiOrganizzazioneDirettoreID', 
  default: '',
});

export const PartiOrganizzazioneModello = atom({
  key: 'PartiOrganizzazioneModello', 
  default: '',
});

export const PartiOrganizzazionePersonale = atom({
  key: 'PartiOrganizzazionePersonale', 
  default: '',
});

export const PartiOrganizzazioneDH = atom({
  key: 'PartiOrganizzazioneDH', 
  default: '',
});

export const PartiOrganizzazioneOrdinaria = atom({
  key: 'PartiOrganizzazioneOrdinaria', 
  default: '',
});

//ADD L2 INFRASTRUTTURA

export const selectedItemsAsset = atom<string[]>({
  key: 'selectedItemsAsset', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

//ADD L2 COMPLIANCE

export const PartiComplianceDirettoreCompliance = atom({
  key: 'PartiComplianceDirettoreCompliance', 
  default: '',
});

export const PartiComplianceDirettoreComplianceID = atom({
  key: 'PartiComplianceDirettoreComplianceID', 
  default: '',
});

export const PartiComplianceDate = atom<dayjs.Dayjs | null>({
  key: 'PartiComplianceDate', 
  default: dayjs(), // Initialize with the current date  
});

//ADD L2 DOCUMENTS

export const PartiDocumentsPath = atom({
  key: 'PartiDocumentsPath', 
  default: '',
});

// Assuming PartiDocumentsAttached is defined to store File objects
export const PartiDocumentsAttached = atom<File[]>({
  key: 'PartiDocumentsAttached',
  default: [],
});


export const PartiAttachedFilesInfo = atom<Array<{ name: string, size: number, type: string } | null>>({
  key: 'PartiAttachedFilesInfo',
  default: [],
});

// STATES

export interface PartiComplianceData {
  id: string;
  name: string;
  kind: string;
  objective: string;
  description: string;  
 
};

export const PartiComplianceDataState = atom<PartiComplianceData[]>({
  key: 'PartiComplianceDataState', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export const PartiisConfirmDialogVisible = atom<boolean>({
  key: 'PartiisConfirmDialogVisible', // Unique ID (with respect to other atoms/selectors)
  default: false, // Default value (aka initial value)
});


type CheckboxProcessiIdType = string;

export const PartiselectedCheckboxesProcessiState = atom<CheckboxProcessiIdType[]>({
  key: 'PartiselectedCheckboxesProcessiState',
  default: [], // default to an empty array
});

export const PartisearchTerm = atom({
  key: 'PartisearchTerm',
  default: '',
});

//// PROCESSI STATE

export interface BusinessProcess {
  id: string;
  creationTs: string | null;
  updateTs: string | null;
  createdBy: string;
  updatedBy: string;
  name: string;
  kind: string;
  objective: string;
  description: string;
  has_compliance_responsible: string;
  compliance_responsible_assignment_start: string;
  compliance_responsible: string | null;
}

export interface Unit {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  prefLabel: string;
  subOrganizationOf: string;
  hasOrganizationalCard: string;
  isOrganizationalUnit: boolean;
  hasUnitResponsible: string;
  isFormalOrganization: boolean;
  hasDomain: string;
  hasEntityLegalForm: string;
  identifier: string;
  hasRegisteredSite: string;
  hasModel: string;
  hasPrimarySite: string;
  pec: string;
  hasLegalRepresentative: string;
  hasDocumentsFolderLink: string;
  ordinary_activity: string;
  dh_activity: string;
  has_compliance_responsible: string;
  compliance_responsible_assignment_start: string;
  compliance_responsible: string | null;
  primary_site: {
    id: string;
    creationTs: string;
    updateTs: string;
    createdBy: string;
    updatedBy: string;
    site_name: string;
    site_short_name: string;
    has_site_address: string;
    site_email: string;
    site_telephone: string;
    has_site_responsible: string;
    fax: string;
    has_compliance_responsible: string;
    compliance_responsible_assignment_start: string;
    has_documents_folder_link: string;
    compliance_responsible: string | null;
    site_responsible: string | null;
  };
  responsible: {
    id: string;
    creationTs: string;
    updateTs: string;
    createdBy: string;
    updatedBy: string;
    surname: string;
    name: string;
    has_personal_card: string;
    member_of_formal_organization: string;
    reports_to: string;
    has_smart_working_site: string;
    documents_folder_link: string;
    based_at: string;
  };
  organizationalCard: any; // Use specific type if known
  organizationModel: any; // Use specific type if known
  entityLegalForm: any; // Use specific type if known
}

export interface ComplianceData {
  content: {
    id: string;
    creationTs: string | null;
    updateTs: string | null;
    createdBy: string;
    updatedBy: string;
    has_business_process: string;
    has_unit: string;
    unit: Unit;
    business_process: BusinessProcess;
  };
  selected: boolean;
}

export const complianceDataState = atom<ComplianceData[]>({
  key: 'complianceDataState',
  default: [], // Default value (aka initial value)
});

export interface PartiRole {
  id: string;
  role_description: string;
  responsibility: string;
};

export interface PartiModel {
  id: string;
  organizational_unit: {
    id: string;
    prefLabel: string;  
  };
  company_role: PartiRole[]; 
}

export const PartiModelDataState = atom<PartiModel[]>({
  key: 'PartiModelDataState', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export const PartiRoleState = atom<PartiRole[]>({
  key: 'PartiRoleState', // Unique ID (with respect to other atoms/selectors)
  default: [], // Default value (aka initial value)
});

export const PartiselectedAggrId = atom({
  key: 'PartiselectedAggrId',
  default: '',
});

export const PartiselectedModelId = atom({
  key: 'PartiselectedModelId',
  default: '',
});

export const PartiselectedModelName = atom({
  key: 'PartiselectedModelName',
  default: '',
});

export const PartiLegalForm = atom({
  key: 'PartiLegalForm',
  default: '',
});


export const PartiselectedLegalFormID = atom({
  key: 'PartiselectedLegalFormID',
  default: '',
});

export const PartiselectedCategoryID = atom({
  key: 'PartiselectedCategoryID',
  default: '',
});

export const PartiselectedCategory = atom({
  key: 'PartiselectedCategory',
  default: '',
});

export const PartiComplianceDeleteConfirmationDialog = atom<boolean>({
  key: 'PartiComplianceDeleteConfirmationDialog', 
  default: false,
});
