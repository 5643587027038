import { keycloakIp } from "./API";

const keycloakConfig = {
  url: keycloakIp,
  realm: 'complere',
  clientId: 'complere-fe',
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`
};

export default keycloakConfig;


