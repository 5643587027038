import React, { useState, useEffect, useRef, useContext } from "react";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import dayjs from 'dayjs';

import { useRecoilState, useRecoilValue } from "recoil";

import {
  SelectedL1ID,
  SelectedL2ID,
  SelectedL3ID,
  SelectedL4ID,
  isLoadingRec,
  isMessageBoxVisibleClosingRec,
  isMessageBoxVisibleRec,
  setErrorRec,


  
} from "../../../../recoil";

import {

  addL1ComplianceDate,
  addL1ComplianceDirettoreCompliance,
  addL1ComplianceDirettoreComplianceID,
  addL1OrganizzazioneDirettore,
  addL1OrganizzazioneDirettoreID,
  addL1SchedaGeneraleFax,
  addL1SchedaGeneraleIndirizzo,
  addL1SchedaGeneraleNomeAbbreviato,
  addL1SchedaGeneraleNomeCompleto,
  addL1SchedaGeneralePEC,
  addL1SchedaGeneraleTelefono,
  addL1AttachedFilesInfo,
  addL1DocumentsAttached,
  addL1DocumentsPath,

  
} from "../cybersecurity-board-contestoleadership-organizzazione-sub/L1-Add-Recoil";

import SchedaGenerale from "./cybersecurity-board-org-org-L1-Add-sub/organigramma-L1-Add-1-SchedaGenerale";
import Organizzazione from "./cybersecurity-board-org-org-L1-Add-sub/organigramma-L1-Add-2-Organizzazione";
import Compliance from "./cybersecurity-board-org-org-L1-Add-sub/organigramma-L1-Add-6-GestioneCompliance";
import Documentazione, { useInitiateUploadL1 } from "./cybersecurity-board-org-org-L1-Add-sub/organigramma-L1-Add-7-Documentazione";

import {
  FrameNavLogo,
  PicLogoIcon,
  FrameTopGradient,
  ButtonIsoActiveText,
  ButtonIsoActive,
  ButtonIsoInactiveText,
  ButtonIsoInactive,
  FrameButtonIso,
  FrameUserSearch,
  FrameUserSearchAuto,
  ButtonUser,
  ButtonNotification,
  IcoUserNotification,
  FrameLeftNav,
  FrameNavButtons,
  NavButtonWhite,
  NavButtonWhiteFocused,
  NavButtonWhiteText,
  NavButtonWhiteChild,
  NavButtonWhiteChildText,
  NavButtonWhiteChildFocused,
  NavButtonBlue,
  NavButtonBlueFocused,
  NavButtonBlueText,
  NavButtonBlueChild,
  NavButtonBlueChildFocused,
  ButtonGreen,
  ButtonGrey,
  ButtonText,
  ArrowGrey,
  ArrowGreyRight,
  ArrowWhite,
  ArrowWhiteRight,
  LineMagenta40,
  LineMagenta50,
  FrameButtonIcon,
  IcoButtonIcon,
  FrameNavWorkspace,
  WorkspaceLine1,
  WorkspaceLine2,
  FrameBoardMain,
  PageRoot,
  ScrollableDiv,
  MainBoardSubMenuButton,
  MainBoardSubMenuButtonContainer,
  MainBoardContentContainer,
  MainBoardSubMenuButtonContainerOrganigramma,
} from "../../styled-components";

import {
  FrameTopGradient100,
  SearchBarWithIconsRoot,
  Search,
  LineDivider,
  IconButton,
  BackgroundIcon,
  MainBoardContentContainerColumn,
  MainBoardContentContainerFile,
  FileButtonArrowGrey,
  FileButtonWhiteText,
  SearchGroup,
  IconsGroup,
  Title,
  TitleText,
  BoardScrollableDiv,
  ButtonClose,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteTitleAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  InputTextbox,
  CreaContainerColumn,
  FlexContainerCrea,
  InputContainer,
  IconContainer,
  InputDescriptionText,
  SubMenuButtonContainerOrganigrammaNoScroll,
  CreaSubMenuButton,
  SubMenuButtonContainerOrganigramma,
  Backdrop,
  MessageContainer,
  CloseMessageButton,
  MessageboxText,
  BackdropTransparent,
  DescriptionContainerCrea,
  DescriptionContainerCreaText,
  IconSquare,
  CreaSubMenuButtonMultiple,
  SubMenuButtonContainerOrganigrammaMultiple,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";
import { URL_DirectorList, URL_LV1, useApi, useToken } from "../../../../API";
import { PATH_L1 } from "../../../../docPaths";




const YourAddL1Component = () => {
  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const token = useToken(); // Call useToken here at the top level
  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] = useRecoilState(isMessageBoxVisibleClosingRec);

  const [message, setMessage] = useState("");
  

  ////////////POST/////////////////
  const { post } = useApi();

  const [directorLV1, setDirectorLV1] = useRecoilState(addL1OrganizzazioneDirettore);
  const [directorIdLV1, setDirectorIdLV1] = useRecoilState(addL1OrganizzazioneDirettoreID);
  const [directorComplianceLV1, setDirectorComplianceLV1] = useRecoilState(addL1ComplianceDirettoreCompliance);
  const [directorComplianceIdLV1, setDirectorComplianceIdLV1] = useRecoilState(addL1ComplianceDirettoreComplianceID);
  const [complianceDateLV1, setComplianceDateLV1] = useRecoilState(addL1ComplianceDate);
  const [FaxLV1, setFaxLV1] = useRecoilState(addL1SchedaGeneraleFax);
  const [IndirizzoLV1, setIndirizzoLV1] = useRecoilState(addL1SchedaGeneraleIndirizzo);
  const [NomeAbbreviatoLV1, setNomeAbbreviatoLV1] = useRecoilState(addL1SchedaGeneraleNomeAbbreviato);
  const [NomeCompletoLV1, setNomeCompletoLV1] = useRecoilState(addL1SchedaGeneraleNomeCompleto);
  const [PECLV1, setPECLV1] = useRecoilState(addL1SchedaGeneralePEC);
  const [TelefonoLV1, setTelefonoLV1] = useRecoilState(addL1SchedaGeneraleTelefono);

  const [AttachedFileInfoLV1, setAttachedFileInfoLV1] = useRecoilState(addL1AttachedFilesInfo);
  const [DocumentsAttachedLV1, setDocumentsAttachedLV1] = useRecoilState(addL1DocumentsAttached);
  const [DocumentsPathLV1, setDocumentsPathLV1] = useRecoilState(addL1DocumentsPath);
 
  const initiateUpload = useInitiateUploadL1();

  const handleSave = async () => {

    if (!NomeCompletoLV1 || !NomeAbbreviatoLV1) {

      setMessage('Si prega di compilare tutti i campi obbligatori: Nome completo e Nome abbreviato');
      setIsMessageBoxVisibleClosing(true);
      return;
    }

    const newSiteData = {
      site_name: NomeCompletoLV1,
      site_short_name: NomeAbbreviatoLV1,
      fax: FaxLV1,
      site_telephone: TelefonoLV1,
      has_site_responsible: directorIdLV1,
      address: {
        country_name: "IT",
        region: "Lazio",
        locality: "Roma",
        postal_code: "00162",
        street_address: IndirizzoLV1,
        building_number: "",
        apartment_number: "",
      },
      site_email: PECLV1,
      has_documents_folder_link: PATH_L1+NomeAbbreviatoLV1,
      has_compliance_responsible: directorComplianceIdLV1,
      compliance_responsible_assignment_start: complianceDateLV1,
    };

    console.log(JSON.stringify(newSiteData));

    

    try {
      setIsLoading(true);
      // Call post directly with the object
      const responseData = await post(
        URL_LV1,
        newSiteData
      );
      await initiateUpload();
      console.log("La componente organizzativa è stata creata con successo:", responseData);
      setMessage("La componente organizzativa è stata creata con successo!");
      setIsLoading(false);
      setIsMessageBoxVisible(true);
      //navigate(-2); navigate two pages before
      // Clear the form fields
      setDirectorComplianceIdLV1("");
    setDirectorComplianceLV1("");
    setDirectorIdLV1("");
    setDirectorLV1("");
    setComplianceDateLV1(dayjs());
    setFaxLV1("");
    setPECLV1("");
    setTelefonoLV1("");
    setIndirizzoLV1("");
    setNomeCompletoLV1("");
    setNomeAbbreviatoLV1("");
    setDocumentsAttachedLV1([]);
    setAttachedFileInfoLV1([]);
    setDocumentsPathLV1('');
      // If you're updating a list in the UI, you might want to add the new site to that list
    } catch (error) {
      console.error("Error creating site:", error);
      setMessage(
        `Error creating site: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
      setIsLoading(false);
      setIsMessageBoxVisible(true);
      // Handle errors, such as by displaying an error message to the user
    }
  };

  //////////////////////////

  /////////GET DIRECTORS////////////////

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch data from the API
        const response = await fetch(
          URL_DirectorList,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response Data:", responseData);

        // Assuming responseData is the array you want to map
        const transformedData: DirectorDataItem[] = responseData.map(
          (item: DirectorApiContentItem) => {
            return {
              director_id: item.id,
              director_name: item.alias, // Changed from 'site_name' to 'name'

              // Map other properties as needed
            };
          }
        );

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data on component mount or when token changes
    fetchData();
  }, [token]); // Depend on the token

  ////////////////////

 
  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); // Close the message box
    closeOverlay();
  };

  const handleClose = () => {
    setIsMessageBoxVisibleClosing(false); // Close the message box
   
  };

  ////////////////////CHANGE ACTIVE COMPONENT///////////
  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      case "Organizzazione":
        return <Organizzazione />;
      // case "Infrastruttura":
      //   return <Infrastruttura />;
      // case "ProcessiGenerali":
      //   return <Processi />;
      // case "PartiInteressate":
      //   return <Parti />;
      case "SistemaCompliance":
        return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

{isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleClose}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>
        NUOVA COMPONENTE ORGANIZZATIVA
      </OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SchedaGenerale")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/1"
          >
            Scheda generale
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Organizzazione")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/2"
          >
            Organizzazione
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SistemaCompliance")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/6"
          >
            Sistema Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Documentazione")}
            to="/Cybersecurity/Organizzazione/Organigramma/CreaL1/7"
          >
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>

        {renderActiveComponent()}
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={closeOverlay}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSave}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default YourAddL1Component;
