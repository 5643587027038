import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import styled from "styled-components";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione"; // Correct path
import { Box, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Checkbox } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  CustomDropdownItem,
  CustomDropdownList,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  DropDownMenuScrollbar,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputDescriptionTextDatePicker,
  InputTextbox,
  InputTextboxButton,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import {

  isLoadingRec,
  isMessageBoxVisibleRec,
  SelectedL4ID,
  setErrorRec,
  setMessageRec,
} from "../../../../../recoil";

import CancelIcon from "@mui/icons-material/Clear";
import { DateField, DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { ArrowButtonText, ButtonGrey, ButtonText } from "../../../styled-components";
import { URL_PersonRolesCompliance, URL_UnitComplianceProcessRolePerson, useToken } from "../../../../../API";
import {
  showL4ComplianceData,
  showL4ComplianceDataState,
  showL4Model2,
  showL4Model2State,
  showL4ModelCompliance,
  showL4ModelComplianceState,
  showL4Role2,
  showL4Role2State,
  showL4RoleCompliance,
  showL4RoleComplianceState,
  showL4SelectedPlaceUnitAssociationState,
  showL4isVisible,
  showL4isVisible2,
  showL4isVisiblePopUp2,
  showL4searchTerm,
  showL4searchTerm2,
  showL4selectedAggrIdCompliance,
  showL4selectedModelId,
  showL4selectedModelId2,
  showL4selectedModelName,
  showL4selectedModelName2,
} from "../L4-Show-recoil";

type GroupedModels = {
  [key: string]: {
    id: string;
    unit: {
      id: string;
      prefLabel: string;
      compliance_responsible_assignment_start: string;
    };
    compliance_role: showL4RoleCompliance[];
  };
};

interface RolesAccordionProps {
  role: showL4RoleCompliance[];
  model: showL4ModelCompliance;
}

interface ModelSelectorProps {
  models: showL4ModelCompliance[];
  onModelChange: (newModelId: string) => void; // Now expects a string
}


interface RolesAccordionProps2 {
  role: showL4Role2[] | null;
  model: showL4Model2;
}

interface ModelSelectorProps2 {
  models: showL4Model2[];
  onModelChange2: (newModelId: string) => void; // Now expects a string
}

///////////////// LAST POPUP

interface LastPopupProps {
  isVisible: boolean;
}

const LastPopupBlack = styled.div<LastPopupProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  //border: 5px solid #ffffff;
  z-index: 100;
`;

///////////////////////////////////////// LAST POP UP END

///////////////// LAST POPUP2

interface LastPopupProps2 {
  isVisible: boolean;
}

const LastPopupBlack2 = styled.div<LastPopupProps2>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup2 = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  //border: 5px solid #ffffff;
  z-index: 100;
`;

///////////////////////////////////////// LAST POP UP END2

/// PLACE UNIT ASSOCIATION

interface PlaceUnitAssociation {
  compliance_role: {
    id: string;
    role_description: string;
    responsibility: string;
  };
}

const useFetchPlaceUnitAssociations = (token: string | null | undefined, L4ID: string) => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [selectedUnitAssociations, setSelectedUnitAssociations] = useRecoilState(showL4SelectedPlaceUnitAssociationState);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${URL_PersonRolesCompliance}${L4ID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data from API");
        }

        const data: PlaceUnitAssociation[] = await response.json();

        // Filter out non-selected associations and map to the required structure
        const selectedAssociations = data.map((assoc) => ({
          id: assoc.compliance_role.id, // Use the id from content
          role_description: assoc.compliance_role.role_description, // Use the has_place id from content
          responsibility: assoc.compliance_role.responsibility, // Use the has_unit name from content, you might need to resolve the name from an id.
          // compliance_responsible_assignment_start: assoc.organizational_unit.compliance_responsible_assignment_start,
        }));

        // Set the selected associations
        setSelectedUnitAssociations(selectedAssociations);
      } catch (error) {
        setError(error instanceof Error ? error.message : "An unknown error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    if (token && L4ID) {
      fetchData();
    }
  }, [token, L4ID, setSelectedUnitAssociations]);

  return { selectedUnitAssociations };
};

/// PLACE UNIT ASSOCIATION END

/// PERSON ROLES FETCH
const useFetchComplianceData = (token: string | null | undefined) => {

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [selectedModelId, setSelectedModelId] = useRecoilState(showL4selectedModelId);
  const [selectedModelName, setSelectedModelName] = useRecoilState(showL4selectedModelName);
  const [selectedAggrId, setSelectedAggrId] = useRecoilState(showL4selectedAggrIdCompliance);
  const [Modeldata, setModelData] = useRecoilState(showL4ModelComplianceState);
  const [selectedModelRoles, setSelectedModelRoles] = useRecoilState(showL4RoleComplianceState);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const dataResponse = await fetch(`${URL_PersonRolesCompliance}${L4ID}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!dataResponse.ok) {
          throw new Error(`API request failed: ${dataResponse.statusText}`);
        }

        const responseData: showL4ModelCompliance[] = await dataResponse.json();

        const groupedModels = responseData.reduce((acc: GroupedModels, item) => {
          if (!acc[item.unit.id]) {
            acc[item.unit.id] = {
              ...item,
              compliance_role: Array.isArray(item.compliance_role) ? [...item.compliance_role] : [item.compliance_role],
            };
          } else {
            if (Array.isArray(item.compliance_role)) {
              acc[item.unit.id].compliance_role.push(...item.compliance_role);
            } else {
              acc[item.unit.id].compliance_role.push(item.compliance_role);
            }
          }
          return acc;
        }, {} as GroupedModels);

        const transformedData: showL4ModelCompliance[] = Object.values(groupedModels);

        setModelData(transformedData);
        if (transformedData.length > 0) {
          setSelectedModelId(transformedData[0].unit.id);
          setSelectedModelName(transformedData[0].unit.prefLabel);
          setSelectedModelRoles(transformedData[0].compliance_role);
          setSelectedAggrId(transformedData[0].id);
        }
        console.log("Compliance data: ", transformedData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setIsLoading(false);
      }
    };
    if (token && L4ID) fetchData();
  }, [token, L4ID]);

  // Return something from the hook if needed
};

// PERSON ROLES FETCH END

// MODEL SELECTOR


const ModelSelector: React.FC<ModelSelectorProps> = ({ models, onModelChange }) => {
  const [selectedModelId, setSelectedModelId] = useRecoilState(showL4selectedModelId);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" sx={{ color: "#04446c", padding: "8px" }}></FormLabel>
      <RadioGroup aria-label="model" name="model" value={selectedModelId} onChange={(event) => onModelChange(event.target.value)} sx={{ padding: "5px 10px 10px 20px" }}>
        {models.map((model) => (
          <FormControlLabel
            key={model.unit.id}
            value={model.unit.id}
            control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#6f8aab" } }} />}
            label={model.unit.prefLabel} // Use organizational_unit.prefLabel as label
            sx={{ color: "primary.main", "& .MuiTypography-root": { fontSize: "0.875rem" } }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};


/// MODEL SELECTOR END

// FETCH COMPLIANCE DATA

const useFetchAggrData = (token: string | null | undefined) => {  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [complianceData, setComplianceData] = useRecoilState(showL4ComplianceDataState);
  const [selectedAggrId, setSelectedAggrId] = useRecoilState(showL4selectedAggrIdCompliance);

  useEffect(() => {

    const fetchComplianceData = async () => {

      if (!token || !selectedAggrId) {
        setError("Token or selected aggregator ID is missing");
        setIsLoading(false);
        return;
      }

  setIsLoading(true);
  setError(null);

  const url = `${URL_UnitComplianceProcessRolePerson}${selectedAggrId}`;

  try {
    const response = await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.ok) {
      throw new Error(`API request failed: ${response.statusText}`);
    }
    const data = await response.json();
    if (Array.isArray(data)) {
      // Transform each item to include the selected property directly within the ComplianceData object
      const transformedData = data
        .map((item) => ({
          ...item.content, // Spread the content of each item
          selected: item.selected, // Add the selected property
        }))
        .sort((a, b) => b.selected - a.selected);
      setComplianceData(transformedData);
    } else {
      setError("Data received is not in expected format");
      setComplianceData([]);
    }
  } catch (err) {
    setError(err instanceof Error ? err.message : "An unknown error occurred");
  } finally {
    setIsLoading(false);
  }
};

fetchComplianceData();
}, [token, selectedAggrId]);

// Return any states or functions that might be needed by the component
return { isLoading, error, complianceData };
};

// FETCH COMPLIANCE DATA END

// GROUP COMPLIANCE BY PROCESS

const groupComplianceByBusinessProcess = (complianceData: showL4ComplianceData[]) => {
  return complianceData.reduce((acc, item) => {
    const bpId = item.compliance_process_unit_association.process_unit_association.business_process.id;
    if (!acc[bpId]) {
      acc[bpId] = [];
    }
    acc[bpId].push(item);
    return acc;
  }, {} as { [key: string]: showL4ComplianceData[] });
};

// GROUP COPLIANCE BY PROCESS END


// ACCORDION

// Use `groupedByBusinessProcess` to render nested accordions in your component

  //const url = `http://localhost:8091/api/v1/unit-compliance-process-compliance-role-person/selection/bycomplianceroleperson/${selectedAggrId}`; person.unit.complianceroles.ecubit13

  const RolesAccordion: React.FC<RolesAccordionProps> = ({ role, model }) => {
    const [complianceData, setComplianceData] = useRecoilState(showL4ComplianceDataState);
    const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
    const [error, setError] = useRecoilState<string | null>(setErrorRec);
    const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
    const [message, setMessage] = useRecoilState(setMessageRec);
    const [searchTerm, setSearchTerm] = useRecoilState(showL4searchTerm);
    const [selectedAggrId, setSelectedAggrId] = useRecoilState(showL4selectedAggrIdCompliance);

    console.log("Aggregated ID: ", selectedAggrId);
    // Function to fetch compliance data

    const filteredComplianceData = searchTerm
      ? complianceData.filter(
          (dataItem) =>
            dataItem.compliance_process_unit_association.compliance_process.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            dataItem.compliance_process_unit_association.compliance_process.description.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : complianceData;

    // Assuming `role` is an array of roles for a single model
    const filteredRoles = role.filter((singleRole) => {
      // First, check if the role description matches the search term
      const roleMatchesSearchTerm = singleRole.role_description.toLowerCase().includes(searchTerm.toLowerCase());

      // Then, check if any compliance data related to this role matches the search term
      const complianceDataForRole = complianceData.filter(
        (dataItem) =>
          dataItem.compliance_process_unit_association.compliance_process.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dataItem.compliance_process_unit_association.compliance_process.description.toLowerCase().includes(searchTerm.toLowerCase())
      );

      // A role should be included if it matches the search term or if any of its compliance data does
      return roleMatchesSearchTerm || complianceDataForRole.length > 0;
    });

    // Use `filteredRoles` instead of `role` for rendering accordions


    // useEffect(() => {
    //   if (role) {
    //     role.forEach((singleRole) => {
    //       fetchComplianceData(singleRole.id);
    //     });
    //   }
    // }, [role]);

    if (!role) return null;

    const groupedByBusinessProcess = useMemo(() => groupComplianceByBusinessProcess(complianceData), [complianceData]);

    return (
      <Box sx={{ mt: 2 }}>
        {role.map((role, roleIndex) => (
          <Accordion key={role.id} expanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="#04446c">{role.role_description}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ flexDirection: "column" }}>
              {isLoading && <Typography>Loading...</Typography>}
              {error && <Typography color="error">{error}</Typography>}
              {Object.entries(groupComplianceByBusinessProcess(filteredComplianceData)).map(([businessProcessId, complianceItems]) => (
                <Accordion expanded={true} key={businessProcessId} sx={{ mt: 1, boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)" }}>
                  <AccordionSummary sx={{ paddingTop: "0px", fontStyle: "italic", marginBottom: "-15px" }}>
                    {/* Assuming the first item's business process name is representative of all items in the group */}
                    <Typography>{complianceItems[0]?.compliance_process_unit_association.process_unit_association.business_process.name}:</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {complianceItems.map((item, index) => (
                      <Box key={index} sx={{ mt: 1 }}>
                        <Divider sx={{ my: 2, marginTop: "0px" }} />
                        <Typography>{item.compliance_process_unit_association.compliance_process.name}</Typography>
                        <Typography>{item.compliance_process_unit_association.compliance_process.description}</Typography>

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.selected}
                              inputProps={{ "aria-label": "Is Responsible" }}
                              disabled={true}
                              sx={{
                                color: "#425a77",
                                "& .MuiSvgIcon-root": { fontSize: 16 },
                                "&.Mui-checked": { color: "#425a77" },
                                padding: "0px",
                                marginRight: "10px",
                              }}
                            />
                          }
                          label="Questo processo Compliance é associato alla persona selezionata"
                          sx={{
                            marginTop: "10px", // Adjust top margin as needed

                            marginLeft: "0px",
                            color: "#425a77", // Change label color using the theme's color palette
                            display: "flex", // Ensures the label and checkbox are in a flex container
                            flexDirection: "row", // Aligns the label and checkbox horizontally
                            justifyContent: "flex-start", // Aligns the checkbox and label to the start of the flex container
                            alignItems: "center", // Centers the label and checkbox vertically
                            // Custom label positioning can be adjusted here
                          }}
                          labelPlacement="end" // Adjust label placement relative to the checkbox ("end" positions the label after the checkbox)
                        />
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

// ACCORDION END

// SEARCH INPUT 1 

interface SearchInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
// Search input component
const SearchInput: React.FC<SearchInputProps> = React.memo(({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // Automatically focus the input when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return <LastPopupSearch ref={inputRef} type="text" value={value} onChange={onChange} placeholder="Cerca" />;
});

// SEARCH INPUT 1 END


// PERSON ROLES COMPLIANCE 
const useFetchModelData2 = (token: string | null | undefined) => {

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  
  const [Modeldata2, setModelData2] = useRecoilState(showL4Model2State);
  const [selectedModelId2, setSelectedModelId2] = useRecoilState(showL4selectedModelId2);
  const [selectedModelName2, setSelectedModelName2] = useRecoilState(showL4selectedModelName2);
  const selectedModel2 = Modeldata2.find((model) => model.unit.id === selectedModelId2);
  const [selectedModelRoles2, setSelectedModelRoles2] = useRecoilState(showL4Role2State);
  
  useEffect(() => {
  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (!token) throw new Error("User is not authenticated");

      const dataResponse = await fetch(`${URL_PersonRolesCompliance}${L4ID}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!dataResponse.ok) {
        throw new Error(`API request failed: ${dataResponse.statusText}`);
      }

      const responseData: showL4Model2[] = await dataResponse.json();

      type GroupedModels2 = {
        [key: string]: {
          unit: {
            id: string;
            prefLabel: string;
            compliance_responsible_assignment_start: string;
          };
          compliance_role: showL4Role2[];
        };
      };

      const groupedModels2 = responseData.reduce((acc: GroupedModels2, item) => {
        if (!acc[item.unit.id]) {
          acc[item.unit.id] = {
            ...item,
            compliance_role: Array.isArray(item.compliance_role) ? [...item.compliance_role] : [item.compliance_role],
          };
        } else {
          if (Array.isArray(item.compliance_role)) {
            acc[item.unit.id].compliance_role.push(...item.compliance_role);
          } else {
            acc[item.unit.id].compliance_role.push(item.compliance_role);
          }
        }
        return acc;
      }, {} as GroupedModels2);

      const transformedData: showL4Model2[] = Object.values(groupedModels2);

      setModelData2(transformedData);
      if (transformedData.length > 0) {
        setSelectedModelId2(transformedData[0].unit.id);
        setSelectedModelName2(transformedData[0].unit.prefLabel);
        setSelectedModelRoles2(transformedData[0].compliance_role); // Corrected to use compliance_roles
      }
      console.log("Compliance data: ", transformedData);
    } catch (err) {
      setError(err instanceof Error ? err.message : "An unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  fetchData();
}, [token, L4ID]);
};

// PERSON ROLES COMPLIANCE END

// MODEL SELECTOR 2


const ModelSelector2: React.FC<ModelSelectorProps2> = ({ models, onModelChange2 }) => {
  const [selectedModelId2, setSelectedModelId2] = useRecoilState(showL4selectedModelId2);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" sx={{ color: "#04446c", padding: "8px" }}></FormLabel>
      <RadioGroup aria-label="model" name="model" value={selectedModelId2} onChange={(event) => onModelChange2(event.target.value)} sx={{ padding: "5px 10px 10px 20px" }}>
        {models.map((model) => (
          <FormControlLabel
            key={model.unit.id}
            value={model.unit.id}
            control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#6f8aab" } }} />}
            label={model.unit.prefLabel} // Use organizational_unit.prefLabel as label
            sx={{ color: "primary.main", "& .MuiTypography-root": { fontSize: "0.875rem" } }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

// MODEL SELECTOR 2 END

// ROLES ACCORDION 2


const RolesAccordion2: React.FC<RolesAccordionProps2> = ({ role, model }) => {
  if (!role) return null; // or some placeholder if no role is selected
  const [searchTerm2, setSearchTerm2] = useRecoilState(showL4searchTerm2);

  const filteredRoles = role?.filter((r) => r.role_description.toLowerCase().includes(searchTerm2.toLowerCase()) || r.responsibility.toLowerCase().includes(searchTerm2.toLowerCase())) || [];

  if (!filteredRoles.length) return <Typography sx={{ marginTop: "15px", marginLeft: "15px" }}>Non vi sono elemeneti corrispondenti.</Typography>;

  return (
    <Box sx={{ mt: 2 }}>
      {filteredRoles.map((filteredRole, index) => (
        <Accordion key={index} expanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{filteredRole.role_description}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ flexDirection: "column" }}>
            <Divider sx={{ my: 2, marginTop: "-10px" }} />
            <Typography>{filteredRole.responsibility}</Typography>
            <Typography>
              <br />{" "}
            </Typography>
            <Typography>Inizio incarico: {model.unit.compliance_responsible_assignment_start}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

// ROLES ACCORDINO 2 END

// SEARCH INPUT 2

  interface SearchInputProps2 {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }

  const SearchInput2: React.FC<SearchInputProps2> = React.memo(({ value, onChange }) => {
    const inputRef2 = useRef<HTMLInputElement>(null);
    useEffect(() => {
      // Automatically focus the input when the component mounts
      if (inputRef2.current) {
        inputRef2.current.focus();
      }
    }, []);
    return <LastPopupSearch ref={inputRef2} type="text" value={value} onChange={onChange} placeholder="Cerca" />;
  });

// SEARCH INPUT 2 END

// HANDLE MODEL CHANGE 1/2

const useHandleModelChange = () => {
  const [, setSelectedModelId] = useRecoilState(showL4selectedModelId);
  const [, setSelectedModelRoles] = useRecoilState(showL4RoleComplianceState);
  const [Modeldata] = useRecoilState(showL4ModelComplianceState);

  const handleModelChange = (newModelId: string) => {
    // Update the selected model ID
    setSelectedModelId(newModelId);

    // Find the new model by ID and update the roles
    const newModel = Modeldata.find((model) => model.unit.id === newModelId);
    if (newModel) {
      setSelectedModelRoles(newModel.compliance_role);
    } else {
      setSelectedModelRoles([]);
    }
  };

  return handleModelChange;
};


const useHandleModelChange2 = () => {
  const [, setSelectedModelId2] = useRecoilState(showL4selectedModelId2);
  const [, setSelectedModelRoles2] = useRecoilState(showL4Role2State);
  const [Modeldata2] = useRecoilState(showL4Model2State);

  const handleModelChange2 = (newModelId: string) => {
    // Update the selected model ID
    setSelectedModelId2(newModelId);

    // Find the new model by ID and update the roles
    const newModel = Modeldata2.find((model) => model.unit.id === newModelId);
    if (newModel) {
      setSelectedModelRoles2(newModel.compliance_role);
    } else {
      setSelectedModelRoles2([]);
    }
  };

  return handleModelChange2;
};



// HANDLE MODEL CHANGE 1/2 END

//////////////////////////MAIN COMPONENT

const MainComponent = () => {
  const [searchTerm, setSearchTerm] = useRecoilState(showL4searchTerm);
  const [searchTerm2, setSearchTerm2] = useRecoilState(showL4searchTerm2);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm2(event.target.value);
  };

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const token = useToken(); // Call useToken here at the top level
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const [Modeldata, setModelData] = useRecoilState(showL4ModelComplianceState);
  const [selectedModelId, setSelectedModelId] = useRecoilState(showL4selectedModelId);
  const [selectedModelName, setSelectedModelName] = useRecoilState(showL4selectedModelName);
  const [selectedAggrId, setSelectedAggrId] = useRecoilState(showL4selectedAggrIdCompliance);
  const selectedModel = Modeldata.find((model) => model.unit.id === selectedModelId);
  const [complianceData, setComplianceData] = useRecoilState(showL4ComplianceDataState);
  const [selectedModelRoles, setSelectedModelRoles] = useRecoilState(showL4RoleComplianceState);

  const [Modeldata2, setModelData2] = useRecoilState(showL4Model2State);
  const [selectedModelId2, setSelectedModelId2] = useRecoilState(showL4selectedModelId2);
  const [selectedModelName2, setSelectedModelName2] = useRecoilState(showL4selectedModelName2);
  const selectedModel2 = Modeldata2.find((model) => model.unit.id === selectedModelId2);
  const [selectedModelRoles2, setSelectedModelRoles2] = useRecoilState(showL4Role2State);

  const [, setSelectedUnitAssociations] = useRecoilState(showL4SelectedPlaceUnitAssociationState);
  const { selectedUnitAssociations } = useFetchPlaceUnitAssociations(token, L4ID);
  setSelectedUnitAssociations(selectedUnitAssociations);

  useFetchComplianceData(token);
  useFetchAggrData(token);
  useFetchModelData2(token);
  const handleModelChange = useHandleModelChange();
  const handleModelChange2 = useHandleModelChange2();

  const { closeOverlay } = useContext(OverlayContext);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); 
    closeOverlay();
  };

  const [isVisible, setIsVisible] = useRecoilState(showL4isVisiblePopUp2);
  const togglePopup = () => {
    setIsVisible(!isVisible);
  };
  const [isVisible2, setIsVisible2] = useRecoilState(showL4isVisible2);
  const togglePopup2 = () => {
    setIsVisible2(!isVisible2);
  };


  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Ruoli Compliance</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup2}>
              <ArrowButtonText>Apri per visualizzare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Processi Compliance</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup}>
              <ArrowButtonText>Apri per visualizzare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>
        </CreaContainerColumn>

        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>LISTA PROCESSI COMPLIANCE</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <SearchInput value={searchTerm} onChange={handleSearchChange} />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer>

              <LastPopupScrollbar>
                <Grid container spacing={2} sx={{ width: "100%" }}>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        backgroundColor: "#fff", // Set the background color to white
                        borderRadius: "4px", // Set the border-radius to 4px
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Apply a shadow
                        padding: "0px 10px 10px 5px", // Optional: Add padding inside the box if needed
                        marginTop: "16px",
                        marginLeft: "20px",
                      }}
                    >
                      <ModelSelector models={Modeldata} onModelChange={handleModelChange} />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    {selectedModelRoles && selectedModel && <RolesAccordion role={selectedModelRoles} model={selectedModel} />}
                  </Grid>
                </Grid>
              </LastPopupScrollbar>
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <ButtonGrey onClick={togglePopup}>
                <ButtonText>Chiudi</ButtonText>
              </ButtonGrey>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>

        <LastPopupBlack2 isVisible={isVisible2}>
          <LastPopup2>
            <LastPopupTitleContainer>LISTA RUOLI COMPLIANCE</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <SearchInput2 value={searchTerm2} onChange={handleSearchChange2} />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer>

              <LastPopupScrollbar>
                <Grid container spacing={2} sx={{ width: "100%" }}>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        backgroundColor: "#fff", // Set the background color to white
                        borderRadius: "4px", // Set the border-radius to 4px
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Apply a shadow
                        padding: "0px 10px 10px 5px", // Optional: Add padding inside the box if needed
                        marginTop: "16px",
                        marginLeft: "20px",
                      }}
                    >
                      <ModelSelector2 models={Modeldata2} onModelChange2={handleModelChange2} />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    {selectedModelRoles2 && selectedModel2 && <RolesAccordion2 role={selectedModelRoles2} model={selectedModel2} />}
                  </Grid>
                </Grid>
              </LastPopupScrollbar>
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <ButtonGrey onClick={togglePopup2}>
                <ButtonText>Chiudi</ButtonText>
              </ButtonGrey>
            </LastPopupButtonContainer>
          </LastPopup2>
        </LastPopupBlack2>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_compliance.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
          La scheda specifica gli elementi del sistema di gestione della Compliance definita basandosi sulla struttura organizzativa mappata precedentemente e in linea con il contesto attuale. Questo
          assicura il rispetto delle normative vigenti e la trasparenza nell'ambito della sicurezza delle informazioni.
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
