import React, { useState, useEffect, useMemo, useRef} from 'react';
import styled from 'styled-components';
import { useKeycloak } from "@react-keycloak/web";
import {OverlayContext} from '../cybersecurity-board-contestoleadership-organizzazione';
import { useNavigate, Routes, Route, Outlet } from "react-router-dom";

import { IconButton,IconsGroup,BackgroundIcon, Backdrop, BackdropTransparent,MessageContainer,MessageboxText,CloseMessageButton, } from "./cybersecurity-board-gestionecompliance-organigramma-styled-components";
import { StyledTable, StyledTbody, StyledTd, StyledTh, StyledThead, TableItemText, TableTitleText, TableScrollableDiv, TableContainer, TopContainer, Search, SearchIcon, SearchInputContainer, StyledTableItem, StyledActionTh, StyledTdCheckbox, TableContainerCampoApplicazione, TopContainerCampoApplicazione, SearchCampoApplicazione, StyledTableCampoApplicazione, TableScrollableDivCampoApplicazione} from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-unitaoperative-styled-components";

import { useRecoilState, useRecoilValue } from 'recoil';

import { 
  SelectedL1ID,
  SelectedL2ID,
  SelectedL3ID,
  SelectedL4ID,
 selectedCheckboxesCompliance,
} from '../../../../recoil';
import { Checkbox } from '@mui/material';
import { CustomDropdownListInfrastruttura, DropDownMenuScrollbarInfrastruttura, InputContainerHorizontal, InputTextboxHorizontal, MainBoardContentContainerColumnGestionCompliance, MainBoardContentContainerColumnInfratrutturaContain, MainBoardContentContainerColumnInfratrutturaHorizontal, TitleTextHorizontalGestioneCompliance, TriangleContainerInfrastruttura } from '../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components';
import { CustomDropdownItem, FadeOutComponent, FrameTopGradient100, TitleText } from '../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components';

import CancelIcon from '@mui/icons-material/Clear';
import { URL_Cybersecurity, URL_LV1List } from '../../../../API';

interface DataItem {
  id: string,
  selected: string,
  has_unit: string,
  kind: string,
  name: string,
  description: string,
  
  // Add any other properties that might exist in your data objects
}

  type SortableKeys = keyof DataItem;


  type ApiContentItem = {
    id: string;
    name: string;
    responsible_name: string;
    responsible_id: string;
    site_name: string;
    site_id: string;
    email: string;
    phone: string;
    // include other properties if there are any
  };

  type StyledTrProps = {
    selected: boolean;
  }

  const StyledTr = styled.tr<StyledTrProps>`

  cursor: pointer;
  background-color: ${props => {
    //console.log(`Is row selected: ${props.selected}`); // This should log true or false based on selection
    return props.selected ? '#f3f7fb' : '#ffffff';
  }};

  &:hover {
    background-color: #e9eff7; // Make sure this doesn't override your selection color
  }
`;

  const useToken = () => {
    const { keycloak, initialized } = useKeycloak();
  
    return initialized && keycloak.authenticated ? keycloak.token : null;
  };
  
  const useApi = () => {
  
  const token = useToken();
  
  ////
  
    const get = async (url: string) => {
      if (!token) throw new Error("User is not authenticated");
  
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }
  
      return await response.json();
    };
  
    // Add more methods for POST, PUT, DELETE, etc.
  
    return { get };
  };


  interface OverlayComponentProps {
    isAnimating: boolean;
  }
  
  
  const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
    opacity: ${(props) => (props.isAnimating ? "1" : "0")};
    visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
  `;


  const Board27001Dashboard: React.FC = () => {




    const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useState(false);
    const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useState(false);
    
    const [CheckboxesCompliance,setCheckboxesCompliance] = useRecoilState(selectedCheckboxesCompliance);

  
 /////////////OVERLAY ADD OPEN CLOSE
    
   
const navigate = useNavigate();

const toggleOverlayAdd2 = () => {
  if (!isOverlayVisibleAdd2) {
    // Check if the current path is '/Cybersecurity/Organizzazione'
    if (window.location.pathname === "/Cybersecurity/CampoApplicazione") {
      navigate(`CampoApplicazione/CreaCampoApplicazione/1`); // Navigate to 'Organigramma/AggiungiL1' if the condition is true
    } else {
      navigate(`${window.location.pathname}/CreaCampoApplicazione/1`); // Navigate to '/AggiungiL1' if the condition is false
    }

    setIsOverlayVisibleAdd2(true); // Show the overlay
    setTimeout(() => setIsOverlayAnimatingAdd2(true), 10); // Start animation after it's visible
  } else {
    setIsOverlayAnimatingAdd2(false); // Start hiding animation
    setTimeout(() => setIsOverlayVisibleAdd2(false), 310); // Wait for animation to finish before hiding
  }
};

 // Function to close the overlay
 const closeOverlayAdd2 = () => {

  //clean recoil variables here


  navigate(`/Cybersecurity/GestioneCompliance/CampoApplicazione`);
  setIsOverlayAnimatingAdd2(false); // Start the hide animation
  // Use a timeout equal to the transition duration to set visibility to false
  setTimeout(() => {
    setIsOverlayVisibleAdd2(false);
  }, 300); // 300ms is the duration of the exit transition
};


////////////////overlay open close end




const DataTable: React.FC<{ data: DataItem[] }> = ({ data }) => {



   



  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [selectedL2, setSelectedL2] = useState<string | null>(null);
  const [selectedL3, setSelectedL3] = useState<string | null>(null);
  const [selectedL4, setSelectedL4] = useState<string | null>(null);


const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);


const handleCheckboxChange = (id: string) => {
if (CheckboxesCompliance.includes(id)) {
  setCheckboxesCompliance(CheckboxesCompliance.filter(itemId => itemId !== id));
  console.log(CheckboxesCompliance);
} else {
  setCheckboxesCompliance([...CheckboxesCompliance, id]);
  console.log(CheckboxesCompliance);
}
};


const [isLoading, setIsLoading] = useState(false);
const [error, setError] = useState<string | null>(null);
const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

const [sortConfig, setSortConfig] = useState<{ key: SortableKeys; direction: 'ascending' | 'descending' } | null>(null);

// Function to handle sorting
const sortedData = useMemo(() => {
  let sortableItems = [...data]; // Create a new array to avoid mutating the original data
  if (sortConfig !== null) {
    sortableItems.sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      
      // Handle different types if necessary, e.g., string vs number
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortConfig.direction === 'ascending' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
      // Add more conditions for other types if necessary
      
      return 0;
    });
  }
  return sortableItems;
}, [data, sortConfig]);

// Click handler for headers
const requestSort = (key: SortableKeys) => {
  let direction: 'ascending' | 'descending' = 'ascending';
  if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  }
  setSortConfig({ key, direction });
};

const [selectedId, setSelectedId] = useState<string | null>(null);


const handleRowSelect = (id: string) => {

  setSelectedId(id);
  
};

useEffect(() => {
  // This effect will run after `L2ID` has been updated.
  console.log("L3ID now has the value:", L3ID);
  // Here, you can add any logic that needs to run right after L2ID changes.
}, [L3ID]); // Dependency array ensures this effect runs only when L2ID changes.


//////////////////////GLOBAL SEARCH

const [globalFilterText, setGlobalFilterText] = useState('');

const handleGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
setGlobalFilterText(event.target.value);
};

////////////////////////////////////// FILTER

const [filterText, setFilterText] = useState('');
// Event handler for the search input
const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
setFilterText(event.target.value);
};

// Filter and then sort the data
const filteredAndSortedData = useMemo(() => {
const filteredData = data
  .filter(item => {
    return item.has_unit?.toLowerCase().includes(filterText?.toLowerCase());
  })
  .filter(item => {
    // If there's global filter text, check all the relevant properties
    if (globalFilterText) {
      const allText = Object.values(item).join(' ').toLowerCase();
      return allText.includes(globalFilterText?.toLowerCase());
    }
    return true; // If there's no global filter text, don't filter further
  });

// Then sort the filtered data
if (sortConfig !== null) {
  filteredData.sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];
    
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortConfig.direction === 'ascending' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
    return 0;
  });
}

return filteredData;
}, [data, sortConfig, filterText, globalFilterText]);




  return (
    <TableContainerCampoApplicazione>
    <TopContainerCampoApplicazione>
    <SearchInputContainer>
<SearchCampoApplicazione placeholder='Filtra per unità operativa' onChange={handleFilterChange} />
<SearchIcon src="/funnel.svg" />
</SearchInputContainer>
<SearchInputContainer>
<SearchCampoApplicazione placeholder='Cerca in tutta la tabella' onChange={handleGlobalFilterChange} />
<SearchIcon src="/search.svg" />
</SearchInputContainer>

{/* <IconsGroup>
      <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/doc.svg" />
        </IconButton>
        <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/edit.svg" />
        </IconButton>
        <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/delete.svg" />
        </IconButton>
        <IconButton>
        <BackgroundIcon
          loading="eager"
          alt=""
          src="/add.svg"
          onClick={toggleOverlayAdd2} // Use the prop here
        />
        </IconButton>
      </IconsGroup> */}
</TopContainerCampoApplicazione>



    <StyledTableCampoApplicazione>
    <StyledThead>
       
    <StyledActionTh onClick={() => requestSort('selected')}><TableTitleText>▼</TableTitleText></StyledActionTh>
     <StyledTh onClick={() => requestSort('has_unit')}><TableTitleText>▼ Unitá operativa</TableTitleText></StyledTh>
     <StyledTh onClick={() => requestSort('kind')}><TableTitleText>▼ Categoria</TableTitleText></StyledTh>
     <StyledTh onClick={() => requestSort('name')}><TableTitleText>▼ Denominazione</TableTitleText></StyledTh>
     <StyledTh onClick={() => requestSort('description')}><TableTitleText>▼ Descrizione</TableTitleText></StyledTh>

  
   
    
   </StyledThead>
  </StyledTableCampoApplicazione>
  <TableScrollableDivCampoApplicazione>
   <StyledTableItem>
      <StyledTbody>
        
      {filteredAndSortedData.map(item => (
           <StyledTr
           key={item.id} // Use the unique ID as key instead of index
           onClick={() => handleRowSelect(item.id)} // Set the selected item ID on click
           selected={selectedId === item.id} // Conditionally apply the background color
         >


<StyledTdCheckbox>
            <TableItemText> <Checkbox
checked={CheckboxesCompliance.includes(item.id)}
onChange={() => handleCheckboxChange(item.id)}
disabled={true} 
sx={{
  color: '#425a77',
  '& .MuiSvgIcon-root': { fontSize: 16 },
  '&.Mui-checked': { color: '#425a77' },
  padding: '0px'
}}
/>

                </TableItemText>
                </StyledTdCheckbox>
              <StyledTd title={item.has_unit}><TableItemText>{item.has_unit}</TableItemText></StyledTd>
          <StyledTd title={item.kind}><TableItemText>{item.kind}</TableItemText></StyledTd>
          <StyledTd title={item.name}><TableItemText>{item.name}</TableItemText></StyledTd>
          <StyledTd title={item.description}><TableItemText>{item.description}</TableItemText></StyledTd>
           
          </StyledTr>
        ))}
       
      </StyledTbody>
  
    </StyledTableItem>
      </TableScrollableDivCampoApplicazione>

      
      <OverlayContext.Provider value={{ closeOverlay: closeOverlayAdd2, selectedL1, setSelectedL1, selectedL2, setSelectedL2, selectedL3,setSelectedL3,selectedL4,setSelectedL4 }}>

{isOverlayVisibleAdd2 && (
  <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd2}>
    {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
    <FadeOutComponent><FrameTopGradient100></FrameTopGradient100></FadeOutComponent>

    <Outlet />
  </OverlayComponentAdd1>
)}
</OverlayContext.Provider>

    </TableContainerCampoApplicazione>
  );
};



    const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
    const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
    const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
    const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

    const [data, setData] = useState<DataItem[]>([]);
    const [selectedL1, setSelectedL1] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  
    
  const [LV1List, setLV1List] = useState("");
  const [LV1ListId, setLV1ListId] = useState(""); // State to hold the ID of the director

    // useKeycloak should be called inside the functional component
    const { keycloak, initialized } = useKeycloak();
  
    const token = initialized && keycloak.authenticated ? keycloak.token : null;
  
    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          if (!token) throw new Error("User is not authenticated");
    
          const response = await fetch(URL_Cybersecurity, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          if (!response.ok) {
            throw new Error(`API request failed: ${response.statusText}`);
          }
    
          const responseData = await response.json();
          console.log("Response Data:", responseData);
    
          // Check if responseData is an array and transform each item
          if (Array.isArray(responseData)) {
            const transformedData = responseData
              .filter(item => !LV1ListId || item.content.unit.hasPrimarySite === LV1ListId)
              .map(item => ({
                id: item.content.id,
                selected: item.selected,
                has_unit: item.content.unit?.prefLabel,
                kind: item.content.business_process?.kind,
                name: item.content.business_process?.name,
                description: item.content.business_process?.description,
                // Map other properties as needed
              }));
    
            console.log("Transformed Data:", transformedData);
            setData(transformedData);
    
            // Initialize selectedCheckboxes based on the `selected` field
            const initiallySelected = transformedData
              .filter(item => item.selected)
              .map(item => item.id);
    
            setCheckboxesCompliance(initiallySelected);
          } else {
            console.error('responseData is not an array:', responseData);
          }
          
        } catch (error) {
          setError(error instanceof Error ? error.message : 'An unknown error occurred');
          setIsMessageBoxVisible(true);
        } finally {
          setIsLoading(false);
        }
      };
    
      fetchData();
    }, [token, LV1ListId]); // Include LV1ListId as a dependency to refetch when it changes
    
  
   
  ///////////////////////////////////LV1 ITEMLIST



  type LV1ApiContentItem = {
    id: string;
    alias: string;
  };

  type LV1DataItem = {
    LV1_id: string;
    LV1_name: string;
  };

  //TABLE API START

  const [LV1data, setLV1Data] = useState<LV1DataItem[]>([]);
 


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");
    
        // Fetch data from the API
        const response = await fetch(
          URL_LV1List,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
    
        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }
    
        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response Data:", responseData);
    
        // Access the content array from the responseData
        const contentArray = responseData.content; // This line is added to access the content field
    
        // Check if contentArray is indeed an array
        if (!Array.isArray(contentArray)) {
          throw new Error('Expected content to be an array');
        }
    
        // Map the content array to transform the data
        const transformedData: LV1DataItem[] = contentArray.map(
          (item: LV1ApiContentItem) => {
            return {
              LV1_id: item.id,
              LV1_name: item.alias, // Changed from 'site_name' to 'name'
              // Map other properties as needed
            };
          }
        );
    
        console.log("Transformed Data:", transformedData);
        setLV1Data(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };
    
    // Fetch data on component mount or when token changes
    fetchData();
    
  }, [token]); // Depend on the token

  ////////////////////


  /////FILTER INPUT DIRECTORS

  // Create a map from director names to IDs
  const directorNameToId = new Map(
    LV1data.map((item) => [item.LV1_name, item.LV1_id])
  );

  const handleDirectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLV1List(value);

    // If the value is a director name, also set the director ID
    if (directorNameToId.has(value)) {
      setLV1ListId(directorNameToId.get(value) || "");
      console.log(directorNameToId.get(value) || "");
    }
  };

  ////////////////////TOGGLE DROPDOWN///////////

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Event listener to close dropdown if clicked outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Add a type guard for event.target
      if (
        dropdownRef.current &&
        event.target instanceof Node &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    setLV1List('');
    setLV1ListId('');
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (selectedDirectorId: string) => {
    // Find the director name corresponding to the selected ID
    const selectedDirectorName = LV1data.find(
      (item) => item.LV1_id === selectedDirectorId
    )?.LV1_name;

    if (selectedDirectorName) {
      // Update the director name in the input box
      setLV1List(selectedDirectorName);

      // Update the director ID
      setLV1ListId(selectedDirectorId);
    }

    // Close the dropdown
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    console.log("Selected LV1ListId ID:", LV1ListId);
  }, [LV1ListId]); // This effect will run whenever directorId changes

  

  const ClearLV1 = () => {
    setLV1List('');
    setLV1ListId('');

    /////////////////////// END LIST LV1 ////////

  
  };

    return( 

      <MainBoardContentContainerColumnInfratrutturaContain>
    

          {
  isMessageBoxVisible && (
    <Backdrop>
      <MessageContainer>
        <p><MessageboxText>{error}</MessageboxText></p>
        <CloseMessageButton onClick={() => setIsMessageBoxVisible(false)}>
          <MessageboxText>Close</MessageboxText>
        </CloseMessageButton>
      </MessageContainer>
    </Backdrop>
  )
}


{isLoading && (
      <BackdropTransparent>
        <MessageContainer>
          <MessageboxText>Loading...</MessageboxText>
        </MessageContainer>
      </BackdropTransparent>
    )}

    {error && (
      <Backdrop>
        <MessageContainer>
          <p>
            <MessageboxText>{error}</MessageboxText>
          </p>
          <CloseMessageButton
            onClick={() => {
              setIsMessageBoxVisible(false);
              setError(null);
            }}
          >
            <MessageboxText>Close</MessageboxText>
          </CloseMessageButton>
        </MessageContainer>
      </Backdrop>
    )}

<MainBoardContentContainerColumnInfratrutturaHorizontal>
        <TitleTextHorizontalGestioneCompliance>
          <TitleText>Visualizza per:</TitleText>
        </TitleTextHorizontalGestioneCompliance>

        <InputContainerHorizontal>
          <InputTextboxHorizontal
            list="directors-list"
            value={LV1List}
            onChange={handleDirectorChange}
            onClick={handleDropdownToggle}
            placeholder="Componente organizzativa"
          />
          {LV1List && (
   <CancelIcon
   style={{
    fontSize: '16px',
     cursor: 'pointer',
     position: 'absolute',
     right: '30px',
     top: '50%',
     transform: 'translateY(-50%)',
     color: '#c6c6c6', // Change the color if needed
   }}
   onClick= {ClearLV1} // Clears the input field
 />
  )}
          <TriangleContainerInfrastruttura>
            {isDropdownOpen ? "▲" : "▼"} {/* Change icons as needed */}
          </TriangleContainerInfrastruttura>

          {isDropdownOpen && (
            <CustomDropdownListInfrastruttura>
              <DropDownMenuScrollbarInfrastruttura ref={dropdownRef}>
                {LV1data
                  .filter((item) =>
                    item.LV1_name
                      .toLowerCase()
                      .includes(LV1List.toLowerCase())
                  )
                  .map((filteredItem, index) => (
                    <CustomDropdownItem
                      key={index}
                      onClick={() =>
                        handleOptionSelect(filteredItem.LV1_id)
                      }
                    >
                      {filteredItem.LV1_name}
                    </CustomDropdownItem>
                  ))}
              </DropDownMenuScrollbarInfrastruttura>
            </CustomDropdownListInfrastruttura>
          )}
        </InputContainerHorizontal>

    
      </MainBoardContentContainerColumnInfratrutturaHorizontal>
      <MainBoardContentContainerColumnGestionCompliance>

    <DataTable data={data} />
    </MainBoardContentContainerColumnGestionCompliance>
    </MainBoardContentContainerColumnInfratrutturaContain>
    );
  };
  
  export default Board27001Dashboard;