import React, {
  useState,
  useEffect,
  useRef,
  RefObject,
  useContext,
  useMemo,
  useCallback,
} from "react";
import styled from "styled-components";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione"; // Correct path
import { Checkbox, Divider } from "@mui/material";
import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/Circle";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { debounce } from "lodash";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  CustomDropdownItem,
  CustomDropdownList,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  DropDownMenuScrollbar,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  InputTextboxButton,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  SelectedL4ID,
  SelectedL1ID,
  selectedCheckboxesCompliance,
  isLoadingRec,
  setErrorRec,
  isMessageBoxVisibleRec,
  setMessageRec,
} from "../../../../../recoil";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  ArrowButtonText,
  ButtonBaseText,
  ButtonGrey,
  ButtonText,
  NavButtonWhiteText,
  NavMessageText,
} from "../../../styled-components";
import {
  IconContainerList,
  ListContainer,
  ListScrollbar,
  ListTextbox,
} from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import {
  SearchIcon,
  SearchInputContainer,
  SearchInputContainerSolo,
  SearchPopup,
  StyledActionTh,
  StyledTable,
  StyledTableItem,
  StyledTbody,
  StyledTd,
  StyledTdCheckbox,
  StyledTdUnitaOperativa,
  StyledTh,
  StyledThead,
  StyledUnitaOperativaTh,
  TableContainerPopup,
  TableItemText,
  TableScrollableDivPopup,
  TableTitleText,
  TopContainer,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-unitaoperative-styled-components";

import {
  DirectorDataItem,
  LV1DataItem,
  LV1DataItemState,
  LuoghiOrganizzazioneSelectedL1,
  LuoghiOrganizzazioneSelectedL1ID,
  addLuoghiConfirmedLV1List,
  addLuoghiOrganizzazioneDirettore,
  addLuoghiOrganizzazioneDirettoreID,
  addLuoghi_2_isDropdownOpenDirectors,
  addLuoghi_2_isDropdownOpenLV1,
  directorDataState,
} from "../Luoghi-Add-recoil";
import {
  URL_DirectorList,
  URL_L2ByL1,
  URL_LV1List,
  URL_OrgCard,
  URL_PersonRoles,
} from "../../../../../API";
import { TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_LUOGHI_2 } from "../../tabs-description";

const useToken = () => {
  const { keycloak, initialized } = useKeycloak();
  return initialized && keycloak.authenticated ? keycloak.token : null;
};

type DirectorApiContentItem = {
  id: string;
  alias: string;
};

interface SearchInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type LV1ApiContentItem = {
  id: string;
  alias: string;
};

const useHandleCloseAndNavigate = () => {
  const [, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const { closeOverlay } = useContext(OverlayContext);
  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); // Close the message box
    //closeOverlay(); // Ensure closeOverlay is defined or imported
  };

  return handleCloseAndNavigate;
};

//LV1 LIST FETCH

const useFetchDataLv1 = (token: string | undefined | null) => {
  const [dataLV1, setDataLV1] = useRecoilState(LV1DataItemState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(
    isMessageBoxVisibleRec
  );
  const [message, setMessage] = useRecoilState(setMessageRec);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch dataLV1 from the API
        const response = await fetch(URL_LV1List, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response dataLV1:", responseData);

        // Access the content array from the responseData
        const contentArray = responseData.content; // This line is added to access the content field

        // Check if contentArray is indeed an array
        if (!Array.isArray(contentArray)) {
          throw new Error("Expected content to be an array");
        }

        // Map the content array to transform the dataLV1
        const transformedData: LV1DataItem[] = contentArray.map(
          (item: LV1ApiContentItem) => {
            return {
              LV1_id: item.id,
              LV1_name: item.alias, // Changed from 'site_name' to 'name'
              // Map other properties as needed
            };
          }
        );

        console.log("Transformed dataLV1:", transformedData);
        setDataLV1(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  return dataLV1;
};
/////// LV1 LIST END

/// FETCH DATA

const useFetchDirectorData = (token: string | null | undefined) => {
  const [dataDirector, setDataDirector] = useRecoilState(directorDataState);

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(
    isMessageBoxVisibleRec
  );
  const [message, setMessage] = useRecoilState(setMessageRec);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch data from the API
        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response Data:", responseData);

        // Assuming responseData is the array you want to map
        const transformedData: DirectorDataItem[] = responseData.map(
          (item: DirectorApiContentItem) => {
            return {
              director_id: item.id,
              director_name: item.alias, // Changed from 'site_name' to 'name'

              // Map other properties as needed
            };
          }
        );

        console.log("Transformed Data:", transformedData);
        setDataDirector(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [token]);
};

//////// DATA FETCH END

// HANDLE LV1 CHANGE

const useHandleLV1Change = () => {
  const [LV1List, setLV1List] = useRecoilState(LuoghiOrganizzazioneSelectedL1);

  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLV1List(event.target.value);
    },
    [setLV1List]
  );
};

/////

// HANDLE DROP DOWN TOGGLE

export const useDropdownToggleLV1 = () => {
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(
    addLuoghi_2_isDropdownOpenLV1
  );
  const [, setConfirmedLV1List] = useRecoilState(addLuoghiConfirmedLV1List);
  const [LV1List] = useRecoilState(LuoghiOrganizzazioneSelectedL1);

  const handleDropdownToggleLV1 = () => {
    if (!isDropdownOpenLV1) {
      setConfirmedLV1List(LV1List);
    }
    setIsDropdownOpenLV1(!isDropdownOpenLV1);
  };

  return handleDropdownToggleLV1;
};

////// DROP DOWN TOGGLE END

////// SELECT LV1

const useHandleOptionSelectLV1 = (data: LV1DataItem[]) => {
  const [dataLV1, setDataLV1] = useRecoilState(LV1DataItemState);
  const [LV1List, setLV1List] = useRecoilState(LuoghiOrganizzazioneSelectedL1);
  const handleDropdownToggleLV1 = useDropdownToggleLV1();
  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(
    addLuoghiConfirmedLV1List
  );
  const [LV1ListId, setLV1ListId] = useRecoilState(
    LuoghiOrganizzazioneSelectedL1ID
  );
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(
    addLuoghi_2_isDropdownOpenLV1
  );

  const handleOptionSelectLV1 = useCallback(
    (selectedLV1Id: string) => {
      console.log(`Handle LV1 ID: ${selectedLV1Id}`); // Log the selected LV1 ID for debugging
      console.log("Selected ID:", selectedLV1Id);
      console.log("DataLV1:", dataLV1);
      const selectedLV1Name = dataLV1.find(
        (item) => item.LV1_id === selectedLV1Id
      )?.LV1_name;

      if (selectedLV1Name) {
        setLV1List(selectedLV1Name); // Update input text
        setConfirmedLV1List(selectedLV1Name); // Confirm the selection
        setLV1ListId(selectedLV1Id);
        setL1ID(selectedLV1Id);
        setIsDropdownOpenLV1(false); // Close dropdown
      }
    },
    [dataLV1]
  );

  return handleOptionSelectLV1;
};

//SELECT LV1 END

// OUTSIDE CLICK LV1

const useOutsideClickLV1 = (ref: RefObject<HTMLDivElement>) => {
  const [LV1List, setLV1List] = useRecoilState(LuoghiOrganizzazioneSelectedL1);
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(
    addLuoghi_2_isDropdownOpenLV1
  );
  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(
    addLuoghiConfirmedLV1List
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (isDropdownOpenLV1) {
          setLV1List(confirmedLV1List); // Revert to confirmed value
          setIsDropdownOpenLV1(false); // Close dropdown
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    ref,
    confirmedLV1List,
    isDropdownOpenLV1,
    setLV1List,
    setIsDropdownOpenLV1,
  ]);
};

// OUTSIDE CLICK END

/// HANDEL OPTION SELECT DIRECTOR

const useHandleOptionSelectDirector = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useRecoilState(
    addLuoghi_2_isDropdownOpenDirectors
  );
  const [dataDirector] = useRecoilState(directorDataState);
  const [, setDirector] = useRecoilState(addLuoghiOrganizzazioneDirettore);
  const [, setDirectorId] = useRecoilState(addLuoghiOrganizzazioneDirettoreID);

  const handleSelectDirector = useCallback(
    (selectedDirectorId: string) => {
      const selectedDirector = dataDirector.find(
        (director) => director.director_id === selectedDirectorId
      );

      if (selectedDirector) {
        setDirector(selectedDirector.director_name);
        setDirectorId(selectedDirector.director_id);
        setIsDropdownOpen(false); // Close the dropdown after selection
      }
    },
    [dataDirector, setDirector, setDirectorId, setIsDropdownOpen]
  );

  return handleSelectDirector;
};

/// HANDLE DIRECTOR CHANGE

const useHandleDirectorChange = () => {
  const [dataDirector] = useRecoilState(directorDataState);
  const [, setDirector] = useRecoilState(addLuoghiOrganizzazioneDirettore);
  const [, setDirectorId] = useRecoilState(addLuoghiOrganizzazioneDirettoreID);

  // Create a memoized function to handle the input change
  const handleDirectorChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      // Map creation could potentially be moved outside of the callback if the dataDirector state doesn't change frequently
      const directorNameToId = new Map(
        dataDirector.map((item) => [item.director_name, item.director_id])
      );

      setDirector(value);

      // If the value matches a director name, also set the director ID
      if (directorNameToId.has(value)) {
        setDirectorId(directorNameToId.get(value) || "");
      }
    },
    [dataDirector, setDirector, setDirectorId]
  );

  return handleDirectorChange;
};

// DIRECTOR CHANGE END

// DROP DOWN TOGGLE DIRECTOR

const useDropdownToggleDirectors = () => {
  const [, setDirector] = useRecoilState(addLuoghiOrganizzazioneDirettore);
  const [, setDirectorId] = useRecoilState(addLuoghiOrganizzazioneDirettoreID);
  const [isDropdownOpen, setIsDropdownOpen] = useRecoilState(
    addLuoghi_2_isDropdownOpenDirectors
  );

  // Use useCallback to memoize the function, so it doesn't get recreated on every render
  const handleDropdownToggleDirector = useCallback(() => {
    setDirector("");
    setDirectorId("");
    setIsDropdownOpen(!isDropdownOpen);
  }, [isDropdownOpen, setDirector, setDirectorId, setIsDropdownOpen]);

  return handleDropdownToggleDirector;
};

// DROPDOWN TOGGLE END

// OUTSIDE CLICK director

const useOutsideClickDirectors = (ref: RefObject<HTMLDivElement>) => {
  // Use the state related to the directors' dropdown visibility
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(
    addLuoghi_2_isDropdownOpenDirectors
  );
  // Optionally, if you want to revert to a confirmed director when clicking outside, use the state that holds the confirmed director

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (isDropdownOpenDirectors) {
          setIsDropdownOpenDirectors(false); // Close the dropdown
          // Optionally, revert to the confirmed director
          // setDirector(confirmedDirector);
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    isDropdownOpenDirectors,
    setIsDropdownOpenDirectors /* confirmedDirector, setDirector */,
  ]);
};

// OUTSIDE CLICK END

// CLEAR LV2

const useClearLV2 = () => {
  const [, setL1ID] = useRecoilState(SelectedL1ID);
  const [, setDirector] = useRecoilState(addLuoghiOrganizzazioneDirettore);
  const [, setDirectorId] = useRecoilState(addLuoghiOrganizzazioneDirettoreID);

  // Define the clear function using useCallback to ensure it's only recreated if one of the dependencies changes
  const clearLV2 = useCallback(() => {
    setL1ID("");
    setDirector("");
    setDirectorId("");
  }, [setL1ID, setDirector, setDirectorId]);

  return clearLV2;
};

// CLEAR LV2 END

const MainComponent = () => {
  const token = useToken();

  const [dataDirector, setDataDirector] = useRecoilState(directorDataState);

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(
    isMessageBoxVisibleRec
  );
  const [message, setMessage] = useRecoilState(setMessageRec);

  const [LV1List, setLV1List] = useRecoilState(LuoghiOrganizzazioneSelectedL1);
  const [LV1ListId, setLV1ListId] = useRecoilState(
    LuoghiOrganizzazioneSelectedL1ID
  );
  const [director, setDirector] = useRecoilState(
    addLuoghiOrganizzazioneDirettore
  );
  const [directorId, setDirectorId] = useRecoilState(
    addLuoghiOrganizzazioneDirettoreID
  );
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);

  const [dataLV1, setDataLV1] = useState<LV1DataItem[]>([]);
  const finalDataLV1 = useFetchDataLv1(token);

  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(
    addLuoghiConfirmedLV1List
  );

  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(
    addLuoghi_2_isDropdownOpenLV1
  );
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(
    addLuoghi_2_isDropdownOpenDirectors
  );

  const dropdownRefDirector = useRef<HTMLDivElement>(null);
  const dropdownRefLV1 = useRef<HTMLDivElement>(null);

  const handleCloseAndNavigate = useHandleCloseAndNavigate();

  const handleDropdownToggleLV1 = useDropdownToggleLV1();
  const handleOptionSelectLV1 = useHandleOptionSelectLV1(dataLV1);
  const handleLV1Change = useHandleLV1Change();

  const handleDirectorChange = useHandleDirectorChange();
  const handleOptionSelectDirector = useHandleOptionSelectDirector();
  const handleDropdownToggleDirectors = useDropdownToggleDirectors();

  const ClearLV2 = useClearLV2();

  const { closeOverlay } = useContext(OverlayContext);

  useOutsideClickLV1(dropdownRefLV1);
  useOutsideClickDirectors(dropdownRefDirector);
  //useOutsideClickDirectors(dropdownRefDirector);

  useFetchDirectorData(token);

  useEffect(() => {
    // Find the item in finalDataLV1 that matches the LV1ListId
    const selectedLV1 = finalDataLV1.find((item) => item.LV1_id === LV1ListId);
    // If found, update the LV1List to the name (alias) of the found item
    if (selectedLV1) {
      setLV1List(selectedLV1.LV1_name);
    }
  }, [LV1ListId, finalDataLV1, setLV1List]); // Dependencies array includes LV1ListId, finalDataLV1, and setLV1List

  // useEffect(() => {
  //   console.log("Selected LV1ListId ID:", LV1ListId, "L1ID: ", L1ID);
  // }, [LV1ListId, L1ID]); // This effect will run whenever directorId changes

  // useEffect(() => {
  //   console.log("Selected Director ID:", directorId);
  // }, [directorId]); // This effect will run whenever directorId changes

  // When clicking outside, ensure to revert to confirmed value if dropdown was open

  // const ClearLV1 = () => {
  //   setLV1List("");
  //   setLV1ListId("");
  //   setL1ID("");

  //   setSelectedUnitCheckboxes([]);
  //   setSelectedRolesRadio({});
  //   setDataTable([]); //wassat
  //   setTestoRuoli("Apri per modificare");
  //   setTestoUnitaOperative("Apri per modificare");
  // };

  /////////////////////////////////////////

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Componente organizzativa
            </InputDescriptionText>

            <InputTextbox
              list="l1-list"
              value={LV1List}
              onChange={handleLV1Change}
              onClick={handleDropdownToggleLV1}
              placeholder="Seleziona"
            />
            {/* {LV1List && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6", // Change the color if needed
                }}
                onClick={ClearLV1} // Clears the input field
              />
            )} */}
            <TriangleContainer>
              {isDropdownOpenLV1 ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpenLV1 && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefLV1}>
                  {finalDataLV1
                    .filter((item) =>
                      item.LV1_name.toLowerCase().includes(
                        LV1List.toLowerCase()
                      )
                    )
                    .map((filteredItem, index) => (
                      <CustomDropdownItem
                        key={index}
                        onClick={() =>
                          handleOptionSelectLV1(filteredItem.LV1_id)
                        }
                      >
                        {filteredItem.LV1_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Responsabile luogo
            <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip></InputDescriptionText>

            <InputTextbox
              list="directors-list"
              value={director}
              onChange={handleDirectorChange}
              onClick={handleDropdownToggleDirectors}
              placeholder="Seleziona"
            />
            {director && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6", // Change the color if needed
                }}
                onClick={ClearLV2} // Clears the input field
              />
            )}
            <TriangleContainer>
              {isDropdownOpenDirectors ? "▲" : "▼"}{" "}
              {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpenDirectors && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefDirector}>
                  {dataDirector
                    .filter((item) =>
                      item.director_name
                        .toLowerCase()
                        .includes(director.toLowerCase())
                    )
                    .map((filteredItem, index) => (
                      <CustomDropdownItem
                        key={index}
                        onClick={() =>
                          handleOptionSelectDirector(filteredItem.director_id)
                        }
                      >
                        {filteredItem.director_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare
          src="/description_show_organization.svg"
          alt="Description Icon"
        />
        <DescriptionContainerCreaText>
        {TAB_DESCRIPTION_LUOGHI_2 ? TAB_DESCRIPTION_LUOGHI_2 : TAB_DESCRIPTION_GLOBAL}
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
