import { atom, selector } from "recoil";

export const isControlliOpen = atom<boolean>({
  key: "isControlliOpen",
  default: false,
});

export const isControlliAnimating = atom<boolean>({
  key: "isControlliAnimating",
  default: false,
});

export const isControlliOpenEdit = atom<boolean>({
  key: "isControlliOpen",
  default: false,
});

export const isControlliAnimatingEdit = atom<boolean>({
  key: "isControlliAnimating",
  default: false,
});

export const GAP_ID = atom<string>({
  key: "GAP_ID",
  default: "",
});

export const GAP_controlID = atom<string>({
  key: "GAP_controlID",
  default: "",
});

export const GAP_categoria = atom<string>({
  key: "GAP_categoria",
  default: "",
});

export const GAP_descrizione_controllo = atom<string>({
  key: "GAP_descrizione_controllo",
  default: "",
});

export const GAP_obbligo = atom<string>({
  key: "GAP_obbligo",
  default: "",
});

export const GAP_obbligo_label = atom<string>({
  key: "GAP_obbligo_label",
  default: "",
});

export const GAP_priority = atom<string>({
  key: "GAP_priority",
  default: "",
});
export const GAP_priority_label = atom<string>({
  key: "GAP_priority_label",
  default: "",
});


export const GAP_val_calc = atom<string>({
  key: "GAP_val_calc",
  default: "",
});

export const GAP_val_appr = atom<string>({
  key: "GAP_val_appr",
  default: "",
});

export const GAP_notes_todo = atom<string>({
  key: "GAP_notes_todo",
  default: "",
});

export const GAP_notes_done = atom<string>({
  key: "GAP_notes_done",
  default: "",
});

export const GAP_notes_ev = atom<string>({
  key: "GAP_notes_ev",
  default: "",
});

export const reFetchIndicatorGAPControlli = atom<number>({
  key: "reFetchIndicatorGAPControlli",
  default: 0,
});

export const GAP_control_value_2_avarage = atom<number>({
  key: "GAP_control_value_2_avarage",
  default: 0,
});

interface ChartData5 {
  category: string;
  obbligatorio: number;
  consigliato: number;
  libero: number;
}

export const GAP_chartDataState5 = atom<ChartData5[]>({
  key: "GAP_chartDataState5", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

export const GAP_control_value_2_total = atom<number>({
  key: "GAP_control_value_2_total",
  default: 0,
});

export const GAP_control_value_2_total_5 = atom<number>({
  key: "GAP_control_value_2_total_5",
  default: 0,
});

export const GAP_control_value_2_total_004 = atom<number>({
  key: "GAP_control_value_2_total_004",
  default: 0,
});

export const GAP_control_value_2_total_0514 = atom<number>({
  key: "GAP_control_value_2_total_0514",
  default: 0,
});

export const GAP_control_value_2_total_1524 = atom<number>({
  key: "GAP_control_value_2_total_1524",
  default: 0,
});

export const GAP_control_value_2_total_2534 = atom<number>({
  key: "GAP_control_value_2_total_2534",
  default: 0,
});

export const GAP_control_value_2_total_3544 = atom<number>({
  key: "GAP_control_value_2_total_3544",
  default: 0,
});

export const GAP_control_value_2_total_455 = atom<number>({
  key: "GAP_control_value_2_total_455",
  default: 0,
});


// Define types for control_duty and control_priority
type ControlDuty = "obbligatorio" | "consigliato" | "libero";
type ControlPriority = "nd" | "bassa" | "media" | "alta";

// Define the structure of the atom
interface AverageDutyGroupsState {
  obbligatorio: Record<ControlPriority, number>;
  consigliato: Record<ControlPriority, number>;
  libero: Record<ControlPriority, number>;
}

// Create the Recoil atom
export const averageDutyGroupsState = atom<AverageDutyGroupsState>({
  key: 'averageDutyGroupsState', // unique ID (with respect to other atoms/selectors)
  default: {
    obbligatorio: { nd: 0, bassa: 0, media: 0, alta: 0 },
    consigliato: { nd: 0, bassa: 0, media: 0, alta: 0 },
    libero: { nd: 0, bassa: 0, media: 0, alta: 0 },
  }, // default value (aka initial value)
});