import React, { useState, useContext,createContext, } from 'react';
import styled from 'styled-components';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// Define the shape of the context data for better TypeScript support
interface OverlayContextType {
  closeOverlay: () => void;
  selectedL1: string | null;
  setSelectedL1: React.Dispatch<React.SetStateAction<string | null>>;
  selectedL2: string | null;
  setSelectedL2: React.Dispatch<React.SetStateAction<string | null>>;
  selectedL3: string | null;
  setSelectedL3: React.Dispatch<React.SetStateAction<string | null>>;
  selectedL4: string | null;
  setSelectedL4: React.Dispatch<React.SetStateAction<string | null>>;
  

}


// Create the context with a default value
export const OverlayContext = createContext<OverlayContextType>({
  closeOverlay: () => {},
  selectedL1: null,
  setSelectedL1: () => {}, // This is a no-op function just for the default value
  selectedL2: null,
  setSelectedL2: () => {}, // This is a no-op function just for the default value
  selectedL3: null,
  setSelectedL3: () => {}, // This is a no-op function just for the default value
  selectedL4: null,
  setSelectedL4: () => {}, // This is a no-op function just for the default value
});

import {
 
  FrameNavLogo,
  PicLogoIcon,
  FrameTopGradient,
  ButtonIsoActiveText,
  ButtonIsoActive,
  ButtonIsoInactiveText,
  ButtonIsoInactive,
  FrameButtonIso,
  FrameUserSearch,
  FrameUserSearchAuto,
  ButtonUser,
  ButtonNotification,
  IcoUserNotification,
  FrameLeftNav,
  FrameNavButtons,
  NavButtonWhite,
  NavButtonWhiteFocused,
  NavButtonWhiteText,
  NavButtonWhiteChild,
  NavButtonWhiteChildText,
  NavButtonWhiteChildFocused,
  NavButtonBlue,
  NavButtonBlueFocused,
  NavButtonBlueText,
  NavButtonBlueChild,
  NavButtonBlueChildFocused,
  ArrowGrey,
  ArrowGreyRight,
  ArrowWhite,
  ArrowWhiteRight,
  LineMagenta40,
  LineMagenta50,
  FrameButtonIcon,
  IcoButtonIcon,
  FrameNavWorkspace,
  WorkspaceLine1,
  WorkspaceLine2,
  FrameBoardMain,
  PageRoot,
  ScrollableDiv,
  MainBoardSubMenuButton,
  MainBoardSubMenuButtonContainer,
  MainBoardContentContainer,

  
  } from "../styled-components";

 
  const Board27001ContestoLeadershipOrganizzazione: React.FC = () => {
   
    const location = useLocation();
   
    const isActive = (path: string) => {
      // Check if the current location is the index route of Organizzazione
      return location.pathname === "/Cybersecurity/Infrastruttura" && path === "StrutturaAsset";
    };
    
    

    return (
    
     <FrameBoardMain>

    <MainBoardSubMenuButtonContainer>
    <MainBoardSubMenuButton to="/Cybersecurity/Infrastruttura/StrutturaAsset" className={isActive("StrutturaAsset") ? "active" : ""}>Struttura asset</MainBoardSubMenuButton>
    <MainBoardSubMenuButton to="/Cybersecurity/Infrastruttura/StatoAsset">Stato asset</MainBoardSubMenuButton>
    <MainBoardSubMenuButton to="/Cybersecurity/Infrastruttura/Luoghi">Luoghi</MainBoardSubMenuButton>
    </MainBoardSubMenuButtonContainer>

 
      
    <Outlet />

      

    </FrameBoardMain> 
      
    );
  };

  export default Board27001ContestoLeadershipOrganizzazione;