import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
 *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
    body {
      margin: 0; line-height: normal;
      overflow: hidden;
      font-family: 'Titillium Web', Arial, sans-serif;
      
    }
:root {

/* fonts */
--font-titillium-web: 'Titillium Web';

/* font sizes */
--font-size-base: 16px;
--font-size-xl: 20px;
--font-size-mini: 15px;
--font-size-micro: 13px;

/* Colors */
--color-white: #fff;
--color-lightgray-100: #d7d7d7;
--color-lightgray-200: #d6d6d6;
--color-lightgray-300: #ccc;
--color-deepskyblue: #309ee5;
--color-aliceblue: #eff3fb;
--color-steelblue-100: #1a81c3;
--color-steelblue-200: #1075b3;
--color-steelblue-300: #0b6daa;
--color-steelblue-400: #15669a;
--color-steelblue-500: #2a4b73;
--color-steelblue-600: #0a5686;
--color-steelblue-700: #04446c;
--color-whitesmoke: #f1f1f1;
--color-lightslategray: #6f8aab;
--color-lightsteelblue-100: #cbd4ec;
--color-lightsteelblue-200: #b8c7f1;
--color-slategray-100: #5c718e;
--color-slategray-200: #425a77;
--color-gainsboro: #e6eefd;
--color-darkslategray: #26394d;
--color-lavender: #d9e6fe;

--color-menu-selected-light-blue: #f3f7fb;
--color-menu-hover-light-blue: #e9eff7;

--color-menu-scheda-fascia: #c8d6e8;

/* Border radiuses */
--br-8xs: 5px;

}
 /* DatePicker Global Styles */
 .MuiInputBase-root {
      border-radius: 0px;
      background-color: #ffffff;
      margin-bottom: 0px;
      margin-left: 0px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

      & .MuiOutlinedInput-notchedOutline {
        border-color: #cccccc;
      }

      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: #cccccc;
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #1a81c3;
        border-width: 1px;
        box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);
      }
    }

    .MuiInputBase-input {
      height: 2px;
      color: #0e163d;
      font-size: 13px;
      font-family: 'Titillium Web';
    }

    .MuiSvgIcon-root {
      color: #425A77;
      font-size: 1.25rem;
    }
`;
