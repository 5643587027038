import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import {
  AttachedFilesList,
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconSquare,
  InputButtonText,
  InputContainer,

  InputDescriptionText,
  InputTextbox,
  InputTextboxButton,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import {
 
  addL2DocumentsPath,
  addL2DocumentsAttached,
  addL2AttachedFilesInfo,
 

}  from '../../cybersecurity-board-contestoleadership-organizzazione-sub/L2-Add-recoil';

import { NavButtonWhiteText } from "../../../styled-components";

const MainComponent = () => {
  ////////////RECOIL

  const [docPath, setDocPath] = useRecoilState(addL2DocumentsPath);
  const [docAttached, setDocAttached] = useRecoilState(addL2DocumentsAttached);
  const [filesInfo, setFilesInfo] = useRecoilState(addL2AttachedFilesInfo);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    // Check if the current ref is not null before calling click
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileList = Array.from(files); // Convert FileList to Array
      setDocAttached(fileList); // Store File objects

      const fileInfoArray = fileList.map(file => ({
        name: file.name,
        size: file.size,
        type: file.type,
      }));
      setFilesInfo(fileInfoArray); // Store file info for display
    }
  };


  const uploadFiles = async () => {
    if (!docAttached || docAttached.length === 0) {
      console.log("No files to upload.");
      return;
    }
  
    const formData = new FormData();
    // Assuming you're storing the actual File objects in docAttached
    for (let i = 0; i < docAttached.length; i++) {
      formData.append("files", docAttached[i]);
    }
  
    try {
      const response = await fetch("YOUR_BACKEND_ENDPOINT", {
        method: "POST",
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error("Failed to upload files");
      }
  
      const result = await response.json();
      console.log("Files uploaded successfully:", result);
      // Optionally clear selected files after successful upload
      setDocAttached([]);
      setFilesInfo([]);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };
  
  

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>

          
          <InputContainer>
            <InputDescriptionText>Allega documenti</InputDescriptionText>
            <input style={{ display: 'none' }}  ref={fileInputRef} type="file" multiple onChange={handleFileChange} />
            
            <InputTextboxButton onClick={handleButtonClick}> <InputButtonText>Seleziona file dal computer</InputButtonText> </InputTextboxButton>
            <TriangleContainer>▼</TriangleContainer>
            <IconContainer />
          </InputContainer>

          {
  filesInfo.length > 0 && (
    <AttachedFilesList>
      {filesInfo.map((file, index) => (
        file && <li key={index}>{file.name} </li>
      ))}
    </AttachedFilesList>
  )
}
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Nome cartella documentazione
            </InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={docPath}
              onChange={(e) => setDocPath(e.target.value)}
            />
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
          Inserire i dettagli identificativi dell'unità operativa, comprensivi
          di un nome completo che rifletta le competenze e i servizi offerti, un
          nome abbreviato per una rapida identificazione, oltre ai principali
          canali di comunicazione per facilitare un contatto efficace e una
          pronta corrispondenza
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
