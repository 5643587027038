import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { useNavigate, Routes, Route, Outlet, useLocation } from "react-router-dom";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";
import { ButtonGreen, ButtonGrey, ButtonText, MainBoardContentContainerOrganizzazione, MainBoardSubMenuButtonContainerOrganigramma } from "../../styled-components";
import dayjs from "dayjs";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  SelectedL1ID,
  SelectedL2ID,
  SelectedL3ID,
  SelectedL4ID,
  selectedItemsAsset,
  addL1ComplianceDate,
  addL1ComplianceDirettoreCompliance,
  addL1ComplianceDirettoreComplianceID,
  addL1OrganizzazioneDirettore,
  addL1OrganizzazioneDirettoreID,
  addL1SchedaGeneraleFax,
  addL1SchedaGeneraleIndirizzo,
  addL1SchedaGeneraleNomeAbbreviato,
  addL1SchedaGeneraleNomeCompleto,
  addL1SchedaGeneralePEC,
  addL1SchedaGeneraleTelefono,
  addL1AttachedFilesInfo,
  addL1DocumentsAttached,
  addL1DocumentsPath,
  addL2OrganizzazioneSelectedL1,
  addL2OrganizzazioneSelectedL1ID,
  reFetchIndicatorL1,
} from "../../../../recoil";

import {
  FrameTopGradient100,
  SearchBarWithIconsRoot,
  Search,
  LineDivider,
  IconButton,
  BackgroundIcon,
  MainBoardContentContainerColumn,
  MainBoardContentContainerFile,
  FileButtonArrowGrey,
  FileButtonWhiteText,
  SearchGroup,
  IconsGroup,
  Title,
  TitleText,
  BoardScrollableDiv,
  ButtonClose,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteTitleAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  CreaSubMenuButton,
  SubMenuButtonContainerOrganigrammaNoScroll,
  MessageContainer,
  MessageboxText,
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  FadeOutComponent,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";
import { useKeycloak } from "@react-keycloak/web";
import {
  BoardScrollableDivOrganigramma,
  MainBoardContentContainerFileOgranigramma,
} from "../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import { URL_LV1List, URL_LV1_Detailed, URL_LV2ListByLV1, useApi, useToken } from "../../../../API";
import { L1DeleteConfirmationDialog } from "./L1-Add-Recoil";
import { useOverlayControlL4Show } from "./cybersecurity-board-organizzazione-persone";
import { useOverlayControlL3Show } from "./cybersecurity-board-organizzazione-ruoligenerali";
import { useOverlayControlL2Show } from "./cybersecurity-board-organizzazione-unitaoperative";
import { showL4initialPath } from "./L4-Show-recoil";
import { showL3initialPath } from "./L3-Show-recoil ";
import { showL2initialPath } from "./L2-Add-recoil";

const FileButtonWhite = styled.button<{ isActive: boolean }>`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: ${(props) => (props.isActive ? "#f3f7fb" : "var(--color-white)")};
  /* box-shadow: ${(props) => (props.isActive ? "0px 2px 3px rgba(0, 0, 0, 0.25)" : "0px 0px 0px rgba(0, 0, 0, 0.25)")}; */
  position: relative;
  border-bottom: 1px solid #f1f1f1;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  &:hover {
    background-color: #e9eff7;
  }
`;

interface OverlayComponentProps {
  isAnimating: boolean;
}

const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: ${(props) => (props.isAnimating ? "1" : "0")};
  visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
`;

////////////////////////////////  SEARCH //////////////////////////////////////

interface SearchBarProps {
  searchTerm: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAddClick: () => void; 
  onDeleteClick: () => void; 
  onEditClick: () => void;
  onShowClick: () => void;
}

interface SearchBarSmallProps {
  searchTerm: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

  onShowClick: () => void;
}

// SearchBar component (assuming it is separated)
const SearchBar = React.forwardRef<HTMLInputElement, SearchBarProps>(({ searchTerm, onChange, onAddClick, onDeleteClick, onEditClick, onShowClick }, ref) => {
  return (
    <SearchBarWithIconsRoot>
      <Search placeholder="Cerca" value={searchTerm} onChange={onChange} ref={ref} />
      <LineDivider />
      <IconsGroup>
        <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/doc.svg" onClick={onShowClick} />
        </IconButton>
        {/* <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/edit.svg" onClick={onEditClick} />
        </IconButton> */}
        <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/delete.svg" onClick={onDeleteClick} />
        </IconButton>
        <IconButton>
          <BackgroundIcon
            loading="eager"
            alt=""
            src="/add.svg"
            onClick={onAddClick} // Use the prop here
          />
        </IconButton>
      </IconsGroup>
      {/* Icons and other elements */}
    </SearchBarWithIconsRoot>
  );
});

const SearchBarSmall = React.forwardRef<HTMLInputElement, SearchBarSmallProps>(({ searchTerm, onChange, onShowClick }, ref) => {
  return (
    <SearchBarWithIconsRoot>
      <Search placeholder="Cerca" value={searchTerm} onChange={onChange} ref={ref} />
      <LineDivider />
      <IconsGroup>
        <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/doc.svg" onClick={onShowClick} />
        </IconButton>
      </IconsGroup>
      {/* Icons and other elements */}
    </SearchBarWithIconsRoot>
  );
});

const SearchBarZero = React.forwardRef<HTMLInputElement, SearchBarSmallProps>(({ searchTerm, onChange, onShowClick }, ref) => {
  return (
    <SearchBarWithIconsRoot>
      <Search placeholder="Cerca" value={searchTerm} onChange={onChange} ref={ref} />
      {/* <LineDivider />
      <IconsGroup>
        <IconButton>
          <BackgroundIcon loading="eager" alt="" src="/doc.svg" onClick={onShowClick} />
        </IconButton>
      </IconsGroup> */}
      {/* Icons and other elements */}
    </SearchBarWithIconsRoot>
  );
});


//////////// DELETE SITE 
const useDeleteSite= () => {

  const { del } = useApi(); 
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorL1);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useRecoilState(L1DeleteConfirmationDialog);
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);

  const handleDelete = useCallback(async () => {
    if (!L1ID) return;
    
    const url = URL_LV1_Detailed;
    try {
      await del(url, L1ID);
      console.log(`Deleted site with ID: ${L1ID}`);
      // Move the setRefetchIndicator and dialog visibility updates to a finally block
    } catch (error) {
      console.error('Failed to delete site:', error);
    } finally {
      // Ensure these states are updated after the try/catch block
      setRefetchIndicator(prev => prev + 1);
      setIsConfirmDialogVisible(false); // Close confirmation dialog
    }
  }, [L1ID, del, setRefetchIndicator]);

  const showDeleteConfirmation = useCallback(() => {
    setIsConfirmDialogVisible(true);
  }, []);

  return { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible };
};

/////////////DELETE PLACE END

///////////// API PARSING ////////////

interface ValueItem {
  id: string;
  alias: string;
}

interface NestedItem {
  value: ValueItem;
  leaves: NestedItem[]; // Recursive type reference for nested structure
}

// Define a type for your data items for BUTTONS
type DataItem = {
  id: string;
  text: string;
};

/////////////////////////////////////////////////////////////////////////

const CybersecurityOrganigramma = () => {
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const [selectedAssets, setSelectedAssets] = useRecoilState(selectedItemsAsset);
  const [directorLV1, setDirectorLV1] = useRecoilState(addL1OrganizzazioneDirettore);
  const [directorIdLV1, setDirectorIdLV1] = useRecoilState(addL1OrganizzazioneDirettoreID);
  const [directorComplianceLV1, setDirectorComplianceLV1] = useRecoilState(addL1ComplianceDirettoreCompliance);
  const [directorComplianceIdLV1, setDirectorComplianceIdLV1] = useRecoilState(addL1ComplianceDirettoreComplianceID);
  const [complianceDateLV1, setComplianceDateLV1] = useRecoilState(addL1ComplianceDate);
  const [FaxLV1, setFaxLV1] = useRecoilState(addL1SchedaGeneraleFax);
  const [IndirizzoLV1, setIndirizzoLV1] = useRecoilState(addL1SchedaGeneraleIndirizzo);
  const [NomeAbbreviatoLV1, setNomeAbbreviatoLV1] = useRecoilState(addL1SchedaGeneraleNomeAbbreviato);
  const [NomeCompletoLV1, setNomeCompletoLV1] = useRecoilState(addL1SchedaGeneraleNomeCompleto);
  const [PECLV1, setPECLV1] = useRecoilState(addL1SchedaGeneralePEC);
  const [TelefonoLV1, setTelefonoLV1] = useRecoilState(addL1SchedaGeneraleTelefono);

  const [AttachedFileInfoLV1, setAttachedFileInfoLV1] = useRecoilState(addL1AttachedFilesInfo);
  const [DocumentsAttachedLV1, setDocumentsAttachedLV1] = useRecoilState(addL1DocumentsAttached);
  const [DocumentsPathLV1, setDocumentsPathLV1] = useRecoilState(addL1DocumentsPath);
 
  const [message, setMessage] = useState("");
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  ///// GLOBAL SHOW L4 PROPAGATION
  const location = useLocation();
  const [originalPathL4, setOriginalPathL4] = useRecoilState(showL4initialPath);
  const { toggleOverlayL4, isVisibleL4, isAnimatingL4, closeOverlayL4 } = useOverlayControlL4Show("/Cybersecurity/Organizzazione/Persone/VisualizzaL4/1");

  const handleShowL4 = () => {
    setOriginalPathL4(location.pathname);
    console.log('Location: ' ,location.pathname)
    toggleOverlayL4();  // Properly using the hook inside an event handler
};


  ///// GLOBAL SHOW L3 PROPAGATION
  const [originalPathL3, setOriginalPathL3] = useRecoilState(showL3initialPath);
  const { toggleOverlayL3, isVisibleL3, isAnimatingL3, closeOverlayL3 } = useOverlayControlL3Show("/Cybersecurity/Organizzazione/Ruoli/CreaL3/1");

  const handleShowL3 = () => {
    setOriginalPathL3(location.pathname);
    toggleOverlayL3();  // Properly using the hook inside an event handler
};

  ///// GLOBAL SHOW L2 PROPAGATION
  const [originalPathL2, setOriginalPathL2] = useRecoilState(showL2initialPath);
  const { toggleOverlayL2, isVisibleL2, isAnimatingL2, closeOverlayL2 } = useOverlayControlL2Show("/Cybersecurity/Organizzazione/UnitaOperative/VisualizzaL2/1");

  const handleShowL2 = () => {
    setOriginalPathL2(location.pathname);
    toggleOverlayL2();  // Properly using the hook inside an event handler
};

  ////////////////// API FETCH LV1/////////////////////
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorL1);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { get } = useApi();


  type ApiContentItem = {
    id: string;
    alias: string;
    // include other properties if there are any
  };

  const fetchDataLV1 = async () => {
    setIsLoading(true);
    try {
      const data = await get(URL_LV1List);
      if (data && data.content) {
        const transformedData: DataItem[] = data.content.map((item: ApiContentItem) => ({
          id: item.id,
          text: item.alias,
        }));
        setL1Data(transformedData);
        if (setL1Data.length > 0) {
          setTriggerL1Selection(true);
        }
        setSelectedL1(transformedData.length > 0 ? transformedData[0].id : null);
      }
    } catch (err) {
      // Error handling
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataLV1();
  }, [reFetchIndicator]); // Empty array means this effect runs once on mount

  ////////////////////////////////////////////

  // Inside your main component
  const handleSearchChange = (level: "L1" | "L2" | "L3" | "L4") => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    switch (level) {
      case "L1":
        setSearchTermL1(value);
        break;
      case "L2":
        setSearchTermL2(value);
        break;
      case "L3":
        setSearchTermL3(value);
        break;
      case "L4":
        setSearchTermL4(value);
        break;
    }
  };

  const [isOverlayVisibleAdd1, setIsOverlayVisibleAdd1] = useState(false);
  const [isOverlayAnimatingAdd1, setIsOverlayAnimatingAdd1] = useState(false);
  const [isOverlayVisibleEdit1, setIsOverlayVisibleEdit1] = useState(false);
  const [isOverlayAnimatingEdit1, setIsOverlayAnimatingEdit1] = useState(false);
  const [isOverlayVisibleShow1, setIsOverlayVisibleShow1] = useState(false);
  const [isOverlayAnimatingShow1, setIsOverlayAnimatingShow1] = useState(false);

  const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useState(false);
  const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useState(false);

  const [isOverlayVisibleAdd3, setIsOverlayVisibleAdd3] = useState(false);
  const [isOverlayAnimatingAdd3, setIsOverlayAnimatingAdd3] = useState(false);

  const [isOverlayVisibleAdd4, setIsOverlayVisibleAdd4] = useState(false);
  const [isOverlayAnimatingAdd4, setIsOverlayAnimatingAdd4] = useState(false);

  ///////////////////////////////////////////////////

  const navigate = useNavigate();

  // Create refs for each search input
  const searchInputRefL1 = useRef<HTMLInputElement>(null);
  const searchInputRefL2 = useRef<HTMLInputElement>(null);
  const searchInputRefL3 = useRef<HTMLInputElement>(null);
  const searchInputRefL4 = useRef<HTMLInputElement>(null);

  // States for search terms
  const [searchTermL1, setSearchTermL1] = useState("");
  const [searchTermL2, setSearchTermL2] = useState("");
  const [searchTermL3, setSearchTermL3] = useState("");
  const [searchTermL4, setSearchTermL4] = useState("");


  // States for each level's selected item and data
  const [l1Data, setL1Data] = useState<DataItem[]>([]);
  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [l2Data, setL2Data] = useState<DataItem[]>([]);
  const [selectedL2, setSelectedL2] = useState<string | null>(null);
  const [l3Data, setL3Data] = useState<DataItem[]>([]);
  const [selectedL3, setSelectedL3] = useState<string | null>(null);
  const [l4Data, setL4Data] = useState<DataItem[]>([]);
  const [selectedL4, setSelectedL4] = useState<string | null>(null);

  const [nestedData, setNestedData] = useState<NestedItem[]>([]);

  useEffect(() => {
    setFilteredL1Data(filterData(l1Data, searchTermL1));
  }, [l1Data, searchTermL1]);

  useEffect(() => {
    // Filter L2 data when searchTermL2 or l2Data changes
    const filteredL2Data = filterData(l2Data, searchTermL2);
    setFilteredL2Data(filteredL2Data);
  }, [l2Data, searchTermL2]);

  useEffect(() => {
    // Filter L3 data when searchTermL3 or l3Data changes
    const filteredL3Data = filterData(l3Data, searchTermL3);
    setFilteredL3Data(filteredL3Data);
  }, [l3Data, searchTermL3]);

  useEffect(() => {
    // Filter L4 data when searchTermL4 or l4Data changes
    const filteredL4Data = filterData(l4Data, searchTermL4);
    setFilteredL4Data(filteredL4Data);
  }, [l4Data, searchTermL4]);

  const [filteredL1Data, setFilteredL1Data] = useState<DataItem[]>([]);
  const [filteredL2Data, setFilteredL2Data] = useState<DataItem[]>([]);
  const [filteredL3Data, setFilteredL3Data] = useState<DataItem[]>([]);
  const [filteredL4Data, setFilteredL4Data] = useState<DataItem[]>([]);

  // Function to filter data based on search term
  const filterData = (data: DataItem[], searchTerm: string) => {
    return data.filter((item) => item.text.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const [displayableData, setDisplayableData] = useState<{
    L1: DataItem[];
    L2: DataItem[];
    L3: DataItem[];
    L4: DataItem[];
  }>({
    L1: [],
    L2: [],
    L3: [],
    L4: [],
  });

  const sortDataItems = (data: DataItem[]) => {
    return data.sort((a: DataItem, b: DataItem) => a.text.localeCompare(b.text));
  };

  const [triggerL1Selection, setTriggerL1Selection] = useState(false);
  const [triggerL2Selection, setTriggerL2Selection] = useState(false);
  const [triggerL3Selection, setTriggerL3Selection] = useState(false);
  const [triggerL4Selection, setTriggerL4Selection] = useState(false);

  useEffect(() => {
    if (triggerL1Selection && l1Data.length > 0) {
      handleSelectL1(l1Data[0].id); // Select the first item of L2
      setTriggerL1Selection(false); // Reset the trigger flag
    }
  }, [triggerL1Selection, l1Data]); // Depend on the trigger flag and the L2 data
  useEffect(() => {
    if (triggerL2Selection && l2Data.length > 0) {
      handleSelectL2(l2Data[0].id); // Select the first item of L2
      setTriggerL2Selection(false); // Reset the trigger flag
    }
  }, [triggerL3Selection, l3Data]); // Depend on the trigger flag and the L2 data
  useEffect(() => {
    if (triggerL3Selection && l3Data.length > 0) {
      handleSelectL3(l3Data[0].id); // Select the first item of L2
      setTriggerL3Selection(false); // Reset the trigger flag
    }
  }, [triggerL3Selection, l3Data]); // Depend on the trigger flag and the L2 data

  useEffect(() => {
    if (triggerL4Selection && l4Data.length > 0) {
      handleSelectL4(l4Data[0].id); // Select the first item of L2
      setTriggerL4Selection(false); // Reset the trigger flag
    }
  }, [triggerL4Selection, l4Data]); // Depend on the trigger flag and the L2 data

  const handleSelectL1 = async (id: string) => {
    const selectedItem = l1Data.find((item) => item.id === id);
    if (selectedItem) {
      setL1ID(id);
      setSelectedL1(id);
      console.log("LV1ID: ", id);
      setSelectedL2(null);
      setSelectedL3(null);
      setSelectedL4(null);
      try {
        setIsLoading(true);
        const data = await get(URL_LV2ListByLV1 + '/' + id);

        setNestedData(data);
        const newL2Data = sortDataItems(
          data.map((item: NestedItem) => ({
            id: item.value.id,
            text: item.value.alias,
          }))
        );
        setL2Data(newL2Data); // Set the L2 data
        if (newL2Data.length > 0) {
          setTriggerL2Selection(true);
        }
        setFilteredL2Data(filterData(newL2Data, searchTermL2)); // Filter the L2 data

        // Clear out L3 and L4 data
        setL3Data([]);
        setFilteredL3Data([]);
        setSelectedL3(null);

        setL4Data([]);
        setFilteredL4Data([]);
        setSelectedL4(null);
        setDisplayableData((prevData) => ({
          ...prevData,
          L2: newL2Data,
          L3: [],
          L4: [],
        }));
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSelectL2 = async (id: string) => {
    const selectedItem = nestedData.find((item) => item.value.id === id);
    if (selectedItem) {
      setL2ID(id);
      setSelectedL2(id);
      console.log("LV2ID: ", id);
      setSelectedL3(null);
      setSelectedL4(null);
      const newL3Data = sortDataItems(
        selectedItem.leaves.map((leaf) => ({
          id: leaf.value.id,
          text: leaf.value.alias,
        }))
      );
      setL3Data(newL3Data);
      if (newL3Data.length > 0) {
        setTriggerL3Selection(true);
      }
      setFilteredL3Data(filterData(newL3Data, searchTermL3));
      // Clear out L4 data
      setL4Data([]);
      setFilteredL4Data([]);
      setSelectedL4(null);
      // No need to update L4 here, as it should be cleared or handled by handleSelectL3
    }
  };

  const handleSelectL3 = async (l3Id: string) => {
    // Ensure we have a selected L2 item before proceeding
    if (selectedL2) {
      // Find the L2 item first to ensure we are looking within the correct branch
      const selectedL2Item = nestedData.find((item) => item.value.id === selectedL2);

      // Now find the L3 item within the leaves of the selected L2 item
      const selectedL3Item = selectedL2Item?.leaves.find((leaf) => leaf.value.id === l3Id);

      if (selectedL3Item) {
        setL3ID(l3Id);
        setSelectedL3(l3Id);
        console.log("LV3ID: ", l3Id);
        setSelectedL4(null);

        // Direct children of the selected L3 item
        const newL4Data = selectedL3Item.leaves.map((leaf) => ({
          id: leaf.value.id,
          text: leaf.value.alias,
        }));

        setL4Data(sortDataItems(newL4Data)); // Sort the new L4 data
        if (newL4Data.length > 0) {
          setTriggerL4Selection(true);
        }
        // Filter if necessary, or directly set the filtered data as new data
        setFilteredL4Data(sortDataItems(newL4Data));
      }
    } else {
      // Handle the case where an L2 item isn't selected
      console.error("No L2 item selected. Cannot select L3.");
    }
  };

  const handleSelectL4 = async (id: string) => {
    setL4ID(id);
    setSelectedL4(id);
    console.log("LV4ID: ", id);

    // If there's more data to fetch or operations to perform on L4 selection, do it here.
  };
 

  //////////////////// TOGGLE OVERLAY 1 ////////////////////

  const toggleOverlayAdd1 = () => {
    if (!isOverlayVisibleAdd1) {
      // Check if the current path is '/Cybersecurity/Organizzazione'
      if (window.location.pathname === "/Cybersecurity/Organizzazione") {
        navigate(`Organigramma/CreaL1/1`); // Navigate to 'Organigramma/AggiungiL1' if the condition is true
      } else {
        navigate(`${window.location.pathname}/CreaL1/1`); // Navigate to '/AggiungiL1' if the condition is false
      }

      setIsOverlayVisibleAdd1(true); // Show the overlay
      setTimeout(() => setIsOverlayAnimatingAdd1(true), 10); // Start animation after it's visible
    } else {
      setIsOverlayAnimatingAdd1(false); // Start hiding animation
      setTimeout(() => setIsOverlayVisibleAdd1(false), 310); // Wait for animation to finish before hiding
    }
  };

  // Function to close the overlay
  const closeOverlayAdd1 = () => {
    
    setDirectorComplianceIdLV1("");
    setDirectorComplianceLV1("");
    setDirectorIdLV1("");
    setDirectorLV1("");
    setComplianceDateLV1(dayjs());
    setFaxLV1("");
    setPECLV1("");
    setTelefonoLV1("");
    setIndirizzoLV1("");
    setNomeCompletoLV1("");
    setNomeAbbreviatoLV1("");
    setDocumentsAttachedLV1([]);
    setAttachedFileInfoLV1([]);
    setDocumentsPathLV1('');

    navigate(`/Cybersecurity/Organizzazione/Organigramma`);
    setIsOverlayAnimatingAdd1(false); // Start the hide animation
    // Use a timeout equal to the transition duration to set visibility to false
    setTimeout(() => {
      setIsOverlayVisibleAdd1(false);
      fetchDataLV1();
    }, 300); // 300ms is the duration of the exit transition
  };

  /////EDIT

  const toggleOverlayEdit1 = () => {
    if (!isOverlayVisibleEdit1) {
      // Check if the current path is '/Cybersecurity/Organizzazione'
      if (window.location.pathname === "/Cybersecurity/Organizzazione") {
        navigate(`Organigramma/ModificaL1`); // Navigate to 'Organigramma/AggiungiL1' if the condition is true
      } else {
        navigate(`${window.location.pathname}/ModificaL1`); // Navigate to '/AggiungiL1' if the condition is false
      }

      setIsOverlayVisibleEdit1(true); // Show the overlay
      setTimeout(() => setIsOverlayAnimatingEdit1(true), 10); // Start animation after it's visible
    } else {
      setIsOverlayAnimatingEdit1(false); // Start hiding animation
      setTimeout(() => setIsOverlayVisibleEdit1(false), 310); // Wait for animation to finish before hiding
    }
  };

  // Function to close the overlay
  const closeOverlayEdit1 = () => {
    navigate(`/Cybersecurity/Organizzazione/Organigramma`);
    setIsOverlayAnimatingEdit1(false); // Start the hide animation
    // Use a timeout equal to the transition duration to set visibility to false
    setTimeout(() => {
      setIsOverlayVisibleEdit1(false);
      fetchDataLV1();
    }, 300); // 300ms is the duration of the exit transition
  };

  ////SHOW

  const toggleOverlayShow1 = () => {
    if (!isOverlayVisibleShow1) {
      // Check if the current path is '/Cybersecurity/Organizzazione'
      if (window.location.pathname === "/Cybersecurity/Organizzazione") {
        navigate(`Organigramma/VisualizzaL1/1`); // Navigate to 'Organigramma/AggiungiL1' if the condition is true
      } else {
        navigate(`${window.location.pathname}/VisualizzaL1/1`); // Navigate to '/AggiungiL1' if the condition is false
      }

      setIsOverlayVisibleShow1(true); // Show the overlay
      setTimeout(() => setIsOverlayAnimatingShow1(true), 10); // Start animation after it's visible
    } else {
      setIsOverlayAnimatingShow1(false); // Start hiding animation
      setTimeout(() => setIsOverlayVisibleShow1(false), 310); // Wait for animation to finish before hiding
    }
  };

  // Function to close the overlay
  const closeOverlayShow1 = () => {
    navigate(`/Cybersecurity/Organizzazione/Organigramma`);
    setIsOverlayAnimatingShow1(false); // Start the hide animation
    // Use a timeout equal to the transition duration to set visibility to false
    setTimeout(() => {
      setIsOverlayVisibleShow1(false);
      fetchDataLV1();
    }, 300); // 300ms is the duration of the exit transition
  };

  ////////////////////////2///////////////////////////

  const toggleOverlayAdd2 = () => {
    if (!isOverlayVisibleAdd2) {
      // Check if the current path is '/Cybersecurity/Organizzazione'
      if (window.location.pathname === "/Cybersecurity/Organizzazione") {
        navigate(`Organigramma/CreaL2/1`); // Navigate to 'Organigramma/AggiungiL1' if the condition is true
      } else {
        navigate(`${window.location.pathname}/CreaL2/1`); // Navigate to '/AggiungiL1' if the condition is false
      }

      setIsOverlayVisibleAdd2(true); // Show the overlay
      setTimeout(() => setIsOverlayAnimatingAdd2(true), 10); // Start animation after it's visible
    } else {
      setIsOverlayAnimatingAdd2(false); // Start hiding animation
      setTimeout(() => setIsOverlayVisibleAdd2(false), 310); // Wait for animation to finish before hiding
    }
  };

  // Function to close the overlay
  const closeOverlayAdd2 = () => {
    navigate(`/Cybersecurity/Organizzazione/Organigramma`);
    setIsOverlayAnimatingAdd2(false); // Start the hide animation
    // Use a timeout equal to the transition duration to set visibility to false
    setTimeout(() => {
      setIsOverlayVisibleAdd2(false);
    }, 300); // 300ms is the duration of the exit transition
  };

  /////////////////////////3//////////////////////////

  const toggleOverlayAdd3 = () => {
    if (!isOverlayVisibleAdd3) {
      // Check if the current path is '/Cybersecurity/Organizzazione'
      if (window.location.pathname === "/Cybersecurity/Organizzazione") {
        navigate(`Organigramma/Crea3`); // Navigate to 'Organigramma/AggiungiL1' if the condition is true
      } else {
        navigate(`${window.location.pathname}/Crea3`); // Navigate to '/AggiungiL1' if the condition is false
      }

      setIsOverlayVisibleAdd3(true); // Show the overlay
      setTimeout(() => setIsOverlayAnimatingAdd3(true), 10); // Start animation after it's visible
    } else {
      setIsOverlayAnimatingAdd3(false); // Start hiding animation
      setTimeout(() => setIsOverlayVisibleAdd3(false), 310); // Wait for animation to finish before hiding
    }
  };

  // Function to close the overlay
  const closeOverlayAdd3 = () => {
    navigate(`/Cybersecurity/Organizzazione/Organigramma`);
    setIsOverlayAnimatingAdd3(false); // Start the hide animation
    // Use a timeout equal to the transition duration to set visibility to false
    setTimeout(() => {
      setIsOverlayVisibleAdd3(false);
    }, 300); // 300ms is the duration of the exit transition
  };

  ////////////////////////4///////////////////////////

  const toggleOverlayAdd4 = () => {
    if (!isOverlayVisibleAdd4) {
      // Check if the current path is '/Cybersecurity/Organizzazione'
      if (window.location.pathname === "/Cybersecurity/Organizzazione") {
        navigate(`Organigramma/Crea4`); // Navigate to 'Organigramma/AggiungiL1' if the condition is true
      } else {
        navigate(`${window.location.pathname}/Crea4`); // Navigate to '/AggiungiL1' if the condition is false
      }

      setIsOverlayVisibleAdd4(true); // Show the overlay
      setTimeout(() => setIsOverlayAnimatingAdd4(true), 10); // Start animation after it's visible
    } else {
      setIsOverlayAnimatingAdd4(false); // Start hiding animation
      setTimeout(() => setIsOverlayVisibleAdd4(false), 310); // Wait for animation to finish before hiding
    }
  };

  // Function to close the overlay
  const closeOverlayAdd4 = () => {
    navigate(`/Cybersecurity/Organizzazione/Organigramma`);
    setIsOverlayAnimatingAdd4(false); // Start the hide animation
    // Use a timeout equal to the transition duration to set visibility to false
    setTimeout(() => {
      setIsOverlayVisibleAdd4(false);
    }, 300); // 300ms is the duration of the exit transition
  };

  const toggleOverlayShow4 = () => {
    if (!isOverlayVisibleShow1) {
      // Check if the current path is '/Cybersecurity/Organizzazione'
      if (window.location.pathname === "/Cybersecurity/Organizzazione/Organigramma") {
        navigate(`Persone/VisualizzaL4/1`); // Navigate to 'Organigramma/AggiungiL1' if the condition is true
      } else {
        navigate(`${window.location.pathname}/Persone/VisualizzaL4/1`); // Navigate to '/AggiungiL1' if the condition is false
      }

      setIsOverlayVisibleShow1(true); // Show the overlay

      setTimeout(() => setIsOverlayAnimatingShow1(true), 10); // Start animation after it's visible
    } else {
      setIsOverlayAnimatingShow1(false); // Start hiding animation
      setTimeout(() => setIsOverlayVisibleShow1(false), 310); // Wait for animation to finish before hiding
    }
  };

  const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeleteSite();


  return (
    <MainBoardContentContainerOrganizzazione>
      <MainBoardContentContainerColumn>
        <MainBoardContentContainerFileOgranigramma>
          <Title>
            <TitleText>L1: COMPONENTI ORGANIZZATIVE</TitleText>
          </Title>
          <SearchBar
            searchTerm={searchTermL1}
            onChange={handleSearchChange("L1")}
            onAddClick={toggleOverlayAdd1} // Pass the function here
            onEditClick={toggleOverlayEdit1} // Pass the function here
            onDeleteClick={showDeleteConfirmation}
            onShowClick={toggleOverlayShow1}
            ref={searchInputRefL1}
          />

          <BoardScrollableDivOrganigramma>
            {/* Map through L1 data (static for now, could be fetched from an API) */}
            {/* TODO: Replace with actual L1 data */}
            {filteredL1Data.map((item) => (
              <FileButtonWhite key={item.id} isActive={selectedL1 === item.id} onClick={() => handleSelectL1(item.id)}>
                <FileButtonWhiteText>{item.text}</FileButtonWhiteText>
                <FileButtonArrowGrey src="/arrow-line-right-grey.svg" alt="Arrow Icon" />
              </FileButtonWhite>
            ))}
          </BoardScrollableDivOrganigramma>
        </MainBoardContentContainerFileOgranigramma>
      </MainBoardContentContainerColumn>

      {/* Repeat the structure for L2, L3, and L4, mapping through l2Data, l3Data, and l4Data respectively */}
      {/* and using handleSelectL2, handleSelectL3 for onClick handlers */}

      {/* L2 Column */}
      <MainBoardContentContainerColumn>
        <MainBoardContentContainerFileOgranigramma>
          <Title>
            <TitleText>L2: UNITA' OPERATIVE</TitleText>
          </Title>
          <SearchBarSmall searchTerm={searchTermL2} onChange={handleSearchChange("L2")} onShowClick={handleShowL2} ref={searchInputRefL2} />

          <BoardScrollableDivOrganigramma>
            {/* Map through L1 data (static for now, could be fetched from an API) */}
            {/* TODO: Replace with actual L1 data */}
            {filteredL2Data.map((item) => (
              <FileButtonWhite key={item.id} isActive={selectedL2 === item.id} onClick={() => handleSelectL2(item.id)}>
                <FileButtonWhiteText>{item.text}</FileButtonWhiteText>
                <FileButtonArrowGrey src="/arrow-line-right-grey.svg" alt="Arrow Icon" />
              </FileButtonWhite>
            ))}
          </BoardScrollableDivOrganigramma>
        </MainBoardContentContainerFileOgranigramma>
      </MainBoardContentContainerColumn>
      {/* L3 Column */}
      <MainBoardContentContainerColumn>
        <MainBoardContentContainerFile>
          <Title>
            <TitleText>L3: RUOLI</TitleText>
          </Title>
          <SearchBarSmall searchTerm={searchTermL3} onChange={handleSearchChange("L3")} onShowClick={handleShowL3} ref={searchInputRefL3} />

          <BoardScrollableDivOrganigramma>
            {/* Map through L1 data (static for now, could be fetched from an API) */}
            {/* TODO: Replace with actual L1 data */}
            {filteredL3Data.map((item) => (
              <FileButtonWhite key={item.id} isActive={selectedL3 === item.id} onClick={() => handleSelectL3(item.id)}>
                <FileButtonWhiteText>{item.text}</FileButtonWhiteText>
                <FileButtonArrowGrey src="/arrow-line-right-grey.svg" alt="Arrow Icon" />
              </FileButtonWhite>
            ))}
          </BoardScrollableDivOrganigramma>
        </MainBoardContentContainerFile>
      </MainBoardContentContainerColumn>
      {/* L4 Column */}
      <MainBoardContentContainerColumn>
        <MainBoardContentContainerFile>
          <Title>
            <TitleText>L4: PERSONALE</TitleText>
          </Title>
          <SearchBarSmall searchTerm={searchTermL4} onChange={handleSearchChange("L4")} onShowClick={handleShowL4} ref={searchInputRefL4} />

          <BoardScrollableDivOrganigramma>
            {/* Map through L1 data (static for now, could be fetched from an API) */}
            {/* TODO: Replace with actual L1 data */}
            {filteredL4Data.map((item) => (
              <FileButtonWhite key={item.id} isActive={selectedL4 === item.id} onClick={() => handleSelectL4(item.id)}>
                <FileButtonWhiteText>{item.text}</FileButtonWhiteText>
                <FileButtonArrowGrey src="/arrow-line-right-grey.svg" alt="Arrow Icon" />
              </FileButtonWhite>
            ))}
          </BoardScrollableDivOrganigramma>
        </MainBoardContentContainerFile>
      </MainBoardContentContainerColumn>

      <OverlayContext.Provider
        value={{
          closeOverlay: closeOverlayAdd1,
          selectedL1,
          setSelectedL1,
          selectedL2,
          setSelectedL2,
          selectedL3,
          setSelectedL3,
          selectedL4,
          setSelectedL4,
        }}
      >
        {isOverlayVisibleAdd1 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd1}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd1} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>
            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>

      <OverlayContext.Provider
        value={{
          closeOverlay: closeOverlayEdit1,
          selectedL1,
          setSelectedL1,
          selectedL2,
          setSelectedL2,
          selectedL3,
          setSelectedL3,
          selectedL4,
          setSelectedL4,
        }}
      >
        {isOverlayVisibleEdit1 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingEdit1}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayEdit1} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>

      <OverlayContext.Provider
        value={{
          closeOverlay: closeOverlayShow1,
          selectedL1,
          setSelectedL1,
          selectedL2,
          setSelectedL2,
          selectedL3,
          setSelectedL3,
          selectedL4,
          setSelectedL4,
        }}
      >
        {isOverlayVisibleShow1 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingShow1}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayShow1} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>

      <OverlayContext.Provider
        value={{
          closeOverlay: closeOverlayAdd2,
          selectedL1,
          setSelectedL1,
          selectedL2,
          setSelectedL2,
          selectedL3,
          setSelectedL3,
          selectedL4,
          setSelectedL4,
        }}
      >
        {isOverlayVisibleAdd2 && (
          <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd2}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>
      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      {
isConfirmDialogVisible && (
  <Backdrop>
    <MessageContainer>
      <p><MessageboxText>Sei sicuro di voler rimuovere questa componente organizzativa?</MessageboxText></p>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
        <CloseMessageButton onClick={() => setIsConfirmDialogVisible(false)}>
          <MessageboxText>No</MessageboxText>
        </CloseMessageButton>
        <CloseMessageButton onClick={handleDelete}>
          <MessageboxText>Rimuovi</MessageboxText>
        </CloseMessageButton>
      </div>
    </MessageContainer>
  </Backdrop>
)
}

      {/* {nestedData && (
        <div>
          <h2>API Data:</h2>
          <pre>{JSON.stringify(nestedData, null, 2)}</pre>
        </div>
      )} */}
    </MainBoardContentContainerOrganizzazione>
  );
};

export default CybersecurityOrganigramma;
