import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  AttachedFilesList,
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputButtonText,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  InputTextboxButton,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";

import { NavButtonWhiteText } from "../../../styled-components";

import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { addL4AttachedFilesInfo, addL4DocumentsAttached, addL4DocumentsPath, addL4fiscalCode } from "../L4-Add-recoil";
import { URL_FileManager, useApi } from "../../../../../API";
import { PATH_L4 } from "../../../../../docPaths";

export const useUploadFile = () => {
  const { post } = useApi();

  const upload = useCallback(
    async (dir: string, file: File, fileName: string) => {
      const formData = new FormData();
      formData.append("file", file, fileName);

      const url = `${URL_FileManager}${encodeURIComponent(dir)}&file=${encodeURIComponent(fileName)}`;

      try {
        const response = await post(url, formData);
        return response;
      } catch (error) {
        console.error(`Failed to upload file: ${fileName}`, error);
        throw error;
      }
    },
    [post]
  );

  return upload;
};

export const useHandleFileUpload = () => {
  const uploadFile = useUploadFile();

  // Adjust the signature to accept an array of File objects
  const handleFileUpload = useCallback(
    async (selectedFiles: File[] | null, dir = "directory") => {
      if (selectedFiles) {
        const uploadPromises = selectedFiles.map((file) => uploadFile(dir, file, file.name));

        try {
          await Promise.all(uploadPromises);
          console.log("All files uploaded successfully");
        } catch (error) {
          console.error("Error uploading one or more files:", error);
        }
      }
    },
    [uploadFile]
  );

  return handleFileUpload;
};

export const useInitiateUpload = () => {
  const docAttached = useRecoilValue(addL4DocumentsAttached);
  const docPath = useRecoilValue(addL4fiscalCode);
  const handleFileUpload = useHandleFileUpload();

  const initiateUpload = useCallback(async () => {
    console.log("Files to upload:", docAttached);

    if (docAttached && docAttached.length > 0) {
      try {
        await handleFileUpload(docAttached, PATH_L4+docPath);
        console.log("File(s) uploaded successfully.");
      } catch (error) {
        console.error("Error uploading file(s):", error);
      }
    } else {
      console.log("No files selected for upload.");
    }
  }, [docAttached, handleFileUpload]);

  return initiateUpload;
};

export const handleDownload = async (token: string | undefined | null) => {
  const dir = "people/person009";
  const fileName = "27.sql";
  const url = `${URL_FileManager}${encodeURIComponent(dir)}&file=${encodeURIComponent(fileName)}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorHeader = response.headers.get("X-App-Error");
      console.error("Error header:", errorHeader);
      throw new Error(`API request failed: ${response.statusText}`);
    }

    response.headers.forEach((value, key) => {
      console.log(`Response header: ${key} = ${value}`);
    });

    const data = await response.blob();
    const downloadUrl = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(downloadUrl);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

const MainComponent = () => {
  ////////////RECOIL

  //const [docPath, setDocPath] = useRecoilState(addL4DocumentsPath);
  const [docPath, setDocPath] = useRecoilState(addL4fiscalCode);
  const [docAttached, setDocAttached] = useRecoilState(addL4DocumentsAttached);
  const [filesInfo, setFilesInfo] = useRecoilState(addL4AttachedFilesInfo);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const removeFile = (index: number) => {
    // Remove file from docAttached and filesInfo based on index
    setDocAttached((currentFiles) => currentFiles.filter((_, i) => i !== index));
    setFilesInfo((currentFilesInfo) => currentFilesInfo.filter((_, i) => i !== index));
  };

  const handleButtonClick = () => {
    // Check if the current ref is not null before calling click
    if (fileInputRef.current !== null) {
      fileInputRef.current.value = ''; // Reset the value
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFileList = Array.from(files); // Convert FileList to Array

      // Append new files to the existing files
      setDocAttached((currentFiles) => [...currentFiles, ...newFileList]);

      const newFileInfoArray = newFileList.map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
      }));
      console.log("File info: ", newFileInfoArray);
      // Append new file info for display
      setFilesInfo((currentFilesInfo) => [...currentFilesInfo, ...newFileInfoArray]);
    }
  };

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Allega documenti</InputDescriptionText>
            <input style={{ display: "none" }} ref={fileInputRef} type="file" multiple onChange={handleFileChange} />

            <InputTextboxButton onClick={handleButtonClick}>
              {" "}
              <InputButtonText>Seleziona file dal computer</InputButtonText>{" "}
            </InputTextboxButton>
            <TriangleContainer>▼</TriangleContainer>
            <IconContainer />
          </InputContainer>

          {filesInfo.length > 0 && (
            <AttachedFilesList>
              {filesInfo.map(
                (file, index) =>
                  file && (
                    
                    <li key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <UploadFileIcon style={{ fontSize: "18px", color: "#cccccc", marginRight: "10px" }}/>
                      <span>{file.name}</span>
                      
                      <IconButton
                        onClick={() => removeFile(index)}
                        size="small"
                        style={{ marginLeft: "auto" }} // This will push the button to the right
                      >
                        <CancelIcon style={{ fontSize: "14px", color: "#cccccc" }} />
                      </IconButton>
                    </li>
                  )
              )}
            </AttachedFilesList>
          )}

          <div>{/* <button onClick={handleDownload}>Download File</button> */}</div>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Nome cartella documentazione</InputDescriptionText>
            <InputTextbox disabled={true} placeholder="" value={PATH_L4+docPath} onChange={(e) => setDocPath(e.target.value)} />
            <IconContainer />
          </InputContainer>
          {/* <button onClick={initiateUpload}>Upload Files</button> */}
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_docs.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
        Nella scheda è descritto il percorso della cartella principale dove archiviare i documenti dell’entità selezionata e utilizzare la funzione di allegato per inserire direttamente la documentazione rilevante per la compliance in Cybersecurity in riferimento all’entità, garantendo così un accesso immediato e organizzato ai file essenziali.

        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
