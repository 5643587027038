import { atom, selector } from 'recoil';
import dayjs from 'dayjs';

// SCHEDA GENERALE

export const ProcessiComplianceID = atom({
    key: 'ProcessiComplianceID', 
    default: '',
  });
  
export const ProcessiComplianceName = atom({
    key: 'ProcessiComplianceName', 
    default: '',
  });
  
  export const ProcessiComplianceKind = atom({
    key: 'ProcessiComplianceKind', 
    default: '',
  });
  
  export const ProcessiComplianceNota = atom({
    key: 'ProcessiComplianceNota', 
    default: '',
  });

  export const ProcessiComplianceRifNorm = atom({
    key: 'ProcessiComplianceRifNorm', 
    default: '',
  });
  
  export const ProcessiComplianceObjective = atom({
    key: 'ProcessiComplianceObjective', 
    default: '',
  });
  
  
  export const ProcessiComplianceDescription = atom({
    key: 'ProcessiComplianceDescription', 
    default: '',
  });

  // COMPLIANCE
  
 
  export const ProcessiComplianceComplianceDate = atom<dayjs.Dayjs | null>({
    key: 'ProcessiComplianceComplianceDate', 
    //default: dayjs(),
    default: null,
  });

  export const ProcessiComplianceIsDropdownOpenDirectors = atom<boolean>({
    key: 'ProcessiComplianceIsDropdownOpenDirectors', 
    default: false,
  });

  export const ProcessiComplianceComplianceResponsible = atom({
    key: 'ProcessiComplianceComplianceResponsible', 
    default: '',
  });
  
  export const ProcessiComplianceComplianceResponsibleID = atom({
    key: 'ProcessiComplianceComplianceResponsibleID', 
    default: '',
  });
  

// DOCUMENTAZIONE

export const ProcessiComplianceDocumentsPath = atom({
    key: 'ProcessiComplianceDocumentsPath', 
    default: '',
  });
  
 
  export const ProcessiComplianceDocumentsAttached = atom<File[]>({
    key: 'ProcessiComplianceDocumentsAttached',
    default: [],
  });
  
  
  export const ProcessiComplianceAttachedFilesInfo = atom<Array<{ name: string, size: number, type: string } | null>>({
    key: 'ProcessiComplianceAttachedFilesInfo',
    default: [],
  });

  // EXTRA
    
  export type LV1DataItem = {
    LV1_id: string;
    LV1_name: string;
  };
  
  export const LV1DataItemState = atom<LV1DataItem[]>({
    key: 'LV1DataItemState', // Unique ID (with respect to other atoms/selectors)
    default: [], // Default value (aka initial value)
  });

  export type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };
  export const directorDataState = atom<DirectorDataItem[]>({
    key: 'directorDataState', // Unique ID (with respect to other atoms/selectors)
    default: [], // Default value (aka initial value)
  });

  export const ProcessiComplianceOrganizzazioneSelectedL1 = atom({
    key: 'ProcessiComplianceOrganizzazioneSelectedL1', 
    default: '',
  });

  export const ProcessiComplianceOrganizzazioneSelectedL1ID = atom({
    key: 'ProcessiComplianceOrganizzazioneSelectedL1ID', 
    default: '',
  });

  export const ProcessiCompliance_2_isDropdownOpenLV1 = atom({
    key: 'ProcessiCompliance_2_isDropdownOpenLV1', 
    default: false,
  });

  export const ProcessiComplianceConfirmedLV1List = atom<string>({
    key: 'ProcessiComplianceConfirmedLV1List', // Unique ID (with respect to other atoms/selectors)
    default: '', // Updated default value to be an empty object
  });
  
  export const ProcessiComplianceIsDropdownOpenDirectorsCompliance = atom<boolean>({
    key: 'ProcessiComplianceIsDropdownOpenDirectorsCompliance', 
    default: false,
  });
  
  export const ProcessiComplianceDeleteConfirmationDialog = atom<boolean>({
    key: 'ProcessiComplianceDeleteConfirmationDialog', 
    default: false,
  });