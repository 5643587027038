import { atom, selector } from 'recoil';
import dayjs from 'dayjs';

// SCHEDA GENERALE

export const LuoghiID = atom({
    key: 'LuoghiID', 
    default: '',
  });
  
export const LuoghiName = atom({
    key: 'LuoghiName', 
    default: '',
  });
  
  export const LuoghiIdentificativo = atom({
    key: 'LuoghiIdentificativo', 
    default: '',
  });
  
 
  
  export const LuoghiNomeAbbreviato = atom({
    key: 'LuoghiNomeAbbreviato', 
    default: '',
  });
  
  
  export const LuoghiIndirizzo = atom({
    key: 'LuoghiIndirizzo', 
    default: '',
  });

  
  export const LuoghiEmail = atom({
    key: 'LuoghiEmail', 
    default: '',
  });
  
  
  export const LuoghiTelefono = atom({
    key: 'LuoghiTelefono', 
    default: '',
  });

  export const LuoghiFax = atom({
    key: 'LuoghiFax', 
    default: '',
  });


  
  export const LuoghiComplianceDate = atom<dayjs.Dayjs | null>({
    key: 'LuoghiComplianceDate', 
    default: dayjs(),
  });
  

// DOCUMENTAZIONE

export const LuoghiDocumentsPath = atom({
    key: 'LuoghiDocumentsPath', 
    default: '',
  });
  
 
  export const LuoghiDocumentsAttached = atom<File[]>({
    key: 'LuoghiDocumentsAttached',
    default: [],
  });
  
  
  export const LuoghiAttachedFilesInfo = atom<Array<{ name: string, size: number, type: string } | null>>({
    key: 'LuoghiAttachedFilesInfo',
    default: [],
  });

  // ORGANIZZAZIONE

  export type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };
  export const directorDataState = atom<DirectorDataItem[]>({
    key: 'directorDataState', // Unique ID (with respect to other atoms/selectors)
    default: [], // Default value (aka initial value)
  });
  
  export type LV1DataItem = {
    LV1_id: string;
    LV1_name: string;
  };
  
  export const LV1DataItemState = atom<LV1DataItem[]>({
    key: 'LV1DataItemState', // Unique ID (with respect to other atoms/selectors)
    default: [], // Default value (aka initial value)
  });

  export const LuoghiOrganizzazioneSelectedL1 = atom({
    key: 'LuoghiOrganizzazioneSelectedL1', 
    default: '',
  });

  export const LuoghiOrganizzazioneSelectedL1ID = atom({
    key: 'LuoghiOrganizzazioneSelectedL1ID', 
    default: '',
  });
  
  export const addLuoghi_2_isDropdownOpenLV1 = atom({
    key: 'addLuoghi_2_isDropdownOpenLV1', 
    default: false,
  });

  export const addLuoghiConfirmedLV1List = atom<string>({
    key: 'addLuoghiConfirmedLV1List', // Unique ID (with respect to other atoms/selectors)
    default: '', // Updated default value to be an empty object
  });
  
  export const addLuoghi_2_isDropdownOpenDirectors = atom<boolean>({
    key: 'addLuoghi_2_isDropdownOpenDirectors', 
    default: false,
  });
  
  export const addLuoghiOrganizzazioneDirettore = atom({
    key: 'addLuoghiOrganizzazioneDirettore', 
    default: '',
  });
  
  export const addLuoghiOrganizzazioneDirettoreID = atom({
    key: 'addLuoghiOrganizzazioneDirettoreID', 
    default: '',
  });

  export const addLuoghiOrganizzazioneDirettoreCompliance = atom({
    key: 'addLuoghiOrganizzazioneDirettoreCompliance', 
    default: '',
  });
  
  export const addLuoghiOrganizzazioneDirettoreComplianceID = atom({
    key: 'addLuoghiOrganizzazioneDirettoreComplianceID', 
    default: '',
  });

  export const addLuoghi_2_isDropdownOpenDirectorsCompliance = atom<boolean>({
    key: 'addLuoghi_2_isDropdownOpenDirectorsCompliance', 
    default: false,
  });
  
  export const addLuoghiDeleteConfirmationDialog = atom<boolean>({
    key: 'addLuoghiDeleteConfirmationDialog', 
    default: false,
  });