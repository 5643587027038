import React, { useState } from 'react';
import styled from 'styled-components';
import { useKeycloak } from '@react-keycloak/web';
import { useLocation } from 'react-router-dom';

import {
 
  FrameNavLogo,
  PicLogoIcon,
  FrameTopGradient,
  ButtonIsoActiveText,
  ButtonIsoActive,
  ButtonIsoInactiveText,
  ButtonIsoInactive,
  FrameButtonIso,
  FrameUserSearch,
  FrameUserSearchAuto,
  ButtonUser,
  ButtonNotification,
  IcoUserNotification,
  FrameLeftNav,
  FrameNavButtons,
  NavButtonWhite,
  NavButtonWhiteFocused,
  NavButtonWhiteText,
  NavButtonWhiteChild,
  NavButtonWhiteChildText,
  NavButtonWhiteChildFocused,
  NavButtonBlue,
  NavButtonBlueFocused,
  NavButtonBlueText,
  NavButtonBlueChild,
  NavButtonBlueChildFocused,
  ArrowGrey,
  ArrowGreyRight,
  ArrowWhite,
  ArrowWhiteRight,
  LineMagenta40,
  LineMagenta50,
  FrameButtonIcon,
  IcoButtonIcon,
  FrameNavWorkspace,
  WorkspaceLine1,
  WorkspaceLine2,
  FrameBoardMain,
  PageRoot,
  ScrollableDiv,
  ButtonGrey,
  MainBoardSubMenuButtonContainer,
  MainBoardSubMenuButton
 
  } from "../styled-components";
import ApiCheck from '../../check-API';

 
  const Board27001Organizzazione: React.FC = () => {
  
    const { keycloak } = useKeycloak();

    const onLogout = () => {
      keycloak.logout({ redirectUri: window.location.origin });
    };
  

    const location = useLocation();
   
    const isActive = (path: string) => {
      // Check if the current location is the index route of Organizzazione
      return location.pathname === "/Cybersecurity/Monitoraggio" && path === "Monitoraggio";
    };
    
    const ImageContainer = styled.div`
    display: flex;
    justify-content: center; // Centers the child horizontally
    align-items: center; // Centers the child vertically
    height: 100%; // Adjust as needed, could be a specific height like 400px
    width: 100%; // Adjust as needed
    margin-top: 30px;
  `;

    const StyledImage = styled.img`
  max-width: 80%; // Ensures the image is responsive and fits its container
  height: auto; // Maintains the aspect ratio of the image
  //border-radius: 0px; // Optional: Adds rounded corners to the image
  //box-shadow: 0 4px 6px rgba(0,0,0,0.1); // Optional: Adds a subtle shadow around the image
`;

    return (
    
      <FrameBoardMain>

<MainBoardSubMenuButtonContainer>
    <MainBoardSubMenuButton to="/Cybersecurity/Monitoraggio" className={isActive("Monitoraggio") ? "active" : ""}>Monitoraggio</MainBoardSubMenuButton>
    {/* <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/Clienti">Clienti</MainBoardSubMenuButton>
    <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/Fornitori">Fornitori</MainBoardSubMenuButton>
    <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/AltriEnti">Altri enti</MainBoardSubMenuButton> */}

    </MainBoardSubMenuButtonContainer>
  
<ImageContainer>
    <StyledImage src={'../monitoraggio.png'} alt={'alt'} />
    </ImageContainer>

    </FrameBoardMain>
      
    );
  };

  export default Board27001Organizzazione;