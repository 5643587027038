import { atom, selector } from 'recoil';
import dayjs from 'dayjs';

// SCHEDA GENERALE
export const addL4PersonID = atom({
    key: 'addL4PersonID', 
    default: '',
  });
  

export const addL4Surname = atom({
    key: 'addL4Surname', 
    default: '',
  });
  
  export const addL4Name = atom({
    key: 'addL4Name', 
    default: '',
  });
  
  
  export const addL4birthDate = atom<dayjs.Dayjs | null>({
    key: 'addL4birthDate', 
    //default: dayjs(),
    default: null,
  });
  
  
  export const addL4birthPlace = atom({
    key: 'addL4birthPlace', 
    default: '',
  });
  
  
  export const addL4gender = atom({
    key: 'addL4gender', 
    default: '',
  });
  
  
  export const addL4fiscalCode = atom({
    key: 'addL4fiscalCode', 
    default: '',
  });
  
  
  export const addL4residenceAddress = atom({
    key: 'addL4residenceAddress', 
    default: '',
  });
  
  export const addL4domicileAddress = atom({
    key: 'addL4domicileAddress', 
    default: '',
  });
  
  
  export const addL4workEmail = atom({
    key: 'addL4workEmail', 
    default: '',
  });
  
  
  export const addL4personalEmail = atom({
    key: 'addL4personalEmail', 
    default: '',
  });
  
  
  export const addL4workPhone = atom({
    key: 'addL4workPhone', 
    default: '',
  });
  
  
  export const addL4privatePhone = atom({
    key: 'addL4privatePhone', 
    default: '',
  });

// DOCUMENTAZIONE

export const addL4DocumentsPath = atom({
    key: 'addL4DocumentsPath', 
    default: '',
  });
  
 
  export const addL4DocumentsAttached = atom<File[]>({
    key: 'addL4DocumentsAttached',
    default: [],
  });
  
  
  export const addL4AttachedFilesInfo = atom<Array<{ name: string, size: number, type: string } | null>>({
    key: 'addL4AttachedFilesInfo',
    default: [],
  });

