import React, { useState, useEffect, useRef, useContext } from "react";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import  dayjs  from "dayjs";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  addL2SchedaGeneraleNomeCompleto,
  addL2SchedaGeneraleNomeAbbreviato,
  addL2SchedaGeneraleEmail,
  addL2SchedaGeneraleTelefono,
  addL2SchedaGeneraleFax,
  addL2SchedaGeneralePEC,
  addL2SchedaGeneraleReferente,
  addL1SchedaGeneraleIndirizzo,
  addL2OrganizzazioneModello,
  addL2AttachedFilesInfo,
  addL2ComplianceDate,
  addL2ComplianceDirettoreCompliance,
  addL2ComplianceDirettoreComplianceID,
  addL2DocumentsAttached,
  addL2DocumentsPath,
  addL2OrganizzazioneDH,
  addL2OrganizzazioneDirettore,
  addL2OrganizzazioneDirettoreID,
  addL2OrganizzazioneOrdinaria,
  addL2OrganizzazionePersonale,
  addL2OrganizzazioneSelectedL1,
  addL2OrganizzazioneSelectedL1ID,
  addL2SchedaGeneraleIndirizzo,



} from "../../../../recoil";

import SchedaGenerale from "./cybersecurity-board-org-org-L4-Show-sub/organigramma-L4-Show-1-SchedaGenerale";
import Organizzazione from "./cybersecurity-board-org-org-L4-Show-sub/organigramma-L4-Show-2-Organizzazione";
import Infrastruttura from "./cybersecurity-board-org-org-L4-Show-sub/organigramma-L4-Show-3-Infrastruttura";
import Processi from "./cybersecurity-board-org-org-L4-Show-sub/organigramma-L4-Show-4-Processi";
import Parti from "./cybersecurity-board-org-org-L4-Show-sub/organigramma-L4-Show-3-Infrastruttura";
import Compliance from "./cybersecurity-board-org-org-L4-Show-sub/organigramma-L4-Show-6-GestioneCompliance";
import Documentazione from "./cybersecurity-board-org-org-L4-Show-sub/organigramma-L4-Show-7-Documentazione";

import {
  FrameNavLogo,
  PicLogoIcon,
  FrameTopGradient,
  ButtonIsoActiveText,
  ButtonIsoActive,
  ButtonIsoInactiveText,
  ButtonIsoInactive,
  FrameButtonIso,
  FrameUserSearch,
  FrameUserSearchAuto,
  ButtonUser,
  ButtonNotification,
  IcoUserNotification,
  FrameLeftNav,
  FrameNavButtons,
  NavButtonWhite,
  NavButtonWhiteFocused,
  NavButtonWhiteText,
  NavButtonWhiteChild,
  NavButtonWhiteChildText,
  NavButtonWhiteChildFocused,
  NavButtonBlue,
  NavButtonBlueFocused,
  NavButtonBlueText,
  NavButtonBlueChild,
  NavButtonBlueChildFocused,
  ButtonGreen,
  ButtonGrey,
  ButtonText,
  ArrowGrey,
  ArrowGreyRight,
  ArrowWhite,
  ArrowWhiteRight,
  LineMagenta40,
  LineMagenta50,
  FrameButtonIcon,
  IcoButtonIcon,
  FrameNavWorkspace,
  WorkspaceLine1,
  WorkspaceLine2,
  FrameBoardMain,
  PageRoot,
  ScrollableDiv,
  MainBoardSubMenuButton,
  MainBoardSubMenuButtonContainer,
  MainBoardContentContainer,
  MainBoardSubMenuButtonContainerOrganigramma,
} from "../../styled-components";

import {
  FrameTopGradient100,
  SearchBarWithIconsRoot,
  Search,
  LineDivider,
  IconButton,
  BackgroundIcon,
  MainBoardContentContainerColumn,
  MainBoardContentContainerFile,
  FileButtonArrowGrey,
  FileButtonWhiteText,
  SearchGroup,
  IconsGroup,
  Title,
  TitleText,
  BoardScrollableDiv,
  ButtonClose,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteTitleAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  InputTextbox,
  CreaContainerColumn,
  FlexContainerCrea,
  InputContainer,
  IconContainer,
  InputDescriptionText,
  SubMenuButtonContainerOrganigrammaNoScroll,
  CreaSubMenuButton,
  SubMenuButtonContainerOrganigramma,
  Backdrop,
  MessageContainer,
  CloseMessageButton,
  MessageboxText,
  BackdropTransparent,
  DescriptionContainerCrea,
  DescriptionContainerCreaText,
  IconSquare,
  CreaSubMenuButtonMultiple,
  SubMenuButtonContainerOrganigrammaMultiple,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";
import { URL_DirectorList, useApi, useToken } from "../../../../API";
import { showL4ComponenteOrganizzativa, showL4DocPath, showL4Name, showL4RuoliEnte, showL4SuperioreDiretto, showL4Surname, showL4Title, showL4UnitaOperative, showL4birthDate, showL4birthPlace, showL4domicileAddress, showL4fiscalCode, showL4gender, showL4idDocumentNumber, showL4personalEmail, showL4privatePhone, showL4residenceAddress, showL4workEmail, showL4workPhone } from "./L4-Show-recoil";
import { useOverlayControlL4Show } from "./cybersecurity-board-organizzazione-persone";


const YourAddL1Component = () => {


  const [surname, setSurname] = useRecoilState(showL4Surname);
  const [name, setName] = useRecoilState(showL4Name);
  const [birthDate, setBirthDate] = useRecoilState(showL4birthDate);
  const [birthPlace, setBirthPlace] = useRecoilState(showL4birthPlace);
  const [gender, setGender] = useRecoilState(showL4gender);
  const [fiscalCode, setFiscalCode] = useRecoilState(showL4fiscalCode);
  const [residenceAddress, setResidenceAddress] = useRecoilState(showL4residenceAddress);
  const [domicileAddress, setDomicileAddress] = useRecoilState(showL4domicileAddress);
  const [workEmail, setWorkEmail] = useRecoilState(showL4workEmail);
  const [personalEmail, setPersonalEmail] = useRecoilState(showL4personalEmail);
  const [workPhone, setWorkPhone] = useRecoilState(showL4workPhone);
  const [privatePhone, setPrivatePhone] = useRecoilState(showL4privatePhone);
  const [docPath, setDocPath] = useRecoilState(showL4DocPath);
  const [Title, setTitle] = useRecoilState(showL4Title);
  
  const [idDocumentNumber, setIdDocumentNumber] = useRecoilState(showL4idDocumentNumber);
  const [ruoliEnte, setRuoliEnte] = useRecoilState(showL4RuoliEnte);
  const [unitaOperative, setUnitaOperative] = useRecoilState(showL4UnitaOperative);
  const [superioreDiretto, setSuperioreDiretto] = useRecoilState(showL4SuperioreDiretto);
  const [componenteOrganizzativa, setComponenteOrganizzativa] = useRecoilState(showL4ComponenteOrganizzativa);


  // GLOBAL SHOW L4 PROPAGATION

  const { toggleOverlayL4, isVisibleL4, isAnimatingL4, closeOverlayL4 } = useOverlayControlL4Show("/Cybersecurity/Organizzazione/Persone/VisualizzaL4/1");

  //const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const token = useToken(); // Call useToken here at the top level
  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");


  /////////GET DIRECTORS////////////////

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch data from the API
        const response = await fetch(
          URL_DirectorList,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response Data:", responseData);

        // Assuming responseData is the array you want to map
        const transformedData: DirectorDataItem[] = responseData.map(
          (item: DirectorApiContentItem) => {
            return {
              director_id: item.id,
              director_name: item.alias, // Changed from 'site_name' to 'name'

              // Map other properties as needed
            };
          }
        );

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data on component mount or when token changes
    fetchData();
  }, [token]); // Depend on the token

  ////////////////////

  /////FILTER INPUT DIRECTORS

  // Create a map from director names to IDs
  const directorNameToId = new Map(
    data.map((item) => [item.director_name, item.director_id])
  );

 
  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); // Close the message box
    //closeOverlay();
  
  };

  ///////////////// MESSAGE ANNULLA BUTTON /////////

  const handleClose = () => {

    closeOverlayL4();
    
    setSurname('');
    setName('');
    setBirthDate(dayjs());
    setBirthPlace('');
    setGender('');
    setFiscalCode('');
    setResidenceAddress('');
    setDomicileAddress('');
    setWorkEmail('');
    setPersonalEmail('');
    setWorkPhone('');
    setPrivatePhone('');
    setTitle('');
    
  };

  ////////////////////CHANGE ACTIVE COMPONENT///////////
  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      case "Organizzazione":
        return <Organizzazione />;
      case "Infrastruttura":
        return <Infrastruttura />;
      case "ProcessiGenerali":
        return <Processi />;
      case "PartiInteressate":
        return <Parti />;
      case "SistemaCompliance":
        return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };


  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlayL4();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>
       SCHEDA PERSONALE DI {Title.toUpperCase()}
      </OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SchedaGenerale")}
            to="/Cybersecurity/Organizzazione/Persone/VisualizzaL4/1"
          >
            Scheda generale
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Organizzazione")}
            to="/Cybersecurity/Organizzazione/Persone/VisualizzaL4/2"
          >
            Organizzazione
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/Organizzazione/Persone/VisualizzaL4/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/Organizzazione/Persone/VisualizzaL4/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Organizzazione/UnitaOperative/CreaL2/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SistemaCompliance")}
            to="/Cybersecurity/Organizzazione/Persone/VisualizzaL4/6"
          >
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Documentazione")}
            to="/Cybersecurity/Organizzazione/Persone/VisualizzaL4/7"
          >
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>

        {renderActiveComponent()}
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={handleClose}>
          <ButtonText>Chiudi</ButtonText>
        </ButtonGrey>
      
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default YourAddL1Component;
