import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
  useCallback,
  ChangeEvent,
} from "react";
import styled from "styled-components";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione"; // Correct path
import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Autocomplete,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { Checkbox } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  AttachedFilesList,
  Backdrop,
  BackdropTransparent,
  BackgroundIcon,
  CloseMessageButton,
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FileListScrollbar,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  IconsGroup,
  InputContainer,
  InputContainerRoles,
  InputDescriptionText,
  InputDescriptionTextRoles,
  InputTextboxButton,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
  RoleListDescriptionScrollbar,
  RoleListScrollbar,
  Search,
  SearchRoles,
  TriangleContainer,
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  isLoadingRec,
  isMessageBoxVisibleClosingRec,
  isMessageBoxVisibleRec,
  reFetchIndicatorL3,
  SelectedL3ID,
  SelectedL4ID,
  setErrorRec,
  setMessageRec,
} from "../../../../../recoil";

import {
  ArrowButtonText,
  ButtonGreen,
  ButtonGrey,
  ButtonText,
} from "../../../styled-components";
import {
  URL_DirectorList,
  URL_Model,
  URL_PersonRoles,
  URL_RoleList,
  URL_RoleMOdelsAssociation,
  URL_RoleModels,
  URL_RoleShort,
  URL_UnitProcessRolePerson,
  useApi,
  useToken,
} from "../../../../../API";

import {
  GroupedModel,
  Role,
  addL3ComlpianceDataState,
  addL3ComplianceData,
  addL3Model,
  addL3ModelDataState,
  addL3Role,
  addL3RoleDataState,
  addL3RoleID,
  addL3RoleListData,
  addL3RoleListDataID,
  addL3RoleState,
  addL3electedModelCheckboxesState,
  addL3searchTerm,
  addL3searchTermModelliRuoli,
  addL3selectedAggrId,
  addL3selectedCheckboxesProcessiState,
  addL3selectedModelId,
  addL3selectedModelName,
  addL3selectedModelRadioId,
  roleDataInterface,
} from "../L3-Add-recoil";
import {
  SearchIcon,
  SearchInputContainer,
  SearchInputContainerSolo,
  SearchInputContainerSoloRoles,
  TopContainer,
  TopContainerRoles,
} from "../cybersecurity-board-organizzazione-unitaoperative-styled-components";
import { useOverlayControlL3Show } from "../cybersecurity-board-organizzazione-ruoligenerali";
import { TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_L3_1 } from "../../tabs-description";

// INTERFACES

interface RolesAccordionProps {}

interface ModelSelectorProps {
  models: GroupedModel[];
  selectedModelId: string;
  onModelChange: (newModelId: string) => void;
}
/////////////////////NEW

interface OrganizationRole {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  has_organization_model: string;
  has_role: string;
  organization_model_name: string;
  role_description: string;
  role_long_description: string;
  is_responsible: boolean;
}

///////////// POPUP

interface LastPopupProps {
  isVisible: boolean;
}

const LastPopupBlack = styled.div<LastPopupProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  //border: 5px solid #ffffff;
  z-index: 100;
`;

///////////// POPUP END

const useFetchModelData = (token: string | null | undefined) => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [modelData, setModelData] = useRecoilState(addL3ModelDataState);
  const [roleData, setRoleData] = useRecoilState(addL3RoleDataState);
  const [selectedModelRadioId, setSelectedModelRadioId] = useRecoilState(
    addL3selectedModelRadioId
  );
  const [selectedModelCheckboxes, setSelectedModelCheckboxes] = useRecoilState(
    addL3electedModelCheckboxesState
  );

  const [reFetchIndicator, setRefetchIndicator] =
    useRecoilState(reFetchIndicatorL3);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      if (!token) throw new Error("User is not authenticated");
      const response = await fetch(`${URL_RoleModels}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }
      const responseData: OrganizationRole[] = await response.json();
      const groupedModels: { [key: string]: GroupedModel } = {};

      const rolesMap = new Map<string, Role>();

      responseData.forEach((item) => {
        if (!groupedModels[item.has_organization_model]) {
          groupedModels[item.has_organization_model] = {
            id: item.has_organization_model,
            name: item.organization_model_name,

            roles: [],
            responsibleRole: {} as Role,
          };
        }
        const newRole: Role = {
          id: item.has_role,
          description: item.role_description,
          has_organization_model: item.has_organization_model,
          longDescription: item.role_long_description,
          isResponsible: item.is_responsible,
        };

        // Update the roles map with new or updated role information
        rolesMap.set(newRole.id, newRole);

        groupedModels[item.has_organization_model].roles.push(newRole);
        if (item.is_responsible) {
          groupedModels[item.has_organization_model].responsibleRole = newRole;
        }
      });

      const sortedModels = Object.values(groupedModels).sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setModelData(Object.values(sortedModels));
      setRoleData(Array.from(rolesMap.values())); // Convert roles map to array
      // Initialize checkbox states
      // Initialize checkbox states without adding responsible roles
      setSelectedModelCheckboxes((prevCheckboxes) => {
        const updatedCheckboxes = { ...prevCheckboxes };
        sortedModels.forEach((model) => {
          updatedCheckboxes[model.id] = [];
        });
        return updatedCheckboxes;
      });

      setSelectedModelRadioId(Object.values(sortedModels)[0].id);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  }, [token, setIsLoading, setModelData, setError, reFetchIndicator]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    modelData,
  };
};

// MODEL SELECTOR

const ModelSelector: React.FC<ModelSelectorProps> = ({
  models,
  onModelChange,
  selectedModelId,
}) => {
  // Sort models alphabetically by 'name'
  const sortedModels = useMemo(() => {
    return [...models].sort((a, b) => a.name.localeCompare(b.name));
  }, [models]);

  return (
    <FormControl component="fieldset">
      <FormLabel
        component="legend"
        sx={{ color: "#6f8aab", padding: "8px" }}
      ></FormLabel>
      <RadioGroup
        aria-label="model"
        name="model"
        value={selectedModelId}
        onChange={(event) => onModelChange(event.target.value)}
        sx={{ padding: "5px 10px 10px 20px" }}
      >
        {sortedModels.map((model) => (
          <FormControlLabel
            key={model.id}
            value={model.id}
            control={
              <Radio
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#6f8aab" },
                }}
              />
            }
            label={model.name} // Use organizational_unit.prefLabel as label
            sx={{
              color: "#6f8aab",
              "& .MuiTypography-root": { fontSize: "0.875rem" },
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

// MODEL SELECTOR END

// ACCORDION

const RolesAccordion: React.FC<RolesAccordionProps> = () => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [searchTermModelliRuoli, setSearchTermModelliRuoli] = useRecoilState(
    addL3searchTermModelliRuoli
  );
  const roles = useRecoilValue(addL3RoleDataState);
  const [selectedModelRadioId, setSelectedModelRadioId] = useRecoilState(
    addL3selectedModelRadioId
  );

  const [selectedModelCheckboxes, setSelectedModelCheckboxes] = useRecoilState(
    addL3electedModelCheckboxesState
  );

  const sortedRoles = useMemo(() => {
    return [...roles].sort((a, b) => {
      // Check if either role is the responsible role
      const isAResponsible =
        a.isResponsible && a.has_organization_model === selectedModelRadioId;
      const isBResponsible =
        b.isResponsible && b.has_organization_model === selectedModelRadioId;

      // Responsible roles always come first
      if (isAResponsible && !isBResponsible) {
        return -1;
      }
      if (!isAResponsible && isBResponsible) {
        return 1;
      }

      // If neither or both are responsible, sort alphabetically
      return a.description
        .toLowerCase()
        .localeCompare(b.description.toLowerCase());
    });
  }, [roles, selectedModelRadioId]);

  const filteredRoles = useMemo(() => {
    return sortedRoles.filter(
      (role) =>
        role.description
          .toLowerCase()
          .includes(searchTermModelliRuoli.toLowerCase()) ||
        role.longDescription
          .toLowerCase()
          .includes(searchTermModelliRuoli.toLowerCase())
    );
  }, [sortedRoles, searchTermModelliRuoli]);

  const isRoleChecked = (role: Role) => {
    return (
      selectedModelCheckboxes[selectedModelRadioId]?.includes(role.id) ||
      role.isResponsible
    );
  };

  const handleCheckboxChange = useCallback(
    (role: Role, checked: boolean) => {
      setSelectedModelCheckboxes((prev) => {
        const currentCheckboxes = new Set(prev[selectedModelRadioId] || []);
        if (checked) {
          currentCheckboxes.add(role.id);
        } else {
          currentCheckboxes.delete(role.id);
        }
        return {
          ...prev,
          [selectedModelRadioId]: Array.from(currentCheckboxes),
        };
      });
    },
    [setSelectedModelCheckboxes, selectedModelRadioId]
  );

  useEffect(() => {
    // Collect all responsible role IDs for the current model
    const initiallySelected = roles
      .filter(
        (role) =>
          role.isResponsible &&
          role.has_organization_model === selectedModelRadioId
      )
      .map((role) => role.id);

    // Update the selectedModelCheckboxes state only for the current model
    setSelectedModelCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [selectedModelRadioId]: initiallySelected,
    }));
  }, []);

  if (!roles) return null;

  return (
    <Box sx={{ mt: 2 }}>
      {filteredRoles.map((role) => (
        <Accordion key={role.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color="#04446c">
              {" "}
              {role.isResponsible &&
              role.has_organization_model == selectedModelRadioId
                ? `Responsabile: ${role.description}`
                : role.description}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ flexDirection: "column" }}>
            <Typography>
              {role.longDescription || "No detailed description available."}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRoleChecked(role)}
                  onChange={(e) => handleCheckboxChange(role, e.target.checked)}
                  inputProps={{ "aria-label": "Is Responsible" }}
                  disabled={
                    (role.isResponsible &&
                      role.has_organization_model == selectedModelRadioId) ||
                    false
                  }
                  sx={{
                    color: "#04446c",
                    "& .MuiSvgIcon-root": { fontSize: 16 },
                    "&.Mui-checked": { color: "#04446c" },
                    padding: "0px",
                    marginRight: "10px",
                  }}
                />
              }
              label="Questo ruolo é associato al modello selezionato"
              sx={{
                marginTop: "10px", // Adjust top margin as needed

                marginLeft: "0px",
                color: "#04446c", // Change label color using the theme's color palette
                display: "flex", // Ensures the label and checkbox are in a flex container
                flexDirection: "row", // Aligns the label and checkbox horizontally
                justifyContent: "flex-start", // Aligns the checkbox and label to the start of the flex container
                alignItems: "center", // Centers the label and checkbox vertically
                // Custom label positioning can be adjusted here
              }}
              labelPlacement="end" // Adjust label placement relative to the checkbox ("end" positions the label after the checkbox)
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

// ACCORDION END

//HANDLE MODEL CHANGE
const useHandleModelChange = () => {
  const [, setSelectedModelRadioId] = useRecoilState(addL3selectedModelRadioId);
  const [selectedModelCheckboxes, setSelectedModelCheckboxes] = useRecoilState(
    addL3electedModelCheckboxesState
  );

  const handleModelChange = useCallback(
    (newModelId: string) => {
      setSelectedModelRadioId(newModelId);
      setSelectedModelCheckboxes((prevCheckboxes) => {
        if (!prevCheckboxes.hasOwnProperty(newModelId)) {
          // Initialize with empty array or perhaps default values from model data
          return { ...prevCheckboxes, [newModelId]: [] };
        }
        return prevCheckboxes;
      });
    },
    [setSelectedModelRadioId, setSelectedModelCheckboxes]
  );

  return handleModelChange;
};

// MODEL CHANGE END

// SEARCH

// Search input component
interface SearchInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchInput: React.FC<SearchInputProps> = React.memo(
  ({ value, onChange }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    // useEffect(() => {
    //   // Automatically focus the input when the component mounts
    //   if (inputRef.current) {
    //     inputRef.current.focus();
    //   }
    // }, []);
    return (
      <LastPopupSearch
        ref={inputRef}
        type="text"
        value={value}
        onChange={onChange}
        placeholder="Cerca"
      />
    );
  }
);

// SEARCH END

const MainComponent = () => {
  const { toggleOverlayL3, isVisibleL3, isAnimatingL3, closeOverlayL3 } =
    useOverlayControlL3Show("/Cybersecurity/Organizzazione/Ruoli/CreaL3/1");

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const token = useToken(); // Call useToken here at the top level

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(
    isMessageBoxVisibleRec
  );

  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] =
    useRecoilState(isMessageBoxVisibleClosingRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [searchTerm, setSearchTerm] = useRecoilState(addL3searchTerm);
  const [searchTermModelliRuoli, setSearchTermModelliRuoli] = useRecoilState(
    addL3searchTermModelliRuoli
  );
  const handleModelChange = useHandleModelChange();
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchChangeModelliRuoli = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTermModelliRuoli(event.target.value);
  };

  //const { closeOverlay } = useContext(OverlayContext);
  const handleClose = () => {
    setIsMessageBoxVisibleClosing(false); // Close the message box
    togglePopup();
  };

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); // Close the message box
    closeOverlayL3();
  };

  ///////////////// LAST POPUP

  const [isVisible, setIsVisible] = useState(false);
  const togglePopup = () => {
    setIsVisible(!isVisible);
    setSearchTermModelliRuoli("");
  };

  ///////////////////////////////////////// LAST POP UP END

  ///////////////////MODEL SELECTOR

  const [ComplianceData, setComplianceData] = useRecoilState(
    addL3ComlpianceDataState
  );
  const [Modeldata, setModelData] = useRecoilState(addL3ModelDataState);
  const [selectedModelId, setSelectedModelId] =
    useRecoilState(addL3selectedModelId);
  const [selectedModelName, setSelectedModelName] = useRecoilState(
    addL3selectedModelName
  );
  const [selectedAggrId, setSelectedAggrId] =
    useRecoilState(addL3selectedAggrId);
  const selectedModel = Modeldata.find((model) => model.id === selectedModelId);
  const [selectedModelRoles, setSelectedModelRoles] =
    useRecoilState(addL3RoleState);

  const { modelData } = useFetchModelData(token);

  useEffect(() => {
    if (modelData) {
      setModelData(modelData);
    }
  }, [modelData]);

  // Use `groupedByBusinessProcess` to render nested accordions in your component

  //const url = `http://localhost:8091/api/v1/unit-compliance-process-compliance-role-person/selection/bycomplianceroleperson/${selectedAggrId}`; staff_companyrole_units.ecubit030
  const [reFetchIndicator, setRefetchIndicator] =
    useRecoilState(reFetchIndicatorL3);
  ///////////////////////////////////////// ROLES

  const [roleData, setRoleData] = useRecoilState(addL3RoleListData);
  const [selectedRoleListId, setSelectedRoleListId] =
    useRecoilState(addL3RoleListDataID);
  const [selectedRole, setSelectedRole] = useState<roleDataInterface>();

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(URL_RoleList, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data: roleDataInterface[] = await response.json();
        setRoleData(data);
        // setSelectedRole(data[0]);
        // setSelectedRoleListId(data[0].id);
      } catch (error) {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      }
    };

    fetchRoles();
  }, []);

  const handleModelSubmit = async () => {
    // if (!newModel.modelName.trim()) {
    //   setIsMessageBoxVisible(true);
    //   setMessage('Please enter a model name.');
    //   return;
    // }
    // if (!newModel.directorId) {
    //   setIsMessageBoxVisible(true);
    //   setMessage('Please select a responsible role.');
    //   return;
    // }
    try {
      const requestBody = {
        id: newModel.modelId,
        hasResponsibleRole: newModel.directorId,
        model_name: newModel.modelName,
      };
      console.log("Sending request with body:", JSON.stringify(requestBody));

      const response = await fetch(URL_Model, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        console.log("Response not OK, status:", response.status);

        // setMessage('Network response was not ok');
        // throw new Error("Network response was not ok");
      }
      // Optionally, handle the response data
      const data = await response.json();
      console.log("Model created:", data);
      // setIsMessageBoxVisible(true);
      // setMessage('Il modello è stato creato con successo');
      handleCloseDialog(); // Close the dialog upon successful submission
    } catch (error) {
      console.error("Failed to post new model:", error);
      setRefetchIndicator((prev) => prev + 1);
      handleCloseDialog();
    }
  };

  const [selectedGroupedModel, setSelectedGroupedModel] =
    useState<GroupedModel>();

  const handleSelectRoleList = (model: GroupedModel) => {
    setSelectedModelId(model.id);
    setSelectedGroupedModel(model);
  };

  const handleRoleListSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value.toLowerCase()); // Convert to lower case for case-insensitive comparison
  };

  // NEW ROLE DIALOG
  const handleOpenDialog = () => {
    const newUUID = generateUUID(); // Generate a new UUID
    setNewModel({
      // Set the new role with the generated UUID
      modelId: newUUID,
      modelName: "",
      roleDescription: "",
      roleResponsibilities: "",
      directorId: "",
    });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewModel({
      // Reset new role state when closing the dialog
      modelId: "",
      modelName: "",
      roleDescription: "",
      roleResponsibilities: "",
      directorId: "",
    });
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [newModel, setNewModel] = useState({
    modelId: "",
    modelName: "",
    roleDescription: "",
    roleResponsibilities: "",
    directorId: "",
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewModel({ ...newModel, [e.target.name]: e.target.value });
  };

  const handleRoleChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value; // Correctly typed as string
    const role = roleData.find((r) => r.id === selectedId);
    setSelectedRole(role); // Update the selected role
    setNewModel({ ...newModel, directorId: selectedId });
  };

  // GENERATE ROLE ID

  function generateUUID(): string {
    return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  function useGenerateUUID(): string {
    const [roleIdPost, setRoleIdPost] = useRecoilState<string>(addL3RoleID);

    useEffect(() => {
      // Generate UUID and set it
      const newUUID = generateUUID();
      setRoleIdPost(newUUID);
      console.log("New person ID: ", newUUID);
    }, [setRoleIdPost]); // This effect should run only once when the component mounts

    return roleIdPost;
  }

  useGenerateUUID();

  const useDeletePerson = () => {
    const { del } = useApi();
    const [reFetchIndicator, setRefetchIndicator] =
      useRecoilState(reFetchIndicatorL3);
    const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);

    const handleDelete = useCallback(async () => {
      if (!selectedModelId) return;

      const url = URL_Model;
      try {
        await del(url, `${selectedModelId}`);
        console.log(`Deleted model with ID: ${selectedModelId}`);
        setRefetchIndicator((prev) => prev + 1);
        setIsConfirmDialogVisible(false); // Close confirmation dialog
        const firstModelId = Modeldata[0].id;
        setSelectedModelId(firstModelId);
      } catch (error) {
        console.error("Failed to model role:", error);
        setRefetchIndicator((prev) => prev + 1);
        setIsConfirmDialogVisible(false); // Close confirmation dialog
        const firstModelId = Modeldata[0].id;
        setSelectedModelId(firstModelId);
      }
    }, [selectedModelId, del, setRefetchIndicator]);

    const showDeleteConfirmation = useCallback(() => {
      setIsConfirmDialogVisible(true);
    }, []);

    return {
      handleDelete,
      showDeleteConfirmation,
      isConfirmDialogVisible,
      setIsConfirmDialogVisible,
    };
  };

  const {
    handleDelete,
    showDeleteConfirmation,
    isConfirmDialogVisible,
    setIsConfirmDialogVisible,
  } = useDeletePerson();
  const [selectedModelRadioId, setSelectedModelRadioId] = useRecoilState(
    addL3selectedModelRadioId
  );

  const initialSet = useRef(false);

  useEffect(() => {
    if (Modeldata.length > 0) {
      // This ensures that a model is selected only if none is selected already
      if (!selectedModelId) {
        const firstModelId = Modeldata[0].id;
        setSelectedModelId(firstModelId);
        // Additional logic if needed when a model is selected
        handleSelectRoleList(Modeldata[0]);
      }
    }
  }, [Modeldata, selectedModelId, setSelectedModelId, handleSelectRoleList]);

  const [selectedModelCheckboxes, setSelectedModelCheckboxes] = useRecoilState(
    addL3electedModelCheckboxesState
  );

  useEffect(() => {
    console.log(selectedModelCheckboxes);
  }, [selectedModelCheckboxes]);

  //////GET SELECTED CHECKBOXES

  const fetchAndUpdateModelRoles = async (modelId: string) => {
    const url = `${URL_RoleMOdelsAssociation}${modelId}`;

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch model roles for model ${modelId}: ${response.statusText}`
        );
      }

      const rolesData = await response.json();

      // Extract role IDs that are selected
      const selectedRoleIds = rolesData
        .filter((role: any) => role.selected)
        .map((role: any) => role.content.role.id);

      // Update state
      setSelectedModelCheckboxes((prev) => ({
        ...prev,
        [modelId]: selectedRoleIds,
      }));
    } catch (error) {
      console.error(`Error fetching model roles for model ${modelId}:`, error);
    }
  };

  useEffect(() => {
    modelData.forEach((model) => {
      console.log("modelData: ", modelData);
      fetchAndUpdateModelRoles(model.id);
    });
  }, [modelData]);

  ///// UPDATE SELECTED CHECKBOXES

  // Function to fetch existing data, modify it, and resend
  const updateSelections = async () => {
    // await Promise.all(
    //   Object.entries(selectedModelCheckboxes)
    //     .filter(([modelId, selectedRoleIds]) => selectedRoleIds.length > 0)  // Check if the array of roles is not empty
    //     .map(async ([modelId, selectedRoleIds]) => {
    await Promise.all(
      Object.entries(selectedModelCheckboxes).map(
        async ([modelId, selectedRoleIds]) => {
          const url = `${URL_RoleMOdelsAssociation}${modelId}`;
          setIsLoading(true);
          console.log(
            `Updating roles for model ${modelId} with selected roles: ${selectedRoleIds.join(
              ", "
            )}`
          );

          try {
            const response = await fetch(url, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (!response.ok) {
              throw new Error(
                `API fetch failed for model ${modelId}: ${response.statusText}`
              );
            }
            const existingRoles = await response.json();

            const updatedRoles = existingRoles.map((item: any) => {
              const isSelected = selectedRoleIds.includes(item.content.role.id);
              console.log(
                `Role ID: ${item.content.role.id}, Selected: ${isSelected}`
              ); // Debugging output
              return {
                ...item,
                selected: isSelected,
              };
            });

            console.log(
              `Sending updated roles for model ${modelId}:`,
              updatedRoles
            );

            const updateResponse = await fetch(url, {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(updatedRoles),
            });

            if (!updateResponse.ok) {
              throw new Error(
                `API update failed for model ${modelId}: ${updateResponse.statusText}`
              );
            }

            console.log(`Update successful for model ${modelId}`);
          } catch (error) {
            console.error(
              `Failed to update selections for model ${modelId}:`,
              error
            );
          }
        }
      )
    );
    //setSelectedModelCheckboxes({});
    setIsLoading(false);
    setMessage("Ruoli associati con successo.");
    setIsMessageBoxVisibleClosing(true);
  };

  ////// UPDATED SELECTED CHECKBOXES END

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            borderRadius: "0px", // Removes the border radius
            width: "800px",
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#C8D6E8",
            color: "#506987",
            padding: "15px",
            fontFamily: "Titillium-Web, sans-serif", // Set font family
            fontSize: "14px",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          AGGIUNGI MODELLO
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="modelName"
            label="Modello"
            type="text"
            fullWidth
            variant="outlined"
            value={newModel.modelName}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ marginBottom: 2 }} // Add spacing below this input
          />

          {/* <InputLabel id="director-label">Responsabile</InputLabel> */}
          <FormControl sx={{ marginBottom: 2, width: "100%" }}>
            <InputLabel id="director-label"></InputLabel>
            <Autocomplete
              id="director-select"
              options={roleData}
              getOptionLabel={(option) => option.role_description || ""}
              value={
                roleData.find((dir) => dir.id === newModel.directorId) || null
              }
              onChange={(event, newValue) => {
                setNewModel({
                  ...newModel,
                  directorId: newValue ? newValue.id : "",
                });
                setSelectedRole(newValue || undefined);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Responsabile"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  //sx={{ marginBottom: 2 }}
                />
              )}
            />
          </FormControl>

          <TextField
            margin="dense"
            name="roleDescription"
            label={`Descrizione ruolo`}
            type="text"
            fullWidth
            multiline
            rows={6}
            variant="outlined"
            value={selectedRole ? selectedRole.responsibility : ""}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 255, // Set the maximum number of characters
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            marginBottom: "20px",
            "& > :not(:last-child)": { marginRight: "20px" },
          }}
        >
          <ButtonGreen onClick={handleCloseDialog}>
            <ButtonText>Annulla</ButtonText>
          </ButtonGreen>

          <ButtonGreen onClick={handleModelSubmit}>
            <ButtonText>Crea</ButtonText>
          </ButtonGreen>
        </DialogActions>
      </Dialog>

      {isConfirmDialogVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>
                Sei sicuro di voler rimuovere questo modello?
              </MessageboxText>
            </p>
            <div
              style={{ display: "flex", justifyContent: "center", gap: "20px" }}
            >
              <CloseMessageButton
                onClick={() => setIsConfirmDialogVisible(false)}
              >
                <MessageboxText>No</MessageboxText>
              </CloseMessageButton>
              <CloseMessageButton onClick={handleDelete}>
                <MessageboxText>Rimuovi</MessageboxText>
              </CloseMessageButton>
            </div>
          </MessageContainer>
        </Backdrop>
      )}

      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleClose}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlayL3();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          {/*       
        <InputContainer>
            <InputDescriptionText>Ruoli</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup2} >

            <ArrowButtonText>Clicca per visualizzare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer> */}

          {/* <InputContainer>
            <InputDescriptionText>Associazione modelli-ruoli</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup}>
              <ArrowButtonText>Apri per modificare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer> */}
          <InputContainerRoles>
            <InputDescriptionTextRoles>
              <TopContainerRoles>
                <SearchInputContainerSoloRoles>
                  <SearchRoles
                    placeholder="Cerca nella lista modelli"
                    onChange={handleRoleListSearchChange}
                    //onChange={handleGlobalFilterChange}
                  />
                  <SearchIcon src="/search.svg" />
                </SearchInputContainerSoloRoles>

                <IconsGroup>
                  {/* <IconButton>
                    <BackgroundIcon loading="eager" alt="" src="/doc.svg" />
                  </IconButton>
                  <IconButton>
                    <BackgroundIcon loading="eager" alt="" src="/edit.svg" />
                  </IconButton> */}
                  <IconButton>
                    <BackgroundIcon
                      loading="eager"
                      alt=""
                      src="/delete.svg"
                      onClick={showDeleteConfirmation}
                    />
                  </IconButton>
                  <IconButton>
                    <BackgroundIcon
                      loading="eager"
                      alt=""
                      src="/link.svg"
                      onClick={togglePopup}
                      //onClick={toggleOverlayAdd2} // Use the prop here
                    />
                  </IconButton>
                  <IconButton>
                    <BackgroundIcon
                      loading="eager"
                      alt=""
                      src="/add.svg"
                      onClick={handleOpenDialog}
                      //onClick={toggleOverlayAdd2} // Use the prop here
                    />
                  </IconButton>
                </IconsGroup>
              </TopContainerRoles>
            </InputDescriptionTextRoles>

            {Modeldata.length > 0 && (
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  border: "1px solid #e6e6e6", // Adjust the color as needed
                  //boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)", // Adjust the shadow as needed
                  borderRadius: "0px", // If you want rounded corners
                  overflow: "hidden", // To ensure the shadow and border encapsulate the whole list including its scroll
                  marginTop: "10px",
                }}
              >
                <RoleListScrollbar>
                  {Modeldata.filter((model) =>
                    model.name.toLowerCase().includes(searchTerm)
                  ).map((model) => (
                    <ListItem
                      key={model.id}
                      button
                      onClick={() => handleSelectRoleList(model)}
                      selected={model.id === selectedModelId}
                      disableRipple
                      sx={{
                        "&.Mui-selected": {
                          bgcolor: "#f3f7fb", // Replace with your color, this is for the selected item background
                          "&:hover": {
                            bgcolor: "#c8d6e8", // Replace with your color, this is for the hover state of the selected item
                          },
                        },
                        "&:hover": {
                          bgcolor: "#e9eff7", // Replace with your color, this is for the hover state of non-selected items
                        },

                        // More styles here
                      }}
                    >
                      <ListItemText primary={model.name} />
                    </ListItem>
                  ))}
                </RoleListScrollbar>
                {Modeldata.filter((model) =>
                  model.name.toLowerCase().includes(searchTerm)
                ).length === 0 && (
                  <Typography sx={{ margin: 2, textAlign: "center" }}>
                    Nessun risultato
                  </Typography>
                )}
              </List>
            )}
          </InputContainerRoles>
        </CreaContainerColumn>
        <CreaContainerColumn>
          {selectedModel && (
            <Box
              sx={{
                marginTop: "-15px",
                padding: 2,
                paddingBottom: 5,
                border: "1px solid #e6e6e6",
                height: "271px",
                bgcolor: "background.paper",
              }}
            >
              <Typography sx={{ fontSize: "0.9rem", marginBottom: "10px" }}>
                Responsabile: {selectedModel.responsibleRole?.description}
              </Typography>
              <Divider sx={{ marginRight: 2 }} />
              <RoleListDescriptionScrollbar>
                <Typography
                  sx={{
                    mt: 1, // Margin top for spacing
                    maxHeight: "200px", // Limits the height of the Typography box to avoid expanding beyond the Box
                    paddingRight: "10px",
                  }}
                >
                  Responsabilità:{" "}
                  {selectedModel.responsibleRole?.longDescription ||
                    "No responsibilities listed."}
                </Typography>
              </RoleListDescriptionScrollbar>
            </Box>
          )}
        </CreaContainerColumn>

        {/* <CreaContainerColumn></CreaContainerColumn> */}

        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>MODELLI E RUOLI</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <SearchInput
                    value={searchTermModelliRuoli}
                    onChange={handleSearchChangeModelliRuoli}
                  />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer>

              <LastPopupScrollbar>
                <Grid container spacing={2} sx={{ width: "100%" }}>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        backgroundColor: "#fff", // Set the background color to white
                        borderRadius: "4px", // Set the border-radius to 4px
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Apply a shadow
                        padding: "0px 10px 10px 5px", // Optional: Add padding inside the box if needed
                        marginTop: "16px",
                        marginLeft: "20px",
                      }}
                    >
                      <ModelSelector
                        models={Modeldata}
                        onModelChange={handleModelChange}
                        selectedModelId={selectedModelRadioId}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    {roleData && <RolesAccordion />}
                  </Grid>
                </Grid>
              </LastPopupScrollbar>
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                }}
              >
                <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Annulla</ButtonText>
                </ButtonGrey>

                <ButtonGrey onClick={updateSelections}>
                  <ButtonText>Salva</ButtonText>
                </ButtonGrey>
              </div>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare
          src="/description_show_process.svg"
          alt="Description Icon"
        />
        <DescriptionContainerCreaText>
        {TAB_DESCRIPTION_L3_1 ? TAB_DESCRIPTION_L3_1 : TAB_DESCRIPTION_GLOBAL}
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
