import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione"; // Correct path
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  CustomDropdownItem,
  CustomDropdownList,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  DropDownMenuScrollbar,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  InputTextboxButton,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  addL2OrganizzazioneSelectedL1,
  addL2OrganizzazioneSelectedL1ID,
  addL2OrganizzazioneDirettore,
  addL2OrganizzazioneDirettoreID,
  addL2OrganizzazioneModello,
  addL2OrganizzazionePersonale,
  addL2OrganizzazioneDH,
  addL2OrganizzazioneOrdinaria,
  SelectedL1ID,
} from "../../../../../recoil";

import CancelIcon from "@mui/icons-material/Clear";
import { ButtonGrey, ButtonText } from "../../../styled-components";
import {
  URL_DirectorList,
  URL_L2ByL1,
  URL_LV1List,
  URL_OrgModel,
  URL_PlacesByUnit,
} from "../../../../../API";
import {
  addAssetDirector,
  addAssetDirectorID,
  addAssetLV1List,
  addAssetLV1ListID,
  addAssetLV2List,
  addAssetLV2ListID,
  addAssetLV3List,
  addAssetLV3ListID,
} from "../ElencoAsset-Add-recoil";
import { TAB_DESCRIPTION_ASSET_2, TAB_DESCRIPTION_GLOBAL } from "../../tabs-description";

const MainComponent = () => {
  const useToken = () => {
    const { keycloak, initialized } = useKeycloak();
    return initialized && keycloak.authenticated ? keycloak.token : null;
  };

  const token = useToken(); // Call useToken here at the top level

  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] =
    useState(false);
  const [message, setMessage] = useState("");

  const [LV1List, setLV1List] = useRecoilState(addAssetLV1List);
  const [LV1ListId, setLV1ListId] = useRecoilState(addAssetLV1ListID);

  const [LV2List, setLV2List] = useRecoilState(addAssetLV2List);
  const [LV2ListId, setLV2ListId] = useRecoilState(addAssetLV2ListID);

  const [LV3List, setLV3List] = useRecoilState(addAssetLV3List);
  const [LV3ListId, setLV3ListId] = useRecoilState(addAssetLV3ListID);
  const [director, setDirector] = useRecoilState(addAssetDirector);
  const [directorId, setDirectorId] = useRecoilState(addAssetDirectorID);
  const { closeOverlay } = useContext(OverlayContext);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); // Close the message box
    closeOverlay();
  };

  const handleClose = () => {
    setIsMessageBoxVisible(false); // Close the message box
  };

  /////////GET DIRECTORS////////////////

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch data from the API
        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response Data:", responseData);

        // Assuming responseData is the array you want to map
        const transformedData: DirectorDataItem[] = responseData.map(
          (item: DirectorApiContentItem) => {
            return {
              director_id: item.id,
              director_name: item.alias, // Changed from 'site_name' to 'name'

              // Map other properties as needed
            };
          }
        );

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data on component mount or when token changes
    fetchData();
  }, [token]); // Depend on the token

  ////////////////////

  /////FILTER INPUT DIRECTORS

  // Create a map from director names to IDs
  const directorNameToId = new Map(
    data.map((item) => [item.director_name, item.director_id])
  );

  const handleDirectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDirector(value);

    // If the value is a director name, also set the director ID
    if (directorNameToId.has(value)) {
      setDirectorId(directorNameToId.get(value) || "");
      console.log(directorNameToId.get(value) || "");
    }
  };

  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  ////////////////////TOGGLE DROPDOWN///////////

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Event listener to close dropdown if clicked outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Add a type guard for event.target
      if (
        dropdownRef.current &&
        event.target instanceof Node &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    // Clear the director name in the input box
    setDirector("");

    // Reset the director ID
    setDirectorId("");

    // Open the dropdown if it is not already open

    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (selectedDirectorId: string) => {
    // Find the director name corresponding to the selected ID
    const selectedDirectorName = data.find(
      (item) => item.director_id === selectedDirectorId
    )?.director_name;

    if (selectedDirectorName) {
      // Update the director name in the input box
      setDirector(selectedDirectorName);

      // Update the director ID
      setDirectorId(selectedDirectorId);
    }

    // Close the dropdown
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    console.log("Selected Director ID:", directorId);
  }, [directorId]); // This effect will run whenever directorId changes

  const ClearDirectors = () => {
    setDirector("");
    setDirectorId("");
  };

  ///////////////////////////////////LV1 ITEMLIST

  type LV1ApiContentItem = {
    id: string;
    alias: string;
  };

  type LV1DataItem = {
    LV1_id: string;
    LV1_name: string;
  };

  const [dataLV1, setDataLV1] = useState<LV1DataItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch dataLV1 from the API
        const response = await fetch(URL_LV1List, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response dataLV1:", responseData);

        // Access the content array from the responseData
        const contentArray = responseData.content; // This line is added to access the content field

        // Check if contentArray is indeed an array
        if (!Array.isArray(contentArray)) {
          throw new Error("Expected content to be an array");
        }

        // Map the content array to transform the dataLV1
        const transformedData: LV1DataItem[] = contentArray.map(
          (item: LV1ApiContentItem) => {
            return {
              LV1_id: item.id,
              LV1_name: item.alias, // Changed from 'site_name' to 'name'
              // Map other properties as needed
            };
          }
        );

        console.log("Transformed dataLV1:", transformedData);
        setDataLV1(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch dataLV1 on component mount or when token changes
    fetchData();
  }, [token, LV1ListId]); // Depend on the token

  ////////////////////

  /////FILTER INPUT LV1

  // Create a map from director names to IDs
  const LV1NameToId = new Map(
    dataLV1.map((item) => [item.LV1_name, item.LV1_id])
  );

  const handleLV1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLV1List(value);

    // If the value is a director name, also set the director ID
    if (LV1NameToId.has(value)) {
      setLV1ListId(LV1NameToId.get(value) || "");
      console.log(LV1NameToId.get(value) || "");
    }
  };

  ////////////////////TOGGLE DROPDOWN LV1///////////

  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useState(false);
  const dropdownRefLV1 = useRef<HTMLDivElement>(null);

  // Event listener to close dropdown if clicked outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Add a type guard for event.target
      if (
        dropdownRefLV1.current &&
        event.target instanceof Node &&
        !dropdownRefLV1.current.contains(event.target)
      ) {
        setIsDropdownOpenLV1(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggleLV1 = () => {
    setLV1List("");
    setLV1ListId("");
    setIsDropdownOpenLV1(!isDropdownOpenLV1);
  };

  const handleOptionSelectLV1 = (selectedDirectorId: string) => {
    // Find the director name corresponding to the selected ID
    const selectedLV1Name = dataLV1.find(
      (item) => item.LV1_id === selectedDirectorId
    )?.LV1_name;

    if (selectedLV1Name) {
      // Update the director name in the input box
      setLV1List(selectedLV1Name);

      // Update the director ID
      setLV1ListId(selectedDirectorId);
    }

    // Close the dropdown
    setIsDropdownOpenLV1(false);
  };

  useEffect(() => {
    console.log("Selected LV1ListId ID:", LV1ListId);
  }, [LV1ListId]); // This effect will run whenever directorId changes

  const ClearLV1 = () => {
    setLV1List("");
    setLV1ListId("");
    setLV2List("");
    setLV2ListId("");
    setLV3List("");
    setLV3ListId("");
  };

  /////////////////////// END LIST LV1 ////////

  ///////////////////////////////////LV2 ITEMLIST

  type LV2ApiContentItem = {
    id: string;
    alias: string;
  };

  type LV2DataItem = {
    LV2_id: string;
    LV2_name: string;
  };

  const [dataLV2, setDataLV2] = useState<LV2DataItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (!LV1ListId) {
        setIsLoading(false); // Ensure to stop the loading state
        setDataLV2([]); // Optionally reset the LV2 data
        setDataLV3([]); // Optionally reset the LV2 data
        return; // Exit the fetch operation early
      }

      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch dataLV1 from the API
        const response = await fetch(`${URL_L2ByL1}${LV1ListId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response dataLV2:", responseData);

        // Access the content array from the responseData
        const contentArray = responseData.content; // This line is added to access the content field

        // Check if contentArray is indeed an array
        if (!Array.isArray(contentArray)) {
          throw new Error("Expected content to be an array");
        }

        // Map the content array to transform the dataLV1
        const transformedData: LV2DataItem[] = contentArray.map(
          (item: LV2ApiContentItem) => {
            return {
              LV2_id: item.id,
              LV2_name: item.alias, // Changed from 'site_name' to 'name'
              // Map other properties as needed
            };
          }
        );

        console.log("Transformed dataLV2:", transformedData);
        setDataLV2(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch dataLV1 on component mount or when token changes
    fetchData();
  }, [token, LV1ListId]); // Depend on the token

  ////////////////////

  /////FILTER INPUT LV2

  // Create a map from director names to IDs
  const LV2NameToId = new Map(
    dataLV2.map((item) => [item.LV2_name, item.LV2_id])
  );

  const handleLV2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLV2List(value);

    // If the value is a director name, also set the director ID
    if (LV2NameToId.has(value)) {
      setLV2ListId(LV2NameToId.get(value) || "");
      console.log(LV2NameToId.get(value) || "");
    }
  };

  ////////////////////TOGGLE DROPDOWN LV2///////////

  const [isDropdownOpenLV2, setIsDropdownOpenLV2] = useState(false);
  const dropdownRefLV2 = useRef<HTMLDivElement>(null);

  // Event listener to close dropdown if clicked outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Add a type guard for event.target
      if (
        dropdownRefLV2.current &&
        event.target instanceof Node &&
        !dropdownRefLV2.current.contains(event.target)
      ) {
        setIsDropdownOpenLV2(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggleLV2 = () => {
    if (!LV1ListId) {
      setIsMessageBoxVisible(true);
      setMessage("Si prega di selezionare una componente organizzativa");
    } else {
      setLV2List("");
      setLV2ListId("");
      setIsDropdownOpenLV2(!isDropdownOpenLV2);
    }
  };

  const handleOptionSelectLV2 = (selectedLV2Id: string) => {
    // Find the LV2 name corresponding to the selected ID
    const selectedLV2Name = dataLV2.find(
      (item) => item.LV2_id === selectedLV2Id
    )?.LV2_name;

    if (selectedLV2Name) {
      // Update the LV2 name in the input box
      setLV2List(selectedLV2Name);

      // Update the LV2 ID
      setLV2ListId(selectedLV2Id);
    }

    // Close the dropdown
    setIsDropdownOpenLV2(false);
  };

  useEffect(() => {
    console.log("Selected LV2ListId ID:", LV2ListId);
  }, [LV2ListId]); // This effect will run whenever directorId changes

  const ClearLV2 = () => {
    setLV2List("");
    setLV2ListId("");
    setLV3List("");
    setLV3ListId("");
  };

  /////////////////////// END LIST LV2 ////////

  ///////////////////////////////////LV3 ITEMLIST

  type LV3ApiContentItem = {
    content: {
      place: {
        id: string;
        name: string;
      };
    };
  };

  type LV3DataItem = {
    LV3_id: string;
    LV3_name: string;
  };

  const [dataLV3, setDataLV3] = useState<LV3DataItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (!LV1ListId || !LV2ListId) {
        setIsLoading(false); // Ensure to stop the loading state
        setDataLV1([]);
        setDataLV2([]); // Optionally reset the LV2 data
        return; // Exit the fetch operation early
      }

      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch dataLV1 from the API
        const response = await fetch(
          `${URL_PlacesByUnit}/${LV2ListId}/${LV1ListId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response dataLV3:", responseData);

        // Access the content array from the responseData
        const contentArray = responseData; // This line is added to access the content field
        // Map the content array to transform the dataLV3
        const transformedData: LV3DataItem[] = contentArray.map(
          (item: LV3ApiContentItem) => {
            return {
              LV3_id: item.content.place.id,
              LV3_name: item.content.place.name, // Changed from 'site_name' to 'name'
              // Map other properties as needed
            };
          }
        );

        console.log("Transformed dataLV3:", transformedData);
        setDataLV3(transformedData);
      } catch (err) {
        // Error handling
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch dataLV3 on component mount or when token changes
    fetchData();
  }, [token, LV2ListId, LV1ListId]); // Depend on the token

  ////////////////////

  /////FILTER INPUT LV3

  // Create a map from director names to IDs
  const LV3NameToId = new Map(
    dataLV3.map((item) => [item.LV3_name, item.LV3_id])
  );

  const handleLV3Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLV3List(value);

    // If the value is a director name, also set the director ID
    if (LV3NameToId.has(value)) {
      setLV3ListId(LV3NameToId.get(value) || "");
      console.log(LV3NameToId.get(value) || "");
    }
  };

  ////////////////////TOGGLE DROPDOWN LV3///////////

  const [isDropdownOpenLV3, setIsDropdownOpenLV3] = useState(false);
  const dropdownRefLV3 = useRef<HTMLDivElement>(null);

  // Event listener to close dropdown if clicked outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Add a type guard for event.target
      if (
        dropdownRefLV3.current &&
        event.target instanceof Node &&
        !dropdownRefLV3.current.contains(event.target)
      ) {
        setIsDropdownOpenLV3(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggleLV3 = () => {
    if (!LV2ListId) {
      setIsMessageBoxVisible(true);
      setMessage("Si prega di selezionare un`unità operativa");
    } else {
      setLV3List("");
      setLV3ListId("");
      setIsDropdownOpenLV3(!isDropdownOpenLV3);
    }
  };

  const handleOptionSelectLV3 = (selectedLV3Id: string) => {
    // Find the LV3 name corresponding to the selected ID
    const selectedLV3Name = dataLV3.find(
      (item) => item.LV3_id === selectedLV3Id
    )?.LV3_name;

    if (selectedLV3Name) {
      // Update the LV3 name in the input box
      setLV3List(selectedLV3Name);

      // Update the LV3 ID
      setLV3ListId(selectedLV3Id);
    }

    // Close the dropdown
    setIsDropdownOpenLV3(false);
  };

  useEffect(() => {
    console.log("Selected LV3ListId ID:", LV3ListId);
  }, [LV3ListId]); // This effect will run whenever directorId changes

  const ClearLV3 = () => {
    setLV3List("");
    setLV3ListId("");
  };

  /////////////////////// END LIST LV3 ////////

  ///////////////// LAST POPUP

  const [isVisible, setIsVisible] = useState(false);
  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  interface LastPopupProps {
    isVisible: boolean;
  }

  const LastPopupBlack = styled.div<LastPopupProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  `;

  const LastPopup = styled.div`
    font-family: var(--font-titillium-web);
    font-size: var(--font-size-micro);
    color: var(--color-steelblue-700);
    position: fixed;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-aliceblue);

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    //border: 5px solid #ffffff;
    z-index: 100;
  `;

  /////////////////////////////////////////

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleClose}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              1. Componente organizzativa
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>

            <InputTextbox
              list="l1-list"
              value={LV1List}
              onChange={handleLV1Change}
              onClick={handleDropdownToggleLV1}
              placeholder="Seleziona"
            />
            {LV1List && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6", // Change the color if needed
                }}
                onClick={ClearLV1} // Clears the input field
              />
            )}
            <TriangleContainer>
              {isDropdownOpenLV1 ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpenLV1 && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefLV1}>
                  {dataLV1
                    .filter((item) =>
                      item.LV1_name.toLowerCase().includes(
                        LV1List.toLowerCase()
                      )
                    )
                    .map((filteredItem, index) => (
                      <CustomDropdownItem
                        key={index}
                        onClick={() =>
                          handleOptionSelectLV1(filteredItem.LV1_id)
                        }
                      >
                        {filteredItem.LV1_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              2. Unità operativa
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>

            <InputTextbox
              list="l2-list"
              value={LV2List}
              onChange={handleLV2Change}
              onClick={handleDropdownToggleLV2}
              placeholder="Seleziona"
            />
            {LV2List && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6", // Change the color if needed
                }}
                onClick={ClearLV2} // Clears the input field
              />
            )}
            <TriangleContainer>
              {isDropdownOpenLV2 && LV1ListId ? "▲" : "▼"}{" "}
              {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpenLV2 && LV1ListId && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefLV2}>
                  {dataLV2
                    .filter((item) =>
                      item.LV2_name.toLowerCase().includes(
                        LV2List.toLowerCase()
                      )
                    )
                    .map((filteredItem, index) => (
                      <CustomDropdownItem
                        key={index}
                        onClick={() =>
                          handleOptionSelectLV2(filteredItem.LV2_id)
                        }
                      >
                        {filteredItem.LV2_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              3. Luogo
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>

            <InputTextbox
              list="l3-list"
              value={LV3List}
              onChange={handleLV3Change}
              onClick={handleDropdownToggleLV3}
              placeholder="Seleziona"
            />
            {LV3List && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6", // Change the color if needed
                }}
                onClick={ClearLV3} // Clears the input field
              />
            )}
            <TriangleContainer>
              {isDropdownOpenLV3 && LV2ListId ? "▲" : "▼"}{" "}
              {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpenLV3 && LV2ListId && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRefLV3}>
                  {dataLV3
                    .filter((item) =>
                      item.LV3_name.toLowerCase().includes(
                        LV3List.toLowerCase()
                      )
                    )
                    .map((filteredItem, index) => (
                      <CustomDropdownItem
                        key={index}
                        onClick={() =>
                          handleOptionSelectLV3(filteredItem.LV3_id)
                        }
                      >
                        {filteredItem.LV3_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Responsabile Asset</InputDescriptionText>

            <InputTextbox
              list="directors-list"
              value={director}
              onChange={handleDirectorChange}
              onClick={handleDropdownToggle}
              placeholder="Seleziona"
            />
            {director && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6", // Change the color if needed
                }}
                onClick={ClearDirectors} // Clears the input field
              />
            )}
            <TriangleContainer>
              {isDropdownOpen ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpen && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRef}>
                  {data
                    .filter((item) =>
                      item.director_name
                        .toLowerCase()
                        .includes(director.toLowerCase())
                    )
                    .map((filteredItem, index) => (
                      <CustomDropdownItem
                        key={index}
                        onClick={() =>
                          handleOptionSelect(filteredItem.director_id)
                        }
                      >
                        {filteredItem.director_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L2-organizzazione.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
        {TAB_DESCRIPTION_ASSET_2 ? TAB_DESCRIPTION_ASSET_2 : TAB_DESCRIPTION_GLOBAL}
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
