import { atom, selector } from 'recoil';
import dayjs from 'dayjs';


export const addL1OrganizzazioneSelectedL1 = atom({
  key: 'addL1OrganizzazioneSelectedL1', 
  default: '',
});

export const addL1OrganizzazioneSelectedL1ID = atom({
  key: 'addL1OrganizzazioneSelectedL1ID', 
  default: '',
});


////////////////////////////ADD L1 SCHEDA GENERALE

export const addL1SchedaGeneraleNomeCompleto = atom({
  key: 'addL1SchedaGeneraleNomeCompleto', 
  default: '',
});

export const addL1SchedaGeneraleNomeAbbreviato = atom({
  key: 'addL1SchedaGeneraleNomeAbbreviato', 
  default: '',
});

export const addL1SchedaGeneraleIndirizzo = atom({
  key: 'addL1SchedaGeneraleIndirizzo', 
  default: '',
});

export const addL1SchedaGeneraleTelefono = atom({
  key: 'addL1SchedaGeneraleTelefono', 
  default: '',
});

export const addL1SchedaGeneraleFax = atom({
  key: 'addL1SchedaGeneraleFax', 
  default: '',
});

export const addL1SchedaGeneralePEC = atom({
  key: 'addL1SchedaGeneralePEC', 
  default: '',
});

//ADD L1 ORGANIZZAZIONE

export const addL1OrganizzazioneDirettore = atom({
  key: 'addL1OrganizzazioneDirettore', 
  default: '',
});

export const addL1OrganizzazioneDirettoreID = atom({
  key: 'addL1OrganizzazioneDirettoreID', 
  default: '',
});

//ADD L1 COMPLIANCE

export const addL1ComplianceDirettoreCompliance = atom({
  key: 'addL1ComplianceDirettoreCompliance', 
  default: '',
});

export const addL1ComplianceDirettoreComplianceID = atom({
  key: 'addL1ComplianceDirettoreComplianceID', 
  default: '',
});

export const addL1ComplianceDate = atom<dayjs.Dayjs | null>({
  key: 'addL1ComplianceDate', 
  //default: dayjs(), // Initialize with the current date 
  default: null, 
});


//ADD L1 DOCUMENTS

export const addL1DocumentsPath = atom({
  key: 'addL1DocumentsPath', 
  default: '',
});

// Assuming addL1DocumentsAttached is defined to store File objects
export const addL1DocumentsAttached = atom<File[]>({
  key: 'addL1DocumentsAttached',
  default: [],
});


export const addL1AttachedFilesInfo = atom<Array<{ name: string, size: number, type: string } | null>>({
  key: 'addL1AttachedFilesInfo',
  default: [],
});

export const L1DeleteConfirmationDialog = atom<boolean>({
  key: 'L1DeleteConfirmationDialog', 
  default: false,
});


export type FileListItem = {
  id: string;
  path: string;
  name: string;
};

export const showL1FileListItemState = atom<FileListItem[]>({
  key: 'showL1FileListItemState',
  default: [],
});