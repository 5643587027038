import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useKeycloak } from "@react-keycloak/web";
import { useLocation } from "react-router-dom";

import {
  FrameNavLogo,
  PicLogoIcon,
  FrameTopGradient,
  ButtonIsoActiveText,
  ButtonIsoActive,
  ButtonIsoInactiveText,
  ButtonIsoInactive,
  FrameButtonIso,
  FrameUserSearch,
  FrameUserSearchAuto,
  ButtonUser,
  ButtonNotification,
  IcoUserNotification,
  FrameLeftNav,
  FrameNavButtons,
  NavButtonWhite,
  NavButtonWhiteFocused,
  NavButtonWhiteText,
  NavButtonWhiteChild,
  NavButtonWhiteChildText,
  NavButtonWhiteChildFocused,
  NavButtonBlue,
  NavButtonBlueFocused,
  NavButtonBlueText,
  NavButtonBlueChild,
  NavButtonBlueChildFocused,
  ArrowGrey,
  ArrowGreyRight,
  ArrowWhite,
  ArrowWhiteRight,
  LineMagenta40,
  LineMagenta50,
  FrameButtonIcon,
  IcoButtonIcon,
  FrameNavWorkspace,
  WorkspaceLine1,
  WorkspaceLine2,
  FrameBoardMain,
  PageRoot,
  ScrollableDiv,
  ButtonGrey,
  MainBoardSubMenuButtonContainer,
  MainBoardSubMenuButton,
} from "../styled-components";
import ApiCheck from "../../check-API";
import { BarChart, PieChart, Gauge, gaugeClasses } from "@mui/x-charts";
import { Stack } from "@mui/material";
import {
  GAP_chartDataState5,
  GAP_control_value_2_avarage,
  GAP_control_value_2_total_5,
  GAP_control_value_2_total,
  reFetchIndicatorGAPControlli,
  GAP_control_value_2_total_004,
  GAP_control_value_2_total_0514,
  GAP_control_value_2_total_1524,
  GAP_control_value_2_total_2534,
  GAP_control_value_2_total_3544,
  GAP_control_value_2_total_455,
  averageDutyGroupsState,
} from "./gap-recoil";
import { useRecoilState } from "recoil";
import { useFetchGAPData } from "./cybersecurity-board-gap-analysis-controlli";

const Board27001Organizzazione: React.FC = () => {
  const onLogout = () => {
    keycloak.logout({ redirectUri: window.location.origin });
  };

  const location = useLocation();

  const isActive = (path: string) => {
    // Check if the current location is the index route of Organizzazione
    return location.pathname === "/Cybersecurity/GapAnalysis" && path === "GapAnalysis";
  };

  const ImageContainer = styled.div`
    display: flex;
    justify-content: center; // Centers the child horizontally
    align-items: center; // Centers the child vertically
    height: 100%; // Adjust as needed, could be a specific height like 400px
    width: 100%; // Adjust as needed
    margin-top: 30px;
  `;

  const StyledImage = styled.img`
    max-width: 95%; // Ensures the image is responsive and fits its container
    height: auto; // Maintains the aspect ratio of the image
    //border-radius: 0px; // Optional: Adds rounded corners to the image
    //box-shadow: 0 4px 6px rgba(0,0,0,0.1); // Optional: Adds a subtle shadow around the image
  `;

  const GridContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: top;
    //gap: 10px; // Space between charts
    /* padding: 10px; */
    height: 100%;
    //border: 2px solid #ff9100;
  `;

  const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 10px; // Space between charts
    padding: 10px; */
    height: 85%;
    width: 60%;
    //border: 2px solid #ff00b3;
  `;
  const ColumnContainer5 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 10px; // Space between charts
  padding: 10px; */
    height: 85%;
    width: 40%;
    //border: 2px solid #ff00b3;
  `;

  const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px; // Space between charts
    padding: 10px;
    padding-bottom: 0px;
    height: 50%;
    width: 100%;
    //border: 2px solid #4400ff;
  `;

  const RowContainer5 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px; // Space between charts
    padding: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
    height: 100%;
    width: 100%;
    //border: 2px solid #4400ff;
  `;

  const ChartArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    background: white; // Sets the background color to white
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); // Adds a subtle shadow
    padding: 5px; // Adds padding inside the chart areas
    margin: 0px; // Adds margin around each chart area for spacing
    height: 100%;
    width: 100%;
  `;

  const ChartTitle = styled.div`
    margin-bottom: 0px;
    font-size: 11px;
    color: #333;
    //background-color: #BEBEBE;
  `;

  const ChartTitleTop = styled.div`
    margin-top: 10px;
    font-size: 11px;
    color: #333;
    //background-color: #BEBEBE;
  `;

  const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    //border: 2px solid #00ff95;
    width: 100%;
    height: 100%;
  `;

  const ChartContainer5 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //border: 2px solid #00ff95;
    width: 100%;
    height: 100%;
    padding-left: 0px;
    padding-bottom: 12px;
  `;

  const ChartFooter = styled.div`
    margin-top: 5px;
    font-size: 11px;
    color: #666;
  `;

  const StandardChartContainerGauge = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //border: 2px solid #00ff95;
    background-image: url("../gauge.svg");
    background-size: contain; // Ensure the background image scales properly
    background-repeat: no-repeat; // Prevent the image from repeating
    background-position: center; // Center the background image
    width: 80%; // Ensures the container takes up the full width of the parent
    height: 100%; // Ensures the container takes up the full height of the parent
    position: relative; // Ensures that child elements (like your gauge) position correctly
  `;

  const GaugePointer = styled.div<{ value: number; maxvalue: number }>`
    position: absolute;
    width: 1%;
    height: 37%; // Adjust the height to match your gauge's dimensions
    background-color: #5a5a5a; // Color of the pointer
    transform-origin: bottom center;
    transform: rotate(${(props) => -90 + (props.value / props.maxvalue) * 180}deg); // Adjust rotation based on value
    bottom: 30%;
    //translate: 0px 10px;
  `;

  const GaugePointer5 = styled.div<{ value: number }>`
    position: absolute;
    width: 1%;
    height: 37%; // Adjust the height to match your gauge's dimensions
    background-color: #5a5a5a; // Color of the pointer
    transform-origin: bottom center;
    transform: rotate(${(props) => -90 + (props.value / 5) * 180}deg); // Adjust rotation based on value
    bottom: 30%;
    //translate: 0px 10px;
  `;

  const { keycloak, initialized } = useKeycloak();
  const token = initialized && keycloak.authenticated ? keycloak.token : null;

  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorGAPControlli);
  const [averageControlValue2, setAverageControlValue2] = useRecoilState(GAP_control_value_2_avarage);
  const [totalControlValue2, setTotalAverageControlValue2] = useRecoilState(GAP_control_value_2_total);
  const [totalControlValue25, setTotalAverageControlValue25] = useRecoilState(GAP_control_value_2_total_5);
  const percentageChart1 = (totalControlValue25 / totalControlValue2) * 100;

  const [chartData5, setChartData5] = useRecoilState(GAP_chartDataState5);

  const [totalControlValue004, setTotalControlValue004] = useRecoilState(GAP_control_value_2_total_004);
  const [totalControlValue0514, setTotalControlValue0514] = useRecoilState(GAP_control_value_2_total_0514);
  const [totalControlValue1524, setTotalControlValue1524] = useRecoilState(GAP_control_value_2_total_1524);
  const [totalControlValue2534, setTotalControlValue2534] = useRecoilState(GAP_control_value_2_total_2534);
  const [totalControlValue3544, setTotalControlValue3544] = useRecoilState(GAP_control_value_2_total_3544);
  const [totalControlValue455, setTotalControlValue455] = useRecoilState(GAP_control_value_2_total_455);

  const [dutyGroups, setDutyGroups] = useRecoilState(averageDutyGroupsState);

  const { fetchData } = useFetchGAPData(token);

  useEffect(() => {
    fetchData();
  }, []);

  //
  return (
    <FrameBoardMain>
      <MainBoardSubMenuButtonContainer>
        <MainBoardSubMenuButton to="/Cybersecurity/GapAnalysis" className={isActive("GapAnalysis") ? "active" : ""}>
          Dashboard
        </MainBoardSubMenuButton>
        <MainBoardSubMenuButton to="/Cybersecurity/Controlli">Controlli</MainBoardSubMenuButton>
        {/* <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/Fornitori">Fornitori</MainBoardSubMenuButton>
    <MainBoardSubMenuButton to="/Cybersecurity/PartiInteressate/AltriEnti">Altri enti</MainBoardSubMenuButton> */}
      </MainBoardSubMenuButtonContainer>

      {/* <ImageContainer>
    <StyledImage src={'../processi_compliance.png'} alt={'alt'} />
    </ImageContainer> */}
      {/* #309EE5 */}
      <GridContainer>
        <ColumnContainer>
          <RowContainer>
            <ChartArea>
              <StandardChartContainerGauge>
                <ChartTitleTop>CONTROLLI IMPLEMENTATI</ChartTitleTop>
                <GaugePointer value={totalControlValue25} maxvalue={totalControlValue2} />
                {/* <StyledImage src={'../gauge.png'} alt={'alt'} /> */}
                <Gauge
                  //width={250} // Set width
                  //height={250} // Set height
                  value={totalControlValue25}
                  valueMax={totalControlValue2}
                  startAngle={-90}
                  endAngle={90}
                  sx={{
                    [`& .${gaugeClasses.valueText}`]: {
                      fontSize: 11,
                      transform: "translate(0px, 6%)",
                      //color: '#333',
                    },
                    [`& .${gaugeClasses.referenceArc}`]: {
                      stroke: "red", // Make the arc transparent
                      opacity: 0, // Make the arc fully transparent
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                      opacity: 0, // Make the track (background) arc fully transparent
                    },
                  }}
                  text={({ value, valueMax }) => `${totalControlValue25} / ${totalControlValue2}\n(${(percentageChart1 ?? 0).toFixed(2)}%)`}
                />

                <ChartFooter>Controlli valutati a 5 / Totale controlli</ChartFooter>
              </StandardChartContainerGauge>
            </ChartArea>
            <ChartArea>
              <StandardChartContainerGauge>
                <ChartTitleTop>VALUTAZIONE MEDIA</ChartTitleTop>
                <GaugePointer5 value={averageControlValue2} />
                {/* <StyledImage src={'../gauge.png'} alt={'alt'} /> */}
                <Gauge
                  //width={250} // Set width
                  //height={250} // Set height
                  value={averageControlValue2}
                  valueMax={5} // Set the maximum value to 5
                  startAngle={-90}
                  endAngle={90}
                  sx={{
                    [`& .${gaugeClasses.valueText}`]: {
                      fontSize: 11,
                      transform: "translate(0px, 6%)",
                    },
                    [`& .${gaugeClasses.referenceArc}`]: {
                      stroke: "red", // Make the arc transparent
                      opacity: 0, // Make the arc fully transparent
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                      opacity: 0, // Make the track (background) arc fully transparent
                    },
                  }}
                  text={({ value, valueMax }) => `${value} / ${valueMax}`}
                />
                <ChartFooter>Valutazioni approvate / Totale valutazioni</ChartFooter>
              </StandardChartContainerGauge>
            </ChartArea>
          </RowContainer>
          <RowContainer>
            <ChartArea>
              <ChartContainer>
                <ChartTitle>DISTRIBUZIONE DELLE VALUTAZIONI</ChartTitle>
                <PieChart
                  //slotProps={{ legend: { hidden: true } }}
                  slotProps={{
                    legend: {
                      direction: "row",
                      position: {
                        vertical: "bottom",
                        horizontal: "middle",
                       
                      },
                      padding: -3,
                      labelStyle: {
                        fontSize: 11,
                        fill: "grey",
                      },
                      itemMarkWidth: 20,
                      itemMarkHeight: 2,
                      markGap: 5,
                      itemGap: 10,
                    },
                  }}
                  margin={{ top: 10, bottom: 25, left: 10, right: 10 }}
                  series={[
                    {
                      data: [
                        { id: 0, value: totalControlValue004, label: "0", color: "#BF212F" },
                        { id: 1, value: totalControlValue0514, label: "1", color: "#fa703a" },
                        { id: 3, value: totalControlValue1524, label: "2", color: "#F9A73E" },
                        { id: 4, value: totalControlValue2534, label: "3", color: "#f9ec3e" },
                        { id: 5, value: totalControlValue3544, label: "4", color: "#43b327" },
                        { id: 6, value: totalControlValue455, label: "5", color: "#1a915d" },
                      ],
                    },
                  ]}

                  // width={400}
                  // height={200}
                />
                {/* <ChartFooter>Valutazioni approssimate a valori interi</ChartFooter> */}
              </ChartContainer>
            </ChartArea>
            <ChartArea>
              <ChartContainer>
                <ChartTitle>VALUTAZIONE MEDIA PER OBBLIGO E PRIORITÀ</ChartTitle>
                <BarChart
                  slotProps={{
                    legend: {
                      labelStyle: {
                        fontSize: 12,
                        fill: "black",
                      },
                      direction: "row",
                      padding: -2,
                      position: {
                        vertical: "bottom",
                        horizontal: "middle",
                      },
                      itemMarkWidth: 15,
                      itemMarkHeight: 2,
                      markGap: 5,
                      itemGap: 10,
                    },
                  }}
                  xAxis={[{ scaleType: "band", data: ["Liberi", "Consigliati", "Obbligatori"] }]}
                  yAxis={[{
                    scaleType: "linear", // Ensures the y-axis is linear
                    min: 0, // Minimum value on the y-axis
                    max: 5, // Maximum value on the y-axis
                  tickNumber: 5,
                  }]}
                  series={[
                    {
                      data: [dutyGroups.libero.nd, dutyGroups.consigliato.nd, dutyGroups.obbligatorio.nd],
                      color: "#b3b3b3",
                      label: "ND",
                    },
                    {
                      data: [dutyGroups.libero.bassa, dutyGroups.consigliato.bassa, dutyGroups.obbligatorio.bassa],
                      color: "#1a915d",
                      label: "Bassa",
                    },
                    {
                      data: [dutyGroups.libero.media, dutyGroups.consigliato.media, dutyGroups.obbligatorio.media],
                      color: "#F9A73E",
                      label: "Media",
                    },
                    {
                      data: [dutyGroups.libero.alta, dutyGroups.consigliato.alta, dutyGroups.obbligatorio.alta],
                      color: "#BF212F",
                      label: "Alta",
                    },
                  ]}
                />
                {/* <ChartFooter>Legenda: (priorità non definita, bassa, media, alta)</ChartFooter> */}
              </ChartContainer>
            </ChartArea>
          </RowContainer>
        </ColumnContainer>
        <ColumnContainer5>
          <RowContainer5>
            <ChartArea>
              <ChartContainer5>
                <ChartTitleTop>VALUTAZIONE MEDIA E FREQUENZA DELL'OBBLIGO PER CATEGORIA</ChartTitleTop>
                <BarChart
                  slotProps={{
                    legend: {
                      labelStyle: {
                        fontSize: 12,
                        fill: "black",
                      },
                      direction: "row",
                      padding: -2,
                      position: {
                        vertical: "bottom",
                        horizontal: "middle",
                      },
                      itemMarkWidth: 15,
                      itemMarkHeight: 2,
                      markGap: 5,
                      itemGap: 10,
                    },
                  }}
                  
                  xAxis={[
                    {
                      scaleType: "linear",
                      min: 0,
                      max: 5,
                      tickNumber: 5,
                    },
                  ]}
                  yAxis={[
                    
                    {
                      scaleType: "band",
                      data: chartData5.map((item) => item.category), // Y-axis labels
                      
                    },
                    
                  ]}
                  series={[
                   
                    
                    {
                      label: "Liberi",
                      data: chartData5.map((item) => item.libero), // Free duty data
                      color: "#1a915d", // Color for liberi
                      stack: "total", // Stack these segments together
                    },
                    {
                      label: "Consigliati",
                      data: chartData5.map((item) => item.consigliato), // Consultative duty data
                      color: "#F9A73E", // Color for cons
                      stack: "total", // Stack these segments together
                    },
                    {
                      label: "Obbligatori",
                      data: chartData5.map((item) => item.obbligatorio), // Obligatory duty data
                      color: "#BF212F", // Color for obbl
                      stack: "total", // Stack these segments together
                    },
                  ]}
                  layout="horizontal" // Horizontal bars
                  margin={{ left: 180 }}
                />

                {/* <ChartFooter>Legenda: (obbligatorio, consigliato, libero)</ChartFooter> */}
              </ChartContainer5>
            </ChartArea>
          </RowContainer5>
        </ColumnContainer5>
      </GridContainer>
    </FrameBoardMain>
  );
};

export default Board27001Organizzazione;
