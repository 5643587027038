import React, { useState, useEffect, useRef, useContext, Suspense, lazy } from "react";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useRecoilState, useRecoilValue } from "recoil";
import dayjs from "dayjs";
import {

setErrorRec,
isMessageBoxVisibleRec,
setMessageRec,
isLoadingRec,
reFetchIndicatorL4,
reFetchIndicatorComplianceProcesses,
} from "../../../../recoil"; 

import SchedaGenerale from "./cybersecurity-board-compliance-processi-Add-sub/gestionecompliance-processicompliance-Add-1-SchedaGenerale";
//import Organizzazione from "./cybersecurity-board-compliance-processi-Add-sub/infrastruttura-luoghi-Add-2-Organizzazione";
// const Infrastruttura = lazy(() => import ("./cybersecurity-board-compliance-processi-Add-sub/organigramma-L4-Add-3-Infrastruttura").then(module => ({ default: module.default })));
// import Processi from "./cybersecurity-board-compliance-processi-Add-sub/organigramma-L4-Add-4-Processi";
// import Parti from "./cybersecurity-board-compliance-processi-Add-sub/organigramma-L4-Add-3-Infrastruttura";
import Compliance from "./cybersecurity-board-compliance-processi-Add-sub/gestionecompliance-processicompliance-Add-6-GestioneCompliance";
import Documentazione, {  useInitiateUploadProcessiCompliance } from "./cybersecurity-board-compliance-processi-Add-sub/gestionecompliance-processicompliance-Add-7-Documentazione";

import {
  ButtonGrey,
  ButtonText,
} from "../../styled-components";

import {
  FrameTopGradient100,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteTitleAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  Backdrop,
  MessageContainer,
  CloseMessageButton,
  MessageboxText,
  BackdropTransparent,
  CreaSubMenuButtonMultiple,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "../../cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";

import {
  ProcessiComplianceAttachedFilesInfo,
  ProcessiComplianceComplianceDate,
  ProcessiComplianceComplianceResponsible,
  ProcessiComplianceDescription,
  ProcessiComplianceID,
  ProcessiComplianceDocumentsAttached,
  ProcessiComplianceDocumentsPath,
  ProcessiComplianceKind,
  ProcessiComplianceName,
  ProcessiComplianceObjective,
  ProcessiComplianceComplianceResponsibleID,
  ProcessiComplianceIsDropdownOpenDirectors,
  ProcessiComplianceConfirmedLV1List,
  ProcessiComplianceDeleteConfirmationDialog,
  ProcessiComplianceIsDropdownOpenDirectorsCompliance,
  ProcessiComplianceOrganizzazioneSelectedL1,
  ProcessiComplianceOrganizzazioneSelectedL1ID,
  ProcessiCompliance_2_isDropdownOpenLV1,
  ProcessiComplianceRifNorm,
  ProcessiComplianceNota,

  
} from "./ProcessiCompliance-Add-recoil";

import { URL_ComplianceProcess, URL_LV4List, URL_LV4List_short, URL_PlacesFullList, URL_Process, useApi, useToken } from "../../../../API";
import { PATH_ProcessiCompliance } from "../../../../docPaths";



function generateUUID(): string {
  return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function useGenerateUUID(): string {

  const [ProcessoID, setProcessoID] = useRecoilState<string>(ProcessiComplianceID);

  useEffect(() => {
    // Generate UUID and set it
    const newUUID = generateUUID();
    setProcessoID(newUUID);
    console.log("New place ID: ", newUUID);
  }, [setProcessoID]); // This effect should run only once when the component mounts

  return ProcessoID;
}


export const useHandleSave = () => {

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const { closeOverlay } = useContext(OverlayContext);
  const { post } = useApi();


  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(ProcessiComplianceAttachedFilesInfo);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(ProcessiComplianceDocumentsAttached);
  const [docPath, setDocPath] = useRecoilState(ProcessiComplianceDocumentsPath);
  const [ID, setID] = useRecoilState(ProcessiComplianceID);
  const [name, setName] = useRecoilState(ProcessiComplianceName);
  const [complianceResponsible, setComplianceResponsible] = useRecoilState(ProcessiComplianceComplianceResponsible);
  const [complianceDate, setComplianceDate] = useRecoilState(ProcessiComplianceComplianceDate);
  const [description, setDescription] = useRecoilState(ProcessiComplianceDescription);
  const [kind, setKind] = useRecoilState(ProcessiComplianceKind);
  const [objective, setObjective] = useRecoilState(ProcessiComplianceObjective);
  const [rifNorm, setrifNorm] = useRecoilState(ProcessiComplianceRifNorm);
  const [nota, setNota] = useRecoilState(ProcessiComplianceNota);
  const initiateUpload = useInitiateUploadProcessiCompliance();
  const [organizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(ProcessiComplianceComplianceResponsible);
  const [organizzazioneDirettoreID, setOrganizzazioneDirettoreID] = useRecoilState(ProcessiComplianceComplianceResponsibleID);
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(ProcessiComplianceIsDropdownOpenDirectorsCompliance);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorComplianceProcesses);

  const handleSave = async () => {

    if (!kind || !name ) {
      setMessage("Si prega di compilare tutti i campi obbligatori: denominazione e categoria");
      setIsMessageBoxVisible(true);
      return; // Exit the function early
    }

    const newData = {

      id: ID,
      name: name,
      kind: kind,
      norm_ref: rifNorm,
      note: nota,
      description: description,
      has_compliance_responsible: organizzazioneDirettoreID,
      compliance_responsible_assignment_start: complianceDate,
      documents_folder_link: PATH_ProcessiCompliance,
    };
    
    try {

      setIsLoading(true);
      console.log(JSON.stringify(newData));
      const responseData = await post(
        URL_ComplianceProcess,
        newData
      );
      initiateUpload();
      console.log("Compliance process created successfully:", responseData);
      setMessage("Compliance process created successfully!");
      setRefetchIndicator(prev => prev + 1);
      setIsMessageBoxVisible(true);
      setName('');
      setKind('');
      setrifNorm('');
      setNota('');
      setDescription('');
      setOrganizzazioneDirettoreID('');
      setOrganizzazioneDirettore('');

     
    } catch (error) {
      console.error("Error creating Compliance process:", error);
      setMessage(`Error creating Compliance process: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSave};
};

const MainComponent = () => {

  const token = useToken(); 

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(ProcessiComplianceAttachedFilesInfo);
  const [complianceDate, setComplianceDate] = useRecoilState(ProcessiComplianceComplianceDate);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(ProcessiComplianceDocumentsAttached);
  const [documentsPath, setDocumentsPath] = useRecoilState(ProcessiComplianceDocumentsPath);
  const [ID, setID] = useRecoilState(ProcessiComplianceID);
  const [name, setName] = useRecoilState(ProcessiComplianceName);
 const [organizzazioneSelectedL1, setOrganizzazioneSelectedL1] = useRecoilState(ProcessiComplianceOrganizzazioneSelectedL1);
  const [organizzazioneSelectedL1ID, setOrganizzazioneSelectedL1ID] = useRecoilState(ProcessiComplianceOrganizzazioneSelectedL1ID);
  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(ProcessiComplianceConfirmedLV1List);
  const [organizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(ProcessiComplianceComplianceResponsible);
  const [organizzazioneDirettoreID, setOrganizzazioneDirettoreID] = useRecoilState(ProcessiComplianceComplianceResponsibleID);
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(ProcessiComplianceIsDropdownOpenDirectorsCompliance);
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(ProcessiCompliance_2_isDropdownOpenLV1);


  const { handleSave} = useHandleSave();
  const initiateUpload = useInitiateUploadProcessiCompliance();


  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorComplianceProcesses);

  // CUSTOM HOOKS

  useGenerateUUID();

  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  const handleCloseAndNavigate = () => {
    

    setIsMessageBoxVisible(false); // Close the message box
    closeOverlay();
    
  };


  ///////////////// SAVE AND UPLOAD /////////

  const handleSaveAndUpload = async () => {
    await handleSave();
    initiateUpload(); // Now you can call this function directly
  };

  ///////////////// MESSAGE ANNULLA BUTTON /////////

  const handleClose = () => {

    closeOverlay();

    setAttachedFilesInfo([]); // Assuming this resets to an empty array
    setDocumentsAttached([]); // Assuming this is a boolean that should be reset to false
    setDocumentsPath(''); // Assuming this resets to an empty string
    
    // setSurname('');
    // setName('');
    // setBirthDate(dayjs());
    // setBirthPlace('');
    // setGender('');
    // setFiscalCode('');
    // setResidenceAddress('');
    // setDomicileAddress('');
    // setWorkEmail('');
    // setPersonalEmail('');
    // setWorkPhone('');
    // setPrivatePhone('');
    
  };

  ////////////////////CHANGE ACTIVE COMPONENT///////////

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      // case "Organizzazione":
      //   return <Organizzazione />;
      // case "Infrastruttura":
      //   return <Infrastruttura />;
      // case "ProcessiGenerali":
      //   return <Processi />;
      // case "PartiInteressate":
      //   return <Parti />;
      case "SistemaCompliance":
        return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}



      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>
        NUOVO PROCESSO COMPLIANCE
      </OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SchedaGenerale")}
            to="/Cybersecurity/GestioneCompliance/ProcessiCompliance/CreaProcessiCompliance/1"
          >
            Scheda generale
          </CreaSubMenuButtonMultiple>

          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Organizzazione")}
            to="/Cybersecurity/GestioneCompliance/ProcessiCompliance/CreaProcessiCompliance/2"
          >
            Organizzazione
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/GestioneCompliance/ProcessiCompliance/CreaProcessiCompliance/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/GestioneCompliance/ProcessiCompliance/CreaProcessiCompliance/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Infrastruttura/UnitaOperative/CreaL2/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SistemaCompliance")}
            to="/Cybersecurity/GestioneCompliance/ProcessiCompliance/CreaProcessiCompliance/6"
          >
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Documentazione")}
            to="/Cybersecurity/GestioneCompliance/ProcessiCompliance/CreaProcessiCompliance/7"
          >
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>
        <Suspense fallback={<div>Loading...</div>}>
        {renderActiveComponent()}
        </Suspense>
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={handleClose}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSaveAndUpload}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default MainComponent;
