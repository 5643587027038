import React, { useState, useEffect, useMemo, useCallback } from "react";
import styled from "styled-components";
import { useKeycloak } from "@react-keycloak/web";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";
import { useNavigate, Routes, Route, Outlet, useLocation } from "react-router-dom";

import {
  IconButton,
  IconsGroup,
  BackgroundIcon,
  Backdrop,
  BackdropTransparent,
  MessageContainer,
  MessageboxText,
  CloseMessageButton,
  FadeOutComponent,
  FrameTopGradient100,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";
import {
  StyledTable,
  StyledTbody,
  StyledTd,
  StyledTh,
  StyledThead,
  TableItemText,
  TableTitleText,
  TableScrollableDiv,
  TableContainer,
  TopContainer,
  Search,
  SearchIcon,
  SearchInputContainer,
  StyledTableItem,
  StyledRuoligeneraliTh,
  StyledRuoliGeneraliTd,
} from "./cybersecurity-board-organizzazione-unitaoperative-styled-components";

import { useRecoilState, useRecoilValue } from "recoil";

import {
  SelectedL1ID,
  SelectedL2ID,
  SelectedL3ID,
  SelectedL4ID,
} from "../../../../recoil";
import { URL_RoleModels } from "../../../../API";
import { showL3initialPath, showL3isAnimating, showL3isVisible } from "./L3-Show-recoil ";

interface DataItem {
  id: string;
  organization_model_name: string;
  role_description: string;
  role_long_description: string;

  // Add any other properties that might exist in your data objects
}

type SortableKeys = keyof DataItem;

type ApiContentItem = {
  id: string;
  name: string;
  responsible_name: string;
  responsible_id: string;
  site_name: string;
  site_id: string;
  email: string;
  phone: string;
  // include other properties if there are any
};

type StyledTrProps = {
  selected: boolean;
};

const StyledTr = styled.tr<StyledTrProps>`
  cursor: pointer;
  background-color: ${(props) => {
    //console.log(`Is row selected: ${props.selected}`); // This should log true or false based on selection
    return props.selected ? "#f3f7fb" : "#ffffff";
  }};

  &:hover {
    background-color: #e9eff7; // Make sure this doesn't override your selection color
  }
`;

interface OverlayComponentProps {
  isAnimating: boolean;
}

//// GLOBAL SHOW L3 PROPAGATION

export function useOverlayControlL3Show(initialPath: string) {
  const [isVisibleL3, setIsVisibleL3] = useRecoilState(showL3isVisible);
  const [isAnimatingL3, setIsAnimatingL3] = useRecoilState(showL3isAnimating);
  const [originalPath, setOriginalPath] = useRecoilState(showL3initialPath);
  const navigate = useNavigate();

  const openOverlayL3 = useCallback(() => {
    navigate(initialPath);
    setIsVisibleL3(true);
    setTimeout(() => setIsAnimatingL3(true), 10);
  }, [navigate, initialPath]);

  const closeOverlayL3 = useCallback(() => {
    setIsAnimatingL3(false);
    setTimeout(() => {
      setIsVisibleL3(false);
      navigate(originalPath); // Navigate back
    }, 300);
  }, [navigate]);

  const toggleOverlayL3 = useCallback(() => {
    if (!isVisibleL3) {
      openOverlayL3();
    } else {
      closeOverlayL3();
    }
  }, [isVisibleL3, openOverlayL3, closeOverlayL3]);

  return {
    isVisibleL3,
    isAnimatingL3,
    toggleOverlayL3,
    openOverlayL3,
    closeOverlayL3,
  };
}

const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: ${(props) => (props.isAnimating ? "1" : "0")};
  visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
`;

const DataTable: React.FC<{ data: DataItem[] }> = ({ data }) => {
  /////////////OVERLAY ADD OPEN CLOSE
  const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useState(false);
  const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useState(false);

  const navigate = useNavigate();

  const toggleOverlayAdd2 = () => {
    if (!isOverlayVisibleAdd2) {
      // Check if the current path is '/Cybersecurity/Organizzazione'
      if (window.location.pathname === "/Cybersecurity/Ruoli") {
        navigate(`Ruoli/CreaL3/1`); // Navigate to 'Organigramma/AggiungiL1' if the condition is true
      } else {
        navigate(`${window.location.pathname}/CreaL3/1`); // Navigate to '/AggiungiL1' if the condition is false
      }

      setIsOverlayVisibleAdd2(true); // Show the overlay
      setTimeout(() => setIsOverlayAnimatingAdd2(true), 10); // Start animation after it's visible
    } else {
      setIsOverlayAnimatingAdd2(false); // Start hiding animation
      setTimeout(() => setIsOverlayVisibleAdd2(false), 310); // Wait for animation to finish before hiding
    }
  };

  // Function to close the overlay
  const closeOverlayAdd2 = () => {
    //clean recoil variables here

    navigate(`/Cybersecurity/Organizzazione/Ruoli`);
    setIsOverlayAnimatingAdd2(false); // Start the hide animation
    // Use a timeout equal to the transition duration to set visibility to false
    setTimeout(() => {
      setIsOverlayVisibleAdd2(false);
    }, 300); // 300ms is the duration of the exit transition
  };

  ////////////////overlay open close end
  const [selectedL1, setSelectedL1] = useState<string | null>(null);
  const [selectedL2, setSelectedL2] = useState<string | null>(null);
  const [selectedL3, setSelectedL3] = useState<string | null>(null);
  const [selectedL4, setSelectedL4] = useState<string | null>(null);

  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const [sortConfig, setSortConfig] = useState<{
    key: SortableKeys;
    direction: "ascending" | "descending";
  } | null>(null);

  // Function to handle sorting
  const sortedData = useMemo(() => {
    let sortableItems = [...data]; // Create a new array to avoid mutating the original data
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        // Handle different types if necessary, e.g., string vs number
        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }
        // Add more conditions for other types if necessary

        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  // Click handler for headers
  const requestSort = (key: SortableKeys) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleRowSelect = (id: string) => {
    setSelectedId((prevId) => {
      //console.log(`Previous ID: ${prevId}, New ID: ${id}`);
      return id;
    });

    setL3ID(id);
  };

  useEffect(() => {
    // This effect will run after `L2ID` has been updated.
    console.log("L3ID now has the value:", L3ID);
    // Here, you can add any logic that needs to run right after L2ID changes.
  }, [L3ID]); // Dependency array ensures this effect runs only when L2ID changes.

  //////////////////////GLOBAL SEARCH

  const [globalFilterText, setGlobalFilterText] = useState("");

  const handleGlobalFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGlobalFilterText(event.target.value);
  };

  ////////////////////////////////////// FILTER

  const [filterText, setFilterText] = useState("");
  // Event handler for the search input
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  // Filter and then sort the data
  const filteredAndSortedData = useMemo(() => {
    const filteredData = data
      .filter((item) => {
        return item.organization_model_name
          .toLowerCase()
          .includes(filterText.toLowerCase());
      })
      .filter((item) => {
        // If there's global filter text, check all the relevant properties
        if (globalFilterText) {
          const allText = Object.values(item).join(" ").toLowerCase();
          return allText.includes(globalFilterText.toLowerCase());
        }
        return true; // If there's no global filter text, don't filter further
      });

    // Then sort the filtered data
    if (sortConfig !== null) {
      filteredData.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortConfig.direction === "ascending"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }
        return 0;
      });
    }

    return filteredData;
  }, [data, sortConfig, filterText, globalFilterText]);

  /// GLOBAL SHOW L3 PROPAGATION 
const [isVisibleShow, setIsVisibleShow] = useRecoilState(showL3isVisible);
const [isAnimatingShow, setIsAnimatingShow] = useRecoilState(showL3isAnimating);
const { toggleOverlayL3, isVisibleL3, isAnimatingL3, closeOverlayL3 } = useOverlayControlL3Show("/Cybersecurity/Organizzazione/Ruoli/CreaL3/1");
const [originalPath, setOriginalPath] = useRecoilState(showL3initialPath);
const location = useLocation();

const handleShowL3 = () => {
  setOriginalPath(location.pathname)
  toggleOverlayL3();  // Properly using the hook inside an event handler
};

///// GLOBAL SHOW L4 PROPAGATION END

  return (
    <TableContainer>
      <TopContainer>
        <SearchInputContainer>
          <Search
            placeholder="Filtra per modello organizzativo "
            onChange={handleFilterChange}
          />
          <SearchIcon src="/funnel.svg" />
        </SearchInputContainer>
        <SearchInputContainer>
          <Search
            placeholder="Cerca in tutta la tabella"
            onChange={handleGlobalFilterChange}
          />
          <SearchIcon src="/search.svg" />
        </SearchInputContainer>

        <IconsGroup>
          <IconButton onClick={handleShowL3}>
            <BackgroundIcon loading="eager" alt="" src="/doc.svg" />
          </IconButton>
          {/* <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/edit.svg" />
          </IconButton> */}
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/delete.svg" />
          </IconButton>
          <IconButton>
            <BackgroundIcon
              loading="eager"
              alt=""
              src="/add.svg"
              onClick={handleShowL3} // Use the prop here
            />
          </IconButton>
        </IconsGroup>
      </TopContainer>

      <StyledTable>
        <StyledThead>
          <StyledRuoligeneraliTh
            onClick={() => requestSort("organization_model_name")}
          >
            <TableTitleText>▼ Modello organizzativo</TableTitleText>
          </StyledRuoligeneraliTh>
          <StyledRuoligeneraliTh
            onClick={() => requestSort("role_description")}
          >
            <TableTitleText>▼ Ruolo</TableTitleText>
          </StyledRuoligeneraliTh>
          <StyledTh onClick={() => requestSort("role_long_description")}>
            <TableTitleText>▼ Descrizione responsabilità</TableTitleText>
          </StyledTh>
        </StyledThead>
      </StyledTable>
      <TableScrollableDiv>
        <StyledTableItem>
          <StyledTbody>
            {filteredAndSortedData.map((item) => (
              <StyledTr
                key={item.id} // Use the unique ID as key instead of index
                onClick={() => handleRowSelect(item.id)} // Set the selected item ID on click
                selected={selectedId === item.id} // Conditionally apply the background color
              >
                <StyledRuoliGeneraliTd title={item.organization_model_name}>
                  <TableItemText>{item.organization_model_name}</TableItemText>
                </StyledRuoliGeneraliTd>
                <StyledRuoliGeneraliTd title={item.role_description}>
                  <TableItemText>{item.role_description}</TableItemText>
                </StyledRuoliGeneraliTd>
                <StyledTd>
                  <TableItemText title={item.role_long_description}>
                    {item.role_long_description}
                  </TableItemText>
                </StyledTd>
              </StyledTr>
            ))}
          </StyledTbody>
        </StyledTableItem>
      </TableScrollableDiv>

      <OverlayContext.Provider
        value={{
          closeOverlay: closeOverlayAdd2,
          selectedL1,
          setSelectedL1,
          selectedL2,
          setSelectedL2,
          selectedL3,
          setSelectedL3,
          selectedL4,
          setSelectedL4,
        }}
      >
        {isVisibleShow && (
          <OverlayComponentAdd1 isAnimating={isAnimatingShow}>
            {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
            <FadeOutComponent>
              <FrameTopGradient100></FrameTopGradient100>
            </FadeOutComponent>

            <Outlet />
          </OverlayComponentAdd1>
        )}
      </OverlayContext.Provider>
    </TableContainer>
  );
};



const Board27001Dashboard: React.FC = () => {
  const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
  const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [data, setData] = useState<DataItem[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  // useKeycloak should be called inside the functional component
  const { keycloak, initialized } = useKeycloak();

  const token = initialized && keycloak.authenticated ? keycloak.token : null;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) throw new Error("User is not authenticated");

        const response = await fetch(URL_RoleModels, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("Response Data:", responseData);

        // Check if responseData is an array and transform each item
        if (Array.isArray(responseData)) {
          const transformedData = responseData.map((item) => ({
            id: item.id,
            organization_model_name: item.organization_model_name,
            role_description: item.role_description,
            role_long_description: item.role_long_description,
            // Map other properties as needed
          }));

          console.log("Transformed Data:", transformedData);
          setData(transformedData);
        } else {
          console.error("responseData is not an array:", responseData);
        }
      } catch (error) {
        setError(
          error instanceof Error ? error.message : "An unknown error occurred"
        );
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  return (
    <div>
      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton onClick={() => setIsMessageBoxVisible(false)}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <DataTable data={data} />
    </div>
  );
};

export default Board27001Dashboard;
