import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Accordion from "./accordion";
import { useNavigate } from 'react-router-dom';


import {
  FrameNavLogo,
  PicLogoIcon,
 
  FrameLeftNav,
  FrameNavButtons,
  NavButtonWhite,
  NavButtonWhiteFocused,
  NavButtonWhiteText,
  NavButtonWhiteChild,
  NavButtonWhiteChildText,
  NavButtonWhiteChildFocused,
  NavButtonBlue,
  NavButtonBlueFocused,
  NavButtonBlueText,
  NavButtonBlueChild,
  NavButtonBlueChildFocused,
  ArrowGrey,
  ArrowGreyRight,
  ArrowWhite,
  ArrowWhiteRight,
  LineMagenta40,
  LineMagenta50,
  FrameButtonIcon,
  IcoButtonIcon,
  FrameNavWorkspace,
  WorkspaceLine1,
  WorkspaceLine2,
  FrameBoardMain,
  PageRoot,
  ScrollableDiv,
  ScrollableDivNav,
} from "./styled-components";

// Define a type for the props
interface NavButtonBlueTextProps {
  to: string;
  children: React.ReactNode;
}

// Functional component wrapper for navigation
const NavButton: React.FC<NavButtonBlueTextProps> = ({ to, children }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(to);
  };

  return (
    <NavButtonBlue onClick={handleNavigate}>
      {children}
    </NavButtonBlue>
  );
};


const IsoMenu: React.FC = () => {


  return (
    <FrameLeftNav>
      {/* <FrameNavWorkspace>
         <WorkspaceLine1>WORKSPACE 1234567890</WorkspaceLine1>
         <WorkspaceLine2>Ecubit srl</WorkspaceLine2>
        </FrameNavWorkspace>
        */}
      <ScrollableDivNav>

        <FrameNavButtons>
         

          <Accordion />

        

          <NavButton to="Cybersecurity/Documentazione">
            <NavButtonBlueText >Documentazione</NavButtonBlueText>
            <ArrowWhiteRight alt="" src="/arrow-white-right.svg" />
            <FrameButtonIcon>
              <IcoButtonIcon alt="" src="/documentazione.svg" />
            </FrameButtonIcon>
          </NavButton>

          <NavButton to="Cybersecurity/RiskManagement">
            <NavButtonBlueText >Risk management</NavButtonBlueText>
            <ArrowWhiteRight alt="" src="/arrow-white-right.svg" />
            <FrameButtonIcon>
              <IcoButtonIcon alt="" src="/documentazione.svg" />
            </FrameButtonIcon>
          </NavButton>


          <NavButton to="Cybersecurity/StrumentiAggiuntivi">
            <NavButtonBlueText >Strumenti aggiuntivi</NavButtonBlueText>
            <ArrowWhiteRight alt="" src="/arrow-white-right.svg" />
            <FrameButtonIcon>
              <IcoButtonIcon alt="" src="/documentazione.svg" />
            </FrameButtonIcon>
          </NavButton>


          
        </FrameNavButtons>
      </ScrollableDivNav>
      <FrameNavLogo>
        <PicLogoIcon alt="" src="/logo.svg" />
      </FrameNavLogo>
    </FrameLeftNav>
  );
};

export default IsoMenu;
