import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  AttachedFilesList,
  Backdrop,
  CloseMessageButton,
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FileListScrollbar,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  IconsGroup,
  InputButtonText,
  InputContainer,
  InputDescriptionControlliNota3,
  InputDescriptionText,
  InputTextbox,
  InputTextboxButton,
  InputTextboxControlliNota3,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";

import { NavButtonWhiteText } from "./../../styled-components";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { addL4AttachedFilesInfo, addL4DocumentsAttached, addL4DocumentsPath, addL4fiscalCode } from "../cybersecurity-board-contestoleadership-organizzazione-sub/L4-Add-recoil";
import { URL_FileManager, URL_FileManagerList, useApi, useToken } from "./../../../../API";
import {
  ListContainer,
  ListContainerDocsReduced,
  ListDescriptionText,
  ListDescriptionTextDocsReduced,
  ListScrollbar,
  ListScrollbarDocsReduced,
  ListTextbox,
  ListTextbox50,
} from "./../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import { FileListItem, editL4DocPath, editL4FileListItemState } from "../cybersecurity-board-contestoleadership-organizzazione-sub/L4-Edit-recoil";
import { PATH_Controlli, PATH_L4 } from "./../../../../docPaths";
import { TAB_DESCRIPTION_CONTROLLI_7, TAB_DESCRIPTION_GLOBAL } from "../tabs-description";
import { GAP_controlID, GAP_notes_ev, reFetchIndicatorGAPControlli } from "../gap-recoil";
import { addLuoghiDeleteConfirmationDialog } from "../cybersecurity-board-contestoleadership-infrastruttura-sub/Luoghi-Add-recoil";
import { reFetchIndicatorPlaces } from "../../../../recoil";

export const useUploadFile = () => {
  const { post } = useApi();

  const upload = useCallback(
    async (dir: string, file: File, fileName: string) => {
      const formData = new FormData();
      formData.append("file", file, fileName);

      const url = `${URL_FileManager}${encodeURIComponent(dir)}&file=${encodeURIComponent(fileName)}`;

      try {
        const response = await post(url, formData);
        return response;
      } catch (error) {
        console.error(`Failed to upload file: ${fileName}`, error);
        throw error;
      }
    },
    [post]
  );

  return upload;
};

export const useHandleFileUpload = () => {
  const uploadFile = useUploadFile();

  // Adjust the signature to accept an array of File objects
  const handleFileUpload = useCallback(
    async (selectedFiles: File[] | null, dir = "directory") => {
      if (selectedFiles) {
        const uploadPromises = selectedFiles.map((file) => uploadFile(dir, file, file.name));

        try {
          await Promise.all(uploadPromises);
          console.log("All files uploaded successfully");
        } catch (error) {
          console.error("Error uploading one or more files:", error);
        }
      }
    },
    [uploadFile]
  );

  return handleFileUpload;
};

export const useInitiateUpload = () => {
  const docAttached = useRecoilValue(addL4DocumentsAttached);
  const [gapId, setGapId] = useRecoilState(GAP_controlID);
  const handleFileUpload = useHandleFileUpload();
  const docPath = `${PATH_Controlli}/${gapId}`;
  const initiateUpload = useCallback(async () => {
    console.log("Files to upload:", docAttached);

    if (docAttached && docAttached.length > 0) {
      try {
        await handleFileUpload(docAttached, docPath);
        console.log("File(s) uploaded successfully.");
      } catch (error) {
        console.error("Error uploading file(s):", error);
      }
    } else {
      console.log("No files selected for upload.");
    }
  }, [docAttached, handleFileUpload]);

  return initiateUpload;
};

export const handleDownload = async (token: string | undefined | null, dir: string, fileName: string) => {
  const url = `${URL_FileManager}${encodeURIComponent(dir)}&file=${encodeURIComponent(fileName)}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorHeader = response.headers.get("X-App-Error");
      console.error("Error header:", errorHeader);
      throw new Error(`API request failed: ${response.statusText}`);
    }

    response.headers.forEach((value, key) => {
      console.log(`Response header: ${key} = ${value}`);
    });

    const data = await response.blob();
    const downloadUrl = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(downloadUrl);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

const useFetchDirectoryContents = (docPath: string, token: string | null | undefined) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [directoryContents, setDirectoryContents] = useRecoilState(editL4FileListItemState);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorGAPControlli);

  useEffect(() => {
    const fetchDirectoryContents = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${URL_FileManagerList}${docPath}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch directory contents");
        }

        const filePaths: string[] = await response.json();
        const transformedData: FileListItem[] = filePaths.map((filePath, index) => ({
          id: `file-${index}`,
          path: filePath,
          name: filePath.split("/").pop() || "",
        }));

        setDirectoryContents(transformedData);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unexpected error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchDirectoryContents();
  }, [docPath, token, reFetchIndicator]);

  return { directoryContents };
};

const MainComponent = () => {
  ////////////RECOIL
  const [, setDirectoryContents] = useState<FileListItem[]>([]);
  const token = useToken();
  //const [docPath, setDocPath] = useRecoilState(addL4DocumentsPath);
  const [docPath, setDocPath] = useRecoilState(editL4DocPath);
  const [docAttached, setDocAttached] = useRecoilState(addL4DocumentsAttached);
  const [filesInfo, setFilesInfo] = useRecoilState(addL4AttachedFilesInfo);
  const { directoryContents } = useFetchDirectoryContents(docPath, token);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [gapNoteEv, setGapNoteEv] = useRecoilState(GAP_notes_ev);
  const [gapId, setGapId] = useRecoilState(GAP_controlID);

  const removeFile = (index: number) => {
    // Remove file from docAttached and filesInfo based on index
    setDocAttached((currentFiles) => currentFiles.filter((_, i) => i !== index));
    setFilesInfo((currentFilesInfo) => currentFilesInfo.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const newPath = `${PATH_Controlli}/${gapId}`.replace(/\./g, "_");
    setDocPath(newPath);
  }, [gapId]); // Dependency array includes gapId to react on its changes

  const handleButtonClick = () => {
    // Check if the current ref is not null before calling click
    if (fileInputRef.current !== null) {
      fileInputRef.current.value = ""; // Reset the value
      fileInputRef.current.click();
    }
  };

  const extractDir = (filePath: string) => {
    const pathParts = filePath.split("/");
    pathParts.pop(); // Remove the last part, which is the filename
    return pathParts.join("/"); // Rejoin the remaining parts to get the directory path
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFileList = Array.from(files); // Convert FileList to Array

      // Append new files to the existing files
      setDocAttached((currentFiles) => [...currentFiles, ...newFileList]);

      const newFileInfoArray = newFileList.map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
      }));
      console.log("File info: ", newFileInfoArray);
      // Append new file info for display
      setFilesInfo((currentFilesInfo) => [...currentFilesInfo, ...newFileInfoArray]);
    }
  };

  // Function to handle file deletion
  const handleDelete = async () => {
    if (!fileToDelete || !token) return;

    const dir = extractDir(fileToDelete.path);
    const fileName = fileToDelete.name;
    const url = `${URL_FileManager}${encodeURIComponent(dir)}&file=${encodeURIComponent(fileName)}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to delete file: ${response.statusText}`);
      }

      console.log("File deleted successfully:", fileName);
      setIsConfirmDialogVisible(false); // Close the dialog
      setRefetchIndicator(prev => prev + 1);
      // Optionally update the UI here to reflect the deletion
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useRecoilState(addLuoghiDeleteConfirmationDialog);
  const [fileToDelete, setFileToDelete] = useState<FileListItem | null>(null);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorGAPControlli);
  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionControlliNota3>
              Nota evidenze
              {/* <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip> */}
            </InputDescriptionControlliNota3>

            <InputTextboxControlliNota3 placeholder="" value={gapNoteEv} onChange={(e) => setGapNoteEv(e.target.value)} />
            {/* <IconContainer /> */}
          </InputContainer>

          <ListContainerDocsReduced>
            <ListDescriptionTextDocsReduced>Documentazione</ListDescriptionTextDocsReduced>
            <ListTextbox50>
              <ListScrollbarDocsReduced>
                {directoryContents.map((file) => (
                  <li
                    key={file.id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{file.name}</span>
                    <IconsGroup>
                      <IconButton
                        onClick={() => handleDownload(token, extractDir(file.path), file.name)}
                        size="small"
                        style={{ marginLeft: "auto" }} // This will push the button to the right
                      >
                        <DownloadIcon style={{ fontSize: "14px", color: "#cccccc" }} />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setFileToDelete(file); // Store the file to be deleted
                          setIsConfirmDialogVisible(true); // Show confirmation dialog
                        }}
                        size="small"
                        style={{ marginLeft: "auto" }}
                      >
                        <CancelIcon style={{ fontSize: "14px", color: "#cccccc" }} />
                      </IconButton>
                    </IconsGroup>
                  </li>
                ))}
              </ListScrollbarDocsReduced>
            </ListTextbox50>

            {/* <IconContainerList /> */}
          </ListContainerDocsReduced>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Nome cartella documentazione</InputDescriptionText>
            <InputTextbox disabled={true} placeholder="" value={docPath} />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Allega documenti</InputDescriptionText>
            <input style={{ display: "none" }} ref={fileInputRef} type="file" multiple onChange={handleFileChange} />

            <InputTextboxButton onClick={handleButtonClick}>
              {" "}
              <InputButtonText>Seleziona file dal computer</InputButtonText>{" "}
            </InputTextboxButton>
            <TriangleContainer>▼</TriangleContainer>
            <IconContainer />
          </InputContainer>

          {filesInfo.length > 0 && (
            <AttachedFilesList>
              <FileListScrollbar>
                {filesInfo.map(
                  (file, index) =>
                    file && (
                      <li
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <UploadFileIcon
                          style={{
                            fontSize: "18px",
                            color: "#cccccc",
                            marginRight: "10px",
                          }}
                        />
                        <span>{file.name}</span>

                        <IconButton
                          onClick={() => removeFile(index)}
                          size="small"
                          style={{ marginLeft: "auto" }} // This will push the button to the right
                        >
                          <CancelIcon style={{ fontSize: "14px", color: "#cccccc" }} />
                        </IconButton>
                      </li>
                    )
                )}
              </FileListScrollbar>
            </AttachedFilesList>
          )}

          <div>{/* <button onClick={handleDownload}>Download File</button> */}</div>
          {/* <button onClick={initiateUpload}>Upload Files</button> */}
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_docs.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>{TAB_DESCRIPTION_CONTROLLI_7 ? TAB_DESCRIPTION_CONTROLLI_7 : TAB_DESCRIPTION_GLOBAL}</DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>

      {isConfirmDialogVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>Sei sicuro di voler rimuovere questo controllo?</MessageboxText>
            </p>
            <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
              <CloseMessageButton onClick={() => setIsConfirmDialogVisible(false)}>
                <MessageboxText>No</MessageboxText>
              </CloseMessageButton>
              <CloseMessageButton onClick={handleDelete}>
                <MessageboxText>Rimuovi</MessageboxText>
              </CloseMessageButton>
            </div>
          </MessageContainer>
        </Backdrop>
      )}
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
