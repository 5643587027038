import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import {
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  addL2SchedaGeneraleNomeCompleto,
  addL2SchedaGeneraleNomeAbbreviato,
  addL2SchedaGeneraleEmail,
  addL2SchedaGeneraleTelefono,
  addL2SchedaGeneraleFax,
  addL2SchedaGeneralePEC,
  addL2SchedaGeneraleReferente,
  addL2SchedaGeneraleIndirizzo,
} from "../L2-Add-recoil";
import { Tooltip } from "@mui/material";
import { TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_L2_1_SHOW } from "../../tabs-description";

const MainComponent = () => {
  ////////////RECOIL

  const [nomeCompleto, setNomeCompleto] = useRecoilState(
    addL2SchedaGeneraleNomeCompleto
  );
  const [nomeAbbreviato, setNomeAbbreviato] = useRecoilState(
    addL2SchedaGeneraleNomeAbbreviato
  );
  const [Email, setEmail] = useRecoilState(addL2SchedaGeneraleEmail);
  const [Telefono, setTelefono] = useRecoilState(addL2SchedaGeneraleTelefono);
  const [Fax, setFax] = useRecoilState(addL2SchedaGeneraleFax);
  const [PEC, setPEC] = useRecoilState(addL2SchedaGeneralePEC);
  const [Referente, setReferente] = useRecoilState(
    addL2SchedaGeneraleReferente
  );
  const [Indirizzo, setIndirizzo] = useRecoilState(
    addL2SchedaGeneraleIndirizzo
  );

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Nome completo
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={nomeCompleto}
              onChange={(e) => setNomeCompleto(e.target.value)}
            />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              Nome abbreviato
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={nomeAbbreviato}
              onChange={(e) => setNomeAbbreviato(e.target.value)}
            />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Sito web</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={Indirizzo}
              onChange={(e) => setIndirizzo(e.target.value)}
            />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Referente</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={Referente}
              onChange={(e) => setReferente(e.target.value)}
            />
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Telefono</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={Telefono}
              onChange={(e) => setTelefono(e.target.value)}
            />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Fax</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={Fax}
              onChange={(e) => setFax(e.target.value)}
            />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Email</InputDescriptionText>

            <InputTextbox
              placeholder=""
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <IconContainer />
          </InputContainer>
          <InputContainer>
            <InputDescriptionText>PEC</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={PEC}
              onChange={(e) => setPEC(e.target.value)}
            />
            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
        {TAB_DESCRIPTION_L2_1_SHOW ? TAB_DESCRIPTION_L2_1_SHOW : TAB_DESCRIPTION_GLOBAL}
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
