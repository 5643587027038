import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  AttachedFilesList,
  CreaContainerColumn,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  IconContainer,
  IconSquare,
  InputButtonText,
  InputContainer,
  InputDescriptionText,
  InputTextbox,
  InputTextboxButton,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";

import { NavButtonWhiteText } from "../../../styled-components";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  addL1DocumentsPath,
  addL1DocumentsAttached,
  addL1AttachedFilesInfo,
  addL1SchedaGeneraleNomeAbbreviato,
  showL1FileListItemState,
  FileListItem,
} from "../L1-Add-Recoil";
import {
  URL_FileManager,
  URL_FileManagerList,
  useApi,
  useToken,
} from "../../../../../API";
import { PATH_L1, PATH_L2 } from "../../../../../docPaths";
import {
  ListContainer,
  ListDescriptionText,
  ListScrollbar,
  ListTextbox,
} from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import {
  addL2DocumentsAttached,
  addL2DocumentsPath,
  addL2SchedaGeneraleNomeAbbreviato,
  showL2FileListItemState,
} from "../L2-Add-recoil";
import { TAB_DESCRIPTION_GLOBAL, TAB_DESCRIPTION_L2_7_SHOW } from "../../tabs-description";

export const useUploadFile = () => {
  const { post } = useApi();

  const upload = useCallback(
    async (dir: string, file: File, fileName: string) => {
      const formData = new FormData();
      formData.append("file", file, fileName);

      const url = `${URL_FileManager}${encodeURIComponent(
        dir
      )}&file=${encodeURIComponent(fileName)}`;

      try {
        const response = await post(url, formData);
        console.log(response);
        return response;
      } catch (error) {
        console.error(`Failed to upload file: ${fileName}`, error);
        throw error;
      }
    },
    [post]
  );

  return upload;
};

export const useHandleFileUpload = () => {
  const uploadFile = useUploadFile();

  // Adjust the signature to accept an array of File objects
  const handleFileUpload = useCallback(
    async (selectedFiles: File[] | null, dir = "directory") => {
      if (selectedFiles) {
        const uploadPromises = selectedFiles.map((file) =>
          uploadFile(dir, file, file.name)
        );

        try {
          await Promise.all(uploadPromises);
          console.log("All files uploaded successfully");
        } catch (error) {
          console.error("Error uploading one or more files:", error);
        }
      }
    },
    [uploadFile]
  );

  return handleFileUpload;
};

export const useInitiateUploadL1 = () => {
  const docAttached = useRecoilValue(addL2DocumentsAttached);
  const docPath = useRecoilValue(addL2SchedaGeneraleNomeAbbreviato);
  const handleFileUpload = useHandleFileUpload();

  const initiateUpload = useCallback(async () => {
    console.log("Files to upload:", docAttached);

    if (docAttached && docAttached.length > 0) {
      try {
        await handleFileUpload(docAttached, `${PATH_L2}${docPath}`);
        console.log("File(s) uploaded successfully.");
      } catch (error) {
        console.error("Error uploading file(s):", error);
      }
    } else {
      console.log("No files selected for upload.");
    }
  }, [docAttached, handleFileUpload]);

  return initiateUpload;
};

export const handleDownload = async (
  token: string | undefined | null,
  dir: string,
  fileName: string
) => {
  const url = `${URL_FileManager}${encodeURIComponent(
    dir
  )}&file=${encodeURIComponent(fileName)}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorHeader = response.headers.get("X-App-Error");
      console.error("Error header:", errorHeader);
      throw new Error(`API request failed: ${response.statusText}`);
    }

    response.headers.forEach((value, key) => {
      console.log(`Response header: ${key} = ${value}`);
    });

    const data = await response.blob();
    const downloadUrl = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(downloadUrl);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

const useFetchDirectoryContents = (
  docPath: string,
  token: string | null | undefined
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [directoryContents, setDirectoryContents] = useRecoilState(
    showL2FileListItemState
  );

  useEffect(() => {
    const fetchDirectoryContents = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${URL_FileManagerList}${docPath}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch directory contents");
        }

        const filePaths: string[] = await response.json();
        const transformedData: FileListItem[] = filePaths.map(
          (filePath, index) => ({
            id: `file-${index}`,
            path: filePath,
            name: filePath.split("/").pop() || "",
          })
        );

        setDirectoryContents(transformedData);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unexpected error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchDirectoryContents();
  }, [docPath, token]);

  return { directoryContents };
};

const MainComponent = () => {
  ////////////RECOIL

  //const [docPath, setDocPath] = useRecoilState(PartiDocumentsPath);
  const [DocumentsPath, setDocumentsPath] = useRecoilState(addL2DocumentsPath);
 
  const [docAttached, setDocAttached] = useRecoilState(addL1DocumentsAttached);
  const [filesInfo, setFilesInfo] = useRecoilState(addL1AttachedFilesInfo);
  const token = useToken();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { directoryContents } = useFetchDirectoryContents(DocumentsPath, token);

  const removeFile = (index: number) => {
    // Remove file from docAttached and filesInfo based on index
    setDocAttached((currentFiles) =>
      currentFiles.filter((_, i) => i !== index)
    );
    setFilesInfo((currentFilesInfo) =>
      currentFilesInfo.filter((_, i) => i !== index)
    );
  };

  const handleButtonClick = () => {
    // Check if the current ref is not null before calling click
    if (fileInputRef.current !== null) {
      fileInputRef.current.value = ""; // Reset the value
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFileList = Array.from(files); // Convert FileList to Array

      // Append new files to the existing files
      setDocAttached((currentFiles) => [...currentFiles, ...newFileList]);

      const newFileInfoArray = newFileList.map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
      }));
      console.log("File info: ", newFileInfoArray);
      // Append new file info for display
      setFilesInfo((currentFilesInfo) => [
        ...currentFilesInfo,
        ...newFileInfoArray,
      ]);
    }
  };

  const extractDir = (filePath: string) => {
    const pathParts = filePath.split("/");
    pathParts.pop(); // Remove the last part, which is the filename
    return pathParts.join("/"); // Rejoin the remaining parts to get the directory path
  };

  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <ListContainer>
            <ListDescriptionText>Documentazione</ListDescriptionText>
            <ListTextbox>
              <ListScrollbar>
                {directoryContents.map((file) => (
                  <li
                    key={file.id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{file.name}</span>
                    <IconButton
                      onClick={() =>
                        handleDownload(token, extractDir(file.path), file.name)
                      }
                      size="small"
                      style={{ marginLeft: "auto" }} // This will push the button to the right
                    >
                      <DownloadIcon
                        style={{ fontSize: "14px", color: "#cccccc" }}
                      />
                    </IconButton>
                    {/* <IconButton
                 onClick={() => handleDownload(token, extractDir(file.path), file.name)}

                size="small"
                style={{ marginLeft: 'auto' }} // This will push the button to the right
              >
                <CancelIcon style={{ fontSize: '14px', color: '#cccccc' }} />
                
              </IconButton> */}
                  </li>
                ))}
              </ListScrollbar>
            </ListTextbox>

            {/* <IconContainerList /> */}
          </ListContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Nome cartella documentazione
            </InputDescriptionText>
            <InputTextbox disabled={true} placeholder="" value={DocumentsPath} />
            <IconContainer />
          </InputContainer>
          {/* <button onClick={initiateUpload}>Upload Files</button> */}
          {/* 
          <InputContainer>
            <InputDescriptionText>Allega documenti</InputDescriptionText>
            <input style={{ display: "none" }} ref={fileInputRef} type="file" multiple onChange={handleFileChange} />

            <InputTextboxButton onClick={handleButtonClick}>
              {" "}
              <InputButtonText>Seleziona file dal computer</InputButtonText>{" "}
            </InputTextboxButton>
            <TriangleContainer>▼</TriangleContainer>
            <IconContainer />
          </InputContainer> */}

          {filesInfo.length > 0 && (
            <AttachedFilesList>
              {filesInfo.map(
                (file, index) =>
                  file && (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <UploadFileIcon
                        style={{
                          fontSize: "18px",
                          color: "#cccccc",
                          marginRight: "10px",
                        }}
                      />
                      <span>{file.name}</span>

                      <IconButton
                        onClick={() => removeFile(index)}
                        size="small"
                        style={{ marginLeft: "auto" }} // This will push the button to the right
                      >
                        <CancelIcon
                          style={{ fontSize: "14px", color: "#cccccc" }}
                        />
                      </IconButton>
                    </li>
                  )
              )}
            </AttachedFilesList>
          )}

          <div>
            {/* <button onClick={handleDownload}>Download File</button> */}
          </div>
        </CreaContainerColumn>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_docs.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
        {TAB_DESCRIPTION_L2_7_SHOW ? TAB_DESCRIPTION_L2_7_SHOW : TAB_DESCRIPTION_GLOBAL}
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
