import React, { useState, useEffect, useRef, useContext, useMemo, } from "react";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate, } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { CategoryListBackground, CreaContainerColumn, CreaContainerColumnMultiple, CreaContainerColumnVertical, CreaContainerColumnVerticalMultiple, CustomDropdownItem, CustomDropdownList, DescriptionContainerCrea, DescriptionContainerCreaMultiple, DescriptionContainerCreaText, DropDownMenuScrollbar, FlexContainerCrea, FlexContainerCreaMultiple, IconContainer, IconSquare, InputContainer, InputDescriptionText, InputDescriptionTextCategoryAsset, InputDescriptionTextTriple, InputTextbox, InputTextboxTriple, LastPopupScrollbarAggiungiAsset, OverlayComponentWhiteAdd1, OverlayComponentWhiteAdd1Multiple, TriangleContainer } from '../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { 

  isLoadingRec,
  setErrorRec,


} from '../../../../../recoil';
import { AssetComplianceDate, AssetIdentificativo, AssetName, AssetNomeAbbreviato, AssetSelectedCategoryNode, TreeNode, addAssetAssetStatusID, addAssetSelectedCategoryID, addAssetSpecificheTecniche, treeDataState } from "../ElencoAsset-Add-recoil";
import { URL_CategoryTree } from "../../../../../API";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { debounce } from "lodash";
import { Tooltip } from "@mui/material";

import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { BackdropTransparent, MessageContainer, MessageboxText } from "../cybersecurity-board-infrastruttura-organigramma-styled-components";



const MainComponent = () => {

////////////RECOIL

const [name, setName] = useRecoilState(AssetName);
const [identificativo, setIdentificativo] = useRecoilState(AssetIdentificativo);

const [specifiche, setSpecifiche] = useRecoilState(
addAssetSpecificheTecniche
);
const [categoryID, setCategoryID] = useRecoilState(
addAssetSelectedCategoryID
);
const [complianceDate, setComplianceDate] =
useRecoilState(AssetComplianceDate);

const [isLoading, setIsLoading] =
useRecoilState(isLoadingRec);

const [error, setError] =
useRecoilState(setErrorRec);

const [dataTree, setDataTree] =
useRecoilState(treeDataState);

const [selectedNodeId, setSelectedNodeId] =
useRecoilState(AssetSelectedCategoryNode);


const { keycloak, initialized } = useKeycloak();

const token = initialized && keycloak.authenticated ? keycloak.token : null;

useEffect(() => {
console.log("Selected Category ID:", categoryID);
}, [categoryID]); // This useEffect triggers every time categoryID changes

useEffect(() => {
const fetchData = async () => {
  setIsLoading(true);
  try {
    const response = await fetch(URL_CategoryTree, {
      method: "GET", // Explicitly define the method, even if it's 'GET'
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Adding the Authorization header
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    setDataTree(data);
  } catch (error) {
    setError("Failed to fetch data");
    console.error("Error:", error);
  }
  setIsLoading(false);
};

fetchData();
}, []);

const handleNodeSelect = (
event: React.MouseEvent<HTMLElement>,
nodeId: string
) => {
setCategoryID(nodeId);
setSelectedNodeId(nodeId);
};
const renderTree = (nodes: TreeNode) => (
<TreeItem
  key={nodes.value.id}
  nodeId={nodes.value.id}
  label={nodes.value.name}
  onClick={(event) => handleNodeSelect(event, nodes.value.id)}
>
  {Array.isArray(nodes.leaves)
    ? nodes.leaves.map((node) => renderTree(node))
    : null}
</TreeItem>
);

const [assetStatusID, setAssetStatusID] = useRecoilState(
addAssetAssetStatusID
);
const [isDropdownOpenStatus, setIsDropdownOpenStatus] = useState(false);
const statusDropdownRef = useRef<HTMLDivElement>(null);
const [searchTerm, setSearchTerm] = useState<string>("");
const [debouncedTerm, setDebouncedTerm] = useState("");

useEffect(() => {
function handleClickOutside(event: MouseEvent) {
    if (statusDropdownRef.current && event.target instanceof Node && !statusDropdownRef.current.contains(event.target)) {
        setIsDropdownOpenStatus(false);
    }
}

// Add event listener
document.addEventListener("mousedown", handleClickOutside);
return () => {
    // Cleanup event listener
    document.removeEventListener("mousedown", handleClickOutside);
};
}, []);

const debouncedSearch = useRef(debounce((newTerm) => {
setDebouncedTerm(newTerm);
}, 300)); 
useEffect(() => {

debouncedSearch.current(searchTerm);
}, [searchTerm]);

const assetStatusOptions = [
{ id: "asset.status.in_use", label: "In uso" },
{ id: "asset.status.eliminated", label: "Ritirato" },
{ id: "asset.status.in_storage", label: "In magazzino" },
{ id: "asset.status.archived", label: "Archiviato" },
{ id: "asset.status.in_transit", label: "In transito" },
{ id: "asset.status.missing", label: "Mancante" },
];


const filterTree = (nodeList: TreeNode[], term: string): TreeNode[] => {
return nodeList.reduce<TreeNode[]>((acc, node) => {
  // Check if the current node's name matches the search term
  const nameMatches = node.value.name.toLowerCase().includes(term.toLowerCase());

  // Recursively filter the children (leaves)
  const filteredChildren = node.leaves ? filterTree(node.leaves, term) : [];

  // Include the current node if the name matches or if any children match
  if (nameMatches || filteredChildren.length > 0) {
    acc.push({
      ...node,
      // Only include leaves array if there are any filtered children
      leaves: filteredChildren.length > 0 ? filteredChildren : undefined
    });
  }

  return acc;
}, []);
};

const filteredDataTree = debouncedTerm ? filterTree(dataTree, debouncedTerm) : dataTree;

const getAllNodeIds = (nodes: TreeNode[]) => {
let ids: string[] = [];
nodes.forEach(node => {
  ids.push(node.value.id);
  if (node.leaves) {
    ids = ids.concat(getAllNodeIds(node.leaves));
  }
});
return ids;
};
const allNodeIds = useMemo(() => getAllNodeIds(dataTree), [dataTree]);


  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Nome asset
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              Inventario n.
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={identificativo}
              onChange={(e) => setIdentificativo(e.target.value)}
            />
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>
              Stato asset
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
             
            </InputDescriptionText>
            <div
            ref={statusDropdownRef}
              onClick={() => setIsDropdownOpenStatus(!isDropdownOpenStatus)}
              style={{ position: "relative", width: '100%' }}
            >
              <InputTextbox
                as="input"
                value={
                  assetStatusOptions.find(
                    (option) => option.id === assetStatusID
                  )?.label || ""
                }
                placeholder="Seleziona"
                readOnly
              />
              <TriangleContainer>
                {isDropdownOpenStatus ? "▲" : "▼"}
              </TriangleContainer>
              {isDropdownOpenStatus && (
                <CustomDropdownList>
                  <DropDownMenuScrollbar>
                    {assetStatusOptions.map((option) => (
                      <CustomDropdownItem
                        key={option.id}
                        onClick={() => {
                          setAssetStatusID(option.id);
                          setIsDropdownOpenStatus(false);
                        }}
                      >
                        {option.label}
                      </CustomDropdownItem>
                    ))}
                  </DropDownMenuScrollbar>
                </CustomDropdownList>
              )}
            </div>
          </InputContainer>

          <InputContainer>
            <InputDescriptionTextTriple>
              Specifiche tecniche {specifiche.length}/255
            </InputDescriptionTextTriple>
            <InputTextboxTriple
              placeholder=""
              value={specifiche}
              onChange={(e) => setSpecifiche(e.target.value)}
              maxLength={255}
            />
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
        <InputContainer>
            <InputDescriptionText>Cerca categoria</InputDescriptionText>
            <InputTextbox
              placeholder=""
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
              <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionTextCategoryAsset>
              Categoria
              <Tooltip title="Campo obbligatorio">
                <InfoIcon
                  style={{
                    fontSize: "14px",
                    color: "#04446c",
                    cursor: "pointer",
                    marginLeft: "2px",
                  }}
                />
              </Tooltip>
            </InputDescriptionTextCategoryAsset>

         

            <CategoryListBackground>
              <LastPopupScrollbarAggiungiAsset>
              
                  <TreeView
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                    defaultEndIcon={<div style={{ width: 24 }} />}
                    expanded={allNodeIds}
                    selected={selectedNodeId}
                  >
                    {filteredDataTree.map((treeItem) => renderTree(treeItem))}
                  </TreeView>
              
              </LastPopupScrollbarAggiungiAsset>
            </CategoryListBackground>
          </InputContainer>
        </CreaContainerColumn>
      </FlexContainerCrea>
      {/* <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
              <DescriptionContainerCreaText>
             
              Inserire i dettagli identificativi dell'unità operativa, comprensivi di un nome completo che rifletta le competenze e i servizi offerti, un nome abbreviato per una rapida identificazione, oltre ai principali canali di comunicazione per facilitare un contatto efficace e una pronta corrispondenza


              </DescriptionContainerCreaText>

        </DescriptionContainerCreaMultiple> */}
 {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
