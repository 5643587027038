import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Suspense,
  lazy,
} from "react";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useRecoilState, useRecoilValue } from "recoil";
import dayjs from "dayjs";
import {
  setErrorRec,
  isMessageBoxVisibleRec,
  setMessageRec,
  isLoadingRec,
  reFetchIndicatorL4,
  reFetchIndicatorBusinessProcesses,
  isMessageBoxVisibleClosingRec,
} from "../../../../recoil";

import SchedaGenerale from "./cybersecurity-board-processi-business-Add-sub/processigenerali-businessprocessi-Add-1-SchedaGenerale";
//import Organizzazione from "./cybersecurity-board-processi-business-Add-sub/infrastruttura-luoghi-Add-2-Organizzazione";
// const Infrastruttura = lazy(() => import ("./cybersecurity-board-processi-business-Add-sub/organigramma-L4-Add-3-Infrastruttura").then(module => ({ default: module.default })));
// import Processi from "./cybersecurity-board-processi-business-Add-sub/organigramma-L4-Add-4-Processi";
// import Parti from "./cybersecurity-board-processi-business-Add-sub/organigramma-L4-Add-3-Infrastruttura";
import Compliance from "./cybersecurity-board-processi-business-Add-sub/processigenerali-businessprocessi-Add-6-GestioneCompliance";
import Documentazione, {
  useInitiateUpload,
} from "./cybersecurity-board-processi-business-Add-sub/processigenerali-businessprocessi-Add-7-Documentazione";

import { ButtonGrey, ButtonText } from "../../styled-components";

import {
  FrameTopGradient100,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteTitleAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  Backdrop,
  MessageContainer,
  CloseMessageButton,
  MessageboxText,
  BackdropTransparent,
  CreaSubMenuButtonMultiple,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "../../cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";

import {
  ProcessiAttachedFilesInfo,
  ProcessiComplianceDate,
  ProcessiComplianceResponsible,
  ProcessiDescription,
  ProcessiID,
  ProcessiDocumentsAttached,
  ProcessiDocumentsPath,
  ProcessiKind,
  ProcessiName,
  ProcessiObjective,
  ProcessiComplianceResponsibleID,
  ProcessiIsDropdownOpenDirectors,
  ProcessiConfirmedLV1List,
  ProcessiDeleteConfirmationDialog,
  ProcessiIsDropdownOpenDirectorsCompliance,
  ProcessiOrganizzazioneSelectedL1,
  ProcessiOrganizzazioneSelectedL1ID,
  Processi_2_isDropdownOpenLV1,
  ProcessiSelectionBodyState,
  addProcessSelectedCheckboxesProcessiState,
  ProcessSelectionData,
} from "./Processi-Add-recoil";

import {
  URL_LV4List,
  URL_LV4List_short,
  URL_PlacesFullList,
  URL_Process,
  URL_ProcessesSelected,
  useApi,
  useToken,
} from "../../../../API";
import { PATH_Processi } from "../../../../docPaths";

function generateUUID(): string {
  return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function useGenerateUUID(): string {
  const [ProcessoID, setProcessoID] = useRecoilState<string>(ProcessiID);

  useEffect(() => {
    // Generate UUID and set it
    const newUUID = generateUUID();
    setProcessoID(newUUID);
    console.log("New place ID: ", newUUID);
  }, [setProcessoID]); // This effect should run only once when the component mounts

  return ProcessoID;
}

export const useHandleSave = () => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(
    isMessageBoxVisibleRec
  );
  const [message, setMessage] = useRecoilState(setMessageRec);

  const { closeOverlay } = useContext(OverlayContext);
  const { post } = useApi();

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(
    ProcessiAttachedFilesInfo
  );
  const [documentsAttached, setDocumentsAttached] = useRecoilState(
    ProcessiDocumentsAttached
  );
  const [docPath, setDocPath] = useRecoilState(ProcessiDocumentsPath);
  const [ID, setID] = useRecoilState(ProcessiID);
  const [name, setName] = useRecoilState(ProcessiName);
  const [complianceResponsible, setComplianceResponsible] = useRecoilState(
    ProcessiComplianceResponsible
  );
  const [complianceDate, setComplianceDate] = useRecoilState(
    ProcessiComplianceDate
  );
  const [description, setDescription] = useRecoilState(ProcessiDescription);
  const [kind, setKind] = useRecoilState(ProcessiKind);
  const [objective, setObjective] = useRecoilState(ProcessiObjective);
  const [organizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(
    ProcessiComplianceResponsible
  );
  const [organizzazioneDirettoreID, setOrganizzazioneDirettoreID] =
    useRecoilState(ProcessiComplianceResponsibleID);
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(
    ProcessiIsDropdownOpenDirectorsCompliance
  );
  const initiateUpload = useInitiateUpload();
  const { fetchSelectionData, updateSelection } = useHandleUpdateSelection();
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(
    reFetchIndicatorBusinessProcesses
  );
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(
    addProcessSelectedCheckboxesProcessiState
  );

  
function generateUUID(): string {
  return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function useGenerateUUID(): string {

  const [processID, setprocessID] = useRecoilState<string>(ProcessiID);

  useEffect(() => {
    // Generate UUID and set it
    const newUUID = generateUUID();
    setprocessID(newUUID);
    console.log("New asset ID: ", newUUID);
  }, [setprocessID]); // This effect should run only once when the component mounts

  return processID;
}

  const handleSave = async (processID: string) => {

    if (!kind || !name) {
      setMessage(
        "Si prega di compilare tutti i campi obbligatori: denominazione e categoria"
      );
      setIsMessageBoxVisible(true);
      return; // Exit the function early
    }

    const newData = {
      id: processID,
      name: name,
      kind: kind,
      objective: objective,
      description: description,
      has_compliance_responsible: organizzazioneDirettoreID,
      compliance_responsible_assignment_start: complianceDate,
      compliance_responsible: null,
      documents_folder_link: PATH_Processi,
    };

    try {
      setIsLoading(true);
      console.log(JSON.stringify(newData));
      const responseData = await post(URL_Process, newData);

      initiateUpload();
      // await fetchSelectionData();
      // await updateSelection();
      const fetchedData = await fetchSelectionData();
      if (fetchedData) { // Making sure fetchedData is valid before updating
        await updateSelection(fetchedData); // Assuming updateSelection now takes fetchedData as parameter
      }

      console.log("Process created successfully:", responseData);
      setMessage("Process created successfully!");
      setIsMessageBoxVisible(true);
      setName("");
      setKind("");
      setObjective("");
      setDescription("");
      setOrganizzazioneDirettore("");
      setOrganizzazioneDirettoreID("");

      setRefetchIndicator((prev) => prev + 1);
    } catch (error) {
      console.error("Error creating Process:", error);
      setMessage(
        `Error creating Process: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSave };
};

export const useHandleUpdateSelection = () => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(
    isMessageBoxVisibleRec
  );
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [data, setData] = useRecoilState(ProcessiSelectionBodyState);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(
    reFetchIndicatorBusinessProcesses
  );
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(
    addProcessSelectedCheckboxesProcessiState
  );
  const [ID, setID] = useRecoilState(ProcessiID);
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] =
    useRecoilState(isMessageBoxVisibleClosingRec);

  //const { closeOverlay } = useContext(OverlayContext);
  const { get, patch } = useApi();

  const fetchSelectionData = async (): Promise<ProcessSelectionData[] | null> => {
    setIsLoading(true);
    try {
      const response = await get(`${URL_ProcessesSelected}${ID}`);
      console.log(`Fetched selection data: `, response);
      setData(response); // Assuming the API returns data directly
      setMessage("Data fetched successfully!");
      setIsMessageBoxVisibleClosing(true);
      return response; // Return the fetched data
    } catch (error) {
      console.error("Error fetching selection data:", error);
      setMessage(`Error fetching data: ${
          error instanceof Error ? error.message : "Unknown error"
      }`);
      setIsMessageBoxVisibleClosing(true);
    } finally {
      setIsLoading(false);
    }
    return null; // Ensure that null is returned if the try block fails
};


const updateSelection = async (dataToUpdate: ProcessSelectionData[]) => {
  setIsLoading(true);
  try {
    const updatedItems = dataToUpdate.map((item: ProcessSelectionData) => ({
      ...item,
      selected: selectedCheckboxes.includes(item.content.has_compliance_process),
    }));

    console.log(`Updating selection with data: `, updatedItems);
    const response = await patch(`${URL_ProcessesSelected}${ID}`, updatedItems);
    setMessage("Selection updated successfully!");
    setIsMessageBoxVisibleClosing(true);
    setRefetchIndicator((prev) => prev + 1); // Trigger any dependent processes to refetch
  } catch (error) {
    console.error("Error updating selection:", error);
    setMessage(`Error updating selection: ${
      error instanceof Error ? error.message : "Unknown error"
    }`);
    setIsMessageBoxVisibleClosing(true);
  } finally {
    setIsLoading(false);
  }
};



  return { fetchSelectionData, updateSelection, data, isLoading, message };
};

const MainComponent = () => {
  const token = useToken();

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(
    ProcessiAttachedFilesInfo
  );
  const [complianceDate, setComplianceDate] = useRecoilState(
    ProcessiComplianceDate
  );
  const [documentsAttached, setDocumentsAttached] = useRecoilState(
    ProcessiDocumentsAttached
  );
  const [documentsPath, setDocumentsPath] = useRecoilState(
    ProcessiDocumentsPath
  );
  const [ID, setID] = useRecoilState(ProcessiID);
  const [name, setName] = useRecoilState(ProcessiName);
  const [organizzazioneSelectedL1, setOrganizzazioneSelectedL1] =
    useRecoilState(ProcessiOrganizzazioneSelectedL1);
  const [organizzazioneSelectedL1ID, setOrganizzazioneSelectedL1ID] =
    useRecoilState(ProcessiOrganizzazioneSelectedL1ID);
  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(
    ProcessiConfirmedLV1List
  );
  const [organizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(
    ProcessiComplianceResponsible
  );
  const [organizzazioneDirettoreID, setOrganizzazioneDirettoreID] =
    useRecoilState(ProcessiComplianceResponsibleID);
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(
    ProcessiIsDropdownOpenDirectorsCompliance
  );
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(
    Processi_2_isDropdownOpenLV1
  );

  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(
    addProcessSelectedCheckboxesProcessiState
  );

  const { handleSave } = useHandleSave();

  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(
    isMessageBoxVisibleRec
  );
  const [isMessageBoxVisibleClosing, setIsMessageBoxVisibleClosing] =
    useRecoilState(isMessageBoxVisibleClosingRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  // CUSTOM HOOKS

  useGenerateUUID();

  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); // Close the message box
    closeOverlay();
    setSelectedCheckboxes([]);
  };

  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  const handleCloseMessage = () => {
    setIsMessageBoxVisibleClosing(false); // Close the message box

    setSelectedCheckboxes([]);
  };

  ///////////////// SAVE AND UPLOAD /////////

  const handleSaveAndUpload = async () => {
    await handleSave(ID);
  };

  ///////////////// MESSAGE ANNULLA BUTTON /////////

  const handleClose = () => {
    closeOverlay();
    setSelectedCheckboxes([]);
    setAttachedFilesInfo([]); // Assuming this resets to an empty array
    setDocumentsAttached([]); // Assuming this is a boolean that should be reset to false
    setDocumentsPath(""); // Assuming this resets to an empty string

    // setSurname('');
    // setName('');
    // setBirthDate(dayjs());
    // setBirthPlace('');
    // setGender('');
    // setFiscalCode('');
    // setResidenceAddress('');
    // setDomicileAddress('');
    // setWorkEmail('');
    // setPersonalEmail('');
    // setWorkPhone('');
    // setPrivatePhone('');
  };

  ////////////////////CHANGE ACTIVE COMPONENT///////////

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      // case "Organizzazione":
      //   return <Organizzazione />;
      // case "Infrastruttura":
      //   return <Infrastruttura />;
      // case "ProcessiGenerali":
      //   return <Processi />;
      // case "PartiInteressate":
      //   return <Parti />;
      case "SistemaCompliance":
        return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {isMessageBoxVisibleClosing && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseMessage}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>
        NUOVO PROCESSO
      </OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SchedaGenerale")}
            to="/Cybersecurity/ProcessiGenerali/BusinessProcessi/CreaBusinessProcessi/1"
          >
            Scheda generale
          </CreaSubMenuButtonMultiple>

          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Organizzazione")}
            to="/Cybersecurity/ProcessiGenerali/BusinessProcessi/CreaBusinessProcessi/2"
          >
            Organizzazione
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/ProcessiGenerali/BusinessProcessi/CreaBusinessProcessi/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/ProcessiGenerali/BusinessProcessi/CreaBusinessProcessi/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Infrastruttura/UnitaOperative/CreaL2/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SistemaCompliance")}
            to="/Cybersecurity/ProcessiGenerali/BusinessProcessi/CreaBusinessProcessi/6"
          >
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Documentazione")}
            to="/Cybersecurity/ProcessiGenerali/BusinessProcessi/CreaBusinessProcessi/7"
          >
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>
        <Suspense fallback={<div>Loading...</div>}>
          {renderActiveComponent()}
        </Suspense>
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={handleClose}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSaveAndUpload}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default MainComponent;
