import React, { useState, useEffect, useMemo, useCallback, } from 'react';
import styled from 'styled-components';
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate, Routes, Route, Outlet } from "react-router-dom";
import {OverlayContext} from '../cybersecurity-board-contestoleadership-organizzazione';
import { IconButton,IconsGroup,BackgroundIcon, Backdrop, BackdropTransparent,MessageContainer,MessageboxText,CloseMessageButton, FadeOutComponent, FrameTopGradient100, } from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { StyledTable, StyledTbody, StyledTd, StyledTh, StyledThead, TableItemText, TableTitleText, TableScrollableDiv, TableContainer, TopContainer, Search, SearchIcon, SearchInputContainer, StyledTableItem, StyledPersone2Td, StyledPersone2Th} from "../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-unitaoperative-styled-components";

import { useRecoilState, useRecoilValue } from 'recoil';

import { 
  SelectedL1ID,
  SelectedL2ID,
  SelectedL3ID,
  SelectedL4ID,
  selectedItemsAsset,
  addL2OrganizzazioneDirettore,
  addL2OrganizzazioneDirettoreID,
  addL2OrganizzazioneSelectedL1,
  addL2OrganizzazioneSelectedL1ID,
  reFetchIndicatorParties,
 
} from '../../../../recoil';
import { URL_AltriEnti, URL_Parti, URL_PartiList, useApi } from '../../../../API';
import { PartiComplianceDeleteConfirmationDialog } from './Parti-Add-recoil';


interface DataItem {
  id: string;
  pref_label: string;
  identifier: string;
  has_responsible: string;
  description: string;
  entity_legal_form_category: string;

}

  type SortableKeys = keyof DataItem;

  type StyledTrProps = {
    selected: boolean;
  }

  const StyledTr = styled.tr<StyledTrProps>`

  cursor: pointer;
  background-color: ${props => {
    //console.log(`Is row selected: ${props.selected}`); // This should log true or false based on selection
    return props.selected ? '#f3f7fb' : '#ffffff';
  }};

  &:hover {
    background-color: #e9eff7; // Make sure this doesn't override your selection color
  }
`;
  
interface OverlayComponentProps {
  isAnimating: boolean;
}


const OverlayComponentAdd1 = styled.div<OverlayComponentProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: ${(props) => (props.isAnimating ? "1" : "0")};
  visibility: ${(props) => (props.isAnimating ? "visible" : "hidden")};
`;


//////////// DELETE PART 
const useDeletePlace= () => {

  const { del } = useApi(); 
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorParties);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useRecoilState(PartiComplianceDeleteConfirmationDialog);
  const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);

  const handleDelete = useCallback(async () => {
    if (!L2ID) return;
    
    const url = URL_Parti;
    try {
      await del(url, L2ID);
      console.log(`Deleted party with ID: ${L2ID}`);
      // Move the setRefetchIndicator and dialog visibility updates to a finally block
    } catch (error) {
      console.error('Failed to delete party:', error);
    } finally {
      // Ensure these states are updated after the try/catch block
      setRefetchIndicator(prev => prev + 1);
      setIsConfirmDialogVisible(false); // Close confirmation dialog
    }
  }, [L2ID, del, setRefetchIndicator]);

  const showDeleteConfirmation = useCallback(() => {
    setIsConfirmDialogVisible(true);
  }, []);

  return { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible };
};

/////////////DELETE PLACE END

  const DataTable: React.FC<{ data: DataItem[] }> = ({ data }) => {

    const [selectedAssets, setSelectedAssets] = useRecoilState(selectedItemsAsset);


    const navigate = useNavigate();

    const [isOverlayVisibleAdd2, setIsOverlayVisibleAdd2] = useState(false);
    const [isOverlayAnimatingAdd2, setIsOverlayAnimatingAdd2] = useState(false);
   
    const toggleOverlayAdd2 = () => {
      if (!isOverlayVisibleAdd2) {
        // Check if the current path is '/Cybersecurity/Organizzazione'
        if (window.location.pathname === "/Cybersecurity/PartiInteressate") {
          navigate(`PartiInteressate/CreaPartiInteressate/1`); // Navigate to 'Organigramma/AggiungiL1' if the condition is true
        } else {
          navigate(`${window.location.pathname}/CreaPartiInteressate/1`); // Navigate to '/AggiungiL1' if the condition is false
        }
  
        setIsOverlayVisibleAdd2(true); // Show the overlay
        setTimeout(() => setIsOverlayAnimatingAdd2(true), 10); // Start animation after it's visible
      } else {
        setIsOverlayAnimatingAdd2(false); // Start hiding animation
        setTimeout(() => setIsOverlayVisibleAdd2(false), 310); // Wait for animation to finish before hiding
      }
    };
  
   // Function to close the overlay
   const closeOverlayAdd2 = () => {
    setSelectedAssets([]);
    setDirector('');
    setDirectorId('');
    setL1List('');
    setL1ListID('');
    navigate(`/Cybersecurity/PartiInteressate/PartiInteressate`);
    setIsOverlayAnimatingAdd2(false); // Start the hide animation
    // Use a timeout equal to the transition duration to set visibility to false
    setTimeout(() => {
      setIsOverlayVisibleAdd2(false);
    }, 300); // 300ms is the duration of the exit transition
  };

  
    const [selectedL1, setSelectedL1] = useState<string | null>(null);
    const [selectedL2, setSelectedL2] = useState<string | null>(null);
    const [selectedL3, setSelectedL3] = useState<string | null>(null);
    const [selectedL4, setSelectedL4] = useState<string | null>(null);



const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
const [director, setDirector] = useRecoilState(addL2OrganizzazioneDirettore);
const [directorId, setDirectorId] = useRecoilState(addL2OrganizzazioneDirettoreID);
const [L1List, setL1List] = useRecoilState(addL2OrganizzazioneSelectedL1);
const [L1ListID, setL1ListID] = useRecoilState(addL2OrganizzazioneSelectedL1ID);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  const [sortConfig, setSortConfig] = useState<{ key: SortableKeys; direction: 'ascending' | 'descending' } | null>(null);

  // Function to handle sorting
  const sortedData = useMemo(() => {
    let sortableItems = [...data]; // Create a new array to avoid mutating the original data
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        
        // Handle different types if necessary, e.g., string vs number
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortConfig.direction === 'ascending' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        // Add more conditions for other types if necessary
        
        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  // Click handler for headers
  const requestSort = (key: SortableKeys) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  
  const [selectedId, setSelectedId] = useState<string | null>(null);


  const handleRowSelect = (id: string) => {
    setSelectedId(prevId => {
      //console.log(`Previous ID: ${prevId}, New ID: ${id}`);
      return id;
    });

    setL2ID(id);
    
  };

  useEffect(() => {
    // This effect will run after `L2ID` has been updated.
    console.log("L2ID now has the value:", L2ID);
    // Here, you can add any logic that needs to run right after L2ID changes.
  }, [L2ID]); // Dependency array ensures this effect runs only when L2ID changes.
  

  //////////////////////GLOBAL SEARCH

const [globalFilterText, setGlobalFilterText] = useState('');

const handleGlobalFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setGlobalFilterText(event.target.value);
};

////////////////////////////////////// FILTER

const [filterText, setFilterText] = useState('');
 // Event handler for the search input
const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setFilterText(event.target.value);
};

// Filter and then sort the data
const filteredAndSortedData = useMemo(() => {
  const filteredData = data
    .filter(item => {
      return item.pref_label.toLowerCase().includes(filterText.toLowerCase());
    })
    .filter(item => {
      // If there's global filter text, check all the relevant properties
      if (globalFilterText) {
        const allText = Object.values(item).join(' ').toLowerCase();
        return allText.includes(globalFilterText.toLowerCase());
      }
      return true; // If there's no global filter text, don't filter further
    });

  // Then sort the filtered data
  if (sortConfig !== null) {
    filteredData.sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortConfig.direction === 'ascending' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
      return 0;
    });
  }

  return filteredData;
}, [data, sortConfig, filterText, globalFilterText]);


const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeletePlace();


    return (
      <TableContainer>
      <TopContainer>
      <SearchInputContainer>
  <Search placeholder='Filtra per ragione sociale' onChange={handleFilterChange} />
  <SearchIcon src="/funnel.svg" />
</SearchInputContainer>
<SearchInputContainer>
  <Search placeholder='Cerca in tutta la tabella' onChange={handleGlobalFilterChange} />
  <SearchIcon src="/search.svg" />
  </SearchInputContainer>

  <IconsGroup>
        {/* <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/doc.svg" />
          </IconButton>
          <IconButton>
            <BackgroundIcon loading="eager" alt="" src="/edit.svg" />
          </IconButton> */}
          <IconButton onClick={() => showDeleteConfirmation()}>
            <BackgroundIcon loading="eager" alt="" src="/delete.svg" />
          </IconButton>
          <IconButton>
          <BackgroundIcon
            loading="eager"
            alt=""
            src="/add.svg"
            onClick={toggleOverlayAdd2} // Use the prop here
          />
          </IconButton>
        </IconsGroup>
</TopContainer>


      <StyledTable>
      <StyledThead>
         
         <StyledTh onClick={() => requestSort('pref_label')}><TableTitleText>▼ Ragione sociale</TableTitleText></StyledTh>
         {/* <StyledTh onClick={() => requestSort('entity_legal_form_category')}><TableTitleText>▼ Forma giuridica</TableTitleText></StyledTh> */}
         <StyledTh onClick={() => requestSort('identifier')}><TableTitleText>▼ C.F./P.IVA</TableTitleText></StyledTh>
         <StyledTh onClick={() => requestSort('has_responsible')}><TableTitleText>▼ Responsabile</TableTitleText></StyledTh>
         <StyledPersone2Th onClick={() => requestSort('description')}><TableTitleText>▼ Ambito d'interesse</TableTitleText></StyledPersone2Th>

      
     </StyledThead>
    </StyledTable>
    <TableScrollableDiv>
     <StyledTableItem>
        <StyledTbody>
          
        {filteredAndSortedData.map(item => (
             <StyledTr
             key={item.id} // Use the unique ID as key instead of index
             onClick={() => handleRowSelect(item.id)} // Set the selected item ID on click
             selected={selectedId === item.id} // Conditionally apply the background color
           >
              <StyledTd title={item.pref_label}><TableItemText>{item.pref_label}</TableItemText></StyledTd>
              {/* <StyledTd title={item.entity_legal_form_category}><TableItemText>{item.entity_legal_form_category}</TableItemText></StyledTd>           */}
              <StyledTd title={item.identifier}><TableItemText>{item.identifier}</TableItemText></StyledTd>
              <StyledTd title={item.has_responsible}><TableItemText>{item.has_responsible}</TableItemText></StyledTd>
              <StyledPersone2Td title={item.description}><TableItemText>{item.description}</TableItemText></StyledPersone2Td>
             
            </StyledTr>
          ))}
         
        </StyledTbody>
    
      </StyledTableItem>
        </TableScrollableDiv>

  
        <OverlayContext.Provider value={{ closeOverlay: closeOverlayAdd2, selectedL1, setSelectedL1, selectedL2, setSelectedL2, selectedL3,setSelectedL3,selectedL4,setSelectedL4 }}>

{isOverlayVisibleAdd2 && (
  <OverlayComponentAdd1 isAnimating={isOverlayAnimatingAdd2}>
    {/* <ButtonClose src="/close.svg" onClick={closeOverlayAdd2} /> */}
    <FadeOutComponent><FrameTopGradient100></FrameTopGradient100></FadeOutComponent>

    <Outlet />
  </OverlayComponentAdd1>
)}
</OverlayContext.Provider>

 
      </TableContainer>
    );
  };
  

  const useToken = () => {
    const { keycloak, initialized } = useKeycloak();
  
    return initialized && keycloak.authenticated ? keycloak.token : null;
  };
  
  
  const Board27001Dashboard: React.FC = () => {

    const [reFetchIndicator, setRefetchIndicator] = useRecoilState(reFetchIndicatorParties);

    const [L1ID, setL1ID] = useRecoilState(SelectedL1ID);
    const [L2ID, setL2ID] = useRecoilState(SelectedL2ID);
    const [L3ID, setL3ID] = useRecoilState(SelectedL3ID);
    const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

    const [data, setData] = useState<DataItem[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);

  
    // useKeycloak should be called inside the functional component
    const { keycloak, initialized } = useKeycloak();
  
    const token = initialized && keycloak.authenticated ? keycloak.token : null;

    type Party = {
      id: string,
      pref_label: string,
      identifier: string,
      description: string,
      has_responsible: string,
      kind: string,
      entity_legal_form: {
        category: string,
      },
    }

    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
        try {
         
          // Check for token existence before making the API call
          if (!token) throw new Error("User is not authenticated");
    
          // Fetch data from the API
          const response = await fetch(URL_PartiList, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          // Check if the API call was successful
          if (!response.ok) {
            throw new Error(`API request failed: ${response.statusText}`);
          }
         
         
      const responseData = await response.json();

      const transformedDataArray = responseData.map((item: Party) => ({

        id: item.id,
        pref_label: item.pref_label|| '',
        identifier: item.identifier || '',
        has_responsible: item.has_responsible || '',
        description: item.description || '',
        entity_legal_form_category: item.entity_legal_form?.category || '', // Ensure safe access to nested property
      }));
      



      console.log("Transformed Client Data:", transformedDataArray);
      setData(transformedDataArray);
          
        } catch (err) {
          // Error handling
          setError(err instanceof Error ? err.message : 'An unknown error occurred');
          setIsMessageBoxVisible(true);
        } finally {
          setIsLoading(false);
        }
      };
    
      // Fetch data on component mount or when token changes
      fetchData();
    }, [token, reFetchIndicator]); // Depend on the token
  
   
    const { handleDelete, showDeleteConfirmation, isConfirmDialogVisible, setIsConfirmDialogVisible } = useDeletePlace();

    return( 

      <div>
    

          {
  isMessageBoxVisible && (
    <Backdrop>
      <MessageContainer>
        <p><MessageboxText>{error}</MessageboxText></p>
        <CloseMessageButton onClick={() => setIsMessageBoxVisible(false)}>
          <MessageboxText>Close</MessageboxText>
        </CloseMessageButton>
      </MessageContainer>
    </Backdrop>
  )
}


{isLoading && (
      <BackdropTransparent>
        <MessageContainer>
          <MessageboxText>Loading...</MessageboxText>
        </MessageContainer>
      </BackdropTransparent>
    )}

    {error && (
      <Backdrop>
        <MessageContainer>
          <p>
            <MessageboxText>{error}</MessageboxText>
          </p>
          <CloseMessageButton
            onClick={() => {
              setIsMessageBoxVisible(false);
              setError(null);
            }}
          >
            <MessageboxText>Close</MessageboxText>
          </CloseMessageButton>
        </MessageContainer>
      </Backdrop>
    )}

{
isConfirmDialogVisible && (
  <Backdrop>
    <MessageContainer>
      <p><MessageboxText>Sei sicuro di voler rimuovere questa parte interessata?</MessageboxText></p>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
        <CloseMessageButton onClick={() => setIsConfirmDialogVisible(false)}>
          <MessageboxText>No</MessageboxText>
        </CloseMessageButton>
        <CloseMessageButton onClick={handleDelete}>
          <MessageboxText>Rimuovi</MessageboxText>
        </CloseMessageButton>
      </div>
    </MessageContainer>
  </Backdrop>
)
}

    <DataTable data={data} />


    </div>
    );
  };
  
  export default Board27001Dashboard;