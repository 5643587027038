import styled from "styled-components";
import { NavLink } from 'react-router-dom';
export const BoardScrollableDiv = styled.div`

  overflow-y: hidden; // Enable vertical scroll
  height: 88%; // Ensure this div knows its height

  // Custom scrollbar styles
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 10px;
    &:hover {
      background: #425a77;
    }
  }
`;

export const Title = styled.div`
  display: flex; // Use flexbox for positioning children
  align-items: center; // Vertically center children
  justify-content: left; // Horizontally center children
  width: 100%;
  height: 30px;
  background-color: transparent;
  padding-bottom: 0px;
`;

export const TitleText = styled.div`
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: center; // Ensures text is centered within the element itself
  // No need for width and height since it's a text element and will naturally take the size of its content
`;

export const SearchGroup = styled.div`
  display: flex;
  flex-grow: 1; // Allows the search bar to expand and fill the space
  align-items: center; // Center items vertically
`;

export const IconsGroup = styled.div`
  display: flex;
  align-items: center; // Center items vertically
  gap: 10px;
`;

export const Search = styled.input`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 0px 8px;
  width: 100%;
  // Additional styles for the input
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro); // Example font size
  line-height: 1.5; // Example line height for better readability
  color: var(--color-lightslategray); // Text color

  // Remove default input styling
  outline: none;
  box-shadow: none;

  // Styling for placeholder text
  &::placeholder {
    color: #d1d1d1;
  }

  // Focus styles
  &:focus {
    border-color: var(--color-primary); // Change border color when focused
    // You can add other styles for the focus state
  }
`;

export const SearchRoles = styled.input`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 0px 30px;
  width: 100%;
  // Additional styles for the input
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro); // Example font size
  line-height: 1.5; // Example line height for better readability
  color: var(--color-lightslategray); // Text color

  // Remove default input styling
  outline: none;
  box-shadow: none;

  // Styling for placeholder text
  &::placeholder {
    color: #d1d1d1;
  }

  // Focus styles
  &:focus {
    border-color: var(--color-primary); // Change border color when focused
    // You can add other styles for the focus state
  }
`;


export const LineDivider = styled.div`
  position: relative;
  border-right: 1px solid #d6d6d6;
  box-sizing: border-box;
  width: 1px;
  height: 20px;
`;
export const BackgroundIcon = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
export const IconButton = styled.button`
  position: relative;
  width: 15px;
  height: 15px;
  overflow: hidden;
  flex-shrink: 0;
  border: none; // Remove the default button border
  background: transparent; // Remove the default button background
  padding: 0; // Remove the default padding
  cursor: pointer; // Change cursor to pointer

  // Adjust the icon appearance on hover
  &:hover {
    filter: brightness(0.8); // Darkens the button icon
  }

  // Focus styles if needed
  &:focus {
    outline: none; // Remove default focus outline
    // Add any focus styles if required
  }
`;

export const SearchBarWithIconsRoot = styled.div`
  position: relative;
  background-color: #fff;
  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 35px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 10px;
  padding-left: 0px;
  box-sizing: border-box;
  gap: 10px;
  text-align: left;
  font-size: 15px;
  color: var(--color-lightslategray);
  font-family: var(--font-titillium-web);

  border-bottom: 1px solid #f1f1f1;
`;
export const MainBoardContentContainerColumn = styled.div`
  position: relative;
  width: 25%; // Each column takes up 25% of the parent container's width
  height: 100%; // The height is 100% of the MainBoardContentContainer
  //border-right: 1px solid #d6d6d6;
  //margin-left: 5px;
  //margin-right: 5px;

`;

export const MainBoardContentContainerFile = styled.div`
  position: relative;
  width: 100%; // Adjust width as needed
  height: 96%;
  background-color: #ffffff;
  padding: 8px; // Add padding inside the container
  //box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
  
  border-bottom: 1px solid #f1f1f1;
  overflow: hidden;
`;



export const FileButtonWhite = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: var(--color-white);
  position: relative;
  border-bottom: 1px solid #f1f1f1;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  &:hover {
    
    background-color: #d6d6d6;
  }
`;

export const FileButtonWhiteText = styled.div`
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  
  color: var(--color-steelblue-700);
  //color: var(--color-lightslategray);
  text-align: left;
  width: 210px;
  max-height: 32px; // For two lines of text
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // Limit text to 2 lines
  overflow: hidden;
`;



export const FileButtonArrowGrey = styled.img`
  position: absolute;
  height: 15%;
  width: 6%;
  top: 42%;
  bottom: 42.4%;
  right: 9px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
`;

export const ButtonClose = styled.img`
cursor: pointer;
position: absolute;
height: 30px;
width: 30px;
top: 1.7%;
right: 0%;
bottom: 0%;
left: 15.5%;
max-width: 100%;
overflow: hidden;
max-height: 100%;
object-fit: contain;

  &:hover {
    filter: brightness(0.8); // Darkens the button icon
  }
  
`;

export const OverlayComponentBackgroundAdd1 = styled.div`
  position: absolute;
  width: 81.9%;
  top: 0%;
  left: 18.1%;
  right: 0;
  bottom: 0%;
  background: var(--color-aliceblue);
  //background: var(--color-white) url('/background.jpg') no-repeat center center;
  background-size: cover; // Or 'contain', depending on how you want the image to fit
  border: 1px solid #d6d6d6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex; // Enable flexbox
  justify-content: felx-start; // Center children horizontally
  align-items: center; // Center children vertically
  flex-direction: column;
  `;



export const OverlayComponentWhiteAdd1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-white);
  
  border: 5px solid #ffffff;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
  width: 80%;
  height: 120%;
  overflow: hidden;
`;

export const OverlayComponentWhiteAdd1Multiple = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-white);
  
  //border: 5px solid #ffffff;
  //box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const OverlayComponentWhiteTitleAdd1 = styled.div`
  position: relative;
  margin-top: 5.8%;
  background: transparent;
  font-size: var(--font-size-base);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  //color: #1a81c3;
 
  text-align: center;
  height: 9%;
`;

export const OverlayComponentWhiteButtonFrameAdd1 = styled.div`
  margin-top: 4%;
  margin-bottom: 4%;
  background-color: transparent;
  display: flex;
  justify-content: center; // Use 'center' or another appropriate value
  align-items: center;
  flex-direction: row;
  height: 1%;
  gap: 20px;
`;

export const FlexContainerCrea = styled.div`
  display: flex;
  align-items: stretch; // This will make sure that all children (columns) will have equal height
  width: 100%; // Take the full width of the parent
  height: 100%; // Optional: if you want the container to take the full height of its parent
  //min-height: 600px;
  background-color: var(--color-aliceblue);
`;


export const FlexContainerCreaMultiple = styled.div`
  display: flex;
  align-items: stretch; // This will make sure that all children (columns) will have equal height
  width: 100%; // Take the full width of the parent
  //height: 100%; // Optional: if you want the container to take the full height of its parent
  
  background-color: var(--color-aliceblue);
`;

export const DescriptionContainerCrea = styled.div`
  background-color: var(--color-menu-scheda-fascia);
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center; // Center items vertically
  justify-content: start; // Align content to start
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  //background-color: #cccccc;
`;

export const DescriptionContainerCreaMultiple = styled.div`
  background-color: var(--color-menu-scheda-fascia);
  width: 100%;
  height: 17%;
  display: flex;
  align-items: center; // Center items vertically
  justify-content: start; // Align content to start
  padding-left: 15px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  //background-color: #cccccc;
`;



export const DescriptionContainerCreaText = styled.div`
text-align: justify;
text-justify: inter-word;
font-size: var(--font-size-micro);
font-family: var(--font-titillium-web);
color: var(--color-steelblue-700);
//color: #1a81c3;
//color: #ffffff;
`;

export const IconSquare = styled.img`
  height: 100%; // Take full height of the container
  width: auto; // Maintain aspect ratio of the image
  margin-right: 20px; // Add some space between the icon and the text
`;


export const FlexContainerCreaVertical = styled.div`
  display: flex;
  flex-direction: column; // Stack items vertically
  align-items: stretch; // This will make sure that all children (columns) will have equal width
  width: 100%; // Take the full width of the parent
  height: 100%; // Optional: if you want the container to take the full height of its parent

`;

export const CreaContainerColumnVertical = styled.div`
  position: relative;
  display: flex; // Enable flexbox
  flex-direction: column; // Stack children vertically
  justify-content: center; // Center children horizontally in the flex container
  align-items: center; // Center children vertically in the flex container
  flex: 1; // Each column takes equal space
  height: 100%; // The height is 100% of the parent container
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 100vh;
 
`;

export const CreaContainerColumnVerticalMultiple = styled.div`
  position: relative;
  display: flex; // Enable flexbox
  flex-direction: column; // Stack children vertically
  justify-content: center; // Center children horizontally in the flex container
  align-items: center; // Center children vertically in the flex container
  flex: 1; // Each column takes equal space
  height: 100%; // The height is 100% of the parent container
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 100vh;
 
`;

export const CreaContainerColumn = styled.div`
  position: relative;
  flex: 1; // Each column takes equal space
  height: 100%; // The height is 100% of the parent container
  // /background-color: #cccccc;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

`;

export const CreaContainerColumn80 = styled.div`
  position: relative;
  flex: 4; // Each column takes equal space
  height: 100%; // The height is 100% of the parent container
  // /background-color: #cccccc;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

`;

export const CreaContainerColumn20 = styled.div`
  position: relative;
  flex: 1; // Each column takes equal space
  height: 100%; // The height is 100% of the parent container
  // /background-color: #cccccc;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

`;

export const CreaContainerColumnMultiple = styled.div`
  position: relative;
  flex: 1; // Each column takes equal space
  height: 100%; // The height is 100% of the parent container
  // /background-color: #cccccc;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

`;


export const FrameTopGradient100 = styled.div`
  position: absolute;
  width: 110%;
  top: -1px;
  right: 0px;
  left: -1px;
  background: linear-gradient(90deg, #6f8aab, #425a77);
  //border-right: 1px solid var(--color-lightgray-100);
  //border-left: 1px solid var(--color-lightgray-100);
  //box-sizing: border-box;
  height: 7.89%;
  overflow: hidden;
`;

export const SubMenuButtonContainerOrganigramma = styled.div`
  position: relative;
  background-color: var(--color-menu-scheda-fascia);
  width: 100%;
  height: 78px;
  top: 0;
  display: flex; // Use flexbox for layout
  flex-direction: row; // Arrange children horizontally
  align-items: center; // Align items vertically in the center
  justify-content: space-between; // Distribute space between items
  gap: 10px; // Space between buttons
  padding-left: 20px; // Padding on the left
  padding-right: 20px; // Padding on the right
  margin-top: 0px;
  margin-bottom: 0px;
  //border-bottom: 1px solid #d6d6d6;
  //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.20);
`;

export const SubMenuButtonContainerOrganigrammaMultiple = styled.div`
  position: relative;
  background-color: var(--color-menu-scheda-fascia);
  width: 100%;
  height: 13.5%;
  top: 0;
  display: flex; // Use flexbox for layout
  flex-direction: row; // Arrange children horizontally
  align-items: center; // Align items vertically in the center
  justify-content: space-between; // Distribute space between items
  gap: 10px; // Space between buttons
  padding-left: 20px; // Padding on the left
  padding-right: 20px; // Padding on the right
  margin-top: 0px;
  margin-bottom: 0px;
  //border-bottom: 1px solid #d6d6d6;
  //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.20);
`;

export const SubMenuButtonContainerOrganigrammaMultipleShort = styled.div`
  position: relative;
  background-color: var(--color-menu-scheda-fascia);
  width: 100%;
  height: 13.5%;
  top: 0;
  display: flex; // Use flexbox for layout
  flex-direction: row; // Arrange children horizontally
  align-items: center; // Align items vertically in the center
  justify-content: left; // Distribute space between items
  gap: 10px; // Space between buttons
  padding-left: 20px; // Padding on the left
  padding-right: 20px; // Padding on the right
  margin-top: 0px;
  margin-bottom: 0px;
  //border-bottom: 1px solid #d6d6d6;
  //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.20);
`;

export const SubMenuButtonContainerOrganigrammaNoScroll = styled.div`
  position: relative;
  background-color: var(--color-menu-scheda-fascia);
  width: 100%;
  height: 15%;
  top: 0;
  display: flex; // Use flexbox for layout
  flex-direction: row; // Arrange children horizontally
  align-items: center; // Align items vertically in the center
  justify-content: space-between; // Distribute space between items
  gap: 10px; // Space between buttons
  padding-left: 20px; // Padding on the left
  padding-right: 20px; // Padding on the right
  margin-top: 0%;
  margin-bottom: 0px;
  //border-bottom: 1px solid #d6d6d6;
  //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.20);
`;

export const CreaSubMenuButton = styled(NavLink)`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  
  padding: 5px; // Adjust padding as needed
  position: relative;
  overflow: hidden;
  text-decoration: none; // This removes the underline

  &:hover {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px; // Height of the magenta line
      background: url('/line-green-horizontal.svg') no-repeat;
      background-size: cover;
    }
  }

  &.active {
    background: transparent;
  border: none;
  cursor: pointer;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  padding: 5px; // Adjust padding as needed
  position: relative;
  overflow: hidden;
  text-decoration: none; // This removes the underline
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px; // Height of the magenta line
    background: url('/line-green-horizontal.svg') no-repeat;
    background-size: cover;
  }

  }
`;

export const CreaSubMenuButtonMultiple = styled(NavLink)`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  margin-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px; // Adjust padding as needed
  position: relative;
  overflow: hidden;
  text-decoration: none; // This removes the underline

  &:hover {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px; // Height of the magenta line
      background: url('/line-green-horizontal.svg') no-repeat;
      background-size: cover;
    }
  }

  &.active {
    background: transparent;
  border: none;
  cursor: pointer;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  padding-bottom: 5px; // Adjust padding as needed
  position: relative;
  overflow: hidden;
  text-decoration: none; // This removes the underline
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px; // Height of the magenta line
    background: url('/line-green-horizontal.svg') no-repeat;
    background-size: cover;
  }

  }
`;

export const StyledCreaSubMenuButton = styled(NavLink)`
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: var(--font-size-micro);
    font-family: var(--font-titillium-web);
    color: var(--color-steelblue-700);
    padding: 5px;
    position: relative;
    overflow: hidden;
    text-decoration: none;

    &:hover {
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 2px;
            background: url('/line-green-horizontal.svg') no-repeat;
            background-size: cover;
        }
    }

    &.active {
        padding: 10px;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 2px;
            background: url('/line-green-horizontal.svg') no-repeat;
            background-size: cover;
        }
    }
`;

export const InputContainer = styled.div`
  
  position: relative; // Relative positioning for the container
  display: flex;
  align-items: center; // Align items vertically in the center
  width: 100%;
  margin-top: 10px;

`;

export const InputContainerRoles = styled.div`
  
  position: relative; // Relative positioning for the container
  display: column;
  align-items: center; // Align items vertically in the center
  width: 100%;
  margin-top: -15px;

`;



export const AttachedFilesList = styled.div `
  border: 1px solid #e1e1e1;
  overflow: hidden;
  //display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 10px 10px 10px;
  margin-bottom: 0px;
  //flex-grow: 1; // Take up all available space
  width: 100%;
  min-height: 20px;
  max-height: 155px;
  // Additional styles for the input
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro); // Example font size
  line-height: 1.5; // Example line height for better readability
  color: var(--color-steelblue-700); // Text color
  background-color: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);
`;

export const InputTextbox = styled.input`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 40px 0px 8px;
  margin-bottom: 20px;
  flex-grow: 1; // Take up all available space
  width: 100%;
  height: 35px;
  // Additional styles for the input
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro); // Example font size
  line-height: 1.5; // Example line height for better readability
  color: #5a5a5a; // Text color
  
  white-space: nowrap; // Ensures text stays on a single line
  text-overflow: ellipsis; // Add ellipsis when text overflows
  // Remove default input styling
  outline: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);

  // Styling for placeholder text
  &::placeholder {
    color: var(--color-steelblue-700);
  }

  // Focus styles
  &:focus {
    //border-color: var(--color-primary); // Change border color when focused
    border-color:  #1a81c3;
   
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);
    // You can add other styles for the focus state
  }
`;

export const InputTextboxDouble = styled.textarea`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: block; // Changed from flex to block for textarea
  width: 100%;
  height: auto; // Set the height to auto to allow for resizing based on content
  min-height: 70px; // At least two lines high
  padding: 8px; // Padding inside the textarea
  margin-bottom: 20px;
  resize: none; // Prevent manual resizing if that's what you want


  // Additional styles for the textarea
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  line-height: 1.5;
  color: #5a5a5a;

  // Remove default textarea styling
  outline: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);

  // Styling for placeholder text
  &::placeholder {
    color: var(--color-steelblue-700);
  }

  // Focus styles
  &:focus {
    border-color: #1a81c3;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);
    // You can add other styles for the focus state
  }
`;

export const InputTextboxTriple = styled.textarea`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: block; // Changed from flex to block for textarea
  width: 100%;
  height: auto; // Set the height to auto to allow for resizing based on content
  min-height: 105px; // At least two lines high
  padding: 8px; // Padding inside the textarea
  margin-bottom: 10px;
  resize: none; // Prevent manual resizing if that's what you want


  // Additional styles for the textarea
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  line-height: 1.5;
  color: #5a5a5a;

  // Remove default textarea styling
  outline: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);

  // Styling for placeholder text
  &::placeholder {
    color: var(--color-steelblue-700);
  }

  // Focus styles
  &:focus {
    border-color: #1a81c3;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);
    // You can add other styles for the focus state
  }
`;

export const InputTextboxVerticalFull = styled.textarea`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: block; // Changed from flex to block for textarea
  width: 100%;
  height: auto; // Set the height to auto to allow for resizing based on content
  min-height: 235px; // At least two lines high
  padding: 8px; // Padding inside the textarea
  margin-bottom: 10px;
  resize: none; // Prevent manual resizing if that's what you want


  // Additional styles for the textarea
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  line-height: 1.5;
  color: #5a5a5a;

  // Remove default textarea styling
  outline: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);

  // Styling for placeholder text
  &::placeholder {
    color: var(--color-steelblue-700);
  }

  // Focus styles
  &:focus {
    border-color: #1a81c3;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);
    // You can add other styles for the focus state
  }
`;

export const InputTextboxTripleRealligned = styled.textarea`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: block; // Changed from flex to block for textarea
  width: 100%;
  height: auto; // Set the height to auto to allow for resizing based on content
  min-height: 100px; // At least two lines high
  padding: 8px; // Padding inside the textarea
  margin-bottom: 10px;
  resize: none; // Prevent manual resizing if that's what you want


  // Additional styles for the textarea
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  line-height: 1.5;
  color: #5a5a5a;

  // Remove default textarea styling
  outline: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);

  // Styling for placeholder text
  &::placeholder {
    color: var(--color-steelblue-700);
  }

  // Focus styles
  &:focus {
    border-color: #1a81c3;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);
    // You can add other styles for the focus state
  }
`;

export const InputTextboxControlliNota3 = styled.textarea`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: block; // Changed from flex to block for textarea
  width: 100%;
  height: auto; // Set the height to auto to allow for resizing based on content
  min-height: 120px; // At least two lines high
  padding: 8px; // Padding inside the textarea
  margin-bottom: 0px;
  resize: none; // Prevent manual resizing if that's what you want


  // Additional styles for the textarea
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  line-height: 1.5;
  color: #5a5a5a;

  // Remove default textarea styling
  outline: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);

  // Styling for placeholder text
  &::placeholder {
    color: var(--color-steelblue-700);
  }

  // Focus styles
  &:focus {
    border-color: #1a81c3;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);
    // You can add other styles for the focus state
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 34px; // Adjust this value based on the height of InputTextbox + desired space
  width: 10%;
  height: 2px; // Adjust if you want a thicker line
  background: url('/line-blue-horizontal.svg') no-repeat left center; // Align background to the left
  //background-size: contain; // Adjust this as needed
  
`;

export const IconContainerLong = styled.div`
  position: absolute;
  left: 0px;
  top: 34px; // Adjust this value based on the height of InputTextbox + desired space
  width: 5%;
  height: 2px; // Adjust if you want a thicker line
  background: url('/line-blue-horizontal.svg') no-repeat left center; // Align background to the left
  //background-size: contain; // Adjust this as needed
  
`;

export const IconContainerLongDouble = styled.div`
  position: absolute;
  left: 0px;
  top: 68px; // Adjust this value based on the height of InputTextbox + desired space
  width: 5%;
  height: 2px; // Adjust if you want a thicker line
  background: url('/line-blue-horizontal.svg') no-repeat left center; // Align background to the left
  //background-size: contain; // Adjust this as needed
  
`;



export const InputDescriptionText = styled.div`
  position: absolute;
  top: -18%;
  left: 2px;
  transform: translateY(-50%);
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: left;
  width: 210px;
  max-height: 32px; // For two lines of text
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // Limit text to 2 lines
  overflow: hidden;
`;

export const InputDescriptionTextVerticalFull = styled.div`
  position: absolute;
  top: -4%;
  left: 2px;
  transform: translateY(-50%);
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: left;
  width: 210px;
  max-height: 32px; // For two lines of text
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // Limit text to 2 lines
  overflow: hidden;
`;

export const InputDescriptionTextCategoryAsset = styled.div`
  position: absolute;
  top: -5%;
  left: 2px;
  transform: translateY(-50%);
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: left;
  width: 210px;
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // Limit text to 2 lines
  overflow: hidden;
`;

export const InputDescriptionTextRoles = styled.div`
  position: relative;
  display: flex;
  top: 0%;
  left: 0px;
  transform: translateY(0%);
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: left;
  width: 100%;
  max-height: 32px; // For two lines of text
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // Limit text to 2 lines
  overflow: hidden;
`;

export const InputDescriptionTextDouble = styled.div`
  position: absolute;
  top: -10%;
  left: 2px;
  transform: translateY(-50%);
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: left;
  width: 210px;
  max-height: 32px; // For two lines of text
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // Limit text to 2 lines
  overflow: hidden;
`;

export const InputDescriptionTextTriple = styled.div`
  position: absolute;
  top: -10%;
  left: 2px;
  transform: translateY(-50%);
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: left;
  width: 210px;
  max-height: 48px; // For two lines of text
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // Limit text to 2 lines
  overflow: hidden;
`;

export const InputDescriptionControlliNota3 = styled.div`
  position: absolute;
  top: -6%;
  left: 2px;
  transform: translateY(-50%);
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: left;
  width: 210px;
  max-height: 48px; // For two lines of text
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // Limit text to 2 lines
  overflow: hidden;
`;



export const InputDescriptionTextDatePicker = styled.div`
  position: absolute;
  top: -30%;
  left: 2px;
  transform: translateY(-50%);
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: left;
  width: 210px;
  max-height: 32px; // For two lines of text
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // Limit text to 2 lines
  overflow: hidden;

`;


export const StyledCheckboxPanel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #ffffff; // Example background color
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: -20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  border: 1px solid #e1e1e1;
`;

export const StyledCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);

  input[type='checkbox'] {
    margin-right: 10px;
  }
`;

export const NavButtonWhiteAccordion = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: var(--white);
  position: relative;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  width: 80%;
  height: 35px;
  overflow: visible;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  flex-shrink: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);
  &:hover {
    background-color: var(--color-whitesmoke);
  }
`;



export const NavButtonWhiteTextAccordion = styled.div`
  position: absolute;
  top: -27px;
  left: 1px;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: left;
  display: flex;
  align-items: center;
  width: 206px;
  height: 35px;

`;

export const ArrowGreyAccordion = styled.img`
  position: absolute;
  right: 15px;
  top: 47%;
  height: 7px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
`;

export const IconContainerAccordion = styled.div`
  position: absolute;
  left: -1px;
  top: 32px; // Adjust this value based on the height of InputTextbox + desired space
  width: 10%;
  height: 2px; // Adjust if you want a thicker line
  background: url('/line-blue-horizontal.svg') no-repeat left center; // Align background to the left
  //background-size: contain; // Adjust this as needed
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; // Ensure it's above everything else
`;

export const BackdropTransparent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; // Ensure it's above everything else
`;

export const MessageContainer = styled.div`
  padding: 20px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

`;

export const MessageboxText = styled.div`
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: center; // Ensures text is centered within the element itself
  // No need for width and height since it's a text element and will naturally take the size of its content
`;

export const CloseMessageButton = styled.button`
   cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  border: 1px solid var(--color-steelblue-100);

  position: relative;
  border-radius: var(--br-8xs);
  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 120px;
  height: 30px;
  overflow: hidden;
  &:hover {
    //background-color: var(--color-slategray-100);
    border: 2px solid var(--color-steelblue-100);
  }
`;

export const SelectionDatalist = styled.div`
  position: relative;
  width: 100%;

  &:after {
    content: '▼';
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #888;
  }
`;


export const DivWhiteAccordionInfrastrutturaAsset = styled.div`
  position: relative;
  margin-top: 1.5vh;
  padding: 0;
  background-color: var(--white);
  height: 53.9vh;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);
  width: calc(100%); // Subtract left and right from 100%
  &:hover {
    //border: 1px solid #1a81c3;
    //box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);
    
  }
`;

export const IconContainerDivInfrastrutturaAsset = styled.div`
  position: relative;
  left: -1px;
  top: 53.4vh; // Adjust this value based on the height of InputTextbox + desired space
  width: 10%;
  height: 2px; // Adjust if you want a thicker line
  background: url('/line-blue-horizontal.svg') no-repeat left center; // Align background to the left
  //background-size: contain; // Adjust this as needed
 
`;

export const DivWhiteAccordionInfrastrutturaLuoghi = styled.div`
  position: relative;
  padding: 0;
  margin-top: 1.5vh;
  background-color: var(--white);
  height: 44.4vh;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);
  width: calc(100%); // Subtract left and right from 100%
  &:hover {
    //border: 1px solid #1a81c3;
    //box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);
    
  }
`;

export const IconContainerDivInfrastrutturaLuoghi = styled.div`
  position: relative;
  left: -1px;
  top: 43.9vh; // Adjust this value based on the height of InputTextbox + desired space
  width: 10%;
  height: 2px; // Adjust if you want a thicker line
  background: url('/line-blue-horizontal.svg') no-repeat left center; // Align background to the left
  //background-size: contain; // Adjust this as needed
 
`;

export const DivWhiteAccordionProcessi = styled.div`
  position: relative;
  margin-top: 5.9vh;
  padding: 0;
  background-color: var(--white);
  height: 54vh;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);
  width: calc(100% - 40px); // Subtract left and right from 100%
  &:hover {
    //border: 1px solid #1a81c3;
    //box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);
    
  }
`;

export const IconContainerDivProcessi = styled.div`
  position: relative;
  left: -1px;
  top: 53.6vh; // Adjust this value based on the height of InputTextbox + desired space
  width: 10%;
  height: 2px; // Adjust if you want a thicker line
  background: url('/line-blue-horizontal.svg') no-repeat left center; // Align background to the left
  //background-size: contain; // Adjust this as needed
 
`;

export const DivWhiteAccordionPartiInteressate = styled.div`
margin-top: 0;
  position: relative;
  padding: 0;
  background-color: var(--white);
  height: 15.05vh;
  margin-bottom: 30px;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);
  width: calc(100% - 40px); // Subtract left and right from 100%
  &:hover {
    //border: 1px solid #1a81c3;
    //box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);
    
  }
`;

export const IconContainerDivPartiInteressate = styled.div`
  position: relative;
  left: -1px;
  top: 14.6vh; // Adjust this value based on the height of InputTextbox + desired space
  width: 10%;
  height: 2px; // Adjust if you want a thicker line
  background: url('/line-blue-horizontal.svg') no-repeat left center; // Align background to the left
  //background-size: contain; // Adjust this as needed
 
`;

export const DivContainer = styled.div`
 position: relative;
  display: flex; // Enable flexbox
  flex-direction: column; // Stack children vertically
  //justify-content: center; // Center children horizontally in the flex container
  align-items: center; // Center children verti
margin-top: 6vh;
position:relative;
//background-color: #cccccc;
height: 70vh;
width: 100%;
`;

export const DivWhiteAccordionCompliance = styled.div`
margin-top: 0;
  position: relative;
  padding: 0;
  background-color: var(--white);
  height: 43vh;
  margin-bottom: 30px;
  margin-top: 20px;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);
  width: calc(100%); // Subtract left and right from 100%
  &:hover {
    //border: 1px solid #1a81c3;
    //box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);
    
  }
`;

export const IconContainerDivCompliance = styled.div`
  position: relative;
  left: -1px;
  top: 42.6vh; // Adjust this value based on the height of InputTextbox + desired space
  width: 10%;
  height: 2px; // Adjust if you want a thicker line
  background: url('/line-blue-horizontal.svg') no-repeat left center; // Align background to the left
  //background-size: contain; // Adjust this as needed
 
`;



export const StyledIconContainer = styled.img`
position: absolute;
margin-top: 33vh;
margin-left: 63.9vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;  // Fixed width
  height: 25px; // Fixed height
  //background-color: #f0f0f0; // Example background color
  border-radius: 50%; // Makes it circular
  //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); // Example shadow for aesthetics

  // Additional styling can be added as needed
`;

export const DivWhiteAccordionDocumentazione = styled.div`
  position: relative;
  margin-top: 1.6vh;
  padding: 0;
  background-color: var(--white);
  height: 48vh;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);
  width: calc(100%); // Subtract left and right from 100%
  &:hover {
    //border: 1px solid #1a81c3;
    //box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);
    
  }
`;

export const IconContainerDivDocumentazione = styled.div`
  position: relative;
  left: -1px;
  top: 47.6vh; // Adjust this value based on the height of InputTextbox + desired space
  width: 10%;
  height: 2px; // Adjust if you want a thicker line
  background: url('/line-blue-horizontal.svg') no-repeat left center; // Align background to the left
  //background-size: contain; // Adjust this as needed
 
`;

export const ButtonGreyAllega = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  border: 1px solid #d6d6d6;
  margin-top: 10px;
  position: relative;
  border-radius: var(--br-8xs);
  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 30px;
  overflow: hidden;
  &:hover {
    //background-color: var(--color-slategray-100);
    border: 2px solid #d6d6d6;
  }
`;

export const ButtonGreyAllegaText = styled.b`
  position: absolute;
  height: calc(100% - 27px);
  width: 100%;
  top: 13px;
  left: 0px;
 
  display: flex;
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  text-align: center;
  align-items: center;
  justify-content: center;
 
`;
export const CustomDropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const CustomDropdownList = styled.ul`
  width: 100%;
  max-height: 20vh;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 80%;
  left: 0;
  background-color: #fff;
  //border: 1px solid  #1a81c3;
  border: 1px solid  #dddddd;
  //border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.20);
  z-index: 1;
  overflow-y: hidden;
`;

export const CustomDropdownItem = styled.li`
  border-bottom: 1px solid #f1f1f1;
  padding: 8px 16px;
  cursor: pointer;
  width: 100%;
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  //background-color: var(--color-menu-selected-light-blue);
  &:hover {
    background-color: var(--color-menu-hover-light-blue);
  }
`;

export const DropDownMenuScrollbar = styled.div`
  overflow-y: auto;
  max-height: 19vh; // You can adjust this value as per your requirement
  width: 100%;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const FileListScrollbar = styled.div`
  overflow-y: auto;
  max-height: 15vh; // You can adjust this value as per your requirement
  width: 100%;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const RoleListScrollbar = styled.div`

  overflow-y: auto;
  max-height: 35vh; // You can adjust this value as per your requirement
  width: 100%;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const RoleListDescriptionScrollbar = styled.div`

  overflow-y: auto;
  max-height: 34vh; // You can adjust this value as per your requirement
  width: 100%;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const TriangleContainer = styled.div`
 font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: #bbbbbb;
  position: absolute;
  right: 10px; // Adjust the position as per your design
  top: 33%;
  transform: translateY(-50%);
  // Add more styles as needed
`;
export const InputButtonText = styled.div`
  position: absolute;
  left: 10px;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-lightslategray);
  text-align: left;
  display: flex;
  align-items: center;


`;

export const InputTextboxButton = styled.button`
cursor: pointer;
background-color: #ffffff;
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 0px 8px;
  margin-bottom: 20px;
  flex-grow: 1; // Take up all available space
  width: 100%;
  height: 35px;
  // Additional styles for the input
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro); // Example font size
  line-height: 1.5; // Example line height for better readability
  color: #5a5a5a; // Text color
  

  // Remove default input styling
  outline: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);

  &:hover {
    //background-color: var(--color-menu-hover-light-blue);
    border-color:  #1a81c3;
   
   box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);
   // You can add other styles for the focus state
  }

  // Styling for placeholder text
  &::placeholder {
    color: var(--color-steelblue-700);
  }

  /* // Focus styles
  &:focus {
    //border-color: var(--color-primary); // Change border color when focused
    border-color:  #1a81c3;
   
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);
    // You can add other styles for the focus state
  } */
`;



export const FadeOutComponent = styled.div`
 font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: #bbbbbb;
  position: fixed;
  right: 0px; // Adjust the position as per your design
  top: 0px;
  bottom: 0px;
  width: 81.9%;
  background-color: var(--color-menu-hover-light-blue);
  // Add more styles as needed
`;

export const LastPopupCheckboxContainer = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: #bbbbbb;
  position: relative;
  height: 80%;

  width: 93%;

  background-color: var(--color-menu-hover-light-blue);
  border: 5px solid #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.20);

`;

export const LastPopupButtonContainer = styled.div`
position: relative;
height: 10%;
display: flex;
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically
`;

export const LastPopupTitleContainer = styled.div`
position: relative;
font-family: var(--font-titillium-web);
font-size: var(--font-size-base);
height: 10%;
display: flex;
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically
`;


export const LastPopupHeaderContainer = styled.div`
position: relative;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
top: 0px;
height: 10%;
background-color: var(--color-menu-scheda-fascia);

`;

export const LastPopupScrollbar = styled.div`
  overflow-y: auto;
  height: 90%; // You can adjust this value as per your requirement
  width: 100%;
  padding: 2%;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const LastPopupScrollbarAggiungiAsset = styled.div`
  overflow-y: auto;
  height: 97%; // You can adjust this value as per your requirement
  width: 100%;
  padding: 2%;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const LastPopupSearchInputContainer = styled.div`

position: relative; // Position relative to allow absolute positioning of children
  width: 97%; // The width of the container
  margin-right: 0px;
  height: 60%;

`;

export const CategoryListBackground = styled.div`
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);
  position: relative; // Position relative to allow absolute positioning of children
  width: 100%; // The width of the container
  margin-right: 0px;
  margin-top: 0px;
  height: 235px;
  border: 1px solid #e1e1e1;
  background-color: white;
`;

export const LastPopupSearchIcon = styled.img`
  position: absolute;
  left: 10px; // Position from the right to place it inside the input
  top: 50%;
  transform: translateY(-50%);
  width: 16px; // Set a fixed size for the icon
  height: 16px;
  pointer-events: none; // Make sure clicks "go through" the icon
`;

export const LastPopupSearch = styled.input`
  border: 1px solid #e1e1e1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 0px 30px;
  margin-right: 0px;
  width: 100%;
  height: 100%;
  // Additional styles for the input
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro); // Example font size
  line-height: 1.5; // Example line height for better readability
  color: var(--color-lightslategray); // Text color

  // Remove default input styling
  outline: none;
  box-shadow: none;

  // Styling for placeholder text
  &::placeholder {
    color: #d1d1d1;
    
  }

  // Focus styles
  &:focus {
    //border-color: var(--color-primary); // Change border color when focused
    // You can add other styles for the focus state
  }
`;

export const BackdropModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const DeleteModal = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  z-index: 100;
`;

export const DocumentazioneBG = styled.div`
  width: 100%;
  height: 87%;
  background: white;
  //display: flex;
  //align-items: stretch;
  //margin-left: 10px;
 //margin-right: 30px;
 margin-top: -20px;
 padding-left: 20px;
 padding-right: 20px;
 padding-top: 10px;
 box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

`;