import { atom, selector } from 'recoil';
import dayjs from 'dayjs';

// SCHEDA GENERALE

export const RuoliComplianceID = atom({
    key: 'RuoliComplianceID', 
    default: '',
  });
  
export const RuoliComplianceName = atom({
    key: 'RuoliComplianceName', 
    default: '',
  });
  
  export const RuoliComplianceKind = atom({
    key: 'RuoliComplianceKind', 
    default: '',
  });
  
  export const RuoliComplianceNota = atom({
    key: 'RuoliComplianceNota', 
    default: '',
  });

  export const RuoliComplianceRifNorm = atom({
    key: 'RuoliComplianceRifNorm', 
    default: '',
  });
  
  export const RuoliComplianceObjective = atom({
    key: 'RuoliComplianceObjective', 
    default: '',
  });
  
  
  export const RuoliComplianceDescription = atom({
    key: 'RuoliComplianceDescription', 
    default: '',
  });

  // COMPLIANCE
  
 
  export const RuoliComplianceComplianceDate = atom<dayjs.Dayjs | null>({
    key: 'RuoliComplianceComplianceDate', 
    //default: dayjs(),
    default: null,
  });

  export const RuoliComplianceIsDropdownOpenDirectors = atom<boolean>({
    key: 'RuoliComplianceIsDropdownOpenDirectors', 
    default: false,
  });

  export const RuoliComplianceComplianceResponsible = atom({
    key: 'RuoliComplianceComplianceResponsible', 
    default: '',
  });
  
  export const RuoliComplianceComplianceResponsibleID = atom({
    key: 'RuoliComplianceComplianceResponsibleID', 
    default: '',
  });
  

// DOCUMENTAZIONE

export const RuoliComplianceDocumentsPath = atom({
    key: 'RuoliComplianceDocumentsPath', 
    default: '',
  });
  
 
  export const RuoliComplianceDocumentsAttached = atom<File[]>({
    key: 'RuoliComplianceDocumentsAttached',
    default: [],
  });
  
  
  export const RuoliComplianceAttachedFilesInfo = atom<Array<{ name: string, size: number, type: string } | null>>({
    key: 'RuoliComplianceAttachedFilesInfo',
    default: [],
  });

  // EXTRA
    
  export type LV1DataItem = {
    LV1_id: string;
    LV1_name: string;
  };
  
  export const LV1DataItemState = atom<LV1DataItem[]>({
    key: 'LV1DataItemState', // Unique ID (with respect to other atoms/selectors)
    default: [], // Default value (aka initial value)
  });

  export type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };
  export const directorDataState = atom<DirectorDataItem[]>({
    key: 'directorDataState', // Unique ID (with respect to other atoms/selectors)
    default: [], // Default value (aka initial value)
  });

  export const RuoliComplianceOrganizzazioneSelectedL1 = atom({
    key: 'RuoliComplianceOrganizzazioneSelectedL1', 
    default: '',
  });

  export const RuoliComplianceOrganizzazioneSelectedL1ID = atom({
    key: 'RuoliComplianceOrganizzazioneSelectedL1ID', 
    default: '',
  });

  export const RuoliCompliance_2_isDropdownOpenLV1 = atom({
    key: 'RuoliCompliance_2_isDropdownOpenLV1', 
    default: false,
  });

  export const RuoliComplianceConfirmedLV1List = atom<string>({
    key: 'RuoliComplianceConfirmedLV1List', // Unique ID (with respect to other atoms/selectors)
    default: '', // Updated default value to be an empty object
  });
  
  export const RuoliComplianceIsDropdownOpenDirectorsCompliance = atom<boolean>({
    key: 'RuoliComplianceIsDropdownOpenDirectorsCompliance', 
    default: false,
  });
  
  export const RuoliComplianceDeleteConfirmationDialog = atom<boolean>({
    key: 'RuoliComplianceDeleteConfirmationDialog', 
    default: false,
  });