import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import {
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconContainerLong,
  IconContainerLongDouble,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputDescriptionTextDouble,
  InputTextbox,
  InputTextboxDouble,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components"; 
import { useRecoilState, useRecoilValue } from "recoil";
import { SelectedL4ID } from "../../../../../recoil";
import { InputContainer50 } from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import {
ProcessiAttachedFilesInfo,
ProcessiComplianceDate,
ProcessiComplianceResponsible,
ProcessiDescription,
ProcessiID,
ProcessiDocumentsAttached,
ProcessiDocumentsPath,
ProcessiKind,
ProcessiName,
ProcessiObjective,



} from "../Processi-Add-recoil";
import { DatePicker } from "@mui/x-date-pickers";

const useToken = () => {
  const { keycloak, initialized } = useKeycloak();
  return initialized && keycloak.authenticated ? keycloak.token : null;
};


const MainComponent = () => {
  const token = useToken();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  ////////////RECOIL

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(ProcessiAttachedFilesInfo);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(ProcessiDocumentsAttached);
  const [docPath, setDocPath] = useRecoilState(ProcessiDocumentsPath);
  const [ID, setID] = useRecoilState(ProcessiID);
  const [name, setName] = useRecoilState(ProcessiName);
  const [complianceResponsible, setComplianceResponsible] = useRecoilState(ProcessiComplianceResponsible);
  const [complianceDate, setComplianceDate] = useRecoilState(ProcessiComplianceDate);
  const [description, setDescription] = useRecoilState(ProcessiDescription);
  const [kind, setKind] = useRecoilState(ProcessiKind);
  const [objective, setObjective] = useRecoilState(ProcessiObjective);
  



  return (
    <OverlayComponentWhiteAdd1Multiple>
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>
              Denominazione
              <Tooltip title="Campo obbligatorio">
                <InfoIcon style={{ fontSize: "14px", color: "#04446c", cursor: "pointer", marginLeft:"2px" }} />
              </Tooltip>
            </InputDescriptionText>
            <InputTextbox placeholder="" value={name} onChange={(e) => setName(e.target.value)} />
            <IconContainerLong />
         </InputContainer>

          <InputContainer>
            <InputDescriptionText>Categoria 
            <Tooltip title="Campo obbligatorio">
                <InfoIcon style={{ fontSize: "14px", color: "#04446c", cursor: "pointer", marginLeft:"2px" }} />
              </Tooltip>
              </InputDescriptionText>
            <InputTextbox placeholder="" value={kind} onChange={(e) => setKind(e.target.value)} />
            <IconContainerLong />
          </InputContainer>
        
        
         
       
          <InputContainer>
              <InputDescriptionTextDouble>Obiettivo {objective.length}/255</InputDescriptionTextDouble>
              <InputTextboxDouble  maxLength={255} placeholder="" value={objective} onChange={(e) => setObjective(e.target.value)} />
              <IconContainerLongDouble />
            </InputContainer>
        
            <InputContainer>
            <InputDescriptionTextDouble>Descrizione {description.length}/255</InputDescriptionTextDouble>
            <InputTextboxDouble  maxLength={255} placeholder="" value={description} onChange={(e) => setDescription(e.target.value)} />
            <IconContainerLongDouble />
          </InputContainer>
       
        </CreaContainerColumn>

        {/* <CreaContainerColumn>

      

        </CreaContainerColumn> */}
      </FlexContainerCrea>
      {/* <DescriptionContainerCreaMultiple>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
              <DescriptionContainerCreaText>
             
              Inserire i dettagli identificativi dell'unità operativa, comprensivi di un nome completo che rifletta le competenze e i servizi offerti, un nome abbreviato per una rapida identificazione, oltre ai principali canali di comunicazione per facilitare un contatto efficace e una pronta corrispondenza


              </DescriptionContainerCreaText>

        </DescriptionContainerCreaMultiple> */}
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
