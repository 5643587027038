import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from "react";
import styled from "styled-components";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione"; // Correct path
import { Box, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Checkbox } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  CustomDropdownItem,
  CustomDropdownList,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  DropDownMenuScrollbar,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputDescriptionTextDatePicker,
  InputTextbox,
  InputTextboxButton,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../../cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { isLoadingRec, isMessageBoxVisibleRec, SelectedL4ID, setErrorRec, setMessageRec } from "../../../../../recoil";

import CancelIcon from "@mui/icons-material/Clear";
import { DateField, DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import {
  IconContainerList,
  ListContainer,
  ListDescriptionText,
  ListScrollbar,
  ListTextbox,
} from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import { ArrowButtonText, ButtonGrey, ButtonText } from "../../../styled-components";
import { URL_CompliancebyAsset, URL_DirectorList, URL_PersonRolesCompliance, URL_UnitComplianceProcessRolePerson, useToken } from "../../../../../API";
import {
  L2AddComplianceDataState,
  L2AddUnitComplianceAssociationState,
  L2AddComplianceisVisible,
  L2AddComplianceSearchTerm,
  L2AddselectedCheckboxesComplianceState,
  L2AddComplianceDate,
  L2AddDirectorCompliance,
  L2AddDirectorComplianceID,
  L2AddComplianceProcessByUnitByProcess,
} from "../L2-Add-recoil";

interface RolesAccordionProps {
  //role: editAssetRoleCompliance[];
  // model: editAssetModelCompliance;
}

///////////////// LAST POPUP

interface LastPopupProps {
  isVisible: boolean;
}

const LastPopupBlack = styled.div<LastPopupProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  //border: 5px solid #ffffff;
  z-index: 100;
`;

///////////////////////////////////////// LAST POP UP END

///////////////// LAST POPUP2

interface LastPopupProps2 {
  isVisible: boolean;
}

const LastPopupBlack2 = styled.div<LastPopupProps2>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup2 = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  //border: 5px solid #ffffff;
  z-index: 100;
`;

///////////////////////////////////////// LAST POP UP END2

/// PLACE UNIT ASSOCIATION

interface PlaceUnitAssociation {
  content: {
    compliance_process_unit_association: {
      compliance_process: {
        id: string;
        name: string;
        description: string;
        kind: string;
      };
      process_unit_association: {
        business_process: {
          id: string;
          name: string;
          kind: string;
          description: string;
          objective: string;
        };
      };
    };
  };
  selected: boolean;
}

const useFetchPlaceUnitAssociations = (token: string | null | undefined) => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [selectedUnitAssociations, setSelectedUnitAssociations] = useRecoilState(L2AddUnitComplianceAssociationState);
  const [selectedId, setSelectedId] = useRecoilState(L2AddComplianceProcessByUnitByProcess);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${URL_CompliancebyAsset}${selectedId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data from API");
        }

        const data: PlaceUnitAssociation[] = await response.json();

        console.log("Raw data: ", data);

        // Filter out non-selected associations and map to the required structure
        const selectedAssociations = data.map((assoc) => ({
          compliance_id: assoc.content.compliance_process_unit_association?.compliance_process?.id,
          compliance_name: assoc.content.compliance_process_unit_association?.compliance_process?.name,
          compliance_description: assoc.content.compliance_process_unit_association?.compliance_process?.description,
          compliance_kind: assoc.content.compliance_process_unit_association?.compliance_process?.kind,
          business_id: assoc.content.compliance_process_unit_association?.process_unit_association?.business_process.id,
          business_name: assoc.content.compliance_process_unit_association?.process_unit_association?.business_process.name,
          business_description: assoc.content.compliance_process_unit_association?.process_unit_association?.business_process.description,
          business_kind: assoc.content.compliance_process_unit_association?.process_unit_association?.business_process.kind,
          business_objective: assoc.content.compliance_process_unit_association?.process_unit_association?.business_process.objective,
          selected: assoc.selected,
        }));

        // Set the selected associations
        setSelectedUnitAssociations(selectedAssociations);
      } catch (error) {
        setError(error instanceof Error ? error.message : "An unknown error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchData();
    }
  }, [token, setSelectedUnitAssociations]);

  return { selectedUnitAssociations };
};

/// PLACE UNIT ASSOCIATION END

// FETCH COMPLIANCE DATA

const useFetchAggrData = (token: string | null | undefined) => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [complianceData, setComplianceData] = useRecoilState(L2AddComplianceDataState);
  const [selectedId, setSelectedId] = useRecoilState(L2AddComplianceProcessByUnitByProcess);
  useEffect(() => {
    const fetchComplianceData = async () => {
      // if (!token || !selectedAggrId) {
      //   setError("Token or selected aggregator ID is missing");
      //   setIsLoading(false);
      //   return;
      // }

      setIsLoading(true);
      setError(null);

      const url = `${URL_CompliancebyAsset}${selectedId}`;

      try {
        const response = await fetch(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }
        const data = await response.json();
        if (Array.isArray(data)) {
          // Transform each item to include the selected property directly within the ComplianceData object
          const transformedData = data
            .map((item) => ({
              ...item.content, // Spread the content of each item
              selected: item.selected, // Add the selected property
            }))
            .sort((a, b) => b.selected - a.selected);
          setComplianceData(transformedData);
        } else {
          setError("Data received is not in expected format");
          setComplianceData([]);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    fetchComplianceData();
  }, [token]);

  // Return any states or functions that might be needed by the component
  return { isLoading, error, complianceData };
};

// FETCH COMPLIANCE DATA END

// Use `groupedByBusinessProcess` to render nested accordions in your component

//const url = `http://localhost:8091/api/v1/unit-compliance-process-compliance-role-person/selection/bycomplianceroleperson/${selectedAggrId}`; person.unit.complianceroles.ecubit13

const RolesAccordion: React.FC<RolesAccordionProps> = () => {
  const [complianceData, setComplianceData] = useRecoilState(L2AddUnitComplianceAssociationState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [searchTerm, setSearchTerm] = useRecoilState(L2AddComplianceSearchTerm);
  const [selectedCheckboxes, setSelectedCheckboxes] = useRecoilState(L2AddselectedCheckboxesComplianceState);

  console.log("Accordion data: ", complianceData);
  // Function to fetch compliance data

  const handleCheckboxChange = useCallback(
    (itemId: string, checked: boolean) => {
      console.log("Checkbox ID before update: ", itemId);
      setSelectedCheckboxes((currentSelected) => {
        const updatedSelected = checked ? [...currentSelected, itemId] : currentSelected.filter((id) => id !== itemId);

        console.log("Updated selected checkboxes inside callback: ", updatedSelected);
        return updatedSelected;
      });
    },
    [setSelectedCheckboxes]
  );

  useEffect(() => {
    // Initialize the selectedCheckboxes state with IDs of initially selected items
    const initiallySelected = complianceData.filter((item) => item.selected).map((item) => item?.business_id);
    setSelectedCheckboxes(initiallySelected);
  }, [complianceData, setSelectedCheckboxes]);

  const filteredComplianceData = searchTerm
    ? complianceData.filter((dataItem) => dataItem.compliance_name.toLowerCase().includes(searchTerm.toLowerCase()) || dataItem.business_name.toLowerCase().includes(searchTerm.toLowerCase()))
    : complianceData;

  return (
    <Box sx={{ mt: 2 }}>
      {filteredComplianceData.map((role, roleIndex) => (
        <Accordion key={role?.business_id} expanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color="#04446c">{role.business_name}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ flexDirection: "column" }}>
            {isLoading && <Typography>Loading...</Typography>}
            {error && <Typography color="error">{error}</Typography>}
            {filteredComplianceData.map((item, index) => (
              <Accordion expanded={true} key={index} sx={{ mt: 1, boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)" }}>
                <AccordionSummary sx={{ paddingTop: "0px", fontStyle: "italic", marginBottom: "-15px" }}>
                  {/* Assuming the first item's business process name is representative of all items in the group */}
                  <Typography>{item?.business_name}:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {filteredComplianceData.map((item, index) => (
                    <Box key={index} sx={{ mt: 1 }}>
                      <Divider sx={{ my: 2, marginTop: "0px" }} />
                      <Typography>{item.compliance_name}</Typography>
                      <Typography>{item.compliance_description}</Typography>

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCheckboxes.includes(item?.compliance_id)}
                            onChange={(e) => handleCheckboxChange(item?.compliance_id, e.target.checked)}
                            inputProps={{ "aria-label": "Is Responsible" }}
                            disabled={false}
                            sx={{
                              color: "#425a77",
                              "& .MuiSvgIcon-root": { fontSize: 16 },
                              "&.Mui-checked": { color: "#425a77" },
                              padding: "0px",
                              marginRight: "10px",
                            }}
                          />
                        }
                        label="Questo processo Compliance é associato alla persona selezionata"
                        sx={{
                          marginTop: "10px", // Adjust top margin as needed

                          marginLeft: "0px",
                          color: "#425a77", // Change label color using the theme's color palette
                          display: "flex", // Ensures the label and checkbox are in a flex container
                          flexDirection: "row", // Aligns the label and checkbox horizontally
                          justifyContent: "flex-start", // Aligns the checkbox and label to the start of the flex container
                          alignItems: "center", // Centers the label and checkbox vertically
                          // Custom label positioning can be adjusted here
                        }}
                        labelPlacement="end" // Adjust label placement relative to the checkbox ("end" positions the label after the checkbox)
                      />
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

// ACCORDION END

// SEARCH INPUT 1

interface SearchInputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
// Search input component
const SearchInput: React.FC<SearchInputProps> = React.memo(({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // Automatically focus the input when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return <LastPopupSearch ref={inputRef} type="text" value={value} onChange={onChange} placeholder="Cerca" />;
});

// SEARCH INPUT 1 END

//////////////////////////MAIN COMPONENT

const MainComponent = () => {
  const [searchTerm, setSearchTerm] = useRecoilState(L2AddComplianceSearchTerm);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const token = useToken(); // Call useToken here at the top level
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);

  const [complianceData, setComplianceData] = useRecoilState(L2AddComplianceDataState);

  const [date, setDate] = useRecoilState(L2AddComplianceDate);

  const [director, setDirector] = useRecoilState(L2AddDirectorCompliance);
  const [directorID, setDirectorID] = useRecoilState(L2AddDirectorComplianceID);

  const [, setSelectedUnitAssociations] = useRecoilState(L2AddUnitComplianceAssociationState);
  const { selectedUnitAssociations } = useFetchPlaceUnitAssociations(token);
  setSelectedUnitAssociations(selectedUnitAssociations);

  useFetchAggrData(token);

  useEffect(() => {
    // Assuming setSelectedUnitAssociations is the correct way to store fetched data in state
    if (selectedUnitAssociations.length > 0) {
      console.log("Selected Unit Associations Updated:", selectedUnitAssociations);
    }
  }, [selectedUnitAssociations]);

  const { closeOverlay } = useContext(OverlayContext);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false);
    closeOverlay();
  };

  const [isVisible, setIsVisible] = useRecoilState(L2AddComplianceisVisible);
  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  const ClearLV1 = () => {
    setDirector("");
    setDirectorID("");
  };

  /////////GET DIRECTORS////////////////
  const [data, setData] = useState<DirectorDataItem[]>([]);

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch data from the API
        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response Data:", responseData);

        // Assuming responseData is the array you want to map
        const transformedData: DirectorDataItem[] = responseData.map((item: DirectorApiContentItem) => {
          return {
            director_id: item?.id,
            director_name: item?.alias, // Changed from 'site_name' to 'name'

            // Map other properties as needed
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        // Error handling
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data on component mount or when token changes
    fetchData();
  }, [token]); // Depend on the token

  ////////////////////TOGGLE DROPDOWN///////////

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Event listener to close dropdown if clicked outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Add a type guard for event.target
      if (dropdownRef.current && event.target instanceof Node && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    // Clear the director name in the input box
    setDirector("");

    // Reset the director ID
    setDirectorID("");

    // Open the dropdown if it is not already open

    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (selectedDirectorId: string) => {
    // Find the director name corresponding to the selected ID
    const selectedDirectorName = data.find((item) => item.director_id === selectedDirectorId)?.director_name;

    if (selectedDirectorName) {
      // Update the director name in the input box
      setDirector(selectedDirectorName);

      // Update the director ID
      setDirectorID(selectedDirectorId);
    }

    // Close the dropdown
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    console.log("Selected Director ID:", directorID);
  }, [directorID]); // This effect will run whenever directorId changes

  ////////////////////

  /////FILTER INPUT DIRECTORS

  // Create a map from director names to IDs
  const directorNameToId = new Map(data.map((item) => [item.director_name, item.director_id]));

  const handleDirectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDirector(value);

    // If the value is a director name, also set the director ID
    if (directorNameToId.has(value)) {
      setDirectorID(directorNameToId.get(value) || "");
      console.log(directorNameToId.get(value) || "");
    }
  };

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionText>Responsabile Compliance</InputDescriptionText>

            <InputTextbox list="directors-list" value={director} onChange={handleDirectorChange} onClick={handleDropdownToggle} placeholder="Seleziona" />
            {director && (
              <CancelIcon
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  right: "30px",
                  top: "32%",
                  transform: "translateY(-50%)",
                  color: "#c6c6c6", // Change the color if needed
                }}
                onClick={ClearLV1} // Clears the input field
              />
            )}
            <TriangleContainer>
              {isDropdownOpen ? "▲" : "▼"} {/* Change icons as needed */}
            </TriangleContainer>

            {isDropdownOpen && (
              <CustomDropdownList>
                <DropDownMenuScrollbar ref={dropdownRef}>
                  {data
                    .filter((item) => item.director_name.toLowerCase().includes(director.toLowerCase()))
                    .map((filteredItem, index) => (
                      <CustomDropdownItem key={index} onClick={() => handleOptionSelect(filteredItem.director_id)}>
                        {filteredItem.director_name}
                      </CustomDropdownItem>
                    ))}
                </DropDownMenuScrollbar>
              </CustomDropdownList>
            )}
            <IconContainer />
          </InputContainer>

          <InputContainer>
            <InputDescriptionText>Processi Compliance</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup}>
              <ArrowButtonText>Apri per visualizzare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn>
          <InputContainer>
            <InputDescriptionTextDatePicker>Data inizio incarico</InputDescriptionTextDatePicker>
            <DatePicker
              //label="Data inizio incarico"
              value={date}
              format="DD-MM-YYYY"
              onChange={(newDate: dayjs.Dayjs | null) => setDate(newDate)}
              sx={{
                "& .MuiInputBase-input": {
                  height: "2px", // Example to change the height
                },
                ".MuiInputBase-root": {
                  // Background color for the input field
                  borderRadius: "0px",
                  backgroundColor: "#ffffff", // Example background color
                },
                ".MuiInputBase-input": {
                  // Font color, size, and style for the input text
                  color: "#0e163d", // Example font color
                  fontSize: "15px", // Example font size
                  fontFamily: "Titillium Web", // Example font family
                },

                ".MuiSvgIcon-root": {
                  // Style for the calendar icon
                  color: "#808080", // Example icon color
                  fontSize: "1.25rem", // Example icon size
                },

                // '&:hover .MuiOutlinedInput-notchedOutline': {
                //   borderColor: '#6d6d6d', // Change border color on hover
                // },
                width: "100%",
              }}
            />

            <IconContainer />
          </InputContainer>
        </CreaContainerColumn>

        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>LISTA PROCESSI COMPLIANCE</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <SearchInput value={searchTerm} onChange={handleSearchChange} />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer>

              <LastPopupScrollbar>
                <RolesAccordion />
              </LastPopupScrollbar>
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <div style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Annulla</ButtonText>
                </ButtonGrey>

                <ButtonGrey onClick={togglePopup}>
                  <ButtonText>Salva</ButtonText>
                </ButtonGrey>
              </div>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_compliance.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
          La scheda specifica gli elementi del sistema di gestione della Compliance definita basandosi sulla struttura organizzativa mappata precedentemente e in linea con il contesto attuale. Questo
          assicura il rispetto delle normative vigenti e la trasparenza nell'ambito della sicurezza delle informazioni.
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
