import styled from "styled-components";
import { NavLink } from 'react-router-dom';

// Logo

export const FrameNavLogo = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: var(--color-white);
  position: absolute;
  top: 0px;
  left: 0px;
  border-bottom: 1px solid var(--color-lightgray-100);
  box-sizing: border-box;
  width: 100%;
  height: 8%;
  overflow: hidden;
`;

export const PicLogoIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  height: 40%;
  object-fit: contain; // Maintain the aspect ratio
  transform: translate(-50%, -50%);
  transition: box-shadow 0.3s ease;
`;

//   ISO

export const FrameTopGradient = styled.div`
  position: absolute;
  width: 82%;
  top: 0px;
  right: 0px;
  left: 18%;
  background: linear-gradient(90deg, #6f8aab, #425a77);
  border-right: 1px solid var(--color-lightgray-100);
  border-left: 1px solid var(--color-lightgray-100);
  box-sizing: border-box;
  height: 8%;
  overflow: hidden;
`;



export const ButtonIsoActiveText = styled.div`
  position: absolute;
  top: -4px;
  left: 0px;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-darkslategray);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
`;

export const NavMessageText = styled.div`
  position: absolute;
  top: -4px;
  left: 0px;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: #C70039;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
 
`;

export const ButtonIsoActive = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: var(--color-aliceblue);
  position: relative;
  border-radius: var(--br-8xs);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 125px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
  &:hover {
    background-color: var(--color-lavender);
  }
`;

export const ButtonIsoInactiveText = styled.div`
  position: absolute;
  height: 45px;
  width: 100%;
  top: -4px;
  left: 0px;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-white);
  text-align: top;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonIsoInactive = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: var(--color-darkslategray);
  position: relative;
  border-radius: var(--br-8xs);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 125px;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
  &:hover {
    background-color: var(--color-steelblue-500);
  }
`;

export const FrameButtonIso = styled.div`
  position: absolute;
  bottom: -10px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 40px;
  gap: 7px;
`;

// User-Notification

export const FrameUserSearch = styled.div`
  position: absolute;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 178px;
  overflow: hidden;
`;

export const FrameUserSearchAuto = styled.div`
  position: absolute;
  height: 50%;
  top: 15px;
  right: 26px;
  bottom: 17px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 15px;
`;

export const ButtonUser = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 20px;
  height: 20px;
`;

export const ButtonNotification = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 20px;
  height: 20px;
  bottom: 2px;
`;

export const IcoUserNotification = styled.img`
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;

  ${ButtonUser}:hover & {
    filter: brightness(0.7); // Example: darkens the image on hover
    // Other filters you might use: grayscale(100%), invert(100%), sepia(100%), etc.
  }

  ${ButtonNotification}:hover & {
    filter: brightness(0.7);
  }
`;

// Navigation Menu

export const FrameLeftNav = styled.div`
  position: absolute;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  background-color: var(--color-white);
  width: 18%;
  overflow: hidden;
`;

export const FrameNavButtons = styled.div`
  position: relative;
  height: 32%;
  top: 8%;
  bottom: 0px;
  left: 0px;
  //overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

// Buttons

export const NavButtonWhite = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  background-color: var(--color-white);
  position: relative;
  border-bottom: 1px solid var(--color-lightgray-100);
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  //border: 1px solid red; /* Debugging border */
  &:hover {
    background-color: var(--color-whitesmoke);
  }
`;



export const NavButtonWhiteFocused = styled.button`
cursor: pointer;
border: none;
padding: 0;
background-color: var(--color-whitesmoke);
position: relative;
border-bottom: 1px solid var(--color-lightgray-300);
box-sizing: border-box;
width: 100%;
height: 50px;
overflow: hidden;
flex-shrink: 0;
&:hover {
  background-color: var(--color-lightgray-200);
}
`;

export const NavButtonWhiteText = styled.div`
  position: absolute;
  top: 0px;
  left: 54px;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-lightslategray);
  text-align: left;
  display: flex;
  align-items: center;
  width: 206px;
  height: 50px;
`;

export const ArrowButtonText = styled.div`
  position: absolute;
  top: -7px;
  left: 10px;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-lightslategray);
  text-align: left;
  display: flex;
  align-items: center;
  width: 206px;
  height: 50px;
`;

export const ButtonBaseText = styled.div`
  position: absolute;
  top: 8px;
  left: 10px;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-lightslategray);
  text-align: left;
 
  align-items: center;
  max-width: 85%;
  height: 30px;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

`;

export const NavButtonWhiteChild = styled.div`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: var(--color-gainsboro);
  position: relative;
  border-bottom: 1px solid var(--color-lightgray-300);
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  //border: 1px solid blue; /* Debugging border */
  &:hover {
    background-color: var(--color-lightsteelblue-100);
  }
  
`;

export const NavButtonWhiteChildText = styled.div`
  position: absolute;
  top: 0px;
  left: 54px;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-lightslategray);
  text-align: left;
  display: flex;
  align-items: center;
  width: 206px;
  height: 40px;
`;

export const NavButtonWhiteChildFocused = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: var(--color-lightsteelblue-100);
  position: relative;
  border-bottom: 1px solid var(--color-lightgray-300);
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  &:hover {
    background-color: var(--color-lightsteelblue-200);
  }
`;

export const NavButtonBlue = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: var(--color-deepskyblue);
  position: relative;
  border-bottom: 1px solid var(--color-aliceblue);
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  &:hover {
    background-color: var(--color-steelblue-100);
  }
`;

export const NavButtonBlueFocused = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: var(--color-steelblue-100);
  position: relative;
  border-bottom: 1px solid var(--color-aliceblue);
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  &:hover {
    background-color: var(--color-steelblue-300);
  }
`;

export const NavButtonBlueText = styled.div`
  position: absolute;
  top: 0px;
  left: 54px;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-white);
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
`;

export const NavButtonBlueChild = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: var(--color-steelblue-400);
  position: relative;
  border-bottom: 1px solid var(--color-lightgray-300);
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  &:hover {
    background-color: var(--color-steelblue-600);
  }
`;

export const NavButtonBlueChildFocused = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: var(--color-steelblue-600);
  position: relative;
  border-bottom: 1px solid var(--color-lightgray-300);
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  &:hover {
    background-color: var(--color-steelblue-700);
  }
`;

export const NavButtonBlueChildText = styled.div`
  position: absolute;
  top: 0px;
  left: 54px;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-white);
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
`;

export const ButtonGreen = styled.button`
cursor: pointer;
border: none;
padding: 0;
background-color: transparent;
border: 1px solid var(--color-steelblue-100);

position: relative;
border-radius: var(--br-8xs);
//box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
width: 120px;
height: 30px;
overflow: hidden;
&:hover {
  //background-color: var(--color-slategray-100);
  border: 2px solid var(--color-steelblue-100);
}
`;



export const ButtonGrey = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: #C8D6E8;
  border: 1px solid var(--color-white);
  //border: 2px solid '#ffffff';
  position: relative;
  border-radius: var(--br-8xs);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 120px;
  height: 35px;
  overflow: hidden;
  &:hover {
    //background-color: var(--color-slategray-100);
   // border: 2px solid var(--color-steelblue-100);
   border: 2px solid var(--color-white);
   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

  }
`;

export const ButtonText = styled.b`
  position: absolute;
  height: calc(100% - 27px);
  width: 100%;
  top: 13px;
  left: 0px;
  font-size: var(--font-size-micro);
  display: flex;
  font-family: var(--font-titillium-web);
  color: var(--color-steelblue-700);
  text-align: center;
  align-items: center;
  justify-content: center;
 
`;

// Icons

export const ArrowGrey = styled.img`
  position: absolute;
  height: 14.29%;
  width: 5%;
  top: 42%;
  right: 7.27%;
  bottom: 42.4%;
  left: 86%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
`;

export const ArrowGreyRight = styled.img`
  position: absolute;
  height: 22.5%;
  width: 7.5%;
  top: 42%;
  right: 7.27%;
  bottom: 42.4%;
  left: 85%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
`;

export const ArrowWhite = styled.img`
  position: absolute;
  height: 20%;
  width: 7%;
  top: 42%;
  right: 7.27%;
  bottom: 42.4%;
  left: 86%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
`;

export const ArrowWhiteRight = styled.img`
position: absolute;
height: 22.5%;
width: 7.5%;
top: 42%;
right: 7.27%;
bottom: 42.4%;
left: 85%;
max-width: 100%;
overflow: hidden;
max-height: 100%;
object-fit: contain;
`;

export const LineMagenta40 = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
  height: 40px;
  object-fit: cover;
`;

export const LineMagenta50 = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
  height: 50px;
  object-fit: cover;
`;

export const FrameButtonIcon = styled.div`
  position: absolute;
  height: 20px;
  top: 15px;

  left: 15px;
  width: 20px;
  overflow: hidden;
`;

export const IcoButtonIcon = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: content;
`;

// Workspace

export const FrameNavWorkspace = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(180deg, rgba(66, 90, 119, 0.64), #425a77 68%);
  width: 100%;
  height: 10%;
  overflow: hidden;
`;

export const WorkspaceLine1 = styled.div`
  position: absolute;
  height: 60%;
  width: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-micro);
`;

export const WorkspaceLine2 = styled.div`
  position: absolute;
  height: 140%;
  width: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-micro);
`;

//  Main

export const PageRoot = styled.div`
  position: relative;
  background-color: var(--color-aliceblue);
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-titillium-web);
`;

export const ScrollableDiv = styled.div`
overflow-y: scroll; 
overflow-x: hidden;// Or 'scroll'
height: 100%; // Or set a specific height

// Custom scrollbar styles
&::-webkit-scrollbar {
  width: 0px;
}

&::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

&::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  &:hover {
    background: #425a77;
  }
}
`;

export const ScrollableDivNav = styled.div`

overflow-y: scroll; 
overflow-x: hidden;// Or 'scroll'
height: 100%; // Or set a specific height

// Custom scrollbar styles
&::-webkit-scrollbar {
  width: 0px;
}

&::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

&::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  &:hover {
    background: #425a77;
  }
}
`;

export const FrameBoardMain = styled.div`
  background-color: var(--color-aliceblue);
  position: absolute;
  height: 93%;
  width: 82%;
  top: 8%;

  left: 18%;
  border-right: 1px solid var(--color-lightgray-100);
  border-bottom: 1px solid var(--color-lightgray-100);
  border-left: 1px solid var(--color-lightgray-100);
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
  //padding-left: 15px;
  //padding-right: 8px;
  
`;

export const FrameBoardMainControls = styled.div`
  background-color: var(--color-aliceblue);
  position: absolute;
  height: 93%;
  width: 82%;
  top: 8%;

  left: 18%;
  border-right: 1px solid var(--color-lightgray-100);
  border-bottom: 1px solid var(--color-lightgray-100);
  border-left: 1px solid var(--color-lightgray-100);
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
  //padding-left: 15px;
  //padding-right: 8px;
  
`;

// FrameBoardMain SubMenu

export const MainBoardSubMenuButton = styled(NavLink)`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-lightslategray);
  padding: 10px; // Adjust padding as needed

  position: relative;
  overflow: hidden;
  text-decoration: none; // This removes the underline

  &:hover {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px; // Height of the magenta line
      background: url('/line-magenta-horizontal.svg') no-repeat;
      background-size: cover;
    }
  }

  &.active {
    background: transparent;
  border: none;
  cursor: pointer;
  font-size: var(--font-size-micro);
  font-family: var(--font-titillium-web);
  color: var(--color-lightslategray);
  padding: 10px; // Adjust padding as needed
  position: relative;
  overflow: hidden;
  text-decoration: none; // This removes the underline
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px; // Height of the magenta line
    background: url('/line-magenta-horizontal.svg') no-repeat;
    background-size: cover;
  }

  }
`;


export const MainBoardSubMenuButtonContainer = styled.div`
  position: relative;
  //background-color: #cccccc;
  background-color: var(--color-aliceblue);
  width: 100%;
  height: 48px;
  top: 0;
  display: flex; // Use flexbox for layout
  flex-direction: row; // Arrange children horizontally
  align-items: center; // Align items vertically in the center
  justify-content: flex-start; // Align items to the start (left)
  //justify-content: center; 
  gap: 10px; // Space between buttons
  margin-left: 10px; // Padding around the container
  //border-bottom: 1px solid #d6d6d6;

`;

export const MainBoardSubMenuButtonContainerOrganigramma = styled.div`
  position: relative;
  //background-color: #cccccc;
  background-color: var(--color-gainsboro);
  width: 100%;
  height: 48px;
  top: 0;
  display: flex; // Use flexbox for layout
  flex-direction: row; // Arrange children horizontally
  align-items: center; // Align items vertically in the center
  justify-content: flex-start; // Align items to the start (left)
  //justify-content: center; 
  gap: 10px; // Space between buttons
  margin-left: 0px; // Padding around the container
  //border-bottom: 1px solid #d6d6d6;

`;

export const MainBoardContentContainer = styled.div`
  position: relative;
  background-color: var(--color-aliceblue);
  width: 100%;
  height: 81%;
  display: flex;
  overflow: hidden;
  gap: 5px;

 
`;

export const MainBoardContentContainerInfrastruttura = styled.div`
  position: relative;
  background-color: var(--color-aliceblue);
  width: 100%;
  height: 81%;
  display: flex;
  overflow: hidden;
  gap: 5px;

 
`;


export const MainBoardContentContainerOrganizzazione = styled.div`
  position: relative;
  background-color: var(--color-aliceblue);
  width: 100%;
  height: 92%;
  display: flex;
  overflow: hidden;
  gap: 5px;
  margin-left: 10px;
  padding-right: 20px;

 
`;



