import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from "react";
import styled from "styled-components";
import { OverlayContext } from "../../cybersecurity-board-contestoleadership-organizzazione"; // Correct path
import { Box, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Accordion, AccordionSummary, AccordionDetails, Typography, CircularProgress } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Checkbox } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import {
  Backdrop,
  BackdropTransparent,
  CloseMessageButton,
  CreaContainerColumn,
  CreaContainerColumnMultiple,
  CreaContainerColumnVertical,
  CreaContainerColumnVerticalMultiple,
  CustomDropdownItem,
  CustomDropdownList,
  DescriptionContainerCrea,
  DescriptionContainerCreaMultiple,
  DescriptionContainerCreaText,
  DropDownMenuScrollbar,
  FlexContainerCrea,
  FlexContainerCreaMultiple,
  IconContainer,
  IconSquare,
  InputContainer,
  InputDescriptionText,
  InputDescriptionTextDatePicker,
  InputTextbox,
  InputTextboxButton,
  LastPopupButtonContainer,
  LastPopupCheckboxContainer,
  LastPopupHeaderContainer,
  LastPopupScrollbar,
  LastPopupSearch,
  LastPopupSearchIcon,
  LastPopupSearchInputContainer,
  LastPopupTitleContainer,
  MessageContainer,
  MessageboxText,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteAdd1Multiple,
  TriangleContainer,
} from "../cybersecurity-board-organizzazione-organigramma-styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import {isLoadingRec, isMessageBoxVisibleRec, SelectedL4ID, setErrorRec, setMessageRec } from "../../../../../recoil";

import CancelIcon from "@mui/icons-material/Clear";
import { DateField, DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import {
  IconContainerList,
  ListContainer,
  ListDescriptionText,
  ListScrollbar,
  ListTextbox,
} from "../../cybersecurity-board-contestoleadership-infrastruttura-sub/cybersecurity-board-infrastruttura-organigramma-styled-components";
import { ArrowButtonText, ButtonGrey, ButtonText } from "../../../styled-components";
import { URL_PersonRoles, URL_UnitProcessRolePerson, useToken } from "../../../../../API";
import {
  showL4ComplianceData,
  showL4Model,
  showL4Role,
  showL4BusinessDataState,
  showL4ModelDataState,
  showL4RoleState,
  showL4searchTerm,
  showL4selectedAggrId,
  showL4selectedModelId,
  showL4selectedModelName,
  showL4BusinessData,
} from "../L4-Show-recoil";

// INTERFACES

interface RolesAccordionProps {
  role: showL4Role[];
  model: showL4Model;
  token: string | null | undefined;
}

interface ModelSelectorProps {
  models: showL4Model[];
  selectedModelId: string;
  onModelChange: (newModelId: string) => void; // Now expects a string
}

type GroupedModels = {
  [key: string]: {
    id: string;
    organizational_unit: {
      id: string;
      prefLabel: string;
    };
    company_role: showL4Role[];
  };
};

///////////// POPUP

interface LastPopupProps {
  isVisible: boolean;
}

const LastPopupBlack = styled.div<LastPopupProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const LastPopup = styled.div`
  font-family: var(--font-titillium-web);
  font-size: var(--font-size-micro);
  color: var(--color-steelblue-700);
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-aliceblue);

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  //border: 5px solid #ffffff;
  z-index: 100;
`;

///////////// POPUP END

export const useFetchModelData = (token: string | null | undefined, L4ID: string) => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [modelData, setModelData] = useRecoilState(showL4ModelDataState);
  const [selectedModelId, setSelectedModelId] = useRecoilState(showL4selectedModelId);
  const [selectedModelName, setSelectedModelName] = useRecoilState(showL4selectedModelName);
  const [selectedModelRoles, setSelectedModelRoles] = useRecoilState(showL4RoleState);
  const [selectedAggrId, setSelectedAggrId] = useRecoilState(showL4selectedAggrId);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      if (!token) throw new Error("User is not authenticated");

      const response = await fetch(`${URL_PersonRoles}${L4ID}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }

      const responseData: showL4Model[] = await response.json();
      console.log("API2:", responseData);

      const groupedModels = responseData.reduce((acc: GroupedModels, item) => {
        if (!acc[item.organizational_unit.id]) {
          acc[item.organizational_unit.id] = {
            ...item,
            company_role: Array.isArray(item.company_role) ? [...item.company_role] : [item.company_role],
          };
        } else {
          if (Array.isArray(item.company_role)) {
            acc[item.organizational_unit.id].company_role.push(...item.company_role);
          } else {
            acc[item.organizational_unit.id].company_role.push(item.company_role);
          }
        }
        return acc;
      }, {} as GroupedModels);

      const transformedData: showL4Model[] = Object.values(groupedModels);

      setModelData(transformedData);

      if (transformedData.length > 0) {
        setSelectedModelId(transformedData[0].organizational_unit.id);
        setSelectedModelName(transformedData[0].organizational_unit.prefLabel);
        setSelectedModelRoles(transformedData[0].company_role);
        setSelectedAggrId(transformedData[0].id);
      }

      console.log("General data: ", transformedData);
    } catch (err) {
      setError(err instanceof Error ? err.message : "An unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  }, [token, L4ID]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    modelData,
  };
};

// MODEL SELECTOR

const ModelSelector: React.FC<ModelSelectorProps> = ({ models, onModelChange, selectedModelId }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" sx={{ color: "#04446c", padding: "8px" }}></FormLabel>
      <RadioGroup aria-label="model" name="model" value={selectedModelId} onChange={(event) => onModelChange(event.target.value)} sx={{ padding: "5px 10px 10px 20px" }}>
        {models.map((model) => (
          <FormControlLabel
            key={model.organizational_unit.id}
            value={model.organizational_unit.id}
            control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#6f8aab" } }} />}
            label={model.organizational_unit.prefLabel} // Use organizational_unit.prefLabel as label
            sx={{ color: "primary.main", "& .MuiTypography-root": { fontSize: "0.875rem" } }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

// MODEL SELECTOR END

// ACCORDION

const RolesAccordion: React.FC<RolesAccordionProps> = ({ role, model, token }) => {
  const [complianceData, setComplianceData] = useRecoilState(showL4BusinessDataState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [selectedAggrId, setSelectedAggrId] = useRecoilState(showL4selectedAggrId);
  const [searchTerm, setSearchTerm] = useRecoilState(showL4searchTerm);
  console.log("Aggregated ID: ", selectedAggrId);
  // Function to fetch compliance data

  const filteredComplianceData = searchTerm
    ? complianceData.filter(
        (dataItem) =>
          dataItem.process_unit_association.business_process.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dataItem.process_unit_association.business_process.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dataItem.process_unit_association.business_process.kind.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dataItem.process_unit_association.business_process.objective.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : complianceData;

  // Assuming `role` is an array of roles for a single model
  const filteredRoles = role.filter((singleRole) => {
    // First, check if the role description matches the search term
    const roleMatchesSearchTerm = singleRole.role_description.toLowerCase().includes(searchTerm.toLowerCase());

    // Then, check if any compliance data related to this role matches the search term
    const complianceDataForRole = complianceData.filter(
      (dataItem) =>
        dataItem.process_unit_association.business_process.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        dataItem.process_unit_association.business_process.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        dataItem.process_unit_association.business_process.kind.toLowerCase().includes(searchTerm.toLowerCase()) ||
        dataItem.process_unit_association.business_process.objective.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // A role should be included if it matches the search term or if any of its compliance data does
    return roleMatchesSearchTerm || complianceDataForRole.length > 0;
  });

  // Use `filteredRoles` instead of `role` for rendering accordions
  // useEffect(() => {
  //   if (role) {
  //     role.forEach((singleRole) => {
  //       fetchComplianceData(singleRole.id, token);
  //     });
  //   }
  // }, [role]);

  if (!role) return null;

  const groupedByBusinessProcess = useMemo(() => groupComplianceByBusinessProcess(complianceData), [complianceData]);

  return (
    <Box sx={{ mt: 2 }}>
      {role.map((role, roleIndex) => (
        <Accordion key={role.id} expanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color="#04446c">{role.role_description}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ flexDirection: "column" }}>
            {isLoading && <Typography>Loading...</Typography>}
            {error && <Typography color="error">{error}</Typography>}
            {Object.entries(groupComplianceByBusinessProcess(filteredComplianceData)).map(([businessProcessId, complianceItems]) => (
              <Accordion expanded={true} key={businessProcessId} sx={{ mt: 1, boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)" }}>
                <AccordionSummary sx={{ paddingTop: "0px", fontWeight: 500, fontStyle: "italic", marginBottom: "-15px" }}>
                  {/* Assuming the first item's business process name is representative of all items in the group */}

                  <Typography>{complianceItems[0]?.process_unit_association.business_process.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {complianceItems.map((item, index) => (
                    <Box key={index} sx={{ mt: 1 }}>
                      {/* <Typography>{item.process_unit_association.business_process.name}</Typography> */}
                      <Divider sx={{ my: 2 }} />
                      <Typography>
                        <br />
                      </Typography>
                      <Typography>Categoria: {item.process_unit_association.business_process.kind}</Typography>
                      <Typography>
                        <br />
                      </Typography>
                      <Typography>Obiettivo: {item.process_unit_association.business_process.objective}</Typography>
                      <Typography>
                        <br />
                      </Typography>
                      <Typography>Descrizione: {item.process_unit_association.business_process.description}</Typography>

                      <Typography>
                        <br />
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.selected}
                            inputProps={{ "aria-label": "Is Responsible" }}
                            disabled={true}
                            sx={{
                              color: "#425a77",
                              "& .MuiSvgIcon-root": { fontSize: 16 },
                              "&.Mui-checked": { color: "#425a77" },
                              padding: "0px",
                              marginRight: "10px",
                            }}
                          />
                        }
                        label="Questo processo é associato alla persona selezionata"
                        sx={{
                          marginTop: "10px", // Adjust top margin as needed

                          marginLeft: "0px",
                          color: "#425a77", // Change label color using the theme's color palette
                          display: "flex", // Ensures the label and checkbox are in a flex container
                          flexDirection: "row", // Aligns the label and checkbox horizontally
                          justifyContent: "flex-start", // Aligns the checkbox and label to the start of the flex container
                          alignItems: "center", // Centers the label and checkbox vertically
                          // Custom label positioning can be adjusted here
                        }}
                        labelPlacement="end" // Adjust label placement relative to the checkbox ("end" positions the label after the checkbox)
                      />
                      <Typography>
                        <br />
                      </Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

// ACCORDION END

// FETCH COMPLIANCE DATA

const useFetchComplianceData = (token: string | null | undefined, selectedAggrId: string) => {
  const [complianceData, setComplianceData] = useRecoilState(showL4BusinessDataState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedAggrId || !token) {
        console.log("Missing token or selectedAggrId");
        return;
      }

      setIsLoading(true);
      setError(null);
      const url = `${URL_UnitProcessRolePerson}${selectedAggrId}`;

      try {
        const response = await fetch(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }
        const data = await response.json();

        if (Array.isArray(data)) {
          // Transform each item to include the selected property directly within the ComplianceData object
          const transformedData = data
            .map((item) => ({
              ...item.content, // Spread the content of each item
              selected: item.selected, // Add the selected property
            }))
            .sort((a, b) => b.selected - a.selected);
          setComplianceData(transformedData);
          console.log("API accordion: ", transformedData);
        } else {
          setError("Data received is not in expected format");
          setComplianceData([]);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, selectedAggrId]);

  return { complianceData };
};

// FETCH COMPLIANCE DATA END

// GROUP COMPLIANCE BY PROCESS

const groupComplianceByBusinessProcess = (complianceData: showL4BusinessData[]) => {
  return complianceData.reduce((acc, item) => {
    const bpId = item.process_unit_association.business_process.id;
    if (!acc[bpId]) {
      acc[bpId] = [];
    }
    acc[bpId].push(item);
    return acc;
  }, {} as { [key: string]: showL4BusinessData[] });
};

// GROUP COMPLIANCE BY PROCESS END

//HANDLE MODEL CHANGE

 const useHandleModelChange = () => {
  const [Modeldata, setModelData] = useRecoilState(showL4ModelDataState);
  const [,setSelectedModelId] = useRecoilState(showL4selectedModelId);
  const [,setSelectedModelRoles] = useRecoilState(showL4RoleState);

  const handleModelChange = useCallback((newModelId: string) => {
    setSelectedModelId(newModelId);

    const newModel = Modeldata.find(model => model.organizational_unit.id === newModelId);
    if (newModel) {
      setSelectedModelRoles(newModel.company_role);
    } else {
      // Handle the case where the newModelId does not match any model
      setSelectedModelRoles([]);
    }
  }, [Modeldata, setSelectedModelId, setSelectedModelRoles]);

  return handleModelChange;
};
// MODEL CHANGE END
const MainComponent = () => {
  
  const [L4ID, setL4ID] = useRecoilState(SelectedL4ID);
  const token = useToken(); // Call useToken here at the top level
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState<string | null>(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(isMessageBoxVisibleRec);
  const [message, setMessage] = useRecoilState(setMessageRec);
  const [searchTerm, setSearchTerm] = useRecoilState(showL4searchTerm);
  const handleModelChange = useHandleModelChange();
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const { closeOverlay } = useContext(OverlayContext);

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); // Close the message box
    closeOverlay();
  };

  ///////////////// LAST POPUP

  const [isVisible, setIsVisible] = useState(false);
  const togglePopup = () => {
    setIsVisible(!isVisible);
  };

  ///////////////////////////////////////// LAST POP UP END

  ///////////////////MODEL SELECTOR

  const [ComplianceData, setComplianceData] = useRecoilState(showL4BusinessDataState);
  const [Modeldata, setModelData] = useRecoilState(showL4ModelDataState);
  const [selectedModelId, setSelectedModelId] = useRecoilState(showL4selectedModelId);
  const [selectedModelName, setSelectedModelName] = useRecoilState(showL4selectedModelName);
  const [selectedAggrId, setSelectedAggrId] = useRecoilState(showL4selectedAggrId);
  const selectedModel = Modeldata.find((model) => model.organizational_unit.id === selectedModelId);
  const [selectedModelRoles, setSelectedModelRoles] = useRecoilState(showL4RoleState);

  const { modelData } = useFetchModelData(token, L4ID);
  const { complianceData } = useFetchComplianceData(token, selectedAggrId);

  useEffect(() => {
    if (modelData) {
      setModelData(modelData);
    }
  }, [modelData]);

  useEffect(() => {
    if (complianceData) {
      setComplianceData(complianceData);
    }
  }, [complianceData]);

  // Use `groupedByBusinessProcess` to render nested accordions in your component

  //const url = `http://localhost:8091/api/v1/unit-compliance-process-compliance-role-person/selection/bycomplianceroleperson/${selectedAggrId}`; staff_companyrole_units.ecubit030

  // Search input component
  interface SearchInputProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }

  const SearchInput: React.FC<SearchInputProps> = React.memo(({ value, onChange }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
      // Automatically focus the input when the component mounts
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);
    return <LastPopupSearch ref={inputRef} type="text" value={value} onChange={onChange} placeholder="Cerca" />;
  });

  /////////////////////////////////////////

  return (
    <OverlayComponentWhiteAdd1Multiple>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}
      <FlexContainerCrea>
        <CreaContainerColumn>
          {/*       
        <InputContainer>
            <InputDescriptionText>Ruoli</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup2} >

            <ArrowButtonText>Clicca per visualizzare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer> */}

          <InputContainer>
            <InputDescriptionText>Processi generali</InputDescriptionText>
            <InputTextboxButton onClick={togglePopup}>
              <ArrowButtonText>Apri per visualizzare</ArrowButtonText>
            </InputTextboxButton>
            <IconContainer />
            <TriangleContainer>▶</TriangleContainer>
          </InputContainer>
        </CreaContainerColumn>

        <CreaContainerColumn></CreaContainerColumn>

        <LastPopupBlack isVisible={isVisible}>
          <LastPopup>
            <LastPopupTitleContainer>LISTA PROCESSI GENERALI</LastPopupTitleContainer>
            <LastPopupCheckboxContainer>
              <LastPopupHeaderContainer>
                <LastPopupSearchInputContainer>
                  <SearchInput value={searchTerm} onChange={handleSearchChange} />
                  <LastPopupSearchIcon src="/search.svg" />
                </LastPopupSearchInputContainer>
              </LastPopupHeaderContainer>

              <LastPopupScrollbar>
                <Grid container spacing={2} sx={{ width: "100%" }}>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        backgroundColor: "#fff", // Set the background color to white
                        borderRadius: "4px", // Set the border-radius to 4px
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Apply a shadow
                        padding: "0px 10px 10px 5px", // Optional: Add padding inside the box if needed
                        marginTop: "16px",
                        marginLeft: "20px",
                      }}
                    >
                      <ModelSelector models={Modeldata} onModelChange={handleModelChange} selectedModelId={selectedModelId} />
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    {selectedModelRoles && selectedModel && <RolesAccordion role={selectedModelRoles} model={selectedModel} token={token} />}
                  </Grid>
                </Grid>
              </LastPopupScrollbar>
            </LastPopupCheckboxContainer>
            <LastPopupButtonContainer>
              <ButtonGrey onClick={togglePopup}>
                <ButtonText>Chiudi</ButtonText>
              </ButtonGrey>
            </LastPopupButtonContainer>
          </LastPopup>
        </LastPopupBlack>
      </FlexContainerCrea>
      <DescriptionContainerCreaMultiple>
        <IconSquare src="/description_show_process.svg" alt="Description Icon" />
        <DescriptionContainerCreaText>
          La scheda fornisce una panoramica dettagliata dei processi operativi standard praticati all'interno dell’Organizzazione, includendo sia i processi interdisciplinari sia quelli specifici.
          Questo elenco riflette il flusso di lavoro distintivo, delineando le operazioni e le interazioni tipiche che definiscono l'ambiente operativo e/o produttivo.
        </DescriptionContainerCreaText>
      </DescriptionContainerCreaMultiple>
    </OverlayComponentWhiteAdd1Multiple>
  );
};

export default MainComponent;
