import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Suspense,
  lazy,
} from "react";
import { OverlayContext } from "../cybersecurity-board-contestoleadership-organizzazione";
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useRecoilState, useRecoilValue } from "recoil";
import dayjs from "dayjs";
import {
  setErrorRec,
  isMessageBoxVisibleRec,
  setMessageRec,
  isLoadingRec,
  reFetchIndicatorL4,
  reFetchIndicatorPlaces,
  isMessageBoxVisibleClosingRec,
} from "../../../../recoil";

import SchedaGenerale from "./cybersecurity-board-infrastruttura-luoghi-Add/infrastruttura-luoghi-Add-1-SchedaGenerale";
import Organizzazione from "./cybersecurity-board-infrastruttura-luoghi-Add/infrastruttura-luoghi-Add-2-Organizzazione";
// const Infrastruttura = lazy(() => import ("./\cybersecurity-board-infrastruttura-luoghi-Add/organigramma-L4-Add-3-Infrastruttura").then(module => ({ default: module.default })));
// import Processi from "./\cybersecurity-board-infrastruttura-luoghi-Add/organigramma-L4-Add-4-Processi";
// import Parti from "./\cybersecurity-board-infrastruttura-luoghi-Add/organigramma-L4-Add-3-Infrastruttura";
import Compliance from "./cybersecurity-board-infrastruttura-luoghi-Add/infrastruttura-luoghi-Add-6-GestioneCompliance";
import Documentazione, {
  useInitiateUpload,
} from "./cybersecurity-board-infrastruttura-luoghi-Add/infrastruttura-luoghi-Add-7-Documentazione";

import { ButtonGrey, ButtonText } from "../../styled-components";

import {
  FrameTopGradient100,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteTitleAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  Backdrop,
  MessageContainer,
  CloseMessageButton,
  MessageboxText,
  BackdropTransparent,
  CreaSubMenuButtonMultiple,
  SubMenuButtonContainerOrganigrammaMultipleShort,
} from "../../cybersecurity/cybersecurity-board-contestoleadership-organizzazione-sub/cybersecurity-board-organizzazione-organigramma-styled-components";

import {
  LuoghiAttachedFilesInfo,
  LuoghiComplianceDate,
  LuoghiDocumentsAttached,
  LuoghiDocumentsPath,
  LuoghiEmail,
  LuoghiFax,
  LuoghiID,
  LuoghiIdentificativo,
  LuoghiIndirizzo,
  LuoghiName,
  LuoghiNomeAbbreviato,
  LuoghiOrganizzazioneSelectedL1,
  LuoghiOrganizzazioneSelectedL1ID,
  LuoghiTelefono,
  addLuoghiConfirmedLV1List,
  addLuoghiOrganizzazioneDirettore,
  addLuoghiOrganizzazioneDirettoreID,
  addLuoghi_2_isDropdownOpenDirectors,
  addLuoghi_2_isDropdownOpenLV1,
  addLuoghiOrganizzazioneDirettoreCompliance,
  addLuoghiOrganizzazioneDirettoreComplianceID,
  addLuoghi_2_isDropdownOpenDirectorsCompliance,
} from "./Luoghi-Add-recoil";
import {
  URL_LV4List,
  URL_LV4List_short,
  URL_PlacesFullList,
  useApi,
  useToken,
} from "../../../../API";
import { PATH_Luoghi } from "../../../../docPaths";

function generateUUID(): string {
  return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function useGenerateUUID(): string {
  const [LuogoID, setLuogoID] = useRecoilState<string>(LuoghiID);

  useEffect(() => {
    // Generate UUID and set it
    const newUUID = generateUUID();
    setLuogoID(newUUID);
    console.log("New place ID: ", newUUID);
  }, [setLuogoID]); // This effect should run only once when the component mounts

  return LuogoID;
}

export const useHandleSave = () => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(
    isMessageBoxVisibleRec
  );

  const [message, setMessage] = useRecoilState(setMessageRec);

  const { closeOverlay } = useContext(OverlayContext);
  const { post } = useApi();

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(
    LuoghiAttachedFilesInfo
  );
  const [complianceDate, setComplianceDate] =
    useRecoilState(LuoghiComplianceDate);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(
    LuoghiDocumentsAttached
  );
  const [documentsPath, setDocumentsPath] = useRecoilState(LuoghiDocumentsPath);
  const [email, setEmail] = useRecoilState(LuoghiEmail);
  const [fax, setFax] = useRecoilState(LuoghiFax);
  const [ID, setID] = useRecoilState(LuoghiID);
  const [identificativo, setIdentificativo] =
    useRecoilState(LuoghiIdentificativo);
  const [indirizzo, setIndirizzo] = useRecoilState(LuoghiIndirizzo);
  const [name, setName] = useRecoilState(LuoghiName);
  const [nomeAbbreviato, setNomeAbbreviato] =
    useRecoilState(LuoghiNomeAbbreviato);
  const [organizzazioneSelectedL1, setOrganizzazioneSelectedL1] =
    useRecoilState(LuoghiOrganizzazioneSelectedL1);
  const [organizzazioneSelectedL1ID, setOrganizzazioneSelectedL1ID] =
    useRecoilState(LuoghiOrganizzazioneSelectedL1ID);
  const [telefono, setTelefono] = useRecoilState(LuoghiTelefono);
  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(
    addLuoghiConfirmedLV1List
  );
  const [organizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(
    addLuoghiOrganizzazioneDirettore
  );
  const [organizzazioneDirettoreID, setOrganizzazioneDirettoreID] =
    useRecoilState(addLuoghiOrganizzazioneDirettoreID);
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(
    addLuoghi_2_isDropdownOpenDirectors
  );
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(
    addLuoghi_2_isDropdownOpenLV1
  );
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(
    reFetchIndicatorPlaces
  );
  const [
    organizzazioneDirettoreCompliance,
    setOrganizzazioneDirettoreCompliance,
  ] = useRecoilState(addLuoghiOrganizzazioneDirettoreCompliance);
  const [
    organizzazioneDirettoreComplianceID,
    setOrganizzazioneDirettoreComplianceID,
  ] = useRecoilState(addLuoghiOrganizzazioneDirettoreComplianceID);
  const [
    isDropdownOpenDirectorsCompliance,
    setIsDropdownOpenDirectorsCompliance,
  ] = useRecoilState(addLuoghi_2_isDropdownOpenDirectorsCompliance);

  const handleSave = async () => {
    if (!identificativo || !name || !organizzazioneDirettoreID ) {
      setMessage(
        "Si prega di compilare tutti i campi obbligatori: Nome, Identificativo e Responsabile"
      );
      setIsMessageBoxVisible(true);
      return; // Exit the function early
    }

    const newData = {
      id: ID,
      name: name,
      plan_ref: identificativo,
      has_site: organizzazioneSelectedL1ID,
      has_responsible: organizzazioneDirettoreID,
      site: {
        fax: fax,
        site_short_name: nomeAbbreviato,
        has_site_address: indirizzo,
        site_email: email,
        site_name: organizzazioneSelectedL1,
        site_telephone: telefono,
        //has_site_responsible: organizzazioneDirettoreID,
      },
      has_compliance_responsible: organizzazioneDirettoreComplianceID,
      compliance_responsible_assignment_start: "",
      has_documents_folder_link: PATH_Luoghi,
    };

    try {
      setIsLoading(true);
      console.log(JSON.stringify(newData));
      const responseData = await post(URL_PlacesFullList, newData);

      console.log("Place created successfully:", responseData);
      setMessage("Place created successfully!");
      setIsMessageBoxVisible(true);
      setRefetchIndicator((prev) => prev + 1);
     
    } catch (error) {
      console.error("Error creating Place:", error);
      setMessage(
        `Error creating Place: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
      setIsMessageBoxVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSave };
};

const MainComponent = () => {
  const token = useToken();

  const [attachedFilesInfo, setAttachedFilesInfo] = useRecoilState(
    LuoghiAttachedFilesInfo
  );
  const [complianceDate, setComplianceDate] =
    useRecoilState(LuoghiComplianceDate);
  const [documentsAttached, setDocumentsAttached] = useRecoilState(
    LuoghiDocumentsAttached
  );
  const [documentsPath, setDocumentsPath] = useRecoilState(LuoghiDocumentsPath);
  const [email, setEmail] = useRecoilState(LuoghiEmail);
  const [fax, setFax] = useRecoilState(LuoghiFax);
  const [ID, setID] = useRecoilState(LuoghiID);
  const [identificativo, setIdentificativo] =
    useRecoilState(LuoghiIdentificativo);
  const [indirizzo, setIndirizzo] = useRecoilState(LuoghiIndirizzo);
  const [name, setName] = useRecoilState(LuoghiName);
  const [nomeAbbreviato, setNomeAbbreviato] =
    useRecoilState(LuoghiNomeAbbreviato);
  const [organizzazioneSelectedL1, setOrganizzazioneSelectedL1] =
    useRecoilState(LuoghiOrganizzazioneSelectedL1);
  const [organizzazioneSelectedL1ID, setOrganizzazioneSelectedL1ID] =
    useRecoilState(LuoghiOrganizzazioneSelectedL1ID);
  const [telefono, setTelefono] = useRecoilState(LuoghiTelefono);
  const [confirmedLV1List, setConfirmedLV1List] = useRecoilState(
    addLuoghiConfirmedLV1List
  );
  const [organizzazioneDirettore, setOrganizzazioneDirettore] = useRecoilState(
    addLuoghiOrganizzazioneDirettore
  );
  const [organizzazioneDirettoreID, setOrganizzazioneDirettoreID] =
    useRecoilState(addLuoghiOrganizzazioneDirettoreID);
  const [isDropdownOpenDirectors, setIsDropdownOpenDirectors] = useRecoilState(
    addLuoghi_2_isDropdownOpenDirectors
  );
  const [isDropdownOpenLV1, setIsDropdownOpenLV1] = useRecoilState(
    addLuoghi_2_isDropdownOpenLV1
  );

  const { handleSave } = useHandleSave();
  const initiateUpload = useInitiateUpload();

  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useRecoilState(isLoadingRec);
  const [error, setError] = useRecoilState(setErrorRec);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useRecoilState(
    isMessageBoxVisibleRec
  );

  const [message, setMessage] = useRecoilState(setMessageRec);
  const [reFetchIndicator, setRefetchIndicator] = useRecoilState(
    reFetchIndicatorPlaces
  );

  // CUSTOM HOOKS

  useGenerateUUID();

  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  const handleCloseAndNavigate = () => {
    closeOverlay();
    setIsMessageBoxVisible(false); // Close the message box
   
  };

  ///////////////// SAVE AND UPLOAD /////////

  const handleSaveAndUpload = async () => {
    await handleSave();
    initiateUpload(); // Now you can call this function directly
  };

  ///////////////// MESSAGE ANNULLA BUTTON /////////

  const handleClose = () => {
    closeOverlay();

    setAttachedFilesInfo([]); // Assuming this resets to an empty array
    setDocumentsAttached([]); // Assuming this is a boolean that should be reset to false
    setDocumentsPath(""); // Assuming this resets to an empty string

    // setSurname('');
    // setName('');
    // setBirthDate(dayjs());
    // setBirthPlace('');
    // setGender('');
    // setFiscalCode('');
    // setResidenceAddress('');
    // setDomicileAddress('');
    // setWorkEmail('');
    // setPersonalEmail('');
    // setWorkPhone('');
    // setPrivatePhone('');
  };

  ////////////////////CHANGE ACTIVE COMPONENT///////////

  const [activeComponent, setActiveComponent] = useState("SchedaGenerale");

  const changeActiveComponent = (componentName: string) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "SchedaGenerale":
        return <SchedaGenerale />;
      case "Organizzazione":
        return <Organizzazione />;
      // case "Infrastruttura":
      //   return <Infrastruttura />;
      // case "ProcessiGenerali":
      //   return <Processi />;
      // case "PartiInteressate":
      //   return <Parti />;
      case "SistemaCompliance":
        return <Compliance />;
      case "Documentazione":
        return <Documentazione />;
      default:
        return <SchedaGenerale />;
    }
  };

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}


      {error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>
        NUOVO LUOGO
      </OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaMultipleShort>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SchedaGenerale")}
            to="/Cybersecurity/Infrastruttura/Luoghi/CreaLuoghi/1"
          >
            Scheda generale
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Organizzazione")}
            to="/Cybersecurity/Infrastruttura/Luoghi/CreaLuoghi/2"
          >
            Organizzazione
          </CreaSubMenuButtonMultiple>
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Infrastruttura")}
            to="/Cybersecurity/Infrastruttura/Luoghi/CreaLuoghi/3"
          >
            Infrastruttura
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("ProcessiGenerali")}
            to="/Cybersecurity/Infrastruttura/Luoghi/CreaLuoghi/4"
          >
            Processi generali
          </CreaSubMenuButtonMultiple> */}
          {/* <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("PartiInteressate")}
            to="/Cybersecurity/Infrastruttura/UnitaOperative/CreaL2/5"
          >
            Parti interessate
          </CreaSubMenuButtonMultiple> */}
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("SistemaCompliance")}
            to="/Cybersecurity/Infrastruttura/Luoghi/CreaLuoghi/6"
          >
            Sistema gestione Compliance
          </CreaSubMenuButtonMultiple>
          <CreaSubMenuButtonMultiple
            onClick={() => changeActiveComponent("Documentazione")}
            to="/Cybersecurity/Infrastruttura/Luoghi/CreaLuoghi/7"
          >
            Documentazione
          </CreaSubMenuButtonMultiple>
        </SubMenuButtonContainerOrganigrammaMultipleShort>
        <Suspense fallback={<div>Loading...</div>}>
          {renderActiveComponent()}
        </Suspense>
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={handleClose}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGrey onClick={handleSaveAndUpload}>
          <ButtonText>Crea</ButtonText>
        </ButtonGrey>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default MainComponent;
