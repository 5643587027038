
//If some specific description is an empty string the global one will be used

export const TAB_DESCRIPTION_GLOBAL = `Per una corretta integrazione con i sistemi gestionali dell’Organizzazione, inserire dati coerenti con quelli già esistenti. Assicurarsi che tutte le informazioni siano precise, aggiornate e in linea con le politiche interne. Ogni modifica deve essere sottoposta all'approvazione dei responsabili per mantenere la conformità nelle procedure operative e nella gestione della documentazione.`;
export const TAB_DESCRIPTION_GLOBAL_SHOW = `Sono visualizzate tutte le informazioni e i dati del contesto aziendale rilevanti per la gestione della conformità normativa. Questi elementi permettono di monitorare e/o gestire la compliance attraverso diverse proiezioni semplificate del sistema generale dell'Organizzazione.`;

// L1 ADD
export const TAB_DESCRIPTION_L1_1 = `Inserire il nome completo della Componente Organizzativa per garantire un riconoscimento formale, utilizzare un nome abbreviato per facilitare la comunicazione interna, selezionare il Direttore a capo, fornire l'indirizzo completo e aggiungere altri contatti per assicurare una comunicazione efficace e diretta.`;
export const TAB_DESCRIPTION_L1_2 = `Indicare il nome del Responsabile dell'unità operativa, selezionare il modello della struttura organizzativa interna e elencare le principali attività cliniche e amministrative, sia quelle specialistiche previste dal Day Hospital sia quelle ordinarie, per delineare compiutamente il funzionamento e le competenze dell'unità.`;
export const TAB_DESCRIPTION_L1_6 = `Inserire il nome del Responsabile Compliance e la data di inizio del suo mandato per assicurare il rispetto delle normative vigenti e garantire la trasparenza operativa dell'unità nella sicurezza delle informazioni (Cybersecurity).`;
export const TAB_DESCRIPTION_L1_7 = `Specificare il percorso della cartella principale dove archiviare i documenti della nuova componente organizzativa e utilizzare la funzione di allegato per inserire direttamente la documentazione rilevante per la compliance, garantendo così un accesso immediato e organizzato ai file essenziali.`;

// L1 SHOW
export const TAB_DESCRIPTION_L1_1_SHOW = `Sono visualizzati il nome completo della Componente Organizzativa per garantire un riconoscimento formale, il nome abbreviato per facilitare la comunicazione interna, il Responsabile della Componente Organizzativa, l'indirizzo completo e altri contatti per assicurare una comunicazione efficace e diretta.`;
export const TAB_DESCRIPTION_L1_2_SHOW = `Sono visualizzati il nome del Responsabile della Componente Organizzativa, il modello della struttura organizzativa interna e le principali attività cliniche e amministrative, sia quelle specialistiche previste dal Day Hospital sia quelle ordinarie, per delineare compiutamente il funzionamento e le competenze dell'unità.`;
export const TAB_DESCRIPTION_L1_6_SHOW = `Sono visualizzati il nome del Responsabile Compliance e la data di inizio del suo mandato per assicurare il rispetto delle normative vigenti e garantire la trasparenza operativa dell'unità nella sicurezza delle informazioni (Cybersecurity).`;
export const TAB_DESCRIPTION_L1_7_SHOW = `Sono visualizzati il percorso della cartella principale dove consultare i documenti relativi alla componente organizzativa e la documentazione rilevante per la compliance, garantendo così un accesso immediato e organizzato ai file essenziali.`;

// L2 ADD
export const TAB_DESCRIPTION_L2_1 = ``;
export const TAB_DESCRIPTION_L2_2 = `Indicare il nome del Responsabile dell'unità organizzativa, selezionare il modello della struttura organizzativa interna e elencare le principali attività cliniche e amministrative, sia quelle specialistiche previste dal Day Hospital sia quelle ordinarie, per delineare compiutamente il funzionamento e le competenze dell'unità.`;
export const TAB_DESCRIPTION_L2_4 = `Selezionare i processi standard operativi in uso nell'unità, inclusi quelli interdisciplinari, assicurandosi di includere tutte le attività quotidiane e specialistiche che caratterizzano il flusso di lavoro e le pratiche quotidiane dell'unità operativa.`;
export const TAB_DESCRIPTION_L2_6 = `Inserire il nome del Responsabile Compliance e la data di inizio del suo mandato per assicurare il rispetto delle normative vigenti e garantire la trasparenza operativa dell'unità nella sicurezza delle informazioni (Cybersecurity).`;
export const TAB_DESCRIPTION_L2_7 = `Specificare il percorso della cartella principale dove archiviare i documenti della nuova unità operativa e utilizzare la funzione di allegato per inserire direttamente la documentazione dell’unità rilevante per la compliance, garantendo così un accesso immediato e organizzato ai file essenziali.`;

// L2 SHOW
export const TAB_DESCRIPTION_L2_1_SHOW = ``;
export const TAB_DESCRIPTION_L2_2_SHOW = `Sono visualizzati il nome del Responsabile dell'unità operativa, il modello della struttura organizzativa interna e le principali attività cliniche e amministrative, sia quelle specialistiche previste dal Day Hospital sia quelle ordinarie, per delineare compiutamente il funzionamento e le competenze dell'unità operativa selezionata.`;
export const TAB_DESCRIPTION_L2_4_SHOW = `Sono visualizzati i processi standard operativi in uso nell'unità, inclusi quelli interdisciplinari, inclusi tutte le attività quotidiane e specialistiche che caratterizzano il flusso di lavoro e le pratiche quotidiane dell'unità operativa.`;
export const TAB_DESCRIPTION_L2_6_SHOW = `Sono visualizzati il nome del Responsabile Compliance e la data di inizio del suo mandato per assicurare il rispetto delle normative vigenti e garantire la trasparenza operativa dell'unità nella sicurezza delle informazioni (Cybersecurity).`;
export const TAB_DESCRIPTION_L2_7_SHOW = `Sono visualizzati il percorso della cartella principale dei documenti della unità operativa selezionata e la documentazione dell’unità rilevante per la compliance, garantendo così un accesso immediato e organizzato ai file essenziali.`;

// L3 ADD/SHOW
export const TAB_DESCRIPTION_L3_1 = ``;
export const TAB_DESCRIPTION_L3_2 = ``;
export const TAB_DESCRIPTION_L3_4 = ``;
export const TAB_DESCRIPTION_L3_6 = ``;
export const TAB_DESCRIPTION_L3_7 = ``;

// PARTI INTERESSATE ADD
export const TAB_DESCRIPTION_PARTI_1 = ``;
export const TAB_DESCRIPTION_PARTI_2 = ``;
export const TAB_DESCRIPTION_PARTI_4 = ``;
export const TAB_DESCRIPTION_PARTI_6 = `Inserire il nome del Responsabile Compliance e la data di inizio del suo mandato per assicurare il rispetto delle normative vigenti e garantire la trasparenza operativa dell'unità nella sicurezza delle informazioni (Cybersecurity).`;
export const TAB_DESCRIPTION_PARTI_7 = `Inserire il percorso della cartella principale dei documenti dell'elemento selezionato, e la documentazione rilevante per la compliance, garantendo così un accesso immediato e organizzato ai file essenziali.`;

// ASSET ADD
export const TAB_DESCRIPTION_ASSET_2 = ``;
export const TAB_DESCRIPTION_ASSET_6 = `Inserire il nome del Responsabile Compliance e la data di inizio del suo mandato per assicurare il rispetto delle normative vigenti e garantire la trasparenza operativa dell'unità nella sicurezza delle informazioni (Cybersecurity).`;
export const TAB_DESCRIPTION_ASSET_7 = `Inserire il percorso della cartella principale dei documenti dell'elemento selezionato, e la documentazione rilevante per la compliance, garantendo così un accesso immediato e organizzato ai file essenziali.`;

// LUOGHI ADD
export const TAB_DESCRIPTION_LUOGHI_1 = ``;
export const TAB_DESCRIPTION_LUOGHI_2 = ``;
export const TAB_DESCRIPTION_LUOGHI_4 = ``;
export const TAB_DESCRIPTION_LUOGHI_6 = `Inserire il nome del Responsabile Compliance e la data di inizio del suo mandato per assicurare il rispetto delle normative vigenti e garantire la trasparenza operativa dell'unità nella sicurezza delle informazioni (Cybersecurity).`;
export const TAB_DESCRIPTION_LUOGHI_7 = `Inserire il percorso della cartella principale dei documenti dell'elemento selezionato, e la documentazione rilevante per la compliance, garantendo così un accesso immediato e organizzato ai file essenziali.`;

// PROCESSI_BUSINESS ADD
export const TAB_DESCRIPTION_PROCESSI_BUSINESS_1 = ``;
export const TAB_DESCRIPTION_PROCESSI_BUSINESS_6 = `Inserire il nome del Responsabile Compliance, la data di inizio del suo mandato e Processi Compliance associati per assicurare il rispetto delle normative vigenti e garantire la trasparenza operativa dell'unità nella sicurezza delle informazioni (Cybersecurity).`;
export const TAB_DESCRIPTION_PROCESSI_BUSINESS_7 = `Inserire il percorso della cartella principale dei documenti dell'elemento selezionato, e la documentazione rilevante per la compliance, garantendo così un accesso immediato e organizzato ai file essenziali.`;

// ADEMPIMENTI ADD
export const TAB_DESCRIPTION_ADEMPIMENTI_7 = `Inserire il percorso della cartella principale dei documenti dell'elemento selezionato, e la documentazione rilevante per la compliance, garantendo così un accesso immediato e organizzato ai file essenziali.`;

// PROCESSI_COMPLIANCE ADD
export const TAB_DESCRIPTION_PROCESSI_COMPLIANCE_6 = `Inserire il nome del Responsabile Compliance e la data di inizio del suo mandato per assicurare il rispetto delle normative vigenti e garantire la trasparenza operativa dell'unità nella sicurezza delle informazioni (Cybersecurity).`;
export const TAB_DESCRIPTION_PROCESSI_COMPLIANCE_7 = `Inserire il percorso della cartella principale dei documenti dell'elemento selezionato, e la documentazione rilevante per la compliance, garantendo così un accesso immediato e organizzato ai file essenziali.`;

// CONTROLLI ADD
export const TAB_DESCRIPTION_CONTROLLI_1 = `Questa scheda contiene le informazioni chiave del controllo, classificando il suo obbligo come libero, consigliato o obbligatorio e indicando la priorità per ottimizzare l'allocazione delle risorse. La valutazione, basata su una media pesata dei criteri predefiniti, è integrata da un giudizio finale dell'utente per assicurare l'accuratezza e la conformità agli standard.`;
export const TAB_DESCRIPTION_CONTROLLI_2 = `Questa scheda elenca i criteri usati per valutare il grado di applicazione del controllo. Ogni criterio è dotato di un peso specifico e la somma dei pesi per i criteri definiti deve essere pari a 1. Ciò permette di calcolare una precisa media pesata che influisce sulla valutazione complessiva, riportata nella Scheda generale come “Valutazione calcolata".`;
export const TAB_DESCRIPTION_CONTROLLI_3 = `Questa scheda documenta gli adempimenti compiuti e quelli ancora da compiere relativi al controllo. Nel campo “Adempimenti compiuti” sono elencare le azioni già realizzate. Il campo “Adempimenti da compiere” serve per annotare le misure future necessarie per garantire la piena conformità e l'efficacia del controllo.`;
export const TAB_DESCRIPTION_CONTROLLI_7 = `In questa scheda è possibile allegare i documenti che dimostrano gli adempimenti compiuti rispetto al controllo, utili per revisioni interne e audit esterni. Il campo “Nota evidenze” descrive dettagliatamente in formato riassuntivo le evidenze presentate, facilitando la comprensione e la verifica degli adempimenti documentati.`;
