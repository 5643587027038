import { atom, selector } from 'recoil';
import dayjs from 'dayjs';

// SCHEDA GENERALE

export const ProcessiID = atom({
    key: 'ProcessiID', 
    default: '',
  });
  
export const ProcessiName = atom({
    key: 'ProcessiName', 
    default: '',
  });
  
  export const ProcessiKind = atom({
    key: 'ProcessiKind', 
    default: '',
  });
  
 
  
  export const ProcessiObjective = atom({
    key: 'ProcessiObjective', 
    default: '',
  });
  
  
  export const ProcessiDescription = atom({
    key: 'ProcessiDescription', 
    default: '',
  });

  // COMPLIANCE
  
 
  export const ProcessiComplianceDate = atom<dayjs.Dayjs | null>({
    key: 'ProcessiComplianceDate', 
    //default: dayjs(),
    default: null,
  });

  export const ProcessiIsDropdownOpenDirectors = atom<boolean>({
    key: 'ProcessiIsDropdownOpenDirectors', 
    default: false,
  });

  export const ProcessiComplianceResponsible = atom({
    key: 'ProcessiComplianceResponsible', 
    default: '',
  });
  
  export const ProcessiComplianceResponsibleID = atom({
    key: 'ProcessiComplianceResponsibleID', 
    default: '',
  });
  

// DOCUMENTAZIONE

export const ProcessiDocumentsPath = atom({
    key: 'ProcessiDocumentsPath', 
    default: '',
  });
  
 
  export const ProcessiDocumentsAttached = atom<File[]>({
    key: 'ProcessiDocumentsAttached',
    default: [],
  });
  
  
  export const ProcessiAttachedFilesInfo = atom<Array<{ name: string, size: number, type: string } | null>>({
    key: 'ProcessiAttachedFilesInfo',
    default: [],
  });

  // EXTRA
    
  export type LV1DataItem = {
    LV1_id: string;
    LV1_name: string;
  };
  
  export const LV1DataItemState = atom<LV1DataItem[]>({
    key: 'LV1DataItemState', // Unique ID (with respect to other atoms/selectors)
    default: [], // Default value (aka initial value)
  });

  export type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };
  export const directorDataState = atom<DirectorDataItem[]>({
    key: 'directorDataState', // Unique ID (with respect to other atoms/selectors)
    default: [], // Default value (aka initial value)
  });

  export const ProcessiOrganizzazioneSelectedL1 = atom({
    key: 'ProcessiOrganizzazioneSelectedL1', 
    default: '',
  });

  export const ProcessiOrganizzazioneSelectedL1ID = atom({
    key: 'ProcessiOrganizzazioneSelectedL1ID', 
    default: '',
  });

 
  export const Processi_2_isDropdownOpenLV1 = atom({
    key: 'Processi_2_isDropdownOpenLV1', 
    default: false,
  });

  export const ProcessiConfirmedLV1List = atom<string>({
    key: 'ProcessiConfirmedLV1List', // Unique ID (with respect to other atoms/selectors)
    default: '', // Updated default value to be an empty object
  });
  
  export const ProcessiIsDropdownOpenDirectorsCompliance = atom<boolean>({
    key: 'ProcessiIsDropdownOpenDirectorsCompliance', 
    default: false,
  });
  
  export const ProcessiDeleteConfirmationDialog = atom<boolean>({
    key: 'ProcessiDeleteConfirmationDialog', 
    default: false,
  });

  export const ProcessiSearchTerm = atom({
    key: 'ProcessiSearchTerm', 
    default: '',
  });

  
  export const ProcessiSearchTermProcessi = atom({
    key: 'ProcessiSearchTermProcessi', 
    default: '',
  });


  export const ProcessiIsVisible = atom({
    key: 'ProcessiIsVisible', 
    default: false,
  });
  
  export interface addProcessComplianceData {
    id: string;
    name: string;
    kind: string;
    objective: string;
    description: string;  
   
  };
  
  export const addProcessComplianceDataState = atom<addProcessComplianceData[]>({
    key: 'addProcessComplianceDataState', // Unique ID (with respect to other atoms/selectors)
    default: [], // Default value (aka initial value)
  });
  
  type CheckboxProcessiIdType = string;

export const addProcessSelectedCheckboxesProcessiState = atom<CheckboxProcessiIdType[]>({
  key: 'addProcessSelectedCheckboxesProcessiState',
  default: [], // default to an empty array
});


export interface ComplianceProcess {
  id: string;
  creationTs: string;
  updateTs: string | null;
  createdBy: string;
  updatedBy: string;
  name: string;
  description: string;
  kind: string;
  norm_ref: string;
  note: string;
  has_compliance_responsible: string;
  compliance_responsible_assignment_start: string;
  compliance_responsible: null;
}

export interface BusinessProcess {
  id: string;
  creationTs: string;
  updateTs: string;
  createdBy: string;
  updatedBy: string;
  name: string;
  kind: string;
  objective: string;
  description: string;
  has_compliance_responsible: string;
  compliance_responsible_assignment_start: string;
  compliance_responsible: null;
}

export interface ProcessSelectionContent {
  id: string;
  creationTs: string;
  updateTs: string | null;
  createdBy: string;
  updatedBy: string;
  has_business_process: string;
  has_compliance_process: string;
  compliance_process: ComplianceProcess;
  business_process: BusinessProcess;
}

export interface ProcessSelectionData {
  content: ProcessSelectionContent;
  selected: boolean;
}


export const ProcessiSelectionBodyState = atom<ProcessSelectionData[]>({
  key: 'ProcessiSelectionBodyState',
  default: [],
});
