import React, { useState, useEffect, useRef, useContext, } from "react";
import { OverlayContext } from '../cybersecurity-board-contestoleadership-organizzazione'; // Correct path
import styled from "styled-components";
import { FunctionComponent } from "react";
import { useNavigate, } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";



import {
  FrameNavLogo,
  PicLogoIcon,
  FrameTopGradient,
  ButtonIsoActiveText,
  ButtonIsoActive,
  ButtonIsoInactiveText,
  ButtonIsoInactive,
  FrameButtonIso,
  FrameUserSearch,
  FrameUserSearchAuto,
  ButtonUser,
  ButtonNotification,
  IcoUserNotification,
  FrameLeftNav,
  FrameNavButtons,
  NavButtonWhite,
  NavButtonWhiteFocused,
  NavButtonWhiteText,
  NavButtonWhiteChild,
  NavButtonWhiteChildText,
  NavButtonWhiteChildFocused,
  NavButtonBlue,
  NavButtonBlueFocused,
  NavButtonBlueText,
  NavButtonBlueChild,
  NavButtonBlueChildFocused,
  ButtonGreen,
  ButtonGrey,
  ButtonText,
  ArrowGrey,
  ArrowGreyRight,
  ArrowWhite,
  ArrowWhiteRight,
  LineMagenta40,
  LineMagenta50,
  FrameButtonIcon,
  IcoButtonIcon,
  FrameNavWorkspace,
  WorkspaceLine1,
  WorkspaceLine2,
  FrameBoardMain,
  PageRoot,
  ScrollableDiv,
  MainBoardSubMenuButton,
  MainBoardSubMenuButtonContainer,
  MainBoardContentContainer,
  MainBoardSubMenuButtonContainerOrganigramma,
} from "../../styled-components";

import {
  FrameTopGradient100,
  SearchBarWithIconsRoot,
  Search,
  LineDivider,
  IconButton,
  BackgroundIcon,
  MainBoardContentContainerColumn,
  MainBoardContentContainerFile,
  FileButtonArrowGrey,
  FileButtonWhiteText,
  SearchGroup,
  IconsGroup,
  Title,
  TitleText,
  BoardScrollableDiv,
  ButtonClose,
  OverlayComponentBackgroundAdd1,
  OverlayComponentWhiteAdd1,
  OverlayComponentWhiteTitleAdd1,
  OverlayComponentWhiteButtonFrameAdd1,
  InputTextbox,
  CreaContainerColumn,
  FlexContainerCrea,
  InputContainer,
  IconContainer,
  InputDescriptionText,
  SubMenuButtonContainerOrganigrammaNoScroll,
  CreaSubMenuButton,
  Backdrop,
  MessageContainer,
  CloseMessageButton,
  MessageboxText,
  BackdropTransparent,
  DescriptionContainerCrea,
  IconSquare,
  DescriptionContainerCreaText,
} from "./cybersecurity-board-organizzazione-organigramma-styled-components";
import { URL_DirectorList, URL_LV1_Detailed } from "../../../../API";

const useToken = () => {
  const { keycloak, initialized } = useKeycloak();
  return initialized && keycloak.authenticated ? keycloak.token : null;
};

const useApi = () => {
  const token = useToken();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  //////////post
  const post = async (url: string, data: any) => {
    if (!token) {
      throw new Error("User is not authenticated");
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      // Attempt to parse the response as JSON, but handle empty or non-JSON responses gracefully
      let responseData;
      try {
        responseData = await response.json(); // May throw if the response is not JSON or is empty
      } catch (parseError) {
        if (response.ok) {
          // If the response was OK but parsing failed, it could be an empty response
          return null; // Or some default value as appropriate for your application
        } else {
          // If the response was not OK and parsing failed, throw with the status text
          throw new Error(`API request failed: ${response.statusText}`);
        }
      }

      // Check the OK status after attempting to parse the response
      if (!response.ok) {
        console.error("Error response data:", responseData);
        // Include the parsed responseData in the thrown Error, if available
        throw new Error(`API request failed: ${response.statusText}`, responseData);
      }

      return responseData; // Return the parsed response data
    } catch (error) {
      throw error; // Rethrow the error for the caller to handle
    }
  };

  //////////patch
  const patch = async (url: string, data: any) => {
    if (!token) {
      throw new Error("User is not authenticated");
    }
    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      // Attempt to parse the response as JSON, but handle empty or non-JSON responses gracefully
      let responseData;
      try {
        responseData = await response.json(); // May throw if the response is not JSON or is empty
      } catch (parseError) {
        if (response.ok) {
          // If the response was OK but parsing failed, it could be an empty response
          return null; // Or some default value as appropriate for your application
        } else {
          // If the response was not OK and parsing failed, throw with the status text
          throw new Error(`API request failed: ${response.statusText}`);
        }
      }

      // Check the OK status after attempting to parse the response
      if (!response.ok) {
        console.error("Error response data:", responseData);
        // Include the parsed responseData in the thrown Error, if available
        throw new Error(`API request failed: ${response.statusText}`, responseData);
      }

      return responseData; // Return the parsed response data
    } catch (error) {
      throw error; // Rethrow the error for the caller to handle
    }
  };

  /////////get

  const get = async (url: string) => {
    if (!token) throw new Error("User is not authenticated");

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`API request failed: ${response.statusText}`);
    }

    
    return await response.json();
    
  };

  return { post, get, patch };
};

const YourEditL1Component = () => {

  const { selectedL1, setSelectedL1 } = useContext(OverlayContext);

  const { closeOverlay } = useContext(OverlayContext);

  const navigate = useNavigate();

  const token = useToken(); // Call useToken here at the top level
  const [data, setData] = useState<DirectorDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  const [message, setMessage] = useState("");

  ////////////POST/////////////////
  const { post } = useApi();
  const { get } = useApi();
  const { patch } = useApi();

  const [Id, setSiteId] = useState("");
  const [siteName, setSiteName] = useState("");
  const [siteShortName, setSiteShortName] = useState("");
  const [director, setDirector] = useState("");
  const [directorId, setDirectorId] = useState("");
  const [address, setAddress] = useState("");
  const [telephone, setTelephone] = useState("");
  const [fax, setFax] = useState("");

  const handleSave = async () => {
    const newSiteData = {
      id: selectedL1,
      site_name: siteName,
      site_short_name: siteShortName,
      fax: fax,
      site_telephone: telephone,
      has_site_responsible: directorId,
      address: {
        "street_address": address,
        },
    };

    console.log(JSON.stringify(newSiteData));

    try {
      setIsLoading(true);
      // Call post directly with the object
      const responseData = await patch(URL_LV1_Detailed, newSiteData);

      console.log("Site created successfully:", responseData);
      setMessage("Site created successfully!");
      setIsLoading(false);
      setIsMessageBoxVisible(true);
      //navigate(-2); navigate two pages before
      // Clear the form fields
      setSiteName("");
      setSiteShortName("");
      setDirector("");
      setAddress("");
      setTelephone("");
      setFax("");
      // If you're updating a list in the UI, you might want to add the new site to that list
    } catch (error) {
      
      console.error("Error saving site:", error);
      setMessage(`Error saving site: ${error instanceof Error ? error.message : "Unknown error"}`);
      setIsLoading(false);
      setIsMessageBoxVisible(true);
      // Handle errors, such as by displaying an error message to the user
    }
  };

  //////////////////////////GET ///////

  // useEffect to fetch site details for editing
  useEffect(() => {


    const fetchSiteDetails = async () => {
      if (!token) throw new Error("User is not authenticated");
      //if (!siteId) return; // Do nothing if no site ID is set

      setIsLoading(true);

      try {
  
      const siteDetails = await get(`${URL_LV1_Detailed}/${selectedL1}`);
        
      const directorFullName = `${siteDetails.site_responsible.surname} ${siteDetails.site_responsible.name} `;
      setDirector(directorFullName);
      setSiteId(siteDetails.id);
      setSiteName(siteDetails.site_name); 
      setSiteShortName(siteDetails.site_short_name);
      setDirector(directorFullName); 
      setAddress(siteDetails.address.street_address);
      setTelephone(siteDetails.site_telephone);
      setFax(siteDetails.fax);
      setDirectorId(siteDetails.has_site_responsible); 

        
        // ... set other fields as necessary ...
      } catch (error) {
        console.error("Error fetching site details:", error);
        
        setError(`Error fetching site details: ${error instanceof Error ? error.message : "Unknown error"}`);
      } finally {
        setIsLoading(false);
        
      }
    };

    fetchSiteDetails();
  }, []);

  /////////GET DIRECTORS////////////////

  type DirectorApiContentItem = {
    id: string;
    alias: string;
  };

  type DirectorDataItem = {
    director_id: string;
    director_name: string;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Check for token existence before making the API call
        if (!token) throw new Error("User is not authenticated");

        // Fetch data from the API
        const response = await fetch(URL_DirectorList, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the API call was successful
        if (!response.ok) {
          throw new Error(`API request failed: ${response.statusText}`);
        }

        // Parse the JSON response
        const responseData = await response.json();
        console.log("Response Data:", responseData);
        console.log("Selected L1", selectedL1);

        // Assuming responseData is the array you want to map
        const transformedData: DirectorDataItem[] = responseData.map((item: DirectorApiContentItem) => {
          return {
            director_id: item.id,
            director_name: item.alias, // Changed from 'site_name' to 'name'

            // Map other properties as needed
          };
        });

        console.log("Transformed Data:", transformedData);
        setData(transformedData);
      } catch (err) {
        // Error handling
        setError(err instanceof Error ? err.message : "An unknown error occurred");
        setIsMessageBoxVisible(true);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data on component mount or when token changes
    fetchData();
  }, [token]); // Depend on the token

  ////////////////////

  /////FILTER INPUT DIRECTORS

  // Create a map from director names to IDs
  const directorNameToId = new Map(data.map((item) => [item.director_name, item.director_id]));

  const handleDirectorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDirector(value);

    // If the value is a director name, also set the director ID
    if (directorNameToId.has(value)) {
      setDirectorId(directorNameToId.get(value) || "");
      console.log(directorNameToId.get(value) || "");
    }
  };


  
  ///////////////// MESSAGE CLOSE AND NAVIGATE /////////

  const handleCloseAndNavigate = () => {
    setIsMessageBoxVisible(false); // Close the message box
    closeOverlay();
  };

  ////////////////////TOGGLE OVERLAY///////////

  

  return (
    <OverlayComponentBackgroundAdd1>
      {isLoading && (
        <BackdropTransparent>
          <MessageContainer>
            <MessageboxText>Loading...</MessageboxText>
          </MessageContainer>
        </BackdropTransparent>
      )}

      {isMessageBoxVisible && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{message}</MessageboxText>
            </p>
            <CloseMessageButton onClick={handleCloseAndNavigate}>
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

{error && (
        <Backdrop>
          <MessageContainer>
            <p>
              <MessageboxText>{error}</MessageboxText>
            </p>
            <CloseMessageButton
              onClick={() => {
                setIsMessageBoxVisible(false);
                setError(null);
                closeOverlay();
              }}
            >
              <MessageboxText>Close</MessageboxText>
            </CloseMessageButton>
          </MessageContainer>
        </Backdrop>
      )}

      <FrameTopGradient100 />
      <OverlayComponentWhiteTitleAdd1>NUOVA COMPONENTE ORGANIZZATIVA</OverlayComponentWhiteTitleAdd1>
      <OverlayComponentWhiteAdd1>
        <SubMenuButtonContainerOrganigrammaNoScroll>
          <CreaSubMenuButton to="/Cybersecurity/Organizzazione/Organigramma/ModificaL1">Scheda generale</CreaSubMenuButton>
          {/* <CreaSubMenuButton to="/Cybersecurity/Organizzazione/Organigramma/CreaL1">Unità operative</CreaSubMenuButton>
    <CreaSubMenuButton to="/Cybersecurity/Organizzazione/Organigramma/CreaL1">Ruoli</CreaSubMenuButton>
    <CreaSubMenuButton to="/Cybersecurity/Organizzazione/Organigramma/CreaL1">Personale</CreaSubMenuButton> */}
        </SubMenuButtonContainerOrganigrammaNoScroll>

        <FlexContainerCrea>
          <CreaContainerColumn>
            <InputContainer>
              <InputDescriptionText>Nome completo</InputDescriptionText>
              <InputTextbox placeholder="" value={siteName} onChange={(e) => setSiteName(e.target.value)} />
              <IconContainer />
            </InputContainer>

            <InputContainer>
              <InputDescriptionText>Nome abbreviato</InputDescriptionText>
              <InputTextbox placeholder="" value={siteShortName} onChange={(e) => setSiteShortName(e.target.value)} />
              <IconContainer />
             
            </InputContainer>

            <InputContainer>
                <InputDescriptionText>Direttore</InputDescriptionText>
                
                <InputTextbox list="directors-list" value={director} onChange={handleDirectorChange} placeholder="Seleziona" />
                <datalist id="directors-list">
                  {data.map((item, index) => (
                    <option key={index} value={item.director_name} />
                  ))}
                </datalist>
                <IconContainer />
              </InputContainer>
          </CreaContainerColumn>

          <CreaContainerColumn>
            <InputContainer>
              <InputDescriptionText>Indirizzo sede principale</InputDescriptionText>
              <InputTextbox placeholder="" value={address} onChange={(e) => setAddress(e.target.value)} />
              <IconContainer />
            </InputContainer>

            <InputContainer>
              <InputDescriptionText>Telefono</InputDescriptionText>
              <InputTextbox placeholder="" value={telephone} onChange={(e) => setTelephone(e.target.value)} />
              <IconContainer />
            </InputContainer>
            <InputContainer>
              <InputDescriptionText>Fax</InputDescriptionText>
              <InputTextbox placeholder="" value={fax} onChange={(e) => setFax(e.target.value)} />
              <IconContainer />
            </InputContainer>
          </CreaContainerColumn>
        </FlexContainerCrea>

        <DescriptionContainerCrea>
        <IconSquare src="/crea-L1-schedagenerale.svg" alt="Description Icon" />
              <DescriptionContainerCreaText>
             
              Inserire il nome completo della Componente Organizzativa per garantire un riconoscimento formale, utilizzare un nome abbreviato per facilitare la comunicazione interna, selezionare il Direttore a capo, fornire l'indirizzo completo e aggiungere altri contatti per assicurare una comunicazione efficace e diretta.


              </DescriptionContainerCreaText>

        </DescriptionContainerCrea>
      </OverlayComponentWhiteAdd1>

      <OverlayComponentWhiteButtonFrameAdd1>
        <ButtonGrey onClick={closeOverlay}>
          <ButtonText>Annulla</ButtonText>
        </ButtonGrey>
        <ButtonGreen onClick={handleSave}>
          <ButtonText>Salva</ButtonText>
        </ButtonGreen>
      </OverlayComponentWhiteButtonFrameAdd1>
    </OverlayComponentBackgroundAdd1>
  );
};

export default YourEditL1Component;
